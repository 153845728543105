import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Swiper, SwiperSlide } from "swiper/react";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
//import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import { Lock, Call } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import * as loginActions from "redux/signin/action";
import * as memberActions from "redux/member/action";

import * as yup from "yup";
import TextScroller from "./TextScrolling";
//import { yupResolver } from '@hookform/resolvers'

import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, EffectCoverflow, Pagination]);
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 135,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      mobile: yup.string().required(),
      password: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const submit = ({ mobile, password }) => {
    if (checkbtn == 1) {
      const action = loginActions.Login({ mobile, password, ...props });
      dispatch(action);
    } else {
      const action = memberActions.Save({ mobile, password, ...props });
      dispatch(action);
    }
  };

  useEffect(() => {
    localStorage.removeItem("tokenstatus");
    localStorage.removeItem("Signature");
    localStorage.removeItem("AdminToken");
    localStorage.removeItem("mobile");

    localStorage.clear();
  }, []);
  const dispatch = useDispatch();
  const { isLoading, message, count, show } = useSelector(
    (store) => store.signin
  );

  const {
    isLoading: isLoadingregis,
    message: messageregis,
    datanotify,
  } = useSelector((store) => store.member);

  const [checkbtn, setcheckbtn] = React.useState(1);

  const GoPage = () => {
    //setcheckbtn(2);

    history.push("/signup");
  };

  const GoPageLogin = () => {
    setcheckbtn(1);
  };

  const GoPageForgot = () => {
    history.push("/forgotpass");
  };

  const checkhuay = () => {
    window.location = "http://banhuay44.com/polhuay/index.php";
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const action = loginActions.clearsignin();
    dispatch(action);
  };

  const handleCloseregis = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.GetList();
    dispatch(action);
  };

  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);
  return (
    <>
      <div className="bgheadhome">
        <Container
          maxWidth="lg"
          style={{
            paddingTop: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div>
            <img src="/images/hlogo2.png" />
          </div>
        </Container>
      </div>

      <div className="linelogin">
        <form noValidate onSubmit={handleSubmit(submit)}>
          <div class="homecontainer">
            <div class="pusername">
              {" "}
              <input
                ref={register}
                type="text"
                id="username"
                name="mobile"
                class="form__input_index"
                placeholder="ป้อนเบอร์มือถือ"
                autocomplete="off"
              />
            </div>
            <div class="ppassword">
              {" "}
              <input
                ref={register}
                type="password"
                id="password"
                name="password"
                class="form__input_index"
                placeholder="รหัสผ่าน"
                autocomplete="off"
              />
            </div>
            <div class="pbtnlogin">
              {" "}
              <div className="btnsubmit_home" onClick={submit2}>
                {!isLoading && t("signin.label")}
                {isLoading && (
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            </div>
            <div class="pbtnregister">
              <div className="btnregister_home" onClick={GoPage}>
                <PersonAddIcon className="pp01" />
                <span>สมัครสมาชิก</span>
              </div>
            </div>
            <div class="pbtnforgotpass">
              {" "}
              <div className="btnpass_home" onClick={GoPageForgot}>
                <LockOpenIcon className="pp01" />
                <span>ลืมรหัสผ่าน</span>
              </div>
            </div>
          </div>
          <button type="submit" ref={btnSubmit} className="btnnone"></button>
        </form>
      </div>
      <div className="posttxt">
        {" "}
        <TextScroller text="ยินดีต้อนรับลูกค้าทุกท่าน เว็บเราจ่ายเยอะสุด" />
      </div>
      <Container
        maxWidth="lg"
        style={{
          paddingTop: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Swiper
          //spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="/images/bn011.png" className="mhcasino"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/bn02.png" className="mhcasino"></img>
          </SwiperSlide>
        </Swiper>
      </Container>

      {/*
<Container
        maxWidth="lg"
        style={{
          paddingTop: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <div className="maintop">
          <div className="mpost"></div>
          <div className="mper">
            {" "}
            <div className="btncheck_home1" onClick={GoPageForgot}>
              <span>อัตราจ่าย</span>
            </div>
          </div>
          <div className="mverify">
            {" "}
            <div className="btncheck_home1" onClick={GoPageForgot}>
              <span>กฎกติกา</span>
            </div>
          </div>
          <div className="mcheck">
            {" "}
            <div className="btncheck_home" onClick={checkhuay}>
              <span>เช็คผลหวย</span>
            </div>
          </div>
        </div>
      </Container>
  */}

      <Container
        maxWidth="lg"
        style={{
          paddingTop: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <ul className="preslotto2">
          <li>
            {" "}
            <div className="boxlotto">
              <div className="boxth"></div>
              <div className="txthead">
                หวยไทย <span className="headday">งวดวันที่ 16/09/2564</span>
              </div>
              <div className="showtitle">รางวัลที่ 1</div>
              <div className="shownumber">070935</div>
              <ul className="preslotto">
                <li>
                  {" "}
                  <div className="showtitle">3 ตัวหน้า</div>
                  <div className="shownumber">609, 817</div>
                </li>
                <li>
                  {" "}
                  <div className="showtitle">3 ตัวล่าง</div>
                  <div className="shownumber">379, 007</div>
                </li>
                <li>
                  {" "}
                  <div className="showtitle">2 ตัวล่าง</div>
                  <div className="shownumber">90</div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            {" "}
            <div className="boxlotto">
              <div className="boxaom"></div>
              <div className="txthead">
                หวยออมสิน <span className="headday">งวดวันที่ 16/09/2564</span>
              </div>
              <div className="showtitle">3 ตัวบน</div>
              <div className="shownumber">360</div>
              <div style={{ height: 8 }}></div>
              <div className="showtitle">2 ตัวล่าง</div>
              <div className="shownumber">57</div>
            </div>
          </li>
        </ul>
      </Container>
      <Container
        maxWidth="lg"
        style={{
          paddingTop: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <ul className="preslotto2">
          <li>
            {" "}
            <div className="boxlotto">
              <div className="boxlao"></div>
              <div className="txthead">
                หวยลาว <span className="headday">งวดวันที่ 16/09/2564</span>
              </div>
              <div className="showtitlefix">หวยลาว</div>
              <ul className="preslotto3">
                <li>
                  {" "}
                  <div className="showtitle">3 ตัวบน</div>
                  <div className="shownumber">609</div>
                </li>
                <li>
                  {" "}
                  <div className="showtitle">2 ตัวล่าง</div>
                  <div className="shownumber">45</div>
                </li>
              </ul>

              <div className="showtitlefix">หวยลาวเช้า</div>
              <ul className="preslotto3">
                <li>
                  {" "}
                  <div className="showtitle">3 ตัวบน</div>
                  <div className="shownumber">609</div>
                </li>
                <li>
                  {" "}
                  <div className="showtitle">2 ตัวล่าง</div>
                  <div className="shownumber">45</div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            {" "}
            <div className="boxlotto">
              <div className="boxvn"></div>
              <div className="txthead">
                หวยฮานอย <span className="headday">งวดวันที่ 16/09/2564</span>
              </div>
              <div className="showtitlefix">หวยฮานอย</div>
              <ul className="preslotto3">
                <li>
                  {" "}
                  <div className="showtitle">3 ตัวบน</div>
                  <div className="shownumber">609</div>
                </li>
                <li>
                  {" "}
                  <div className="showtitle">2 ตัวล่าง</div>
                  <div className="shownumber">45</div>
                </li>
              </ul>

              <div className="showtitlefix">หวยฮานอย VIP</div>
              <ul className="preslotto3">
                <li>
                  {" "}
                  <div className="showtitle">3 ตัวบน</div>
                  <div className="shownumber">609</div>
                </li>
                <li>
                  {" "}
                  <div className="showtitle">2 ตัวล่าง</div>
                  <div className="shownumber">45</div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </Container>
      <footer>
        <Container
          maxWidth="lg"
          style={{
            paddingTop: 10,
            //display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            color: "#fff",
          }}
        >
          <div className="footerhead">
            {" "}
            HUAY44 เว็บหวยออนไลน์ ยอดนิยมอันดับ 1
          </div>
          <div>
            ในตอนนี้เส้นทาง หรือช่องทางการเล่นหวยออนไลน์
            ในปัจจุบันนี้กำลังเป็นที่สนใจเป็นอย่างมากของผู้คนเล่นหวย จำนวนมาก
            และประเภทของหวยออนไลน์ในปัจจุบันนี้มีให้เลือกเล่นอยู่มากมายไม่ว่าจะเป็น
            หวยฮานอย ฮานอยวีไอพี ฮานอยพิเศษ และเรายังไม่ได้มีแต่ เว็บหวยไทย
            อย่างเดียว เรายังมี หวยลาว หวยหุ้น
            แม้แต่หวยไทยเราก็บริการให้ทุกท่านได้เลือกเล่นนั้นเอง
            ดังนั้นเรียกได้ว่ามาที่เว็บเดียวท่านก็สามารถแทงหวยออนไลน์
            ได้ทุกประเภทเลยนั้นเอง
          </div>
          <div className="footerhead">
            บริการ หวยฮานอย ฮานอยวีไอพี ฮานอยพิเศษ
          </div>
          <div>
            สำหรับคอหวยทุกท่าน การแทงหวยรัฐบาลอย่างเดียว ยังคงไม่จุใจหลาย
            ๆท่านอย่างแน่นอน เพราะมีการประกาศรางวัลแค่เดืออนละ 2 ครั้ง
            เราจึงเพิ่มโอกาส
            หรือช่องทางการเป็นเศรษฐีของท่านโดยการเปิดให้ทุกท่านได้แทงหวยฮานอย
            หรือหวยประเภทอื่น
            ๆเพราะช่วงเวลาในการประกาศผลรางวัลนั้นเกิดบ่อยกว่าหวยไทย นั้นเอง
            และนอกเหนือไปว่านั้นเรายังเป็นเว็บที่ให้ผลตอบแทนสูงกว่าการซื้อกับเจ้ามือหวย
            และยังไม่เสี่ยงต่อการถูกโกงอย่างแน่นอน
            พร้อมทั้งยังบริการให้กับทุกท่านอย่างไม่มีวันหยุด รับรองได้เลยว่า
            ท่านสามารถแทงหวยกับ HUAY44 ได้ทุกวันอย่างไม่มีวันหยุด HUAY44
            เปิดบริการ หวยลาว หวยหุ้น
            และนอกจากนั้นเรายังมีพนักงานที่คอยให้คำปรึกษาให้แก่ทุกท่าน ตลอด 24
            ชั่วโมง หรือที่สามารถด่วนที่เบอร์ 02-114-7916
            โดยเราจะมีพนักงานที่ค่อยให้การช่วยเหลือ
            ที่มาพร้อมกับวิธีการแก้ปัญหาให้กับเหล่าสมาชิกด้วยความรวดเร็วทันใจ
            เรายังเป็นที่ 1 เล่นหวย แทงหวย ซื้อหวย หวยฮานอย ฮานอยวีไอพี
            ฮานอยพิเศษ หวยลาว หวยหุ้น 2 ตัว 3 ตัว ในเรื่องของการโอนเงิน ถอนเงิน
            ฝากเงิน โดยความรวดเร็วอีกด้วยทำให้ทุกท่านไม่ต้องรอนานนั้นเอง
            มาเล่นหวยกับ HUAY44 กันเถอะ
          </div>
        </Container>
      </footer>
      <Snackbar
        open={show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {t("hloginflase.label")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={datanotify.show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCloseregis}
      >
        <Alert onClose={handleCloseregis} severity="error">
          {t("hlogindub.label")}
        </Alert>
      </Snackbar>
    </>
  );
}
