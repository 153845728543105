import moment from 'moment'
const calDiscountFunc =(values,Amount) => {
    if(values===0){
      return 0
    }else{
      var n = values ? values.split(","):0
       var tt = 0
       var total = 0
       var NewAmount = Amount
       for(var i=0;i<n.length;i++){

        if (n[i].indexOf('%') > -1)
        {
          
          
          var percen = n[i].split("%")
          var pcount = percen.length-1
          //console.log('percen',percen)
          for(var k=0;k<percen.length;k++){

            if(percen[percen.length-1]===''){
              NewAmount = NewAmount - total
              total = NewAmount*percen[k]/100
             tt = tt + total
            }else{
              if(k===pcount){
                NewAmount = NewAmount-total
                total = 1*percen[k]
                tt = tt + total
              }else{
                NewAmount = NewAmount - total
                total = NewAmount*percen[k]/100
               tt = tt + total
              }
            }
          

          }
          
         }else{
            NewAmount = NewAmount-total
            total = 1*n[i]
           tt = tt + total
         }
         
       }
       return parseFloat(tt>0 ? tt:0)
    }
   }


const SumAmount=(state,ID)=>{
  return state.reduce((prev,next) => prev + parseFloat(next[ID] || 0),0)
}

const GetFraction =(state,dataIndex,Fraction,value)=> {
  const { Tax,Price,Value } = state.form
  let DataUse = 0
  if(dataIndex==='TaxAmount'){
      DataUse = parseFloat(Tax)
  }
  if(dataIndex==='PriceAmount'){
      DataUse = parseFloat(Price)
  }
  if(dataIndex==='ValueAmount'){
      DataUse = parseFloat(Value)
  }
  let NewDataValueAmount=0
  if(parseFloat(value)>parseFloat(DataUse)){
           NewDataValueAmount = value-DataUse
           if(NewDataValueAmount>=Fraction){
              NewDataValueAmount = DataUse+Fraction
           }else{
            NewDataValueAmount = value+Fraction
           }
  }else if(parseFloat(DataUse)===parseFloat(value)){
       NewDataValueAmount = parseFloat(DataUse)
  }else{
      NewDataValueAmount = parseFloat(DataUse)-parseFloat(value)
  
    if(NewDataValueAmount>=Fraction){
       NewDataValueAmount = DataUse-Fraction
    }else{
      NewDataValueAmount = value+Fraction
    }
  }
  return parseFloat(NewDataValueAmount)
}


const calAllUpdate =(state,dataIndex,Fraction,value,TaxRate)=> {

  const PerCen7 = TaxRate+100
  const Percen007 = (10*TaxRate)/1000
  const { items,Discount,Price} = state.form

                // กรณีป้อน PriceAmount
                //let PriceAmount = state.form.PriceAmount
                let PriceAmount = SumAmount(items,'PriceAmount')
                if(dataIndex==='PriceAmount'){
                    PriceAmount = GetFraction(state,dataIndex,Fraction,value)
                }

                let TaxType = state.form.TaxType
                // กรณีเลือก ประเภทภาษี
                if(dataIndex==='TaxType'){
                    TaxType = value
                }
                let NewValue = 0
                // กรณีเลือกป้อนส่วนลด
                if(dataIndex==='Discount'){
                    NewValue = value
                }else{
                    NewValue = Discount
                }
               
                    const DiscountAmount = calDiscountFunc(NewValue || 0,PriceAmount)
    
                    // หาค่ารายการเฉพาะที่มีการยกเว้นภาษี
                    const TotalNoVat = items.filter(res=>res.TaxStatus===2)
                    .reduce((prev,next) => prev + next.PriceAmount,0);
    
                    
                    // เปอร์เซ็นมูลค่าสินค้า
                    let PerProduct = 0
                    if(TotalNoVat===0 && PriceAmount===0){
                        PerProduct = 0
                    }else{
                        PerProduct = parseFloat(TotalNoVat/Price)*100 || 0
                    }

                    // หามูลค่าสินค้าและบริการ 1,2
                    // มูลค่าสินค้าหักส่วนลด
                    let PerDiscountProduct = (parseFloat(Price*PerProduct)/100)-(parseFloat(DiscountAmount*PerProduct)/100)

                    // หาภาษีมูลค่าเพิ่ม 1,2
                    let Tax,TaxAmount,BeforeTax,Value,ValueAmount=0
                    
                    // หาค่า ยอดเงินไม่รวมภาษี
                   

                    if(TaxType===2){
                        // เช็คก่อนว่า ValueAmount มีค่าหรือยัง ถ้ามีใช้ค่า ValueAmount ถ้าไม่มีใช้ Value จากสูตรคำนวน
                        // สูตรคำนวน Value อ้างอิงจาก PerDiscountProduct ด้านล่าง
                        Tax = parseFloat(PerDiscountProduct*Percen007)
                         // กรณีเลือกภาษีมูลค่าเพิ่ม

                        if(dataIndex==='TaxAmount'){
                         // เช็คว่าป้อนเกินจำนวน Fraction หรือไม่
                            TaxAmount = GetFraction(state,dataIndex,Fraction,value)
                         }else{
                            TaxAmount = parseFloat(PerDiscountProduct*Percen007)
                         }
  
                        
                    }
                    if(TaxType===3){
                        Tax = (PerDiscountProduct*TaxRate)/PerCen7
                        if(dataIndex==='TaxAmount'){
                            // เช็คว่าป้อนเกินจำนวน Fraction หรือไม่
                            TaxAmount = GetFraction(state,dataIndex,Fraction,value)
                        }else{
                            TaxAmount = (PerDiscountProduct*TaxRate)/PerCen7
                        }
                    }

                    if(TaxType!==2 && TaxType!==3){
                        Tax=0
                        TaxAmount=0
                    }

                    if(TaxType!==3){
                        // กรณีแบบ ไม่มี,แยก,ภาษี 0
                        BeforeTax = PriceAmount - DiscountAmount
                        Value = PerDiscountProduct
                        if(dataIndex==='ValueAmount'){
                            // เช็คว่าป้อนเกินจำนวน Fraction หรือไม่
                            ValueAmount = GetFraction(state,dataIndex,Fraction,value)
                        }else{
                            ValueAmount = PerDiscountProduct
                        }
                        
                    }else{
                        // กรณีแบบรวม
                        Value = (PerDiscountProduct*100)/PerCen7
                        
                        // เช็คว่า ภาษีมูลค่าเพิ่ม 2 มีค่ามั้ย ถ้ามีใช้ TaxAmount 
                        BeforeTax = (PriceAmount - DiscountAmount)-TaxAmount
                        if(dataIndex==='ValueAmount'){
                            // เช็คว่าป้อนเกินจำนวน Fraction หรือไม่
                            ValueAmount = GetFraction(state,dataIndex,Fraction,value)
                        }else{
                            ValueAmount = (PerDiscountProduct*100)/PerCen7
                        }
                    }

                    let Amount = BeforeTax+TaxAmount
                  
                    if(dataIndex==='Del'){
                      return {
                        DiscountAmount,
                        BeforeTax,
                        Tax,
                        TaxAmount,
                        Value,
                        ValueAmount,
                        PriceAmount,
                        Price:PriceAmount,
                        Amount
                      }
                    }else{
                      return {
                        [dataIndex]:value,
                        DiscountAmount,
                        BeforeTax,
                        Tax,
                        TaxAmount,
                        Value,
                        ValueAmount,
                        PriceAmount,
                        Amount
                      }
                    }
                   
}

const AddNewItems =(state)=> {
  
  let FKItem = null
  let FKLabor = null
  let TypeName = null
  const { Items,itemsselect,RowId,FKCostCenter } = state

  var ItemNew = []
  // หาว่าต้องการเพิ่มในแถวที่เท่าไหร่
  const Items2X = Items.findIndex(res => res.No === RowId)

  if (Items2X !== -1) {

    const ItemsAddTotal = itemsselect.length
/*
    let KeyAll = 0
    let IndexCost = 0
    if(parseInt(Items[Items2X].Type)===5){
      const CheckItemsNot = [...Items.slice(0,Items2X)]
      var CountPR = CheckItemsNot.filter(res=>parseInt(res.Type)!==5)
      IndexCost = CountPR.length

    }else{
      // หาตำแหน่งใน Cost ที่มี No ตรงกัน
      
        IndexCost = state.Cost.findIndex(res=>res.ItemsNo===Items[Items2X].No)
      
    }
    
    // ตัดค่าตั้งแต่ 0 - IndexCost
    const BeginCost = state.Cost.splice(0, IndexCost)
    // วนค่าเริ่ม No ใหม่
    const LastCost = state.Cost.map((res,index) => {

      let RunKey = BeginCost.length+(itemsselect.length)*2
      let KeyNo = 1 + index++

      return {
        ...res,
        No: res.ItemsNo + ItemsAddTotal,
        key :res.ItemsNo + ItemsAddTotal,
        children:res.children.map((res,index2)=>{
          //KeyNo = KeyNo+index2
          KeyAll = KeyAll+index2
          return {
            ...res,
            key:RunKey+KeyNo+KeyAll,
            ParentKey:res.ItemsNo + ItemsAddTotal
          }
        })
      }
    })
*/

const NItems = Items.map(res=>({...res}))
    const BeginItem = NItems.splice(0, Items2X)
    const LastItems = NItems.map(res => {
      return {
        ...res,
        No: res.No + ItemsAddTotal,
        key :res.No + ItemsAddTotal
      }
    })
    const CenterItem = itemsselect.map((result, index) => {
      var objItem = state.GetUnit.find((obj => obj.Id === result.FKDefaultunit));
      var i = BeginItem.length + 1 + index
      if(result.Type===2){
        FKItem = result.ItemType==='Boq' ? result.FKItemOld:result.Id
        
      }else if(result.Type===3){
        FKLabor = result.ItemType==='Boq' ? result.FKLaborOld:result.Id
      
      }else if(result.Type===1){
       
      }else{
       
      }
      return {
        ...result,
        Id: 'New',
        No: i,
        key: i
  
        ,Name:result.Name
    ,FKItem:FKItem
    ,FKLabor:FKLabor
    ,Type:result.Type
    ,FKCostCenter
    ,FKBOQWBS :result.FKBOQWBS ? result.FKBOQWBS:null
    ,WBSName:result.WBSName ? result.WBSName:null 
    ,WBSCode:result.WBSCode ? result.WBSCode:null 
    ,Description :result.Name
    ,FKUnit :result.FKDefaultunit
    ,UnitName:objItem ? objItem.Name:null
    ,Buy:0
    ,Withdraw:0
    ,Stock:0
    ,StockQty:result.StockQty
    ,OrderQty :1
    ,Qty :1
    ,PriceUnit :result.Price ? parseFloat(result.Price):0
    ,PriceAmount: parseFloat(1)*parseFloat(result.Price ? result.Price:0)
    ,ReceivingDate:moment(Date.now()).format('DD/MM/YYYY')
    ,Memo:result.Memo
    ,Discount:0
    ,TaxStatus:false
    ,ParentKey:0
      }
    })
    ItemNew.push(...BeginItem, ...CenterItem, ...LastItems)

    return ItemNew
    //const PriceAmount = SumAmount(ItemNew,'PriceAmount')
    //const Cost = CostInsertAdd(CenterItem.filter(res=>parseInt(res.Type)!==5),state.Cost,'ItemsNo',AddRecord,BeginCost,LastCost)
    //this.setState({PriceAmount,Price:PriceAmount,Items:ItemNew,Cost,itemsselect:[],AddIndex:0,ac_tab_footer:''},()=>this.setState({ac_tab_footer:'form'}))
  } else {


    const Addx = itemsselect.map((result, index) => {

      var i = Items.length + 1 + index
      var objItem = state.GetUnit.find((obj => obj.Id === result.FKDefaultunit));
      if(result.Type===2){
        FKItem = result.ItemType==='Boq' ? result.FKItemOld:result.Id
       
      }else if(result.Type===3){
        FKLabor = result.ItemType==='Boq' ? result.FKLaborOld:result.Id
       
      }else{
      
      }
      return {
        ...result,
        Id: 'New',
        No: i,
        key: i
    ,FKItem:FKItem
    ,FKLabor:FKLabor
    ,Type:result.Type
    ,FKCostCenter
    ,FKBOQWBS :result.FKBOQWBS ? result.FKBOQWBS:null
    ,WBSName:result.WBSName ? result.WBSName:null 
    ,WBSCode:result.WBSCode ? result.WBSCode:null 
    ,Description :result.Name
    ,FKUnit :result.FKDefaultunit
    ,UnitName:objItem ? objItem.Name:null
    ,Buy:0
    ,Withdraw:0
    ,Stock:0
    ,StockQty:result.StockQty
    ,OrderQty :1
    ,Qty :1
    ,PriceUnit :result.Price ? parseFloat(result.Price):0
    ,PriceAmount: result.Price ? parseFloat(1)*parseFloat(result.Price ? result.Price:0):0
    ,CostAmount: result.Price ? parseFloat(1)*parseFloat(result.Price ? result.Price:0):0
    ,ReceivingDate:moment(Date.now()).format('DD/MM/YYYY')
    ,Memo:result.Memo
    ,Price:result.Price ? result.Price:0
    ,Discount:0
    ,TaxStatus:false
      }
    })
    let NItem = Items.map(res=>({...res}))
    NItem.push(...Addx)
    //Items.push(...Addx)

    return NItem
    
    //const Cost = CostInsert(Items.filter(res=>parseInt(res.Type)!==5),state.Cost,'ItemsNo')
    //const Cost = CostInsertEdit(Addx.filter(res=>parseInt(res.Type)!==5),state.Cost,'ItemsNo',Items)
    // จัดเรียงอีกรอบ 
    /*
    let NoIndex2 = 0
   let CostLeng = Cost.length
   const NewCost2 = Cost.map((res,index)=>{
    NoIndex2 = ++ index
    
    return {
      ...res,
     key:NoIndex2,
     Qty:res.OrderQty ? res.OrderQty:1,
     CostAmount:res.PriceAmount,
     children:res.children.map((res,index)=>{
      CostLeng = CostLeng+index+NoIndex2
       return {...res,key:CostLeng,ParentKey:NoIndex2}
     })
    }
 })
 */
    // จัดเรียงอีกรอบ 

   // const PriceAmount = SumAmount(Items,'PriceAmount')
   // this.setState({PriceAmount,Price:PriceAmount,Items,Cost:NewCost2,ac_tab_footer:'form'})


  }
 }



   export {
    calDiscountFunc,SumAmount,GetFraction,calAllUpdate,AddNewItems
   }