import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useFetch from "lib/useFetch";

import * as memberActions from "redux/member/action";
import { API_URL, addCommas, lg } from "modules/configApp";

const lgtext = {
  thai:{
    1:'วันที่',
    2:'ชื่อ',
    3:'เบอร์มือถือ',
    4:'ไม่พบข้อมูล'
  },
  lao:{
    1:'ວັນທີ',
    2:'ຊື່',
    3:'ເບີ​ໂທລະ​ສັບ',
    4:'ບໍ່ມີຂໍ້ມູນເທື່ອ'
  }
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eaf4fc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  img: {
    flexGrow: 1,
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  rootbg: {
    backgroundColor: "#171414",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    historyItems,
    meta,
    isLoadingHis: isLoading,
  } = useSelector((store) => store.member);

  const onChange = (e, page) => {
    setPage(page);
  };

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    const actionwithdraw = memberActions.GetMemberAff(page);
    dispatch(actionwithdraw);
  }, [page]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">{lgtext[lg]['1']}</StyledTableCell>
            <StyledTableCell align="left">{lgtext[lg]['2']}</StyledTableCell>
            <StyledTableCell align="left">{lgtext[lg]['3']}</StyledTableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <div className={classes.rootLoading}>
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          {historyItems.map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>{row.dtnow}</StyledTableCell>

              <StyledTableCell style={{ color: "green" }}>
                {row.name}
              </StyledTableCell>
              <StyledTableCell style={{ color: "green" }}>
                {row.mobile}
              </StyledTableCell>
            
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {historyItems.length == 0 && <div className="datano">{lgtext[lg]['4']}</div>}
      <div className={classes.rootpg}>
        {historyItems.length > 0 && (
          <Pagination
            count={meta?.totalPage}
            color="secondary"
            onChange={onChange}
            //siblingCount={0}
          />
        )}
      </div>
    </TableContainer>
  );
}
