import React from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";

import PageList from "./PageList";

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`/logingame`} component={PageList}></Route>
    </Switch>
  );
}
