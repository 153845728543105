import axios from "axios";
import { message } from "antd";
import { API_URL, WEB_TOKEN, ENDPOINT } from "modules/configApp";
import io from "socket.io-client";

const PartAPI = "members";

const MEMBER_REQUEST = "MEMBER/REQUEST";
const MEMBER_SUCCESS = "MEMBER/SUCCESS";
const MEMBER_FAILED = "MEMBER/FAILED";
const MEMBER_SAVE_REQUEST = "MEMBER/SAVE_REQUEST";
const MEMBER_SAVE_SUCCESS = "MEMBER/SAVE_SUCCESS";
const MEMBER_SAVE_FAILED = "MEMBER/SAVE_FAILED";
const MEMBER_DEPOSIT_REQUEST = "MEMBER/DEPOSIT_REQUEST";
const MEMBER_DEPOSIT_SUCCESS = "MEMBER/DEPOSIT_SUCCESS";
const MEMBER_DEPOSIT_SUCCESS_OK = "MEMBER/MEMBER_DEPOSIT_SUCCESS_OK";
const MEMBER_DEPOSIT_FAILED_ALL = "MEMBER/MEMBER_DEPOSIT_FAILED_ALL";
const MEMBER_DEPOSIT_FAILED = "MEMBER/DEPOSIT_FAILED";
const MEMBER_WITHDRAW_REQUEST = "MEMBER/WITHDRAW_REQUEST";
const MEMBER_WITHDRAW_SUCCESS = "MEMBER/WITHDRAW_SUCCESS";
const MEMBER_WITHDRAW_FAILED = "MEMBER/WITHDRAW_FAILED";
const MEMBER_WITHDRAWAGENT_REQUEST = "MEMBER/WITHDRAWAGENT_REQUEST";
const MEMBER_WITHDRAWAGENT_SUCCESS = "MEMBER/WITHDRAWAGENT_SUCCESS";
const MEMBER_WITHDRAWAGENT_FAILED = "MEMBER/WITHDRAWAGENT_FAILED";
const MEMBER_WITHDRAWBONUS_REQUEST = "MEMBER/WITHDRAWBONUS_REQUEST";
const MEMBER_WITHDRAWBONUS_SUCCESS = "MEMBER/WITHDRAWBONUS_SUCCESS";
const MEMBER_WITHDRAWBONUS_FAILED = "MEMBER/WITHDRAWBONUS_FAILED";
const MEMBER_LOGIN_REQUEST = "MEMBER/LOGIN_REQUEST";
const MEMBER_LOGIN_SUCCESS = "MEMBER/LOGIN_SUCCESS";
const MEMBER_LOGIN_FAILED = "MEMBER/LOGIN_FAILED";
const MEMBER_NEWS_REQUEST = "MEMBER/NEWS_REQUEST";
const MEMBER_NEWS_SUCCESS = "MEMBER/NEWS_SUCCESS";
const MEMBER_NEWS_FAILED = "MEMBER/NEWS_FAILED";
const MEMBER_GETCREDIT_REQUEST = "MEMBER/GETCREDIT_REQUEST";
const MEMBER_GETCREDIT_SUCCESS = "MEMBER/GETCREDIT_SUCCESS";
const MEMBER_GETCREDIT_FAILED = "MEMBER/GETCREDIT_FAILED";
const MEMBER_GETDATA_REQUEST = "MEMBER/GETDATA_REQUEST";
const MEMBER_GETDATA_SUCCESS = "MEMBER/GETDATA_SUCCESS";
const MEMBER_GETDATA_FAILED = "MEMBER/GETDATA_FAILED";
const MEMBER_CHECKWITHDRAW_REQUEST = "MEMBER/MEMBER_CHECKWITHDRAW_REQUEST";
const MEMBER_CHECKWITHDRAW_SUCCESS = "MEMBER/MEMBER_CHECKWITHDRAW_SUCCESS";
const MEMBER_CHECKWITHDRAW_FAILED = "MEMBER/MEMBER_CHECKWITHDRAW_FAILED";
const MEMBER_GETWITHDRAW_REQUEST = "MEMBER/GETWITHDRAW_REQUEST";
const MEMBER_GETWITHDRAW_SUCCESS = "MEMBER/GETWITHDRAW_SUCCESS";
const MEMBER_GETWITHDRAW_FAILED = "MEMBER/GETWITHDRAW_FAILED";

export const handleamount = (items) => {
  return (dispatch) => {
    dispatch({
      type: "handleamount",
      payload: { items },
    });
  };
};

export const handlebank = (items) => {
  return (dispatch) => {
    dispatch({
      type: "handlebank",
      payload: { items },
    });
  };
};

export const handleTablotto = (tab) => {
  return (dispatch) => {
    dispatch({
      type: "handleTablotto",
      payload: tab,
    });
  };
};

export const handleBtnHead3 = (tab) => {
  return (dispatch) => {
    dispatch({
      type: "handleBtnHead3",
      payload: tab,
    });
  };
};

export const handleBtnHeadRl3 = (tab) => {
  return (dispatch) => {
    dispatch({
      type: "handleBtnHeadRl3",
      payload: tab,
    });
  };
};

export const handleclearlast = () => {
  return (dispatch) => {
    dispatch({
      type: "handleclearlast",
    });
  };
};

export const handlenumberfix = (tab) => {
  return (dispatch) => {
    dispatch({
      type: "handlenumberfix",
      payload: tab,
    });
  };
};

export const handlecheckbox = (type, numbertext) => {
  return (dispatch) => {
    dispatch({
      type: "handlecheckbox",
      payload: { type, numbertext },
    });
  };
};

export const handletabaction = (items) => {
  return (dispatch) => {
    dispatch({
      type: "handletabaction",
      payload: { items },
    });
  };
};

export const btnclickrate = () => {
  return (dispatch) => {
    dispatch({
      type: "btnclickrate",
    });
  };
};

export const btnclearnumber = () => {
  return (dispatch) => {
    dispatch({
      type: "btnclearnumber",
    });
  };
};

export const addcountter = (number) => {
  return async (dispatch, getState) => {
    const { member } = getState();
    const { btnclickrate } = member;
    if (!btnclickrate) {
      dispatch({
        type: "addcountter",
        payload: number,
      });
    } else {
      dispatch({
        type: "freestate",
      });
    }
  };
};
export const hidelottonotify = () => {
  return (dispatch) => {
    dispatch({
      type: "hidelottonotify",
    });
  };
};

export const handleremoveall = () => {
  return (dispatch) => {
    dispatch({
      type: "handleremoveall",
    });
  };
};

export const handlelottoRate = (item) => {
  return (dispatch) => {
    dispatch({
      type: "handlelottoRate",
      payload: { item },
    });
  };
};
export const delhandlelottoRate = (item) => {
  return (dispatch) => {
    dispatch({
      type: "delhandlelottoRate",
      payload: { item },
    });
  };
};
/*export const handlelineid = (lineid) => {
  return (dispatch) => {
    dispatch({
      type: "handlelineid",
      payload: { lineid },
    });
  };
};*/
export const handlelineid = ({ photo,name,lineid},history,pathurl) => {
  return async (dispatch, getState) => {
   // const { signin } = getState();
   // const { items } = signin;
    //dispatch({ type: "handlelineid" });
    try {
      const {
        data: { code,item },
      } = await axios({
        method: "post",
        url: `${API_URL}/huayauto/checkcreate`,
        data: {
          name,
          lineid,
          WEB_TOKEN,
        },
      });

      if (code === 200) {
        dispatch({
          type:  "handlelineid",
          payload: {lineid, photo} ,
        });
       //history.push("/play/lotto")
       //history.push(pathurl)
     
       if(!item.accno && pathurl ==='/withdraw'){
        history.push('/registerconfirm')
       }else{
        history.push(pathurl)
       }
       
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          window.location = "/";
        }

        if (status === 433) {
          dispatch({
            type: MEMBER_CHANGEPASS_FAILED,
            payload: { errorchangepass: true },
          });
        }
      }
    }
  };
};
export const SaveBill = (huay_sub,lineid,history) => {
  return async (dispatch, getState) => {
    const { signin,member } = getState();
    const { items } = signin;
    const { betlist3t,
      betlist3tod,
      betlist3f,
      betlist3l,
      betlist2t,
      betlist2l,
      betlistrt,
      betlistrl,
    huay_member,
  huay_master } = member

    const newbetlist3t = betlist3t.map(x=>({active:x.active,no:x.number,type:'t3',amount:parseFloat(x.amount)}))
    const newbetlist3tod = betlist3tod.map(x=>({active:x.active,no:x.number,type:'tod3',amount:parseFloat(x.amount)}))
    const newbetlist3f = betlist3f.map(x=>({active:x.active,no:x.number,type:'f3',amount:parseFloat(x.amount)}))
    const newbetlist3l = betlist3l.map(x=>({active:x.active,no:x.number,type:'l3',amount:parseFloat(x.amount)}))
    const newbetlist2t = betlist2t.map(x=>({active:x.active,no:x.number,type:'t2',amount:parseFloat(x.amount)}))
    const newbetlist2l = betlist2l.map(x=>({active:x.active,no:x.number,type:'l2',amount:parseFloat(x.amount)}))
    const newbetlistrt = betlistrt.map(x=>({active:x.active,no:x.number,type:'rt',amount:parseFloat(x.amount)}))
    const newbetlistrl = betlistrl.map(x=>({active:x.active,no:x.number,type:'rl',amount:parseFloat(x.amount)}))
    const newitems = [...newbetlistrl,...newbetlistrt,...newbetlist2l,...newbetlist2t,...newbetlist3t,...newbetlist3f,...newbetlist3tod,...newbetlist3l]
    const checkaction = newitems.filter(x=>x.active == 0)
  
    if(checkaction.length > 0) {
      dispatch({ type: "LOAD_SaveBill_Error" });
    } else {
      dispatch({ type: "LOAD_SaveBill" });
      try {
        const {
          data: {  datas, code },
        } = await axios({
          method: "post",
          url: `${API_URL}/huayauto/huay_bill`,
          data: {items:newitems,huay_member,huay_master,huay_sub,WEB_TOKEN,lineid},
        });
        if (code === 200) {
          dispatch({ type: "LOAD_SaveBill_SUCCESS", payload: { item:datas } });
          history.push('/play/payment')
        } else {
          dispatch({ type: "LOAD_SaveBill_FAILED" });
        }
      } catch (err) {
        const { response } = err;
        // token หมดอายุ
        if (response.status === 401) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          window.location = "/";
        } else {
          const { status } = response?.data?.error;
          // token หมดอายุ
          if (status === 422) {
            localStorage.removeItem("tokenstatus");
            localStorage.clear();
            //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
            //dispatch({ type: SIGNOUT_SUCCESS });
            //push("/signin");
            window.location = "/";
          }
          if (status === 433) {
            dispatch({ type: "LOAD_SaveBill_FAILED" });
          }
        }
      }
    }
   
  };
};

const clearNotify = () => {
  return (dispatch) => {
    dispatch({
      type: MEMBER_DEPOSIT_FAILED_ALL,
      payload: false,
    });
  };
};
const GetDataNew = () => {
  return (dispatch) => {
    dispatch({ type: MEMBER_NEWS_REQUEST });
    try {
      setTimeout(() => dispatch({ type: MEMBER_NEWS_SUCCESS }), 100);
    } catch (err) {
      dispatch({ type: MEMBER_NEWS_FAILED });
    }
  };
};

const MEMBER_GETACC_REQUEST = "MEMBER/MEMBER_GETACC_REQUEST";
const MEMBER_GETACC_SUCCESS = "MEMBER/MEMBER_GETACC_SUCCESS";
const MEMBER_GETACC_FAILED = "MEMBER/MEMBER_GETACC_FAILED";
const GetAccount = () => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GETACC_REQUEST });
    try {
      const {
        data: { item, itemotp, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/members/getmemberaccount`,
        data: null,
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        dispatch({ type: MEMBER_GETACC_SUCCESS, payload: { item, itemotp } });
      } else {
        dispatch({ type: MEMBER_GETACC_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};


export const MEMBER_GETCREBALANCE_REQUEST = "MEMBER/MEMBER_GETCREBALANCE_REQUEST";
export const MEMBER_GETCREBALANCE_SUCCESS = "MEMBER/MEMBER_GETCREBALANCE_SUCCESS";
export const MEMBER_GETCREBALANCE_FAILED = "MEMBER/MEMBER_GETCREBALANCE_FAILED";
export const GetCreditBalance = (page) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GETCREBALANCE_REQUEST });
    try {
      const {
        data: { itemsmember,itemsmembercount,totalPage},
      } = await axios({
        method: "get",
        url: `${API_URL}/members/memberbalancedtl`,
        params: {
          page
        },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      dispatch({ type: MEMBER_GETCREBALANCE_SUCCESS, payload: { totalPage,items:itemsmember,itemsmembercount } });
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

//memberbalancedtl

const MEMBER_CHAGRATE_REQUEST = "MEMBER/MEMBER_CHAGRATE_REQUEST";
const MEMBER_CHAGRATE_SUCCESS = "MEMBER/MEMBER_CHAGRATE_SUCCESS";
const MEMBER_CHAGRATE_FAILED = "MEMBER/MEMBER_CHAGRATE_FAILED";
const changerate = (type) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_CHAGRATE_REQUEST });
    try {
      const {
        data: { code },
      } = await axios({
        method: "get",
        url: `${API_URL}/huay/huay_member_changerate/${type}`,
        data: null,
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        dispatch({ type: MEMBER_CHAGRATE_SUCCESS,  });
        window.location.reload()
      } else {
        dispatch({ type: MEMBER_CHAGRATE_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

export const MEMBER_GETLOTTO_REQUEST = "MEMBER/MEMBER_GETLOTTO_REQUEST";
export const MEMBER_GETLOTTO_SUCCESS = "MEMBER/MEMBER_GETLOTTO_SUCCESS";
export const MEMBER_GETLOTTO_FAILED = "MEMBER/MEMBER_GETLOTTO_FAILED";
export const GetLotto = (id,lineid) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GETLOTTO_REQUEST });
   
    try {
      const {
        data: { lottotypeitems, code,bgphoto,huaytitle,huaytitle_th,huay_master,rewards,huay_member },
      } = await axios({
        method: "get",
        url: `${API_URL}/huayauto/${id}/lottolist`,
        params: { lineid, WEB_TOKEN },
      });
      if (code === 200) {
        dispatch({ type: MEMBER_GETLOTTO_SUCCESS, payload: { lottotypeitems,bgphoto,huaytitle,huay_master,huaytitle_th,huay_member,rewards } });
      } else {
        dispatch({ type: MEMBER_GETLOTTO_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

const MEMBER_GAMEALL_REQUEST = "MEMBER/MEMBER_GAMEALL_REQUEST";
const MEMBER_GAMEALL_SUCCESS = "MEMBER/MEMBER_GAMEALL_SUCCESS";
const MEMBER_GAMEALL_FAILED = "MEMBER/MEMBER_GAMEALL_FAILED";
const GameAllList = (type,lineid) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMEALL_REQUEST });
    try {
      const {
        data: { items: item, code },
      } = await axios({
        method: "get",
        url: `https://apiadminbetg.com/members/gamelist?webtoken=sswinbet`,
        params: { type: type,  lineid,
          WEB_TOKEN },
        data: {
          lineid,
          WEB_TOKEN
        },
      });
      if (code === 200) {
        dispatch({ type: MEMBER_GAMEALL_SUCCESS, payload: { item } });
      } else {
        dispatch({ type: MEMBER_GAMEALL_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

export const GameHuayList = (type) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMEALL_REQUEST });
    try {
      const {
        data: { items: item, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/huayauto/huay_last`,
        params: { type: type },
      });
      if (code === 200) {
        dispatch({ type: MEMBER_GAMEALL_SUCCESS, payload: { item } });
      } else {
        dispatch({ type: MEMBER_GAMEALL_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          window.location = "/";
        }
      }
    }
  };
};

export const GameHuayResult = (datenow) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMEALL_REQUEST });
    try {
      const {
        data: { items: item, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/huay/huay_award/${datenow}`,
     
      });
      if (code === 200) {
        dispatch({ type: "MEMBER_GAMERESULT_SUCCESS", payload: { item } });
      } else {
        dispatch({ type: MEMBER_GAMEALL_FAILED });
      }
    } catch (err) {
      const { response } = err;
     
    }
  };
};

export const GameHuayBillHistory = (status_run,lineid) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMEALL_REQUEST });
    try {
      const {
        data: { items: item, code,totalall,maketing },
      } = await axios({
        method: "get",
        url: `${API_URL}/huayauto/huay_bill_history/`,
        params: { status_run: status_run,lineid: lineid, WEB_TOKEN  },
      });
      if (code === 200) {
        dispatch({ type: "MEMBER_HUAYHISTORY_SUCCESS", payload: { item,totalall,maketing } });
      } else {
        dispatch({ type: MEMBER_GAMEALL_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

export const GetHuayBank = (lineid) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMEALL_REQUEST });
    try {
      const {
        data: { item },
      } = await axios({
        method: "get",
        url: `${API_URL}/huayauto/getbank`,
        params: { lineid: lineid, WEB_TOKEN  },
      });
      dispatch({ type: "MEMBER_HUAYBANK_SUCCESS", payload: { item } });
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

export const GameHuayBillHistoryDetail = (id) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMEALL_REQUEST });
    try {
      const {
        data: { item, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/huayauto/huay_bill/show/${id}`,
        //params: { type: type },
      });
      if (code === 200) {
        dispatch({ type: "MEMBER_HUAYHISTORYDETAIL_SUCCESS", payload: { item } });
      } else {
        dispatch({ type: MEMBER_GAMEALL_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

export const HuayBillCancel = (id) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMEALL_REQUEST });
    try {
      const {
        data: { item, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/huay/huay_bill_cancel/${id}`,
        //params: { type: type },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        window.location.reload()
      } else {
        dispatch({ type: MEMBER_GAMEALL_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }

        if (status === 435) {
            alert('ມົດເວລາຍົກເລີກໂພຍ')
            window.location.reload()
        }

      }
    }
  };
};

//huay_bill_cancel

//huay_bill_history

const MEMBER_GAMELIST_REQUEST = "MEMBER/MEMBER_GAMELIST_REQUEST";
const MEMBER_GAMELIST_SUCCESS = "MEMBER/MEMBER_GAMELIST_SUCCESS";
const MEMBER_GAMELIST_FAILED = "MEMBER/MEMBER_GAMELIST_FAILED";
const GameEvoList = (gamelist) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMELIST_REQUEST });
    try {
      const {
        data: { items: item, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/bet/1/${gamelist}`,
        data: null,
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        dispatch({ type: MEMBER_GAMELIST_SUCCESS, payload: { item } });
      } else {
        dispatch({ type: MEMBER_GAMELIST_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

const MEMBER_GAMELOGIN_REQUEST = "MEMBER/MEMBER_GAMELOGIN_REQUEST";
const MEMBER_GAMELOGIN_SUCCESS = "MEMBER/MEMBER_GAMELOGIN_SUCCESS";
const MEMBER_GAMELOGIN_FAILED = "MEMBER/MEMBER_GAMELOGIN_FAILED";
const GameEvoLogin = (game) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GAMELOGIN_REQUEST });
    try {
      const {
        data: { link, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/bet/1/logingame`,
        params: { game: game },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        dispatch({ type: MEMBER_GAMELOGIN_SUCCESS, payload: { link } });
        //window.open(link);
      } else {
        dispatch({ type: MEMBER_GAMELOGIN_FAILED });
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

//gamelist

const MEMBER_CHANGEPASS_REQUEST = "MEMBER/MEMBER_CHANGEPASS_REQUEST";
const MEMBER_CHANGEPASS_SUCCESS = "MEMBER/MEMBER_CHANGEPASS_SUCCESS";
const MEMBER_CHANGEPASS_FAILED = "MEMBER/MEMBER_CHANGEPASS_FAILED";

const ChangePassword = ({ password, repassword, history }) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_CINFIRM_REQUEST });
    try {
      const {
        data: { code },
      } = await axios({
        method: "post",
        url: `${API_URL}/${PartAPI}/changepassword`,
        data: {
          password,
          newpassword: repassword,
        },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      if (code === 200) {
        dispatch({
          type: MEMBER_CHANGEPASS_SUCCESS,
          payload: { changepass: true },
        });
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          window.location = "/";
        }

        if (status === 433) {
          dispatch({
            type: MEMBER_CHANGEPASS_FAILED,
            payload: { errorchangepass: true },
          });
        }
      }
    }
  };
};

const MEMBER_SAVEACC_REQUEST = "MEMBER/MEMBER_SAVEACC_REQUEST";
const MEMBER_SAVEACC_SUCCESS = "MEMBER/MEMBER_SAVEACC_SUCCESS";
const MEMBER_SAVEACC_FAILED = "MEMBER/MEMBER_SAVEACC_FAILED";

const SaveAccount = ({ accno, accname, accbank, history }) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_SAVEACC_REQUEST });
    try {
      const {
        data: { code, item },
      } = await axios({
        method: "post",
        url: `${API_URL}/${PartAPI}/addaccount`,
        data: {
          accno,
          accname,
          accbank,
        },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      if (code === 200) {
        dispatch({
          type: MEMBER_SAVEACC_SUCCESS,
          payload: item,
        });
        history.push("/dashboard/confirmotp");
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          window.location = "/";
        }

        if (status === 433) {
          dispatch({
            type: MEMBER_OTPACC_FAILED,
            payload: { useacc: true, useotp: false },
          });
        }
      }
    }
  };
};

const MEMBER_OTPACC_REQUEST = "MEMBER/MEMBER_OTPACC_REQUEST";
const MEMBER_OTPACC_SUCCESS = "MEMBER/MEMBER_OTPACC_SUCCESS";
const MEMBER_OTPACC_FAILED = "MEMBER/MEMBER_OTPACC_FAILED";

const ConfirmOTPAccount = ({ smsref, smsotp, history }) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_OTPACC_REQUEST });
    try {
      const {
        data: { code, item },
      } = await axios({
        method: "post",
        url: `${API_URL}/${PartAPI}/confirmotpaccount`,
        data: {
          smsref,
          smsotp,
        },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      if (code === 200) {
        dispatch({
          type: MEMBER_OTPACC_SUCCESS,
          payload: item,
        });
        history.push("/dashboard");
      }
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          window.location = "/";
        }

        if (status === 433) {
          dispatch({
            type: MEMBER_OTPACC_FAILED,
            payload: { useacc: false, useotp: true },
          });
        }
      }
    }
  };
};

const GetCredit = ({lineid}) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_REQUEST });
    try {
      const {
        data: { item, profile },
      } = await axios({
        method: "post",
        url: `${API_URL}/huayauto/checkcredit`,
        data: {
          WEB_TOKEN,lineid
        },
      });

      dispatch({ type: MEMBER_SUCCESS, payload: { item, profile } });
    } catch (err) {
      const { response } = err;

      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          //const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
          //dispatch({ type: SIGNOUT_SUCCESS });
          //push("/signin");
          window.location = "/";
        }
      }
    }
  };
};

const GetList = () => {
  return async (dispatch) => {
    dispatch({ type: MEMBER_REQUEST });
    try {
      dispatch({ type: MEMBER_SUCCESS, payload: [] });
    } catch (err) {
      dispatch({ type: MEMBER_FAILED });
    }
  };
};

const ForgotPassword = ({ UserName, history }) => {
  return async (dispatch) => {
    dispatch({ type: MEMBER_SAVE_REQUEST });
    try {
      const {
        data: { code },
      } = await axios({
        method: "post",
        url: `${API_URL}/${PartAPI}/forgotpassword`,
        data: {
          mobile: UserName,
        },
      });

      if (code === 200) {
        dispatch({
          type: MEMBER_SAVE_SUCCESS,
        });
        history.push("/signin");
      } else {
        message.error("ข้อมูลซ้ำกัน กรุณาป้อนใหม่");
        dispatch({ type: MEMBER_SAVE_FAILED, payload: "" });
      }
    } catch (err) {
      const { response } = err;
      const { status } = response?.data?.error;

      if (status === 422) {
        // OTP หมดอายุ
        dispatch({
          type: MEMBER_SAVE_FAILED,
          payload: {
            show: true,
            message: response?.data?.error?.message,
          },
        });
      }
    }
  };
};

const Save = ({ mobile, password, refurl, memberaff, history }) => {
  return async (dispatch) => {
    dispatch({ type: MEMBER_SAVE_REQUEST });
    try {
      const {
        data: { smsref, updatedAt, mtimestamp, code },
      } = await axios({
        method: "post",
        url: `${API_URL}/${PartAPI}/sendotp`,
        data: {
          mobile: mobile,
          password: password,
          memberaff,
          refurl,
          WEB_TOKEN,
        },
      });

      if (code === 200) {
        localStorage.setItem("smsref", smsref);
        localStorage.setItem("updatedAt", updatedAt);
        localStorage.setItem("mtimestamp", mtimestamp);
        localStorage.setItem("mobile", mobile);
        dispatch({
          type: MEMBER_SAVE_SUCCESS,
          payload: { smsref, updatedAt, mtimestamp, mobile: mobile },
        });
        //history.push("/otp");
        history.push("/registerconfirm");
      } else {
        message.error("ข้อมูลซ้ำกัน กรุณาป้อนใหม่");
        dispatch({ type: MEMBER_SAVE_FAILED, payload: "" });
      }
    } catch (err) {
      const { response } = err;
      const { status } = response?.data?.error;
      localStorage.setItem("mobile", mobile);
      if (status === 423) {
        // ยืนยัน OTP แล้ว
        dispatch({
          type: MEMBER_SAVE_FAILED,
          payload: {},
        });
        history.push("/registerconfirm");
      }

      if (status === 421) {
        // เบอซ้ำ
        dispatch({
          type: MEMBER_SAVE_FAILED,
          payload: {
            show: true,
            message: response?.data?.error?.message,
          },
        });
      }

      if (status === 422) {
        // OTP หมดอายุ
        dispatch({
          type: MEMBER_SAVE_FAILED,
          payload: {
            show: true,
            message: response?.data?.error?.message,
          },
        });
      }

      if (status === 401) {
        // OTP ยังไม่หมดอายุ
        dispatch({
          type: MEMBER_SAVE_FAILED,
          payload: {
            //show: true,
            message: response?.data?.error?.message,
          },
        });
        history.push("/otp");
      }
      if (status === 411) {
        // OTP หมดอายุ แล้วส่ง OTP อีกรอบ
        console.log("หมดอายุ");
        localStorage.setItem(
          "smsref",
          response?.data?.error?.validation[0].smsref
        );
        localStorage.setItem(
          "updatedAt",
          response?.data?.error?.validation[0].updatedAt
        );
        localStorage.setItem(
          "mtimestamp",
          response?.data?.error?.validation[0].mtimestamp
        );
        dispatch({
          type: MEMBER_SAVE_FAILED,
          payload: {
            message: response?.data?.error?.message,
          },
        });
        history.push("/otp");
      }
    }
  };
};

// ยกเลิกการฝาก
const MEMBER_CANCELBOOKING_SUCCESS = "MEMBER_CANCELBOOKING_SUCCESS";
const CancelBooking = () => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_CINFIRM_REQUEST });
    try {
      const {
        data: { item, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/booking/1/cancel`,
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        localStorage.removeItem("updatedAt");
        localStorage.removeItem("mtimestamp");
        dispatch({
          type: MEMBER_CANCELBOOKING_SUCCESS,
          payload: { ...item },
        });
      }
    } catch (err) {
      const { response } = err;

      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;

        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: MEMBER_DEPOSIT_FAILED,
            payload: false,
          });
        }
      }
    }
  };
};

// ยกเลิกการฝาก
const MEMBER_DELBOOKING_SUCCESS = "MEMBER_DELBOOKING_SUCCESS";
const DelBooking = () => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_CINFIRM_REQUEST });
    try {
      const {
        data: { code, item },
      } = await axios({
        method: "get",
        url: `${API_URL}/booking/1/delbooking`,
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        localStorage.removeItem("updatedAt");
        localStorage.removeItem("mtimestamp");
        dispatch({
          type: MEMBER_DELBOOKING_SUCCESS,
          payload: { ...item },
        });
      }
    } catch (err) {
      const { response } = err;

      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;

        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: MEMBER_DEPOSIT_FAILED,
            payload: false,
          });
        }
      }
    }
  };
};

const MEMBER_CHECKBOOKING_REQUEST = "MEMBER_CHECKBOOKING_REQUEST";
const MEMBER_CHECKBOOKING_SUCCESS = "MEMBER_CHECKBOOKING_SUCCESS";
//const MEMBER_CHECKBOOKING_FAILED = "MEMBER_CHECKBOOKING_FAILED";
const MEMBER_CHECKBOOKING_SUCCESS_FAILED = "MEMBER_CHECKBOOKING_SUCCESS_FAILED";
const GetBooking = () => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_CHECKBOOKING_REQUEST });
    try {
      const {
        data: { item, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/booking/1/check`,
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        localStorage.setItem("updatedAt", item.updatedAt);
        localStorage.setItem("mtimestamp", item.mtimestamp);
        dispatch({
          type: MEMBER_CHECKBOOKING_SUCCESS,
          payload: { ...item, show: true },
        });
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status, validation } = response?.data?.error;

        if (status === 402) {
          // มีรอยืนยัน
          const {
            amount,
            mtimestamp,
            bankname,
            accno,
            accname,
            name,
            updatedAt,
          } = validation[0];
          dispatch({
            type: MEMBER_CHECKBOOKING_SUCCESS_FAILED,
            payload: {
              amount: amount,
              mtimestamp: mtimestamp,
              accno,
              accname,
              name,
              bankname,
              updatedAt,
            },
          });
        }

        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: MEMBER_DEPOSIT_FAILED,
            payload: false,
          });
        }
      }
    }
  };
};

const CheckWithDraw = () => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_CHECKWITHDRAW_REQUEST });
    try {
      const {
        data: { items: itemsdata, code },
      } = await axios({
        method: "get",
        url: `${API_URL}/booking/1/checkwithdraw`,
        data: {},
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        dispatch({
          type: MEMBER_CHECKWITHDRAW_SUCCESS,
          payload: itemsdata,
        });
      }
    } catch (err) {
      const { response } = err;

      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: MEMBER_CHECKWITHDRAW_FAILED,
            payload: false,
          });
        }
      }
    }
  };
};

//booking/confirm
const MEMBER_CINFIRM_REQUEST = "MEMBER_CINFIRM_REQUEST";
const MEMBER_CINFIRM_SUCCESS = "MEMBER_CINFIRM_SUCCESS";
const BookingConfirm = ({ slipphoto,slip,history }) => {
  return async (dispatch, getState) => {
    const { ui } = getState();
    const { socket } = ui;

    dispatch({ type: MEMBER_CINFIRM_REQUEST });
    try {
      const {
        data: { code, item },
      } = await axios({
        method: "post",
        url: `${API_URL}/booking/confirm`,
        data: {
          member: "888",
          slipphoto,slip
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
        },
      });

      // มียอด
      if (code === 205) {
        dispatch({
          type: MEMBER_DEPOSIT_SUCCESS_OK,
          payload: { show: true },
        });
        //history.push("/dashboard");
      }

      // รอยืนยันยอด
      if (code === 200 || code === 201) {
        const ndata = { deposit: 1 };
        console.log("item", item);
        socket.emit("sent-message", ndata);
        socket.emit("sentdeposit", item?.bankname);
        dispatch({
          type: MEMBER_CINFIRM_SUCCESS,
          payload: { show: true },
        });
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        if (status === 423) {
          // ยืนยัน OTP แล้ว
          dispatch({
            type: MEMBER_DEPOSIT_FAILED,
            payload: {},
          });
          history.push("/registerconfirm");
        }
      }
    }
  };
};

export const BookingConfirmNew = ({ items,bankname,bank,lineid,slipphoto,slip,history,maketing }) => {
  return async (dispatch, getState) => {
    const { ui } = getState();
    const { socket } = ui;
   
    dispatch({ type: MEMBER_CINFIRM_REQUEST });
    try {
      const {
        data: { item },
      } = await axios({
        method: "post",
        url: `${API_URL}/huayauto/confirm`,
        data: {
          member: "888",
          slipphoto,slip,items,lineid,bankname,maketing,bank,WEB_TOKEN
        },
      });

      // มียอด
      /*
      if (code === 205) {
        dispatch({
          type: MEMBER_DEPOSIT_SUCCESS_OK,
          payload: { show: true },
        });
        //history.push("/dashboard");
      }
      */

      // รอยืนยันยอด
    //  if (code === 200 || code === 201) {
        const ndata = { deposit: 1, token:WEB_TOKEN };
       
        const socket = io(ENDPOINT);
       // socket.emit("sent-message", ndata);
        socket.emit("sentchat", ndata);
       // socket.emit("sentdeposit", item?.bankname);
       // dispatch({
       //   type: MEMBER_CINFIRM_SUCCESS,
       //   payload: { show: true },
       // });
     // }
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        if (status === 423) {
          // ยืนยัน OTP แล้ว
          dispatch({
            type: MEMBER_DEPOSIT_FAILED,
            payload: {},
          });
          history.push("/registerconfirm");
        }
      }
    }
  };
};

// จองยอดฝาก
const Booking = ({ credit, promotion, lineid, history }) => {
  return async (dispatch) => {
    dispatch({ type: MEMBER_DEPOSIT_REQUEST });
    try {
      const {
        data: { item, updatedAt, mtimestamp, code },
      } = await axios({
        method: "post",
        url: `https://apiadminbetg.com/statement/createqrcodeline`,
        data: {
          amount: credit,
          promotion,
          lineid,
          webtoken:'sswinbet'
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
        },
      });

      if (code === 200) {
        localStorage.setItem("updatedAt", updatedAt);
        localStorage.setItem("mtimestamp", mtimestamp);
        dispatch({
          type: MEMBER_DEPOSIT_SUCCESS,
          payload: { updatedAt, mtimestamp, ...item },
        });
      } else {
        message.error("ข้อมูลซ้ำกัน กรุณาป้อนใหม่");
        dispatch({ type: MEMBER_DEPOSIT_FAILED, payload: "" });
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        if (status === 402) {
          // มีรอยืนยัน
          dispatch({
            type: MEMBER_DEPOSIT_FAILED,
            payload: {},
          });
        }
        if (status === 405) {
          // มีรอยืนยัน
          dispatch({
            type: "MEMBER_DEPOSIT_FAILED_BIGPAY",
            payload: {},
          });
        }
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          window.location = "/";
        }
      }
    }
  };
};

const ConfirmOTP = ({ UserName, Password, smsref, history }) => {
  return async (dispatch) => {
    dispatch({ type: MEMBER_SAVE_REQUEST });
    try {
      const {
        data: { msg, code },
      } = await axios({
        method: "post",
        url: `${API_URL}/${PartAPI}/confirmotp`,
        data: {
          mobile: UserName,
          smsotp: Password,
          smsref,
        },
      });

      if (code === 200) {
        dispatch({ type: MEMBER_SAVE_SUCCESS, payload: msg });
        history.push("/registerconfirm");
      } else {
        message.error("ข้อมูลซ้ำกัน กรุณาป้อนใหม่");
        dispatch({ type: MEMBER_SAVE_FAILED, payload: "" });
      }
    } catch (err) {
      const { response } = err;
      const { status } = response?.data?.error;
      if (status === 422) {
        // ป้อน OTP ไม่ถูกต้อง
        dispatch({
          type: MEMBER_SAVE_FAILED,
          payload: {
            showotp: true,
            message: response?.data?.error?.message,
          },
        });
      }
    }
  };
};

const SIGNIN_SUCCESS = "SIGNIN/SUCCESS";

const ConfirmRegister = ({ mobile, accno, accname, accbank, history }) => {
  return async (dispatch, getState) => {
    const { member } = getState();
    const { lineid } = member
    dispatch({ type: MEMBER_SAVE_REQUEST });
    try {
      const {
        data: { code, item },
      } = await axios({
        method: "post",
        url: `${API_URL}/huayauto/updatebank`,
        data: {
          accno,
          accname,
          accbank,
          mobile,
          lineid,
          WEB_TOKEN
        },
      });

      if (code === 200) {
        dispatch({
          type: 'MEMBER_ADDBANK_SUCCESS',
          payload: { item },
        });
       
        history.push("/withdraw");
      } else {
        message.error("ข้อมูลซ้ำกัน กรุณาป้อนใหม่");
        dispatch({ type: MEMBER_SAVE_FAILED, payload: "" });
      }
    } catch (err) {
      const { response } = err;
      const { status } = response?.data?.error;

      if (status === 422) {
        //message.error("ข้อมูลซ้ำกัน กรุณาป้อนใหม่");
        //alert("ขออภัยข้อมูลบัญชีซ้ำกัน กรุณาป้อนใหม่ค่ะ");
        // OTP หมดอายุ
        dispatch({
          type: "MEMBER_SAVE_FAILED_ACC",
          payload: {
            notity: 0,
          },
        });
      }
    }
  };
};

const WithdrawAgent = ({ Amount, history }) => {
  return async (dispatch, getState) => {
    const { ui,member } = getState();
   
    const { lineid } = member
    dispatch({ type: MEMBER_WITHDRAW_REQUEST });
    try {
      const {
        data: { code, item },
      } = await axios({
        method: "post",
        url: `${API_URL}/huayauto/withdrawhuay`, // สำหรับ หวยลาว
        data: {
          amount: parseFloat(Amount),
          lineid,
          WEB_TOKEN
        }
      });

      if (code === 200) {
        const ndata = { withdraw: 1, token:WEB_TOKEN };
       // socket.emit("sent-message", ndata);
        //socket.emit("sentchat", ndata);
        const socket = io(ENDPOINT);
        // socket.emit("sent-message", ndata);
         socket.emit("sentchat", ndata);
       // socket.emit("sentwithdraw", 1);
      
        dispatch({
          type: MEMBER_WITHDRAW_SUCCESS,
          payload: { show: true, credit: item.after_credit, Amount },
        });
        
      } else {
        message.error("ข้อมูลซ้ำกัน กรุณาป้อนใหม่");
        dispatch({ type: MEMBER_DEPOSIT_FAILED, payload: "" });
      }
    } catch (err) {
      const { response } = err;
     
    }
  };
};
const MEMBER_WITHDRAWMONEY_REQUEST = "MEMBER/WITHDRAW_MONEY_REQUEST";
const MEMBER_WITHDRAWMONEY_SUCCESS = "MEMBER/WITHDRAW_MONEY_SUCCESS";
const MEMBER_WITHDRAWMONEY_FAILED = "MEMBER/WITHDRAW_MONEY_FAILED";
const WithdrawMoney = ({ Amount, history }) => {
  return async (dispatch, getState) => {
    //const { ui } = getState();
    //const { socket } = ui;
    // 7=630,1260,2310,1200
    dispatch({ type: MEMBER_WITHDRAWMONEY_REQUEST });
    try {
      const {
        data: { code, item, items, itemcredit },
      } = await axios({
        method: "post",
        url: `${API_URL}/bet/withdrawmoneyhuay`,
        data: {
          amount: Amount
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
        },
      });

      if (code === 200) {
        /*
        const ndata = { withdraw: 1 };
        socket.emit("sent-message", ndata);
        socket.emit("sentwithdraw", 1);
        */
        dispatch({
          type: MEMBER_WITHDRAWMONEY_SUCCESS,
          payload: { item, items },
        });
        dispatch({
          type: "MEMBER_AFF_SUCCESS",
          payload: { item: itemcredit },
        });
      } else {
        message.error("ข้อมูลซ้ำกัน กรุณาป้อนใหม่");
        dispatch({ type: MEMBER_WITHDRAWMONEY_FAILED, payload: "" });
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;

        if (status === 422) {
          // มีรอยืนยัน
          dispatch({
            type: MEMBER_WITHDRAWMONEY_FAILED,
            payload: {},
          });
        }
      }
    }
  };
};

const MEMBER_GETHISTORY_REQUEST = "MEMBER_GETHISTORY_REQUEST";
const MEMBER_GETHISTORY_SUCCESS = "MEMBER_GETHISTORY_SUCCESS";
const MEMBER_GETHISTORY_FAILED = "MEMBER_GETHISTORY_FAILED";
const GetHistory = (page) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GETHISTORY_REQUEST });
    try {
      const {
        data: { items: dataitems, code, meta },
      } = await axios({
        method: "get",
        url: `${API_URL}/booking/1/transection`,
        params: { page: page ? page : 1 },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });
      if (code === 200) {
        dispatch({
          type: MEMBER_GETHISTORY_SUCCESS,
          payload: { items: dataitems, meta },
        });
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status, validation } = response?.data?.error;

        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: MEMBER_GETHISTORY_FAILED,
            payload: false,
          });
        }
      }
    }
  };
};

const MEMBER_GETBETHISTORY_REQUEST = "MEMBER_GETBETHISTORY_REQUEST";
const MEMBER_GETBETHISTORY_SUCCESS = "MEMBER_GETBETHISTORY_SUCCESS";
const MEMBER_GETBETHISTORY_FAILED = "MEMBER_GETBETHISTORY_FAILED";
const GetBetHistory = (page) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GETBETHISTORY_REQUEST });
    try {
      const {
        data: { items: dataitems, code, meta },
      } = await axios({
        method: "get",
        url: `${API_URL}/bet/1/history`,
        params: { page: page ? page : 1 },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      dispatch({
        type: MEMBER_GETBETHISTORY_SUCCESS,
        payload: { items: dataitems, meta },
      });
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status, validation } = response?.data?.error;

        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: MEMBER_GETBETHISTORY_FAILED,
            payload: false,
          });
        }
      }
    }
  };
};

const MEMBER_GETMEMBERAFF_REQUEST = "MEMBER_GETMEMBERAFF_REQUEST";
const MEMBER_GETMEMBERAFF_SUCCESS = "MEMBER_GETMEMBERAFF_SUCCESS";
const MEMBER_GETMEMBERAFF_FAILED = "MEMBER_GETMEMBERAFF_FAILED";
const GetMemberAff = (page) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_GETMEMBERAFF_REQUEST });
    try {
      const {
        data: { items: dataitems, meta },
      } = await axios({
        method: "get",
        url: `${API_URL}/bet/1/memberaff`,
        params: { page: page ? page : 1 },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      dispatch({
        type: MEMBER_GETMEMBERAFF_SUCCESS,
        payload: { items: dataitems, meta },
      });
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;

        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: MEMBER_GETMEMBERAFF_FAILED,
            payload: false,
          });
        }
      }
    }
  };
};

const MEMBER_WINLOSSAFF_REQUEST = "MEMBER_WINLOSSAFF_REQUEST";
const MEMBER_WINLOSSAFF_SUCCESS = "MEMBER_WINLOSSAFF_SUCCESS";
const MEMBER_WINLOSSAFF_FAILED = "MEMBER_WINLOSSAFF_FAILED";
const GetMemberWinlossAff = (page) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: MEMBER_WINLOSSAFF_REQUEST });
    try {
      const {
        data: { item: dataitems,money },
      } = await axios({
        method: "get",
        //url: `${API_URL}/bet/1/winlossaff`,
        url: `${API_URL}/huay/bet_sumaff`,
        params: { page: page ? page : 1 },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      dispatch({
        type: "MEMBER_WINLOSSAFF_HUAY_SUCCESS",
        payload: { items: dataitems, winloss:money },
      });

      //MEMBER_AFF_SUCCESS
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;

        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: MEMBER_WINLOSSAFF_FAILED,
            payload: false,
          });
        }
      }
    }
  };
};


export const GetMonayHuay = (page) => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: "MEMBER_GetMonayHuay_REQUEST" });
    try {
      const {
        data: { money, items:historyitems },
      } = await axios({
        method: "get",
        //url: `${API_URL}/bet/1/winlossaff`,
        url: `${API_URL}/huay/bet_sumaff`,
        params: { page: page ? page : 1 },
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      dispatch({
        type: "MEMBER_GetMonayHuay_SUCCESS",
        payload: { money, items:historyitems },
      });

      //MEMBER_AFF_SUCCESS
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;

        if (status === 422) {
          // ไม่มีข้อมูลจองฝาก
          dispatch({
            type: "MEMBER_GetMonayHuay_FAILED",
            payload: false,
          });
        }
      }
    }
  };
};

export {
  MEMBER_REQUEST,
  MEMBER_SUCCESS,
  MEMBER_FAILED,
  MEMBER_SAVE_REQUEST,
  MEMBER_SAVE_SUCCESS,
  MEMBER_SAVE_FAILED,
  MEMBER_WITHDRAW_REQUEST,
  MEMBER_WITHDRAW_SUCCESS,
  MEMBER_WITHDRAW_FAILED,
  MEMBER_WITHDRAWAGENT_REQUEST,
  MEMBER_WITHDRAWAGENT_SUCCESS,
  MEMBER_WITHDRAWAGENT_FAILED,
  MEMBER_WITHDRAWBONUS_REQUEST,
  MEMBER_WITHDRAWBONUS_SUCCESS,
  MEMBER_WITHDRAWBONUS_FAILED,
  MEMBER_DEPOSIT_REQUEST,
  MEMBER_DEPOSIT_SUCCESS,
  MEMBER_DEPOSIT_FAILED,
  MEMBER_LOGIN_REQUEST,
  MEMBER_LOGIN_SUCCESS,
  MEMBER_LOGIN_FAILED,
  MEMBER_NEWS_REQUEST,
  MEMBER_NEWS_SUCCESS,
  MEMBER_NEWS_FAILED,
  MEMBER_GETCREDIT_REQUEST,
  MEMBER_GETCREDIT_SUCCESS,
  MEMBER_GETCREDIT_FAILED,
  MEMBER_GETDATA_REQUEST,
  MEMBER_GETDATA_SUCCESS,
  MEMBER_GETDATA_FAILED,
  MEMBER_GETWITHDRAW_REQUEST,
  MEMBER_GETWITHDRAW_SUCCESS,
  MEMBER_GETWITHDRAW_FAILED,
  MEMBER_CHECKWITHDRAW_REQUEST,
  MEMBER_CHECKWITHDRAW_SUCCESS,
  MEMBER_CHECKWITHDRAW_FAILED,
  MEMBER_DEPOSIT_SUCCESS_OK,
  MEMBER_DEPOSIT_FAILED_ALL,
  MEMBER_CINFIRM_REQUEST,
  MEMBER_CHECKBOOKING_REQUEST,
  MEMBER_CHECKBOOKING_SUCCESS,
  MEMBER_CINFIRM_SUCCESS,
  MEMBER_CANCELBOOKING_SUCCESS,
  MEMBER_CHECKBOOKING_SUCCESS_FAILED,
  MEMBER_GETBETHISTORY_REQUEST,
  MEMBER_GETBETHISTORY_SUCCESS,
  MEMBER_GETBETHISTORY_FAILED,
  MEMBER_GETMEMBERAFF_REQUEST,
  MEMBER_GETMEMBERAFF_SUCCESS,
  MEMBER_GETMEMBERAFF_FAILED,
  MEMBER_WINLOSSAFF_REQUEST,
  MEMBER_WINLOSSAFF_SUCCESS,
  MEMBER_WINLOSSAFF_FAILED,
  MEMBER_GETHISTORY_REQUEST,
  MEMBER_GETHISTORY_SUCCESS,
  MEMBER_GETHISTORY_FAILED,
  MEMBER_DELBOOKING_SUCCESS,
  MEMBER_GETACC_REQUEST,
  MEMBER_GETACC_SUCCESS,
  MEMBER_GETACC_FAILED,
  MEMBER_WITHDRAWMONEY_REQUEST,
  MEMBER_WITHDRAWMONEY_SUCCESS,
  MEMBER_WITHDRAWMONEY_FAILED,
  MEMBER_GAMELIST_REQUEST,
  MEMBER_GAMELIST_SUCCESS,
  MEMBER_GAMELIST_FAILED,
  MEMBER_GAMELOGIN_REQUEST,
  MEMBER_GAMELOGIN_SUCCESS,
  MEMBER_GAMELOGIN_FAILED,
  MEMBER_SAVEACC_REQUEST,
  MEMBER_SAVEACC_SUCCESS,
  MEMBER_SAVEACC_FAILED,
  MEMBER_OTPACC_REQUEST,
  MEMBER_OTPACC_SUCCESS,
  MEMBER_OTPACC_FAILED,
  MEMBER_CHANGEPASS_SUCCESS,
  MEMBER_CHANGEPASS_REQUEST,
  MEMBER_CHANGEPASS_FAILED,
  MEMBER_GAMEALL_REQUEST,
  MEMBER_GAMEALL_SUCCESS,
  MEMBER_GAMEALL_FAILED,
  MEMBER_CHAGRATE_REQUEST,
  ChangePassword,
  GetMemberWinlossAff,
  GetBetHistory,
  GetMemberAff,
  CheckWithDraw,
  GetList,
  Save,
  WithdrawAgent,
  WithdrawMoney,
  GetDataNew,
  GetCredit,
  ConfirmOTP,
  ConfirmRegister,
  Booking,
  GetBooking,
  BookingConfirm,
  clearNotify,
  ForgotPassword,
  CancelBooking,
  DelBooking,
  GetHistory,
  GetAccount,
  GameEvoList,
  SaveAccount,
  ConfirmOTPAccount,
  GameEvoLogin,
  GameAllList,
  changerate
};
