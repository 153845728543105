import axios from 'axios'
import { message} from 'antd'
import { API_URL } from 'modules/configApp'

const USER_REQUEST = 'USER/REQUEST'
const USER_SUCCESS = 'USER/SUCCESS'
const USER_FAILED = 'USER/FAILED'
const USER_NEWS_REQUEST = 'USER/USER_NEWS_REQUEST'
const USER_NEWS_SUCCESS = 'USER/USER_NEWS_SUCCESS'
const USER_NEWS_FAILED = 'USER/USER_NEWS_FAILED'
const USER_SAVE_REQUEST = 'USER/USER_SAVE_REQUEST'
const USER_SAVE_SUCCESS = 'USER/USER_SAVE_SUCCESS'
const USER_SAVE_FAILED = 'USER/USER_SAVE_FAILED'
const USER_EDIT_REQUEST = 'USER/USER_EDIT_REQUEST'
const USER_EDIT_SUCCESS = 'USER/USER_EDIT_SUCCESS'
const USER_EDIT_FAILED = 'USER/USER_EDIT_FAILED'
const PartAPI = 'User'
const GetList = (data) => {
    return async (dispatch) => {
        dispatch({type:USER_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetList`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:USER_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:USER_FAILED})
          }
         
      }
}

const Save = ({Id,Token,Using,Name,UserName,Password,history}) => {
    return async (dispatch) => {
        dispatch({type:USER_SAVE_REQUEST})
          try{
              const {data:{Status,Data,Text}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/Save`,
                  data:{Id,Token,Using,Name,UserName,Password}
          })

          if(Status===0){
            message.success('บันทึกข้อมูลสำเร็จ');
            history.push('/user/edit/'+Data.Id)
            dispatch({type:USER_SAVE_SUCCESS,payload:Data})
          }else{
            message.error('ข้อมูลซ้ำกัน กรุณาป้อนใหม่');
            dispatch({type:USER_SAVE_FAILED,payload:Text})
          }
          }catch(err){
            message.error('ระบบมีปัญหา กรุณาป้อนใหม่');
            dispatch({type:USER_SAVE_FAILED,payload:err})
          }
         
      }
}


const GetDataNew =()=> {
    return (dispatch) => {
        dispatch({type:USER_NEWS_REQUEST})
        try{
            setTimeout(()=>dispatch({type:USER_NEWS_SUCCESS}),100)
            
        }catch(err){
            dispatch({type:USER_NEWS_FAILED})
        }
    }
}


const GetData =(Id)=> {
    return async (dispatch) => {
        dispatch({type:USER_EDIT_REQUEST})
          try{
              const {data:{Data:{Main}}} = await axios({
                  method: 'get',
                  url: `${API_URL}/${PartAPI}/GetData?Id=${Id}`,
                  headers:{'Content-Type': 'application/json','token':localStorage.getItem('Signature')}
          })
          dispatch({type:USER_EDIT_SUCCESS,payload:Main})
        
          }catch(err){
            dispatch({type:USER_EDIT_FAILED})
          }
         
      }
}

export {
    USER_REQUEST,
    USER_SUCCESS,
    USER_FAILED,
    USER_NEWS_REQUEST,
    USER_NEWS_SUCCESS,
    USER_NEWS_FAILED,
    USER_SAVE_REQUEST,
    USER_SAVE_SUCCESS,
    USER_SAVE_FAILED,
    USER_EDIT_REQUEST,
    USER_EDIT_SUCCESS,
    USER_EDIT_FAILED,
    GetList,
    GetDataNew,
    GetData,
    Save
}