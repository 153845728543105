import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CachedIcon from "@material-ui/icons/Cached";
import PhonelinkLockIcon from "@material-ui/icons/PhonelinkLock";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import RemoveCircleSharpIcon from "@material-ui/icons/RemoveCircleSharp";
import Alert from "@material-ui/lab/Alert";
import { red } from "@material-ui/core/colors";
import CreditCardIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import HistoryIcon from "@material-ui/icons/HistoryOutlined";
import GroupWorkIcon from "@material-ui/icons/GroupAddOutlined";
import RestorePageOutlinedIcon from "@material-ui/icons/RestorePageOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderModeOutlined";
import MoneyIcon from "@material-ui/icons/MoneyOutlined";
import ListAltIcon from '@material-ui/icons/ListAlt';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
//import Container from "@material-ui/core/Container";
//import CircularProgress from "@material-ui/core/CircularProgress";

import { useTranslation } from "react-i18next";
import * as memberActions from "redux/member/action";
import * as uiActions from "redux/ui/action";
import * as loginAcions from "redux/ui/action";
import * as logoutAcions from "redux/signin/action";
import { addCommas, PHOME } from "../../configApp";
import VideoPlayer from 'react-video-js-player';
import MovingText from "react-moving-text";
//import Banner from "./Banner";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "transparent",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    paddingTop: 40,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
    background: "transparent",
    color: "#fff",
    textAlign: "center",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading, items, accountlist, accountlistotp } = useSelector(
    (store) => store.member
  );

  const history = useHistory();
  const goPage = (path) => {
    if (accountlistotp.length === 0) {

      if(PHOME.linkcasino ==1){
        history.push(path);
      } else {
        if(path == '/casinos'){
  
        } else {
          history.push(path);
        }
      }
     
    } else {
      history.push("/dashboard/confirmotp");
    }
  };

  const goPage2 =(path)=> {
    window.open(path)
  }

  React.useEffect(() => {
    const action = memberActions.GetCredit({});
    dispatch(action);

    const actionacc = memberActions.GetAccount();
    dispatch(actionacc);

    const uiaction = uiActions.chatShow(false);
    dispatch(uiaction);
  }, [dispatch]);
  // console.log("items", items);

  const loadCredit = () => {
    const action = memberActions.GetCredit({});
    dispatch(action);
  };

  const logout = () => {
    dispatch(logoutAcions.logout(history));
  };
  
  return (
    <>
      <Container maxWidth="md">
        <div class="containerx">
          {/*<Banner />*/}
          <div className="headhome"></div>

          <div className="topcontain">
            <div className="bgboxprofile">
              <div class="bgline">
                <PhonelinkLockIcon className="mverti" /> {items?.mobile}
                {accountlist.length > 0 && (
                  <span className="pright">
                    <img
                      src={`/images/bank/${accountlist[0].accbank}.png`}
                      height="25"
                      className="imgbank"
                    />
                    {accountlist[0].accbank} : {accountlist[0].accno}{" "}
                  </span>
                )}
              </div>
              <div class="bgline"></div>
            </div>
            <div className="mcredit">
              <div className="pcreditmoney">ຍອດເງິນ</div>
              <div style={{ fontSize: 24 }} className="pcreditmoney2">
                {isLoading && <CircularProgress size={20} color="primary" />}
                {addCommas(
                  parseFloat(items?.credit ? items?.credit : 0).toFixed(0)
                )}
              </div>
              <div className="pcreditmoney">ກີບ</div>
            </div>
            <div className="topcontain_left">
              {" "}
              <div className="btndeposit" onClick={() => goPage("/deposit")}>
                <AddCircleIcon className="mverti" />
                ຝາກເງິນ
              </div>
            </div>

            <div className="topcontain_right">
              {" "}
              <div className="btnwithdraw" onClick={() => goPage("/withdraw")}>
                <RemoveCircleSharpIcon className="mverti" />
                ຖອນເງິນ
              </div>
            </div>
          </div>
          <div style={{ height: 10 }}></div>
          <div className="main_left" onClick={() => goPage("/play/lotto")}>
         
            <div className="divm2">
            <MovingText
                type="fadeIn"
                duration="1000ms"
                delay="0s"
                direction="normal"
                timing="ease"
                iteration="infinite"
                fillMode="none"
              >
                 <img src={PHOME.name1} height={140}></img>
                 </MovingText>
               </div>
              
               </div>
          <div className="main_right">
          <div className="divm2" onClick={() => goPage("/member/aff")}>
                <img src={PHOME.name2} height={58}></img>
                </div>
                <div style={{height:5}}>&nbsp;</div>
                <div className={PHOME.linkcasino ==1 ? 'divm2':'divm2x'} 
                onClick={() => goPage("/casinos")}
                >
                <img src={PHOME.name3} height={58}></img>
                </div>
          </div>
        
          <div style={{ height: 3 }}></div>
          <div className="topcontainx">
            <div style={{ height: 10 }}></div>
            <ul className="phome">
              <li onClick={() => goPage("/deposit")}>
                <div className="divm3">
                  <CreditCardIcon style={{ fontSize: 44 }} />
                  <div>ຝາກ</div>
                </div>
              </li>
              <li onClick={() => goPage("/withdraw")}>
                <div className="divm3">
                  <MonetizationOnIcon style={{ fontSize: 44 }} />
                  <div>ຖອນ</div>
                </div>
              </li>
              <li onClick={() => goPage("/member/history")}>
                <div className="divm3">
                  <HistoryIcon style={{ fontSize: 44 }} />
                  <div>ລາຍການເຄດິດ</div>
                </div>
              </li>
              <li onClick={() => goPage("/play/poy")}>
                <div className="divm3">
                  <ListAltIcon style={{ fontSize: 44 }} />
                  <div>ໂພຍຫວຍ</div>
                </div>
              </li>
              <li onClick={() => goPage("/member/aff")}>
                <div className="divm3">
                 
                  <img src="/images/aff2.png" height={44} />
                  <div>ລິ້ງລ້ຳລວຍ</div>
                </div>
              </li>
             
              <li onClick={() => goPage("/play/result")}>
                <div className="divm3" style={{ marginTop: 7 }}>
                  <BubbleChartIcon style={{ fontSize: 37 }} />
                  <div>ຜົນລາງວັນ</div>
                </div>
              </li>
              <li onClick={() => goPage("/promotion")}>
                <div className="divm3">
                  <img src="/images/them2/HL_PROMOTION.gif" height="50"></img>
                  <div>ໂປໂມຊັ້ນ</div>
                </div>
              </li>
              <li onClick={() => goPage("/contactus")}>
                <div className="divm3">
                  <img src="/images/whatapp.gif" height="50"></img>
                  <div>ຕິດຕໍ່ແອັດມິນ</div>
                </div>
              </li>
            </ul>
          </div>
          <div className="vdiomainxx">
            {
              PHOME.vediohome === 1 &&  <VideoPlayer
              controls={true}
              src={PHOME.deposit}
              className="vdiomain2"
              autoplay={true}
          />
            }
               
     </div>
         
          <div style={{ height: 20 }}></div>
          <ul className="phome2">
            <li>
              <img
                src="/images/them2/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ຝາກ/ຖອນ ໄວລະດັບ ວິນາທີ
            </li>
            <li>
              <img
                src="/images/them2/checkbox.png"
                height="35"
                className="m7889"
              ></img>
             ບ່ອນນີ້ ຖອນໄດ້ບໍ່ຕິດເທີນ ທຸກກໍລະນີ
            </li>
            <li>
              <img
                src="/images/them2/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ບໍລິການ 24 ຊົ່ວໂມງ. ດ້ວຍ AI ພ້ອມບຸກຄະລາກອນ ກວ່າ 100 ຊີວິດ
            </li>
            <li>
              <img
                src="/images/them2/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ລວມເກມເດິມພັນຫລາຍກວ່າ 50 ຄ້າຍຍັກໃຫຍ່
            </li>
            <li>
              <img
                src="/images/them2/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ລະບົບລັນເທິງ Cloud AWS lambda ປອດໄພແລະສະຖຽນທີ່ສຸດ
            </li>
            <li>
              <img
                src="/images/them2/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ຂໍ້ຜິດພາດໃດໆ ເຮົາຮັບຜິດຊອບທຸກກໍລະນີ ຂອງແທ້ມີພຽງ 1 ດຽວ
            </li>
          </ul>
        </div>
      </Container>
      <div style={{ height: 220 }}></div>
    </>
  );
}
