import { useState, useEffect } from "react";
import axios from "axios";
import { WEB_TOKEN } from "modules/configApp";
function useFetch(url, values, type) {
  const [data, setData] = useState({});

  const fetchData = async () => {
    //const res = await axios.get(url)

    if (type === "post") {
      const res = await axios({
        method: "post",
        url: `${url}`,
        data: values,

        headers: {
          Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
        },
      });
      setData(res.data);
    } else {
      const res = await axios({
        method: "get",
        url: `${url}`,
        params: { WEB_TOKEN },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
        },
      });
      setData(res.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return data;
}

export default useFetch;
