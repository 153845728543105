import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Call } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import * as memberActions from "redux/member/action";
//import useFetch from "lib/useFetch";
//import { API_URL } from "modules/configApp";

import * as yup from "yup";

//import { yupResolver } from '@hookform/resolvers'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 385,
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 140,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    width: "100%",
    backgroundColor: "#000",
    height: 35,
    fontSize: 16,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: "#b88c3b",
    textAlign: "center",
    color: "#b88c3b",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: "100%",
  },
  rootbtn: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  headerform: {
    color: "#b88c3b",
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      UserName: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const submit = ({ UserName }) => {
    const dataSave = {
      UserName,
    };

    const action = memberActions.Save({ ...dataSave, ...props });
    dispatch(action);
  };

  useEffect(() => {}, []);
  const dispatch = useDispatch();
  const { isLoading, message, datanotify } = useSelector(
    (store) => store.member
  );

  const GoPage = () => {
    history.push("/signin");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.GetList();
    dispatch(action);
  };

  return (
    <>
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image="images/glogo2.png"
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h5"
              style={{ textAlign: "center", color: "#c01f25" }}
            >
              {t("register.label")}
            </Typography>

            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(submit)}
            >
              {/*
 <input
                placeholder="กรอกเบอร์มือถือ (ไม่ต้องใส่ -)"
                className={classes.input}
              ></input>
                */}

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                inputRef={register}
                id="username"
                name="UserName"
                placeholder={t("mobile.label")}
                //label="เบอร์มือถือ (ไม่ต้องใส่ -)"
                autoComplete="email"
                //autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Call />
                    </InputAdornment>
                  ),
                }}
              />

              {message === 3 && (
                <Alert variant="outlined" severity="error">
                  ข้อมูลสมาชิกไม่ถูกต้อง กรุณาป้อนใหม่อีกครั้ง !!
                </Alert>
              )}
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{
                  color: "#fff",
                  fontSize: 18,
                  float: "right",
                  backgroundColor: "#0780ad",
                }}
              >
                {t("register.label")}
              </Button>
            </form>
          </CardContent>
          {/*
<CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
     */}
        </Card>
        {isLoading && (
          <div className={classes.rootLoading}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </Container>
      <Snackbar
        open={datanotify.show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          ข้อมูลซ้ำกัน กรุณาป้อนใหม่อีกครั้ง
        </Alert>
      </Snackbar>
    </>
  );
}
