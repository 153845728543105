// ฟังชั่นเอาเฉพาะค่าล่าสุดท่านั้น
export function getnumberlast(
  initialState,
  state,
  action,
  betlist3t,
  type,
  tod3
) {
  // เช็คว่ามี 3 ตัวกลับมั้ย
  let newbetlist3t = [];
  const backnumber = initialState.find(
    (x) => x.active == true && x.backnumber == 1
  );
  // หา 3 ตัวบน ว่ามีมั้ย
  const numbertop31 = initialState.find(
    (x) => x.active == true && x.typerun == type
  );
  if (numbertop31) {
    const newnumber = `${state.number1}${state.number2}${action.payload}`;
    // เช็คว่าตรงกับเลขอั้นมั้ย

    let rate = numbertop31.rate;
    let remain = numbertop31.remain;
    let maxbet = numbertop31.maxbet;
    let minbet = numbertop31.minbet;

    const { numberlimit } = numbertop31;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );
    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist3t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      // แสดงว่ามีค่า
      newbetlist3t = [
        {
          name: numbertop31.name,
          typerun: type,
          number: newnumber,
          amount: minbet,
          rate,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        },
      ];
    } else {
      newbetlist3t = [
        {
          name: numbertop31.name,
          typerun: type,
          number: newnumber,
          rate,
          amount: minbet,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        },
      ];
    }

    if (backnumber) {
      // ถ้ามีกลับ
      const newarrnumber = tod3.find((x) => x.number.includes(newnumber));

      const newarray = [...new Set(newarrnumber.number)];
      const nemberlast = newarray.filter((x) => x != newnumber);
      for (let i = 0; i < nemberlast.length; i++) {
        newbetlist3t.push({
          name: numbertop31.name,
          typerun: type,
          number: nemberlast[i],
          rate,
          amount: minbet,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        });
      }
    }
    newbetlist3t.sort((a, b) => {
      return a.number - b.number;
    });
  }
  return newbetlist3t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumber(initialState, state, action, betlist3t, type, tod3) {
  // เช็คว่ามี 3 ตัวกลับมั้ย
  let newbetlist3t = [];
  let remaintotal = 0;
  let checklimit = 0;
  let sumremainlimit = 0;// ยอดรวมที่แทงเลขอั้นนั้นๆ
  let checknumberlimitremain = 0; //ยอดคงเหลือเลขอั้นนั้นๆ
  const backnumber = initialState.find(
    (x) => x.active == true && x.backnumber == 1
  );
  // หา 3 ตัวบน ว่ามีมั้ย
  const numbertop31 = initialState.find(
    (x) => x.active == true && x.typerun == type
  );
  let newnumber = ""
  if (numbertop31) {
    remaintotal = numbertop31.remain
     newnumber = `${state.number1}${state.number2}${action.payload}`;
    // เช็คว่าตรงกับเลขอั้นมั้ย

    let rate = numbertop31.rate;
    let remain = numbertop31.remain;
    let maxbet = numbertop31.maxbet;
    let minbet = numbertop31.minbet;

    const { numberlimit } = numbertop31;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );
    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = parseFloat(checknumberlimit.remain)-parseFloat(minbet);
      checklimit = 1
      checknumberlimitremain = parseFloat(checknumberlimit.remain);
      // ถ้าเลขอั้นแทงเต็มจำนวนให้ปิดรับเลข
      sumremainlimit = betlist3t.filter((x) => x.typerun == type && x.number==newnumber).reduce((total,item)=>total+parseFloat(item.amount),0)
      if(parseFloat(sumremainlimit)>=parseFloat(checknumberlimit.remain)){
        remain = 0
      }
      console.log('maniiiii', remain)
      // เอาครั้งแรกมาบวกพอ
      if(sumremainlimit==0){
        sumremainlimit = sumremainlimit+parseFloat(minbet)
      }
      
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    // ถ้าคงเหลือ 0 ปิดการแทงไปเลย
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist3t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      const data = betlist3t[index];
      // เช็คเลขว่าซ้ำมั้ย
      const indexdub = betlist3t.findIndex((x) => x.typerun == type && x.number==newnumber);
      // ถ้าซ้ำคืนเลขเดิม
      if(indexdub!== -1){
        newbetlist3t = betlist3t
      }else{
        if(remain !== 0) {
        newbetlist3t = [
          ...betlist3t.splice(0, index),
  
          { ...data },
          {
            name: numbertop31.name,
            typerun: type,
            number: newnumber,
            amount: minbet,
            rate,
            remain,
            maxbet,
            minbet,
            checklimit,
            payout: parseFloat(payout).toFixed(0),
          },
          ...betlist3t.slice(index + 1),
        ];
        } else {
          newbetlist3t = betlist3t
        }
      }
      
    } else {
      if(remain !== 0) {
        newbetlist3t = [
          {
            name: numbertop31.name,
            typerun: type,
            number: newnumber,
            rate,
            amount: minbet,
            remain,
            maxbet,
            minbet,
            checklimit,
            payout: parseFloat(payout).toFixed(0),
          },
        ];
      }
     
    }


    if (backnumber) {
      // ถ้ามีกลับ
      const newarrnumber = tod3.find((x) => x.number.includes(newnumber));

      const newarray = [...new Set(newarrnumber.number)];
      const nemberlast = newarray.filter((x) => x != newnumber);
      for (let i = 0; i < nemberlast.length; i++) {

        const checknumberlimit_back = numberlimit.find((x) =>
        x.number.includes(nemberlast[i])
      );
    
      if (checknumberlimit_back) {
        if(checknumberlimit_back.remain !== 0) {
          newbetlist3t.push({
            name: numbertop31.name,
            typerun: type,
            number: nemberlast[i],
            rate,
            amount: minbet,
            remain,
            maxbet,
            minbet,
            checklimit,
            payout: parseFloat(payout).toFixed(0),
          });
        }
      }else{
        newbetlist3t.push({
          name: numbertop31.name,
          typerun: type,
          number: nemberlast[i],
          rate,
          amount: minbet,
          remain,
          maxbet,
          minbet,
          checklimit,
          payout: parseFloat(payout).toFixed(0),
        });
      }
       
      }
    }
    newbetlist3t.sort((a, b) => {
      return a.number - b.number;
    });
  }
  
  const lastitem =  newbetlist3t.map((x, index) => ({ ...x, no: ++index }));
  const sumamount = lastitem.reduce((total,item)=>total+parseFloat(item.amount),0)
  let lastremaintotal = parseFloat(remaintotal)-parseFloat(sumamount)

  // sumremainlimit = 0;// ยอดรวมที่แทงเลขอั้นนั้นๆ
  // checknumberlimitremain = 0; //ยอดคงเหลือเลขอั้นนั้นๆ
  const checkremain2limit = (item,remainamount)=> {
    // แสดงว่าเลขอั้น
    if(parseFloat(item.checklimit)!=0 ){
      if(item.number==newnumber){
        // หายอดคงเหลือเลขอั้นนี้
        // ถ้ายอดรวม
        if(parseFloat(sumremainlimit)>=parseFloat(checknumberlimitremain)){
          return 0.1
        }else{
          const checkrr = parseFloat(checknumberlimitremain)-parseFloat(sumremainlimit)
          // ยอดคงเหลือตัวอั้นเกิน วงเงินทั้งหมด เอาวงเงินทั้งหมด
          if(checkrr>remainamount){
            return remainamount
          }else{
            return parseFloat(checknumberlimitremain)-parseFloat(sumremainlimit)
          }
          
        }
        
      }else{
        return item.remain
      }
    }else{
      return remainamount;
    }
  }


  // checklimit = 0 คิดแค่เลขที่ไม่อั้น
  if(parseFloat(lastremaintotal)==0){
    //newnumber
    lastremaintotal = 0.1
    return lastitem.map(x=>({...x,remain:checkremain2limit(x,lastremaintotal)}))
  }else if(parseFloat(lastremaintotal)<0){
    lastremaintotal = 0.1
    return lastitem.filter(x=>x.number!=newnumber).map(x=>({...x,remain:checkremain2limit(x,lastremaintotal)}))
  }else{
    return lastitem.map(x=>({...x,remain:checkremain2limit(x,lastremaintotal)}))
  }
  

}

export function getnumbertod3(initialState, state, action, betlist3t, type) {
  let newbetlist3t = [];
  let remaintotal = 0;

  // หา 3 ตัวบน ว่ามีมั้ย
  const numbertop31 = initialState.find(
    (x) => x.active == true && x.tode == type
  );
 
  //console.log('numbertop31',numbertop31)
  if (numbertop31) {
    remaintotal = numbertop31.remain
    const newnumber = `${state.number1}${state.number2}${action.payload}`;

    let rate = numbertop31.rate;
    let remain = numbertop31.remain;
    let maxbet = numbertop31.maxbet;
    let minbet = numbertop31.minbet;

    const { numberlimit } = numbertop31;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );
    
    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist3t.findIndex((x) => x.tode == type);
    if (index !== -1) {
      const data = betlist3t[index];
      // แสดงว่ามีค่า
      newbetlist3t = [
        ...betlist3t.splice(0, index),

        { ...data },
        {
          name: numbertop31.name,
          tode: type,
          number: newnumber,
          amount: numbertop31.minbet,
          payout,
          maxbet,
          remain,
          rate,
        },
        ...betlist3t.slice(index + 1),
      ];
    } else {
      newbetlist3t = [
        {
          name: numbertop31.name,
          tode: type,
          number: newnumber,
          amount: numbertop31.minbet,
          payout,
          maxbet,
          remain,
          rate,
        },
      ];
    }

    newbetlist3t.sort((a, b) => {
      return a.number - b.number;
    });
  }
  return newbetlist3t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumbet2t(lottotypeitems, betlist2t, state, action, type) {
  const backnumber = lottotypeitems.find(
    (x) => x.active == true && x.backnumber == 1
  );

  // หา 2 บน ว่ามีมั้ย
  let newbetlist2t = [];
  const itemruntop = lottotypeitems.find(
    (x) => x.active == true && x.typerun == type
  );

  if (itemruntop) {
    let rate = itemruntop.rate;
    let remain = itemruntop.remain;
    let maxbet = itemruntop.maxbet;
    let minbet = itemruntop.minbet;
    let newnumber = `${state.number1}${action.payload}`;

    const { numberlimit } = itemruntop;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );

    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist2t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      const data = betlist2t[index];
      // แสดงว่ามีค่า
      newbetlist2t = [
        ...betlist2t.splice(0, index),

        { ...data },
        {
          name: itemruntop.name,
          typerun: type,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
          number: `${state.number1}${action.payload}`,
        },
        ...betlist2t.slice(index + 1),
      ];
    } else {
      newbetlist2t = [
        {
          name: itemruntop.name,
          typerun: type,
          number: `${state.number1}${action.payload}`,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        },
      ];
    }

    if (backnumber) {
      // ถ้ามีกลับ
      const newnumber = `${state.number1}${action.payload}`;
      const backumbernew = flipInt(newnumber);

      if (backumbernew != newnumber) {
        newbetlist2t.push({
          name: itemruntop.name,
          typerun: type,
          number: backumbernew,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        });
      }
    }

    newbetlist2t.sort((a, b) => {
      return a.number - b.number;
    });
  }

  return newbetlist2t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumbet2tlast(
  lottotypeitems,
  betlist2t,
  state,
  action,
  type
) {
  const backnumber = lottotypeitems.find(
    (x) => x.active == true && x.backnumber == 1
  );

  // หา 2 บน ว่ามีมั้ย
  let newbetlist2t = [];
  const itemruntop = lottotypeitems.find(
    (x) => x.active == true && x.typerun == type
  );

  if (itemruntop) {
    let rate = itemruntop.rate;
    let remain = itemruntop.remain;
    let maxbet = itemruntop.maxbet;
    let minbet = itemruntop.minbet;
    let newnumber = `${state.number1}${action.payload}`;

    const { numberlimit } = itemruntop;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );

    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist2t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      // แสดงว่ามีค่า
      newbetlist2t = [
        {
          name: itemruntop.name,
          typerun: type,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
          number: `${state.number1}${action.payload}`,
        },
      ];
    } else {
      newbetlist2t = [
        {
          name: itemruntop.name,
          typerun: type,
          number: `${state.number1}${action.payload}`,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        },
      ];
    }

    if (backnumber) {
      // ถ้ามีกลับ
      const newnumber = `${state.number1}${action.payload}`;
      const backumbernew = flipInt(newnumber);

      if (backumbernew != newnumber) {
        newbetlist2t.push({
          name: itemruntop.name,
          typerun: type,
          number: backumbernew,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        });
      }
    }

    newbetlist2t.sort((a, b) => {
      return a.number - b.number;
    });
  }

  return newbetlist2t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumbetrt(lottotypeitems, betlist2t, state, action, type) {
  // หา 2 บน ว่ามีมั้ย
  let newbetlist2t = [];
  const itemruntop = lottotypeitems.find(
    (x) => x.active == true && x.typerun == type
  );

  let newnumber = `${action.payload}`;
  const checkdubplicate = betlist2t.find((x) => x.number == newnumber);

  if (itemruntop) {
    let rate = itemruntop.rate;
    let remain = itemruntop.remain;
    let maxbet = itemruntop.maxbet;
    let minbet = itemruntop.minbet;

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist2t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      const data = betlist2t[index];
      // แสดงว่ามีค่า

      newbetlist2t = [
        ...betlist2t.splice(0, index),

        { ...data },
        {
          name: itemruntop.name,
          typerun: type,
          rate: itemruntop.rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
          number: `${action.payload}`,
        },
        ...betlist2t.slice(index + 1),
      ];
    } else {
      newbetlist2t = [
        {
          name: itemruntop.name,
          typerun: type,
          number: `${action.payload}`,
          rate: itemruntop.rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        },
      ];
    }

    newbetlist2t.sort((a, b) => {
      return a.number - b.number;
    });
    if (checkdubplicate) {
      newbetlist2t = betlist2t;
    }
  }

  return newbetlist2t.map((x, index) => ({ ...x, no: ++index }));
}

export function flipInt(str) {
  var splitString = str.split("");
  var reverseArray = splitString.reverse();
  var joinArray = reverseArray.join("");
  return joinArray; // "olleh"
}
