import React from "react";

import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";

import Table from "./TableBetHistory";

export default function FullWidthTabs(props) {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <div style={{ height: 10 }}></div>
      <Table />
    </Container>
  );
}
