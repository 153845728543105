import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import { Lock, Call } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert, AlertTitle } from "@material-ui/lab";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CodeIcon from "@material-ui/icons/Code";
import * as memberActions from "redux/member/action";
import useFetch from "lib/useFetch";
import { API_URL, tokenweb } from "modules/configApp";

import * as yup from "yup";

//import { yupResolver } from '@hookform/resolvers'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 385,
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 140,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: "100%",
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      Password: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/

  const dispatch = useDispatch();
  const {
    isLoading,
    message,
    notify,
    notifysuccess,
    datanotify,
    data,
  } = useSelector((store) => store.member);

  const submit = ({ UserName, Password }) => {
    const dataSave = {
      UserName: localStorage.getItem("mobile"),
      Password,
      smsref: data?.smsref ? data.smsref : localStorage.getItem("smsref"),
    };

    const action = memberActions.ConfirmOTP({ ...dataSave, ...props });
    dispatch(action);
  };

  const [values, setValues] = React.useState(null);

  useEffect(() => {
    // if(!values) {
    /*
      const { updatedAt, mtimestamp, _id } = memberdata;
      const b = moment(updatedAt);
      const a = moment(moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss"));
  
      const nowdate = a.diff(b, "minutes");
*/
    const mtimestamp = data?.mtimestamp
      ? data.mtimestamp
      : localStorage.getItem("mtimestamp");
    const updatedAt = data?.updatedAt
      ? data.updatedAt
      : localStorage.getItem("updatedAt");

    console.log("updatedAt", updatedAt);

    const b = moment(updatedAt);
    const a = moment(moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss"));

    const nowdate = a.diff(b, "minutes");

    const cTime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
    const currentTime = moment(cTime).format("X");

    const diffTime = mtimestamp - currentTime;
    const duration = moment.duration(diffTime * 1000, "milliseconds");
    const interval = 1000;
    const durationall = moment.duration(duration - interval, "milliseconds");

    const txtH = `${durationall.hours()}`;
    const txtM = `${durationall.minutes()}`;
    const txtS = `${durationall.seconds()}`;

    const fullH = txtH.length === 1 ? `0${txtH}` : txtH;
    const fullM = txtM.length === 1 ? `0${txtM}` : txtM;
    const fullS = txtS.length === 1 ? `0${txtS}` : txtS;
    const fulltime = `${fullM}:${fullS}`;

    if (nowdate < 5) {
      setTimeout(() => {
        setValues({
          ...values,
          timelong: fulltime,
        });
      }, 1000);
    } else {
      props.history.push("/signin");
    }
    return () => clearTimeout();
    //}
  }, [values]);

  const [bank, setbank] = React.useState("");

  const onChange = (event) => {
    setbank(event.target.value);
  };

  const bankList = useFetch(API_URL + "/bank/GetList", {}, "post");

  const GoPage = () => {
    history.push("/signin");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.GetList();
    dispatch(action);
  };

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    //GoPage();
    const action = memberActions.GetList();
    dispatch(action);
  };

  return (
    <>
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image="images/glogo2.png"
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              style={{ textAlign: "center", color: "#c01f25" }}
              component="h3"
            >
              Ref: {localStorage.getItem("smsref")} กรอกรหัส OTP
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(submit)}
            >
              <Alert severity="warning">
                <AlertTitle>เหลือเวลาอีก</AlertTitle>

                <Typography
                  variant="h5"
                  component="h5"
                  style={{ textAlign: "center" }}
                >
                  {values?.timelong} นาที
                </Typography>
              </Alert>
              {/*
 <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                disabled
                inputRef={register}
                defaultValue={localStorage.getItem("mobile")}
                id="username"
                name="UserName"
                //label="เบอร์มือถือ "
                autoComplete="email"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Call />
                    </InputAdornment>
                  ),
                }}
              />
                */}

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                inputRef={register}
                name="Password"
                placeholder="ใส่รหัส OTP "
                id="Password"
                autoComplete="current-password"
              />

              {message === 3 && (
                <Alert variant="outlined" severity="error">
                  ข้อมูลสมาชิกไม่ถูกต้อง กรุณาป้อนใหม่อีกครั้ง !!
                </Alert>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                ยืนยัน OTP
              </Button>
              <Button fullWidth size="small" color="primary" onClick={GoPage}>
                ย้อนกลับ
              </Button>
            </form>
          </CardContent>
          {/*
<CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
     */}
        </Card>
        {isLoading && (
          <div className={classes.rootLoading}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </Container>
      <Snackbar
        open={notify}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          ข้อมูลซ้ำกัน กรุณาป้อนใหม่อีกครั้ง
        </Alert>
      </Snackbar>
      <Snackbar
        open={datanotify.showotp}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          รหัส OTP ไม่ถูกต้องกรุณาป้อนใหม่
        </Alert>
      </Snackbar>
      <Snackbar
        open={notifysuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleClose2}
      >
        <Alert onClose={handleClose2} severity="success">
          ยินดีต้อนรับสมาชิกใหม่ค่ะ
        </Alert>
      </Snackbar>
    </>
  );
}
