import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as memberActions from "redux/member/action";
import { addCommas } from "modules/configApp";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eaf4fc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 360,
    flexGrow: 1,
  },
  root: {
    background: "transparent",
  },
  rootLoading: {
    zIndex: 10,
    top: 20,
    //margin: 0,
    //position: "absolute",
  },
  rootpg: {
    backgroundColor: "#fff",
    "& > *": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function CustomizedTables() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    historyItems,
    meta,
    isLoadingHis: isLoading,
  } = useSelector((store) => store.member);

  const onChange = (e, page) => {
    setPage(page);
  };

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    const actionwithdraw = memberActions.GetBetHistory(page);
    dispatch(actionwithdraw);
  }, [page]);

  const history = useHistory();
  const goBack = () => {
    history.push("/");
  };
  return (
    <div>
      <div className="headpaddingtop"></div>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/")}
          className="btnback"
        >
          ย้อนกลับ
        </Button>
      </Container>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="h5"
          className="headtitle"
        >
          {t("bethistory.label")}
        </Typography>

        <div style={{ height: 10 }}></div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>วันเวลา</StyledTableCell>
                <StyledTableCell>ค่ายเกมส์</StyledTableCell>
                <StyledTableCell>เกมส์</StyledTableCell>
                <StyledTableCell align="left">เดิมพัน</StyledTableCell>
                <StyledTableCell>กำไร</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <div className={classes.rootLoading}>
                  <CircularProgress color="secondary" size={30} />
                </div>
              )}
              {historyItems.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.dtnow}</StyledTableCell>
                  <StyledTableCell>{row.webtype}</StyledTableCell>
                  <StyledTableCell>{row.gamedes}</StyledTableCell>

                  <StyledTableCell style={{ color: "green" }}>
                    {addCommas(parseFloat(row.bet).toFixed(2))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.win > 0 ? (
                      <font color="blue">
                        +{addCommas(parseFloat(row.win).toFixed(2))}
                      </font>
                    ) : (
                      <font color="red">
                        {addCommas(parseFloat(row.win).toFixed(2))}
                      </font>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {historyItems.length == 0 && (
                <span style={{ height: 50, magin: 0 }}>No data</span>
              )}
            </TableBody>
          </Table>

          <div className={classes.rootpg}>
            {historyItems.length > 0 && (
              <Pagination
                count={meta?.totalPage}
                color="secondary"
                onChange={onChange}
                //siblingCount={0}
              />
            )}
          </div>
        </TableContainer>
        <div style={{ height: 90 }}></div>
      </CardContent>
    </div>
  );
}
