import React from 'react'
import { Route,Switch,useRouteMatch } from 'react-router-dom'

import FormWithdraw from './FormLao'
//import FormWithdraw from './Form'


export default function Routes() {
    
    const {path} = useRouteMatch()
    return (
        <Switch>
           
            <Route path={`/withdraw`} component={FormWithdraw}></Route>
        </Switch>
    )
}
