import React,{ useState } from 'react'
import { Input } from 'antd'
import { addCommas } from 'modules/configApp'

export default function TextBoxNumber(props) {
    const { name,value } = props
    const [isEditing,setisEditing] = useState(false)
   
    const onChange = (event)=> {
        props.onChange(event,name);
      }
  
      const enterNext =(event)=> {
        if (event.key === 'Enter') {
            event.preventDefault();
            props.onChange(event,name,'Save');
            toggleEditing()
          }
      }

      const blurNext =(event)=> {
        props.onChange(event,name,'Save');
        toggleEditing()
      }

    const toggleEditing = ()=> {
        setisEditing(!isEditing)
    }
    return (
        <>
            {isEditing ? (
          <Input
            type="number"
            name={name}
            id={name}
            value={value || 0}
            onChange={onChange}
            onKeyPress={enterNext} 
            style={{width:'40%',textAlign:'right'}}
            onBlur={blurNext}
          />
        ) : (
          <Input
            type="text"
            name={name}
            value={addCommas(parseFloat(value || 0).toFixed(2))}
            onFocus={toggleEditing}
            style={{width:'40%',textAlign:'right'}}
            readOnly
          />
        )}
        </>
    )
}
