import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import History from "./History";
import BetHistory from "./BetHistory";
import Aff from "./Aff";
import Changepass from "./Changepass";

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/changepassword`} component={Changepass}></Route>
      <Route path={`${path}/aff`} component={Aff}></Route>
      <Route path={`${path}/bethistory`} component={BetHistory}></Route>
      <Route path={`${path}/history`} component={History}></Route>
    </Switch>
  );
}
