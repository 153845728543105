import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
//import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as memberActions from "redux/member/action";
import { addCommas } from "modules/configApp";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eaf4fc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 360,
  },
  root: {
    background: "transparent",
  },
  rootLoading: {
    zIndex: 10,
    top: 20,
    //margin: 0,
    //position: "absolute",
  },
  rootpg: {
    backgroundColor: "#fff",
    "& > *": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function CustomizedTables() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    //historyItems,
    totalPage,
    itemsmembercount,
    itemsmember:historyItems,
    isLoading,
  } = useSelector((store) => store.member);

  const onChange = (e, page) => {
    setPage(page);
  };

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    const actionwithdraw = memberActions.GetCreditBalance(page);
    dispatch(actionwithdraw);
  }, [page]);

  const history = useHistory();
  const goBack = () => {
    history.push("/");
  };
  const checktype =(rows)=> {
    if(parseFloat(rows.type)>0){

    }else{
      if(parseInt(rows.status) ===3){
        return <font color='red'>ແພ້ເດີມພັນ</font>
      }
      if(parseInt(rows.status) ===1){
        return "ຖ້າຜົນເດີມພັນ"
      }
      if(parseInt(rows.status) ===4){
        return "ยกเลิกເດີມພັນ"
      }
      if(parseInt(rows.status) ===2){
        return <font color="green">ຊະນະເດີມພັນ</font>
      }
    }
  }

  const showamount =(rows)=> {
    if(parseFloat(rows.type)>0){
        if(parseInt(rows.type)==1){
          return <font color='green'>+{addCommas(parseFloat(rows.amount).toFixed(0))}</font>
        }
        if(parseInt(rows.type)==2){
          return <font color='red'>-{addCommas(parseFloat(rows.amount).toFixed(0))}</font>
        }
    }else{
      if(parseInt(rows.status) ===3){
        return <font color='red'>-{addCommas(parseFloat(rows.amount).toFixed(0))}</font>
      }
      if(parseInt(rows.status) ===1){
        return <font color='red'>-{addCommas(parseFloat(rows.amount).toFixed(0))}</font>
      }
      if(parseInt(rows.status) ===2){
        return <font color='green'>+{addCommas(parseFloat(rows.reward).toFixed(0))}</font>
      }
      if(parseInt(rows.status) ===4){
        return <font color='black'>+{addCommas(parseFloat(rows.amount).toFixed(0))}</font>
      }
    }
  }
  return (
    <div>
      <div className="headpaddingtop"></div>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/")}
          className="btnback"
        >
          ຍ້ອນກັບ
        </Button>
      </Container>
      <div style={{ height: 10 }}></div>
      <Typography
        gutterBottom
        variant="h5"
        component="h5"
        className="headtitle"
      >
      ລາຍການເຄດິດ
      </Typography>
      <div style={{ height: 10 }}></div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
         
          <TableBody>
            {isLoading && (
              <div className={classes.rootLoading}>
                <CircularProgress color="secondary" size={30} />
              </div>
            )}
            {historyItems.map((row, index) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell>
                  <div>{checktype(row)}</div>
                  <div>{row.huaysubname}</div>
                 
                <div className="dtnow">
                  {row.datenow}
                </div>
                </StyledTableCell>
                <StyledTableCell style={{textAlign:'right'}}>
                  {showamount(row)}
                  <div>ຄົງເຫລືອ</div>
                  <div className="txtremain">
                    
                    {addCommas(parseFloat(row.balance_member).toFixed(0))}</div>
                </StyledTableCell>
               
              
              </StyledTableRow>
            ))}
            {historyItems.length == 0 && (
              <span style={{ height: 200, magin: 0 }}>ไม่มีข้อมูล</span>
            )}
          </TableBody>
        </Table>

        <div className={classes.rootpg}>
          {itemsmembercount > 0 && (
            <Pagination
              count={totalPage}
              color="secondary"
              onChange={onChange}
              //siblingCount={0}
            />
          )}
        </div>
      </TableContainer>
      <div style={{ height: 100 }}></div>
    </div>
  );
}
