import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as memberActions from "redux/member/action";
import { LINE_TOKEN  } from 'modules/configApp'

export default function MediaCard(props) {
  
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {

    const liff = window.liff;
    
    const fetchData = async () => {
      await liff.init({ liffId: `${LINE_TOKEN}` }).catch(err=>{throw err});
      if (liff.isLoggedIn()) {
        let getProfile = await liff.getProfile();
        //setuserLineID(getProfile.userId)
        //setName(getProfile.displayName)
        //setpictureUrl(getProfile.pictureUrl)
        const action = memberActions.handlelineid({photo:getProfile.pictureUrl,name:getProfile.displayName,lineid:getProfile.userId},history,"/play/deposit");
        dispatch(action);
      }else{
        liff.login();
      }
     
    }
    fetchData()
    const action = memberActions.handlelineid({photo:'ssss',name:"testlinexx",lineid:"Uae5bb93c4b4bce990408e21c801eb26f"},history,"/play/deposit");
    //dispatch(action);
    // call the function
   // fetchData()device_id phone
    
    //fetchData()
     
  }, []);
  
  
  localStorage.removeItem("tokenstatus");
  localStorage.removeItem("Signature");
  localStorage.removeItem("AdminToken");
  localStorage.removeItem("mobile");
  localStorage.clear();

  return (
<div style={{color:'#fff'}}>
กำลังเข้าสู่ระบบ HUAY-D ออโต้
</div>

  );
}
