import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "modules/configApp";

import { useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 385,
    backgroundColor: "#fff",
  },
  rootLoading: {
    zIndex: 10,
    top: 180,
    margin: "0 auto",
    position: "absolute",
  },
  media: {
    height: 175,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AutoGrid() {
  
  const location = useLocation();
 
  const loginsbobet = async (fkprovider, gameid, lineid, webtoken) => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `https://apiadminbetg.com/members/opengamelineauto`,
      params: { fkprovider, gameid,lineid, webtoken },
    
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;

    a.click();
    document.body.removeChild(a);
   
  };

  React.useEffect(() => {
    const { search } = location;
    
    const url2 = `https://www.google.com${search}`;
    const url = new URL(url2);

    const gameid = url.searchParams.get("gameid");
    const fkprovider = url.searchParams.get("fkprovider");
    const lineid = url.searchParams.get("lineid");

    loginsbobet(fkprovider,gameid,lineid, 'sswinbet')
   
  }, []);

  return (
    <>
      <div className="mload">กำลังเข้าสู่เกมส์...</div>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}
