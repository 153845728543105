import { calDiscountFunc,SumAmount,GetFraction,AddNewItems,calAllUpdate } from 'lib/common'
import {  PO_REQUEST,PO_SUCCESS,PO_UPDATE_ITEMS_BYID,PO_NEWS_SUCCESS,PO_NEWS_REQUEST,
    PO_ADD_ROW,PO_SELECT_ROW,PO_UPDATE_ITEMS,PO_UPDATE_BYVALUE,PO_DEL_ROW,PO_SELECT_ROW_ITEM,
    PO_SELECT_ROW_ALL,PO_REQUEST_DEL_ROW
} from './action'

const initailState = {
    isLoading:false,
    items:[],
    form:{
        isLoading:false,
        items:[],
        Cost:[],
        RowSelectItem:[],
        POItemAsset:[],
        ItemDel:[],
        CostItemDel:[],
        CostDel:[],
        AssetItemDel:[],
        RowId:null,
        Price:0,
        PriceAmount:0,
        Tax:0,
        TaxAmount:0,
        Amount:0,
        TaxType:2,
        BeforeTax:0,
        Value:0,
        ValueAmount:0,
        Discount:0,
        DiscountAmount:0,
        textbox:0,
        RowAll:false,
        Deposit:0,
        Retention:0,
        RetentionPercen:0,
        DepositPercen:0,
        Memo:null

    }
}

export default function (state = initailState,action){
    switch (action.type) {
        case PO_REQUEST:
            return {
                ...state,isLoading:true,items:[]
            }
        case PO_SUCCESS:
            const items = action.payload.map(res=>({...res,key:res.Id}))
                return {
                    ...state,isLoading:false,items
                }
        case PO_NEWS_REQUEST:
            return {
                ...state,form:{
                    ...state.form,
                    isLoading:true,
                    RowSelectItem:[],
                    items:[]
                }
            }
        case PO_NEWS_SUCCESS:
                return initailState
        case PO_SELECT_ROW:
            return {
                ...state,
                form:{
                    ...state.form,
                    RowId:action.payload
                }
            }
        case PO_SELECT_ROW_ALL:
            return {
                ...state,
                form:{
                    ...state.form,
                    RowAll:action.payload
                }
            }
        // เลือกแถวเพื่อลบข้อมูล
        case PO_SELECT_ROW_ITEM:{
            const {  RowSelectItem } = state.form
            const { items,selected} = action.payload
            let Items = []
            if(selected){
                const check = RowSelectItem.some(res=>res.No===items.No)
                if(!check){
                    RowSelectItem.push({...items})
                    Items = RowSelectItem
                }else{
                    Items = RowSelectItem
                }
            }else{
                Items = RowSelectItem.filter(res=>res.No!==items.No)
            }
            return {
                ...state,
                form:{
                    ...state.form,
                    RowSelectItem:Items
                }
            }
        }

        case PO_REQUEST_DEL_ROW:
            return {
                ...state,
                form:{
                    ...state.form,
                    isLoading:true
                }
            }
        // ลบข้อมูลแถวที่เลือก
        case PO_DEL_ROW:{
            const { RowSelectItem,items } = state.form
            const NewItem = RowSelectItem.reduce((result,list)=>{
                 const data = result.filter(res=>res.No!==list.No)
                 return [...data]
            },[...items])

            const {TaxRate,Fraction } = action.payload
        
            const newState = {
                form:{
                    ...state.form,
                    items:NewItem
                }
            }

            //const { items,Discount,Price} = state.form
                const{ DiscountAmount,
                    BeforeTax,
                    Tax,
                    TaxAmount,
                    Value,
                    Price,
                    ValueAmount,
                    PriceAmount,
                    Amount } = calAllUpdate(newState,'Del',Fraction,0,TaxRate)

            return {
                ...state,
                form:{
                    ...state.form,
                    isLoading:false,
                    items:NewItem,
                    RowSelectItem:[],
                    DiscountAmount,
                    BeforeTax,
                    Tax,
                    TaxAmount,
                    Value,
                    ValueAmount,
                    Price,
                    PriceAmount,
                    Amount
                }
            }
        }
        case PO_ADD_ROW:{
            const NewRow = {
                Id:'New',
                Type:1,
                FKUnit:1,
                key:1,
                No:1,
                TaxStatus:2,
                PriceAmount:0
            }
            const newState = {
                Items:state.form.items,
                RowId:state.form.RowId,
                FKCostCenter:0,
                itemsselect:[{...NewRow}],
                GetUnit:action.payload.unit.items
            }
            // const { Items,itemsselect,RowId,FKCostCenter } = state

            let NewItemsFuc = AddNewItems(newState)

           // console.log('NewItemsFuc',NewItemsFuc)
            /*
            const Items = [...state.form.items,NewRow]
            const NewItems = Items.map((res,index)=>{
                let No = ++index
                return {
                    ...res,No,key:No}
                }
            )
            */

            return {
                ...state,
                form:{
                    ...state.form,
                    items:NewItemsFuc
                    //items:NewItems
                }
            }
        }
        case PO_UPDATE_BYVALUE:
            {
                const { value,dataIndex } = action.payload
                return {
                    ...state,
                    form:{
                        ...state.form,
                        [dataIndex]:value
                    }
                }
            }
            
        case PO_UPDATE_ITEMS_BYID:
            {
                const {value,dataIndex,TaxRate,Fraction } = action.payload
        
                const{ DiscountAmount,
                    BeforeTax,
                    Tax,
                    TaxAmount,
                    Value,
                    ValueAmount,
                    PriceAmount,
                    Amount } = calAllUpdate(state,dataIndex,Fraction,value,TaxRate)
                    return {
                        ...state,
                        form:{
                            ...state.form,
                            [dataIndex]:value,
                            DiscountAmount,
                            BeforeTax,
                            Tax,
                            TaxAmount,
                            Value,
                            ValueAmount,
                            PriceAmount,
                            Amount
                        }
                    }
            }

        case PO_UPDATE_ITEMS:{
            const {  form } = state
            const { items,RowId,TaxType,Discount:DiscountText } = form
            const { dataIndex,PRQty,PriceUnit,OrderQty,Discount,TaxRate } = action.payload
            console.log('TaxRate',TaxRate)
            const PerCen7 = TaxRate+100
            const Percen007 = (10*TaxRate)/1000

            const newData = [...items];
            const index = newData.findIndex(item => item.No === RowId);
            const item = newData[index];
      
            const PriceAmount = parseFloat(PriceUnit || 0) *parseFloat(OrderQty || 0)
      
      // คำนวนหาส่วนลด ในแถวนั้นๆ
      const DiscountAmount = calDiscountFunc(Discount || 0,PriceAmount)
      const PriceAmountDiscount = PriceAmount-DiscountAmount

      newData.splice(index, 1, { ...item, ...action.payload,PriceAmount:PriceAmountDiscount,DiscountAmount });
      
      const ValuePriceAmount = SumAmount(newData,'PriceAmount')

      // คำนวนหาส่วนลดทั้งหมด 
      const DiscountAmountAll = calDiscountFunc(DiscountText || 0,ValuePriceAmount)

      // หาค่ารายการเฉพาะที่มีการยกเว้นภาษี
      const TotalNoVat = newData.filter(res=>res.TaxStatus===2)
      .reduce((prev,next) => prev + next.PriceAmount,0);

      
      // เปอร์เซ็นมูลค่าสินค้า
      let PerProduct = 0
      if(TotalNoVat===0 && ValuePriceAmount===0){
          PerProduct = 0
      }else{
          PerProduct = parseFloat(TotalNoVat/ValuePriceAmount)*100 || 0
      }

      // หามูลค่าสินค้าและบริการ 1,2
      // มูลค่าสินค้าหักส่วนลด
      let PerDiscountProduct = (parseFloat(ValuePriceAmount*PerProduct)/100)-(parseFloat(DiscountAmountAll*PerProduct)/100)

      // หาภาษีมูลค่าเพิ่ม 1,2
      let Tax=0
      // หาค่า ยอดเงินไม่รวมภาษี
      let BeforeTax = 0
      let Value = 0

      if(TaxType===2){
          // เช็คก่อนว่า ValueAmount มีค่าหรือยัง ถ้ามีใช้ค่า ValueAmount ถ้าไม่มีใช้ Value จากสูตรคำนวน
          // สูตรคำนวน Value อ้างอิงจาก PerDiscountProduct ด้านล่าง
          Tax = PerDiscountProduct*Percen007
      }
      if(TaxType===3){
        let NewValue = (PerDiscountProduct*100)/PerCen7
              Tax = NewValue*Percen007
      }
      if(TaxType!==2 && TaxType!==3){
         Tax=0
    }

      if(TaxType!==3){
          // กรณีแบบ ไม่มี,แยก,ภาษี 0
          BeforeTax = ValuePriceAmount - DiscountAmountAll
          Value = PerDiscountProduct
      }else{
          // กรณีแบบรวม
          Value = (PerDiscountProduct*100)/PerCen7
          BeforeTax = (ValuePriceAmount - DiscountAmountAll)-Tax
          
      }

      let Amount = BeforeTax+Tax

            return {
                ...state,
                form:{
                    ...state.form,
                    items:newData,
                    Price:ValuePriceAmount,
                    PriceAmount:ValuePriceAmount,
                    BeforeTax,
                    Value,
                    ValueAmount:Value,
                    DiscountAmount:DiscountAmountAll,
                    Amount,
                    Tax,
                    TaxAmount:Tax
                }
            }
        }
        default:
            return state
    }
}