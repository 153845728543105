import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import PORoutes from "modules/purchase/po/components/Routes";
import dashboardRoutes from "modules/dashboard/components/Routes";
import transferRoutes from "modules/transfer/Routes";
import withdrawRoutes from "modules/withdraw/Routes";
import memberRoutes from "modules/member/Routes";
import casinoRoutes from "modules/casino/Routes";
import chatRoutes from "modules/chat/Routes";
import logingameRoutes from "modules/logingame/Routes";
//import Signin from "./Signin"; // Thai
import Signin from "./Signin2";
import Withdraw from "./Withdraw";
import Deposit from "./Deposit";
import ResultPage from "./ResultPage";
import Poyhuay from "./Poyhuay";
import Payment from "./Payment";
import Playcasino from "./Playcasino";
import Index from "./Index";
import Register from "./Register";
import RegisterNew from "./RegisterNew"; // าว
//import RegisterNew from "./RegisterNewTh";
import Forgotpass from "./Forgotpass";
//import Forgotpass from "./ForgotpassThai";
import RegisterStep2 from "./RegisterStep2"; // lao
//import RegisterStep2 from "./RegisterStep2Th";
import RegisterStep3 from "./RegisterStep3";
//import RegisterStep3 from "./RegisterStep3Th";
import NotFoundComponent from "./NotFoundComponent";
import Promotion from "./Promotion";
import Contact from "./Contact";
import ContactUs from "./ContactUs";
import ContactUsCasino from "./ContactUs_Casino";

import * as loginActions from "redux/signin/action";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      <Component {...props} /> 
    }
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Signin {...props} />
      )
    }
  />
);

//ContactUs

const ContactRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Contact {...props} />
      )
    }
  />
);

const ContactUsRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      <ContactUs {...props} />
    }
  />
);

const ContactUsCasinoRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      <ContactUsCasino {...props} />
    }
  />
);



const RegisteRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Register {...props} />
      )
    }
  />
);

const ForgotpassRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Forgotpass {...props} />
      )
    }
  />
);

const RegisteRouteStep2 = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <RegisterStep2 {...props} />
      )
    }
  />
);

const RegisteRouteStep3 = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <RegisterStep3 {...props} />
      )
    }
  />
);

const RegisteNewRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <RegisterNew {...props} />
      )
    }
  />
);

const PromotionRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Promotion {...props} />} />
);

const PoyRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Poyhuay {...props} />} />
);

const PlaycasinoRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Playcasino {...props} />} />
);

const PaymentRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Payment {...props} />} />
);

const WithdrawRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Withdraw {...props} />} />
);

const DepositRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Deposit {...props} />} />
);

const ResultRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <ResultPage {...props} />} />
);

export default function Content() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    //console.log("App created");

    dispatch(loginActions.reLogin());
  }, [dispatch]);
  return (
    <Switch>
      <WithdrawRoute path="/withdrawnewauto" component={Withdraw} />
      <PrivateRoute path="/logingame" component={logingameRoutes} />
      <PrivateRoute path="/deposit" component={Deposit} />
      <PrivateRoute path="/withdraw" component={withdrawRoutes} />
      <PrivateRoute path="/member" component={memberRoutes} />
      <PrivateRoute path="/chat" component={chatRoutes} />
      <PrivateRoute path="/po" component={PORoutes} />
      <PrivateRoute path="/dashboard" component={dashboardRoutes} />
  <PrivateRoute path="/play" component={casinoRoutes} />
      <LoginRoute path="/signin" component={Signin} />
      <PoyRoute path="/poy" component={Poyhuay} />
      <PoyRoute path="/casinos" component={PlaycasinoRoute} />
      <ResultRoute path="/result" component={ResultPage} />
      <PaymentRoute path="/payment" component={Payment} />
      <ContactRoute path="/contact" component={Contact} />
      <ContactUsRoute path="/contactus" component={ContactUs} />
      <ContactUsCasinoRoute path="/casinos" component={ContactUsCasino} />
      <RegisteNewRoute path="/signup" component={RegisteNewRoute} />
      <RegisteRoute path="/register" component={Register} />
      <PromotionRoute path="/promotion" component={Promotion} />
      <ForgotpassRoute path="/forgotpass" component={Forgotpass} />
      <RegisteRouteStep2 path="/otp" component={RegisterStep2} />
      <RegisteRouteStep3 path="/registerconfirm" component={RegisterStep3} />
      <LoginRoute exact path="/" component={Signin} />
      <Route component={NotFoundComponent} />
    </Switch>
  );
}
