import React from "react";

import { useTranslation } from "react-i18next";

import useFetch from "lib/useFetch";
import { API_URL, addCommas, lg } from "modules/configApp";

const lgtext = {
  thai:{
    1:'สมาชิกแนะนำรวม',
    2:'สมาชิกแนะนำ',
    3:'ยอดรายได้รวม',
    4:'ยอดรายได้',
    5:'รายได้รอยืนยัน',
    6:'รายได้รอยืนยัน',
  },
  lao:{
    1:'ສະມາຊິກແນະນຳລວມ',
    2:'ສະມາຊິກແນະນຳ',
    3:'ຍອດລາຍໄດ້ລວມ',
    4:'ຍອດລາຍໄດ້',
    5:'ລາຍໄດ້ຖ້າຢືນຢັນ',
  }
}


export default function FullWidthTabs(props) {
 
  const { t } = useTranslation();

  const showmoney = (credit) => {
    if (credit > 0) {
      return (
        <font color="green">+{addCommas(parseFloat(credit).toFixed(0))}</font>
      );
    } else {
      return (
        <font color="red">{addCommas(parseFloat(credit).toFixed(0))}</font>
      );
    }
  };

  const showturn = (credit) => {
    if (credit > 0) {
      return (
        <font color="green">{addCommas(parseFloat(credit).toFixed(0))}</font>
      );
    } else {
      return (
        <font color="red">{addCommas(parseFloat(credit).toFixed(0))}</font>
      );
    }
  };

  //const checkwinloss = useFetch(API_URL + "/bet/1/winlossaff", {}, "get");
  const checkwinlosshuay = useFetch(API_URL + "/huay/huay_sumaff", {}, "get");

  return (
    <>
    {!checkwinlosshuay?.item && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
    <div className="topcontain">
      <ul className="dashboard">
        <li>
          <div className="boxdashboard">
            <div className="dashline">{lgtext[lg]['1']}</div>
            <span className="bnxm2">
              {checkwinlosshuay?.item
                ? showturn(checkwinlosshuay?.item?.member_total)
                : 0}
            </span>
          </div>
        </li>
        <li>
          <div className="boxdashboard">
            <div className="dashline">{lgtext[lg]['3']}</div>
            <span className="bnxm2">
            {checkwinlosshuay?.item
                ? showmoney(checkwinlosshuay?.item?.money)
                : 0}
            </span>
          </div>
        </li>
        <li>
          <div className="boxdashboard">
            <div className="dashline">{lgtext[lg]['2']}(7%)</div>
            <span className="bnxm2">
            {checkwinlosshuay?.item
                ? showturn(checkwinlosshuay?.item?.member_level1)
                : 0}
            </span>
          </div>
        </li>
        
        <li>
          <div className="boxdashboard">
            <div className="dashline">{lgtext[lg]['4']}(7%) </div>
            {
              /*
 <span className="bnxm2">{checkwinlosshuay?.item
                ? showmoney(checkwinlosshuay?.item?.sumbetamount_level1_typeaff1)
                : 0}</span>
              */
            }
           
<div style={{ paddingTop:1}}><span className="bnxm2">{checkwinlosshuay?.item
                ? showmoney(checkwinlosshuay?.item?.sumbetamount_level1_total)
                : 0}</span></div>

                
          </div>
        </li>
        <li>
          <div className="boxdashboard">
            <div className="dashline">{lgtext[lg]['2']}(1%)</div>
            <span className="bnxm2">{checkwinlosshuay?.item
                ? showturn(checkwinlosshuay?.item?.member_level2)
                : 0}</span>
          </div>
        </li>
        <li>
          <div className="boxdashboard">
            <div className="dashline">{lgtext[lg]['4']}(1%) </div>
            <span className="bnxm2">{checkwinlosshuay?.item
                ? showmoney(checkwinlosshuay?.item?.sumbetamount_level2_total)
                : 0}</span>
          </div>
        </li>
       
      </ul>
    </div>
    </>
  );
}
