import {  BANK_REQUEST,BANK_SUCCESS,BANK_NEWS_REQUEST,BANK_NEWS_SUCCESS,
    BANK_SAVE_REQUEST,BANK_SAVE_SUCCESS,BANK_SAVE_FAILED,
    BANK_EDIT_REQUEST,BANK_EDIT_SUCCESS,BANK_EDIT_FAILED } from './action'

const initailState = {
    isLoading:false,
    items:[],
    formx:{
        Id:'New',
        Name:null,
        Url:null,
        Using:true
    }
}

export default function (state = initailState,action){
    switch (action.type) {
        case BANK_REQUEST:
            return {
                ...state,isLoading:true,items:[]
            }
        case BANK_SUCCESS:
            const items = action.payload.map((res,index)=>({...res,key:++index}))
                return {
                    ...state,isLoading:false,items
                }
        case BANK_NEWS_REQUEST:
            return {
                ...state,isLoading:true,formx:{
                    ...initailState.formx
                }
            }
        case BANK_NEWS_SUCCESS:
                return {
                    ...state,isLoading:false,formx:{
                        ...initailState.formx
                    }
                }
        case BANK_SAVE_REQUEST:
            return {
                ...state,isLoading:true
            }
        case BANK_SAVE_SUCCESS:
                return {
                    ...state,isLoading:false,formx:{...action.payload,Id:`${action.payload.Id}`}
                }
        case BANK_SAVE_FAILED:
                    return {
                        ...state,isLoading:false
                    }
        case BANK_EDIT_REQUEST:
            return {
                        isLoading:true,formx:{
                         ...initailState.formx
                        }
                    }
        case BANK_EDIT_SUCCESS:
            return {
                        ...state,isLoading:false,formx:{
                            ...action.payload,Id:`${action.payload.Id}`}
                    }
        case BANK_EDIT_FAILED:
            return {
                        ...state,isLoading:false
                    }
        default:
            return state
    }
}