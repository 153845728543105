import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import  { Button,Form,Input,Select,DatePicker } from 'antd'
import { APP_TEXT } from 'modules/configApp'

import * as poActions from 'redux/purchase/po/action'

 const BtnAction=()=> {
    const dispatch = useDispatch()
    const { RowAll } = useSelector(store=>store.po.form)
    const AddRow =()=> {
        const action = poActions.AddRow()
        dispatch(action)
    }

    const DelRow =()=> {
      if(RowAll){
        const action = poActions.DelAllRow()
        dispatch(action)
      }else{
        const action = poActions.DelRow()
        dispatch(action)
      }
      
    }
    return <>
    รายการสั่งซื้อ <Button type="primary">PR</Button> <Button type="primary">BOQ</Button> <Button type="primary">ITEMS</Button> <Button type="primary" onClick={AddRow}>เพิ่ม</Button> <Button type="primary" onClick={DelRow} danger>ลบ</Button>
    </>
}


const InputType =({save,dataIndex,inputRef,title,record,unitItems,modalMeterial,modalLabor})=> {
    if(dataIndex==='TaxStatus'){
        return <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      
      >
          <Select ref={inputRef} onChange={save} showSearch={true} onBlur={save}
         
           filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              APP_TEXT.TaxStatus.map(res=><Select.Option key={res.Id} value={res.Id}>{res.Name}</Select.Option>)
            }
            
          </Select>
        
      </Form.Item>
    }
if(dataIndex==='ReceivingDate'){
    return <Form.Item
    style={{
      margin: 0,
    }}
    name={dataIndex}
  
  >
      <DatePicker ref={inputRef} onChange={save} onBlur={save} />
    
    
  </Form.Item>
}
   if(dataIndex==='Description'){
    const { Type } = record
    if(Type!==2 && Type!==3){
        return <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
       
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    }else{
        return <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      
      >
         <Input.Search ref={inputRef} placeholder="รหัส,ชื่อ" onSearch={Type ===2 ? modalMeterial:modalLabor}  enterButton onBlur={save} />
        
      </Form.Item>
    }
   
   }
   if(dataIndex==='Type'){
    return <Form.Item
    style={{
      margin: 0,
    }}
    name={dataIndex}
  
  >
      <Select ref={inputRef} onChange={save} showSearch={true} onBlur={save}
     
     filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
     dropdownMatchSelectWidth={300}
      >
        {
          APP_TEXT.type_items.map(res=><Select.Option key={res.Id} value={res.Id}>{res.Name}</Select.Option>)
        }
        
      </Select>
    
  </Form.Item>
}

    if(dataIndex==='FKUnit' && record.Type!==5){
        return <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      
      >
          <Select ref={inputRef} onChange={save} showSearch={true} onBlur={save}
         dropdownMatchSelectWidth={300}
           filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              unitItems.map(res=><Select.Option key={res.Id} value={res.Id}>{res.Name}</Select.Option>)
            }
            
          </Select>
        
      </Form.Item>
    }else{
        return <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
       
      >
        <Input ref={inputRef} onPressEnter={save} className='textRight' onBlur={save} />
      </Form.Item>
    }
    
    /*
        return <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
       
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    */
    
  }

export {
    BtnAction,
    InputType
}
