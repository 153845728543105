import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import PageList from "./PageList";
import Sport from "./Sport";
import Evoplay from "./Evoplay";
import Joker from "./Joker";
import Habanero from "./Habanero";
import GameList from "./Gamelist";
import Slot from "./Slot";
import Casino from "./Casino";
import Lotto from "./Lotto";
import Deposit from "./Deposit";
//import Lotto from "./LottoTh";
import LottoBet from "./LottoBet";
import LottoBetResult from "./LottoBetResult";
import Lotto_Result from "./Lotto_Result";
import Lotto_Poy from "./Lotto_Poy";
import Lotto_Payment from "./Lotto_Payment";
import Lotto_Poy_Detail from "./Lotto_Poy_Detail";
import Lotto_Poy_Detail_Payment from "./Lotto_Poy_Detail_Payment";
import Howto from "./Howto";

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:webtype/gamelist`} component={GameList}></Route>
      <Route path={`${path}/:type/:id/lottobet`} component={LottoBet}></Route>
      <Route path={`${path}/poypayment/:id`} component={Lotto_Poy_Detail_Payment}></Route>
      <Route path={`${path}/poy/:id`} component={Lotto_Poy_Detail}></Route>
     
      <Route path={`${path}/lotto/:id`} component={LottoBetResult}></Route>
      <Route path={`${path}/howto`} component={Howto}></Route>
      <Route path={`${path}/evoplay`} component={Evoplay}></Route>
      <Route path={`${path}/joker`} component={Joker}></Route>
      <Route path={`${path}/habanero`} component={Habanero}></Route>
      <Route path={`${path}/sport`} component={Sport}></Route>
      <Route path={`${path}/casino`} component={PageList}></Route>
      <Route path={`${path}/casinos`} component={Casino}></Route>
      <Route path={`${path}/slot`} component={Slot}></Route>
      <Route path={`${path}/lotto`} component={Lotto}></Route>
      <Route path={`${path}/resultlotto`} component={Lotto_Result}></Route>
      <Route path={`${path}/poy`} component={Lotto_Poy}></Route>
      <Route path={`${path}/deposit`} component={Deposit}></Route>
      <Route path={`${path}/payment`} component={Lotto_Payment}></Route>
      <Route path="/" component={PageList}></Route>
    </Switch>
  );
}
