import {
  UI_REQUEST,
  UI_SUCCESS,
  UI_NOTIFY_REQUEST,
  UI_NOTIFY_SUCCESS,
  UI_NOTIFY_FAILED,
  UI_CLEAR_REQUEST,
  UI_CHAT_REQUEST,
} from "./action";
import io from "socket.io-client";
import { ENDPOINT } from "modules/configApp";

const initailState = {
  isLoading: false,
  items: [],
  notify: {
    error: false,
    success: false,
    werror: false,
    wsuccess: false,
  },
  lastadminpost: null,
  socket: null,
  socketdata: null,
  socketmsg: null,
  chat: false,
};

const data = [
  {
    Id: 1,
    Name: "ลูกค้า",
    Link: "member",
    Count: 0,
  },
  {
    Id: 2,
    Name: "ฝาก",
    Link: "deposit",
    Count: 0,
  },
  {
    Id: 3,
    Name: "ถอน",
    Link: "withdraw",
    Count: 0,
  },
  {
    Id: 4,
    Name: "การเงิน",
    Link: "finance",
    Count: 0,
  },
  /*
                {
                    Id:5,Name:'ข้อมูลระบบ',Link:'setting',Count:2,
                    Children:[
                        {Id:51,Name:'ข้อมูลผู้ใช้งาน',Link:'user',Count:0},
                        {Id:52,Name:'ข้อมูลเว็บไซต์',Link:'website',Count:0},
                        {Id:53,Name:'ข้อมูลธนาคาร',Link:'bank',Count:0}]
                }
                */
];

export default function (state = initailState, action) {
  switch (action.type) {
    case UI_CLEAR_REQUEST:
      return {
        ...state,
        notify: {
          error: false,
          success: false,
          werror: false,
          wsuccess: false,
        },
        chat: false,
      };

    case UI_CHAT_REQUEST:
      return {
        ...state,
        chat: action.payload,
      };

    case UI_REQUEST:
      return {
        ...state,
        isLoading: true,
        items: [],
      };
    case UI_SUCCESS:
      const nio = io(ENDPOINT);
      return {
        ...state,
        isLoading: false,
        items: data,
        socket: nio,
      };

    case UI_NOTIFY_REQUEST:
      return {
        ...state,
        notify: {
          error: false,
          success: false,
          werror: false,
          wsuccess: false,
        },
      };
    case UI_NOTIFY_SUCCESS:
      if (action.payload?.chatmember === true) {
        return {
          ...state,
          socketmsg: action.payload,
          lastadminpost: Date.now(),
        };
      } else {
        return {
          ...state,
          notify: action.payload,
        };
      }

    case UI_NOTIFY_FAILED:
      return {
        ...state,
        notify: {
          error: false,
          success: false,
          werror: false,
          wsuccess: false,
        },
      };
    default:
      return state;
  }
}
