import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Spin,Row,Col,Form,Input, Button,Tree } from 'antd';

import { getWindowDimensions  } from 'modules/configApp'
import { textName } from 'lib/language'

import * as costcenterActions from 'redux/costcenter/project/action'

const CostCenterModal=({onAction,textSearch,checkmodal})=> {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [form] = Form.useForm();
    const dispatch = useDispatch()
    const {isLoading,items} = useSelector(store=>store.costcenter)
   
    const ActionData =(Id,{node})=> {
        
        let onj = {
           ...node
          }
          console.log(onj)
        onAction(onj)
    }

    useEffect(()=>{
      // ถ้าเปิด modal ถึงดึงข้อมูลใหม่
      if(checkmodal){
        const Code = textSearch?.split(':')[0]
        const action = costcenterActions.GetListTree({Code:Code,UserId:localStorage.getItem('UserId')})
        dispatch(action)
        form.setFieldsValue({
          Code: Code,
        });
      }
     
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
    
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
      
    },[textSearch,dispatch,form,checkmodal])
 
   


    const onFinish = (values) => {
     const action = costcenterActions.GetListTree({...values,UserId:localStorage.getItem('UserId')})
      dispatch(action)
  };

  

  const onReset = () => {
    form.resetFields();
  };

  const formlayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };



const goTabForm =(event,name)=> {
  if (event.key === 'Enter') {
    event.preventDefault();
    //this[name].focus();
    console.log('ok')
  }
}
    return (
      <div style={{minHeight:windowDimensions.height-243}} >
     
     <Form
       form={form} 
       name="control-hooks" 
        {...formlayout}
        onFinish={onFinish}
        
      >
 <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
          name="Code"  
         label={textName.th.code}
        >
          <Input 
          onKeyPress={goTabForm} 
          />
        </Form.Item>
        
        <Form.Item
          name="Name"  
         label={textName.th.name}
        >
          <Input 
          onKeyPress={goTabForm} 
          />
        </Form.Item>
       
      
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
           
        <Form.Item
          name="StatusId"  
          label={textName.th.status}
        >
          <Input  />
        </Form.Item>
        <Form.Item 
        label=":"
        >
        <Button type="primary" htmlType="submit">
          ค้นหา
        </Button>
        <span className='dotbtn'></span>
        <Button type="primary" htmlType="button" onClick={onReset}>
          เคลียร์
        </Button>
        </Form.Item>
            </Col>
          
        </Row>

        
       
      </Form>
      
         
      
          {isLoading ? <Spin/> : <Tree
        showLine={true}
        defaultExpandAll={true}
        onSelect={ActionData}
        treeData={isLoading ? []:items}
      />}

      
        
        </div>
       
    )
}

export default CostCenterModal