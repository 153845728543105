import {  USER_REQUEST,USER_SUCCESS,USER_NEWS_REQUEST,USER_NEWS_SUCCESS,USER_NEWS_FAILED,
    USER_SAVE_REQUEST,USER_SAVE_SUCCESS,USER_SAVE_FAILED,
    USER_EDIT_REQUEST,USER_EDIT_SUCCESS,USER_EDIT_FAILED } from './action'

const initailState = {
    isLoading:false,
    items:[],
    formx:{
        Id:'New',
        Name:null,
        UserName:null,
        Password:null,
        Using:true
    }
}

export default function (state = initailState,action){
    switch (action.type) {
        case USER_REQUEST:
            return {
                ...state,isLoading:true,items:[]
            }
        case USER_SUCCESS:
            const items = action.payload.map((res,index)=>({...res,key:++index}))
                return {
                    ...state,isLoading:false,items
                }
        case USER_NEWS_REQUEST:
            return {
                ...state,isLoading:true,formx:{
                    ...initailState.formx
                }
            }
        case USER_NEWS_SUCCESS:
                return {
                    ...state,isLoading:false,formx:{
                        ...initailState.formx
                    }
                }
        case USER_SAVE_REQUEST:
            return {
                ...state,isLoading:true
            }
        case USER_SAVE_SUCCESS:
                return {
                    ...state,isLoading:false,formx:{...action.payload,Id:`${action.payload.Id}`}
                }
        case USER_SAVE_FAILED:
                    return {
                        ...state,isLoading:false
                    }
        case USER_EDIT_REQUEST:
            console.log('redux-USER_EDIT_REQUEST',state.formx.Name)
            return {
                        isLoading:true,formx:{
                            Id:'New',
                            Name:null,
                            UserName:null,
                            Password:null,
                            Using:1
                        }
                    }
        case USER_EDIT_SUCCESS:
            const {
                Id,
                Name,
                UserName,
                Password,
                Using
            } = action.payload
            console.log('redux-Name',Name)
            return {
                        ...state,isLoading:false,formx:{
                            Name,
                            UserName,
                            Password,
                            Using,Id:`${action.payload.Id}`}
                    }
        case USER_EDIT_FAILED:
            return {
                        ...state,isLoading:false
                    }
        default:
            return state
    }
}