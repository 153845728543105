import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import Badge from "@material-ui/core/Badge";
//import SmsIcon from "@material-ui/icons/Sms";
//import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

//import PaymentIcon from "@material-ui/icons/Payment";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff",
    height: 93,
    //background: "transparent",
    //opacity: 0.9,
  },
  roottop: {
    position: "fixed",
    bottom: 100,
    width: "100%",
    background: "transparent",

    //opacity: 0.9,
  },
  txt: {
    color: "#fff",
    fontSize: 16,
  },
  txtbet: {
    color: "#f5cb08",
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  //const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const goPage = (path) => {
    history.push(path);
  };
  const { countchat } = useSelector((store) => store.chat);

  return <div>
    
  </div>;
}
