import {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNOUT_SUCCESS,
  SIGNIN_FAILED,
  SIGNIN_PASS_FAILED,
  SIGNIN_CLEAR,
} from "./action";

const initailState = {
  isLoading: false,
  items: null,
  message: null,
  count: 0,
  show: false,
  keyrun: 1,
};

export default function (state = initailState, action) {
  switch (action.type) {
    case "SUM_KEY":
      return {
        ...state,
        keyrun: state.keyrun + 1,
      };
    case SIGNIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        items: null,
        message: null,
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: null,
        items: action.payload,
        count: 0,
      };
    case SIGNIN_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        show: true,
      };
    case SIGNIN_CLEAR:
      return {
        ...state,
        isLoading: false,
        show: false,
      };
    case SIGNIN_PASS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        count: state.count + 1,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: null,
        message: null,
        count: 0,
      };
    default:
      return state;
  }
}
