import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

//import SmsIcon from "@material-ui/icons/Sms";
//import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

//import PaymentIcon from "@material-ui/icons/Payment";
import { WEB_NAME,PHOME } from '../../configApp'

const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff",
    height: 93,
    //background: "transparent",
    //opacity: 0.9,
  },
  roottop: {
    position: "fixed",
    bottom: 100,
    width: "100%",
    background: "transparent",

    //opacity: 0.9,
  },
  txt: {
    color: "#fff",
    fontSize: 16,
  },
  txtbet: {
    color: "#f5cb08",
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  //const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const goPage = (path) => {

    if(PHOME.linkcasino ==1){
      history.push(path);
    } else {
      if(path == '/casinos'){

      } else {
        history.push(path);
      }
    }
  
   
  };
  const { countchat } = useSelector((store) => store.chat);

  const goPage2 =(path)=> {
    if(WEB_NAME !== 'mom147') {
      window.open(path)
    }
  }
  return (
    <div className="footer">
      <ul className="ufooter">
       
 <li onClick={() => goPage("/play/lotto")}>
          <div className={pathname.includes("lotto") && "acbuttom"}>
            <img src="/images/them2/huay.png"></img>
            <div className="acbottomac">หวย</div>
          </div>
        </li>

 <li onClick={() => goPage("/play/sport")}>
          <div className={pathname.includes("sport") && "acbuttom"}>
            <img src="/images/them2/ball.png"></img>
            <div className="acbottomac">กีฬา</div>
          </div>
        </li>
 
        <li onClick={() => goPage("/play/casinos")}>
          <div className={pathname.includes("casinos") && "acbuttom"}>
            <img src="/images/them2/casino.png"></img>
            <div className="acbottomac">คาสิโน</div>
          </div>
        </li>

        <li onClick={() => goPage("/play/slot")} >
          <div className={pathname.includes("slot") && "acbuttom"} >
            <img src="/images/them2/sl.png"></img>
            <div className="acbottomac">สล๊อต</div>
          </div>
        </li>
      </ul>
    </div>
  );
}
