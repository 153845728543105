import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";


import { red } from "@material-ui/core/colors";
import * as uiActions from "redux/ui/action";
import * as loginAcions from "redux/signin/action";
import * as memberActions from "redux/member/action";
import { addCommas } from "../../configApp";
//import { Agent } from "modules/configApp";
import io from "socket.io-client";
import { ENDPOINT } from "modules/configApp";
import { Button } from "antd";
//const ENDPOINT = "http://128.199.177.142:9000/";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //backgroundColor:'#c20731',
    backgroundColor: "#fff",
    //opacity: 0.9,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawerHeaderFix: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    backgroundColor: "#181818",
  },
  drawerHeaderFix2: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    backgroundColor: "#181818",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  avatar: {
    backgroundColor: red[500],
  },
  money: {
    color: "#a29134",
  },
}));

export default function PersistentDrawerRight() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const changeLanguage = (event) => {
    localStorage.setItem("LG", event);
    setAnchorEl(null);
    i18n.changeLanguage(event);
  };

  const { items: itemsSignin } = useSelector((store) => store.signin);
  const { isLoading, items } = useSelector((store) => store.member);

  const logout = () => {
    dispatch(loginAcions.logout(history));
  };

  //console.log(items);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openleft, setOpenleft] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen2 = () => {
    setOpenleft(true);
  };

  const handleDrawerClose2 = () => {
    setOpenleft(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goPage = (path) => {
    history.push(path);
    setOpenleft(false);
    setOpen(false);
  };
  //const logo = "images/hlogo2.png"
  //const logo = "images/biglogo7777.png";
  const logo = "/images/skylot44.png"
  //const logo = "images/pt2.png";
  return (
    <div className="hhtop">
      <ul className="hfooter">
        <li>
          <img src={logo}></img>
        </li>
        <li></li>
      </ul>
    </div>
  );
}
