import axios from 'axios'
import { API_URL } from 'modules/configApp'

const UNIT_REQUEST = 'UNIT/REQUEST'
const UNIT_SUCCESS = 'UNIT/SUCCESS'
const UNIT_FAILED = 'UNIT/FAILED'
const PartAPI = 'Unit'
const GetList = (data) => {
    return async (dispatch) => {
        dispatch({type:UNIT_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetList`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:UNIT_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:UNIT_FAILED})
          }
         
      }
}

export {
    UNIT_REQUEST,
    UNIT_SUCCESS,
    UNIT_FAILED,
    GetList
}