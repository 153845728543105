import React, { useState,useEffect,useRef } from 'react'
import { useHistory,useRouteMatch,useParams } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import { Table,Tabs,Spin,Form,Input, Button,DatePicker,Modal,Select } from 'antd';
import {PaperClipOutlined } from '@ant-design/icons'

import moment from 'moment'
import useFetch from 'lib/useFetch'
import { API_URL,APP_TEXT,getWindowDimensions,addCommas,pageSize,dateFormat  } from 'modules/configApp'
import { textName } from 'lib/language'
import MenuRight from 'modules/ui/components/MenuRight'
import {PageRightTitle} from 'modules/ui/components/ShareComponent'
import {ResizableTitle} from 'modules/ui/components/ResizableTitle'

import Supplier from 'lib/modals/Supplier'
import Approve from 'lib/modals/User'
import CostCenter from 'lib/modals/CostCenter'

import * as poActions from 'redux/purchase/po/action'
import * as subdocActions from 'redux/setting/subdoc/action'

const { Search } = Input;
const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

const List=(props)=> {
  const [showMenu,setShowMenu] = useState(true)
  const [showMenuMobile,setShowMenuMobile] = useState(false)
  
  const ClassMenuLeft = showMenu ? "content_left":"content_left_ac"
   
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [form] = Form.useForm();
    const dispatch = useDispatch()
    const {isLoading,items} = useSelector(store=>store.po)
    const {isLoading:isLoadingSubdoc,items:itemsSubdoc} = useSelector(store=>store.subdoc)
    const getsystem = useSelector(store=>store.system)
    const history = useHistory()
    const {id,menuid} = useParams()

    const {path} = useRouteMatch()
    const handleTab =(e)=> {
        console.log(path,id,menuid)
        history.push(`/po/${id}/${menuid}/${e}`)
    }

    const btnSubmit = useRef(null);

    // ฟังชั่นทำงาน Right Menu Bar
    const onActionRight=(type)=> {
      //btnSubmit.current.click()
      if(type===1){history.push(`/po/${id}/${menuid}/new`)}
      
    }

    const ActionData =(record)=> {
      
      console.log(path,id,menuid)
      history.push(`/po/${id}/${menuid}/edit/${record.Id}`)
    }

    useEffect(()=>{

      const action = poActions.GetList({SubDocTypeId:id,Date1:getsystem.items.StartDate+' 00:00:00',Date2:getsystem.items.ToDay+' 23:59:59'})
      dispatch(action)

      const subdocAction = subdocActions.GetSubdoc(id)
      dispatch(subdocAction)

      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    },[dispatch,getsystem.items.StartDate,getsystem.items.ToDay,id])
 
    const AttShow =(Attachment,text)=> {
      return <>{text} {Attachment!==0 && <PaperClipOutlined style={{color:'blue'}} /> }</>
    }
    const columnslist = [
      { title: 'เลขที่เอกสาร', width: 180, dataIndex: 'Code', key: 'Code',ellipsis:true,
      render: (text,{Attachment}) => AttShow(Attachment,text)},
      { title: 'วันที่สั่งซื้อ', width: 100, dataIndex: 'DocumentDate', key: 'DocumentDate',
      render: text => moment(text).format('DD/MM/YYYY')},
      { title: 'ผู้สั่งซื้อ', dataIndex: 'UserName', key: 'UserName', width: 150,ellipsis:true,
      render: text => text },
      { title: 'Cost Center', dataIndex: 'CostCenterName', key: 'CostCenterName', width: 300, ellipsis:true,
      render: text => text },
      { title: 'วันที่รับของ', dataIndex: 'DeliveryDate', key: 'DeliveryDate', width: 100,
      render: text => moment(text).format('DD/MM/YYYY')},
      { title: 'ยอดรวม', dataIndex: 'Amount', key: 'Amount', align:'right', width: 150 ,sorter: (a, b) => a.Amount - b.Amount,
      render: text => text ? addCommas(parseFloat(text).toFixed(2)) :0},
      { title: 'ยอดภาษี', dataIndex: 'TaxAmount', key: '5', width: 150,align:'right',sorter: (a, b) => a.TaxAmount - b.TaxAmount,
      render: text => text ? addCommas(parseFloat(text).toFixed(2)) :0},
      { title: 'ยอดรวมภาษี', dataIndex: 'TaxAmount', key: '6', width: 150 ,align:'right',sorter: (a, b) => a.TaxAmount - b.TaxAmount,
      render: text => text ? addCommas(parseFloat(text).toFixed(2)) :0},
      { title: 'ผู้อนุมัติ', dataIndex: 'approve', key: '7', width: 100,
      render: text => text},
      { title: 'วันที่อนุมัติ', dataIndex: 'ApproveDate', key: 'ApproveDate',width: 100,
      render: text => text && moment(text).format("DD/MM/YYYY")},
      {
        title: "ขั้น (แถวที่)",
        dataIndex: "DocAppNo",
        key: "DocAppNo",
        width: 100,
        render: text => (
          <div className="textMore" style={{ width: 94 }}>
            {text}
          </div>
        )
      },
      {
        title: "สถานะอนุมัติ",
        dataIndex: "ApproveStatusName",
        key: "ApproveStatusName",
        width: 100,
        render: text => (
          <div className="textMore" style={{ width: 94 }}>
            {text}
          </div>
        )
      },
      { title: 'หมายเหตุ', dataIndex: 'Memo', key: 'Memo',width: 100,
      render: text => <div className="textMore" style={{width:94}} >{text}</div> },
      { title: 'การอ้างอิง', dataIndex: 'Ref', key: 'Ref',width: 100,
      render: text => <div className="textMore" style={{width:94}} >{text}</div> },
      { title: 'ความเห็น', dataIndex: 'Comment', key: 'Comment',width: 100,
      render: text => <div className="textMore" style={{width:94}} >{text}</div> },
      { title: 'สถานะใบสั่งซื้อ', width: 120, dataIndex: 'StatusName', key: 'StatusName',
      render: text => <div className="textMore"  >{text}</div>},
      {
        title: 'สถานะการปิด PO',
        key: 'operation',
        render: () => '',
      },
    ]
    const [state,setState] = useState({columns:columnslist})

    const handleResize = index => (e, { size }) => {
     setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    })
    };

    const components = {
      header: {
        cell: ResizableTitle,
      },
    };

   
  
    const columns = () => state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: handleResize(index),
      }),
    }));

    const onFinish = (values) => {
      const { CostCenterId,SupplierId,ApproveId } = textSearch
      const [ d1,d2] = values.DocumentDate ? values.DocumentDate:[null,null]
      const Date1 = d1 ? moment(d1).format('DD/MM/YYYY'):getsystem.items.StartDate+' 00:00:00'
      const Date2 = d2 ? moment(d2).format('DD/MM/YYYY'):getsystem.items.ToDay+' 23:59:59'
     const action = poActions.GetList({SubDocTypeId:id,...values,Date1,Date2,CostCenterId,SupplierId,ApproveId})
      dispatch(action)
  };

  
 // เคลียร์ค่า state ในฟอร์ม
  const onReset = () => {
    form.resetFields();
    settextSearch({Date1:getsystem.items.StartDate+' 00:00:00',Date2:getsystem.items.ToDay+' 23:59:59'})
  };

  const formlayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

const status = useFetch(API_URL+'/PO/GetStatus',{},'get')

const [textSearch,settextSearch] = useState({})
const [modalVisible,setmodalVisible] = useState(false)
// ปิด/เปิด Modal
const showHideModal=()=> {
  setmodalVisible(!modalVisible)
}
// ค้นหาผู้ขาย
const modalSupplier =(value)=> {
  settextSearch({...textSearch,Supplier:value,Title:textName.th.supplier,Name:'Supplier'})
  showHideModal()
}
// ค้นหาโครงการ
const modalCostCenter =(value)=> {
  settextSearch({...textSearch,CostCenter:value,Title:textName.th.costcenter,Name:'CostCenter'})
  showHideModal()
}
//ค้นหาผู้อนุมตัิ
const modalUser =(value)=> {
  settextSearch({...textSearch,Approve:value,Title:textName.th.approve,Name:'Approve'})
  showHideModal()
}

const goTabForm =(event,name)=> {
  if (event.key === 'Enter') {
    event.preventDefault();
    //this[name].focus();
    console.log('ok')
  }
}

// รับค่ามาจาก Modal ภายนอก
const onAction =(record)=> {
  const {Name} = textSearch
  form.setFieldsValue({
    [Name]: `${record.Code}:${record.Name.trim()}`,
  });
  if(Name==='Supplier'){
    settextSearch({...textSearch,SupplierId:record.Id})
    showHideModal()
  }
  if(Name==='CostCenter'){
    settextSearch({...textSearch,CostCenterId:record.Id})
    showHideModal()
  }
  if(Name==='Approve'){
    settextSearch({...textSearch,ApproveId:record.Id})
    showHideModal()
  }
}

const setShowMenuAll=()=> {
  setShowMenuMobile(!showMenuMobile)
  setShowMenu(!showMenu)
}
    return (
      <>
      <div className={ClassMenuLeft} style={{minHeight:windowDimensions.height-48}}>
              <Tabs defaultActiveKey="list" type="card" size="middle" onChange={handleTab}>
          <TabPane tab="รายการ" key="list">
          <Form
       form={form} 
       name="control-hooks" 
        {...formlayout}
        onFinish={onFinish}
      >
        <ul className="press">
          <li><Form.Item
          name="Code"  
         label={textName.th.code}
        >
          <Input 
          onPressEnter={goTabForm} 
          autoComplete={null}
          />
        </Form.Item>
        
        <Form.Item
          name="Supplier"  
          label={textName.th.supplier}
        >
          <Search placeholder="รหัส,ชื่อ" onSearch={modalSupplier} enterButton />
        </Form.Item>
        <Form.Item
          name="CostCenter"  
          label={textName.th.costcenter}
        >
          <Search placeholder="รหัส,ชื่อ" onSearch={modalCostCenter} enterButton />
        </Form.Item>
        <Form.Item
          name="StatusId"  
          label={textName.th.status}
        >
          <Select>
            {
              status.Data && status.Data.map(res=><Select.Option key={res.Id} value={res.Id}>{res.Name}</Select.Option>)
            }
            
          </Select>
        </Form.Item></li>
        <li>
        <Form.Item
          name="FKItem"  
         label={textName.th.meterial}
        >
          <Input 
          onPressEnter={goTabForm} 
          />
        </Form.Item>
        <Form.Item
          name="FKLabor"  
         label={textName.th.labor}
        >
          <Input 
          onPressEnter={goTabForm} 
          />
        </Form.Item>
            <Form.Item
          name="Approve"  
          label={textName.th.approve}
        >
          <Search placeholder="รหัส,ชื่อ" onSearch={modalUser} enterButton />
        </Form.Item>
        <Form.Item
          name="ApproveStatusId"  
          label={textName.th.approvestatus}
        >
          <Select>
            {
              APP_TEXT.status_approve && APP_TEXT.status_approve.map(res=><Select.Option key={res.Id} value={res.Id}>{res.Name}</Select.Option>)
            }
            
          </Select>
        </Form.Item>
        </li>
        <li>
        {getsystem.isLoading ? null:<Form.Item
          name="DocumentDate"  
          label={textName.th.documentdate}
        >
          <RangePicker
          showNow={true}
          defaultValue={getsystem.isLoading ? null:[moment(getsystem.items.StartDate,dateFormat), moment(getsystem.items.ToDay, dateFormat)]}
          format={dateFormat} />
        </Form.Item>}
            
        <Form.Item
          name="ReciveDate"  
          label={textName.th.recivedate}
        >
          <RangePicker format={dateFormat} />
        </Form.Item>
        <Form.Item 
        label=":"
        >
        <Button type="primary" htmlType="submit" ref={btnSubmit}>
          ค้นหา
        </Button>
        <span className='dotbtn'></span>
        <Button type="primary" htmlType="button" onClick={onReset}>
          เคลียร์
        </Button>
        </Form.Item>
        </li>
        </ul>
      </Form>
  
          {isLoading ? <Spin/> : <Table 
            size="small" 
            bordered 
            components={components} 
            columns={columns()} 
           pagination={pageSize}
            dataSource={isLoading ? []:items}
            scroll={{ x: 2650, y: 300 }}
            showSorterTooltip={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => ActionData(record), // click row
              };
            }}
          
             />}
          </TabPane>
          <TabPane tab="PO" key="new">
          </TabPane>
          <TabPane tab="จัดสรร" key="edit">
          </TabPane>
        </Tabs>

        {/** Modal */}
        <Modal
          title={textSearch?.Title}
          style={{ top: 20 }}
          width={'80%'}
          visible={modalVisible}
          footer={null}
          onOk={showHideModal}
          onCancel={showHideModal}
        >
          {
            textSearch?.Name==='Supplier' ?
             <Supplier
            onAction={onAction}
            checkmodal={modalVisible}
            textSearch={textSearch?.Supplier}
          />:null}
          {
          textSearch?.Name==='Approve' ?
          <Approve
            onAction={onAction}
            checkmodal={modalVisible}
            textSearch={textSearch?.Approve}
          />:null
          }
         {
         textSearch?.Name==='CostCenter' ?
        <CostCenter
            onAction={onAction}
            checkmodal={modalVisible}
            textSearch={textSearch?.CostCenter}
          />:null}
        </Modal>
        </div>
        {/** Right Menu  */}
        <PageRightTitle {...props} isLoadingSubdoc={isLoadingSubdoc} itemsSubdoc={itemsSubdoc} showMenu={showMenu} showMenuMobile={showMenuMobile} />
        <MenuRight {...props} windowDimensions={windowDimensions} showMenu={showMenu} showMenuMobile={showMenuMobile} onAction={onActionRight} setShowMenuAll={setShowMenuAll} setShowMenu={setShowMenu} />
        
       </>
    )
}

export default List