import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "./Header";
//import Header from "./HeaderLao";
//import HeaderHome from "./HeaderWebHome";
import HeaderWeb from "./HeaderWeb";
import Content from "./Content";
//import Footer from "./Footter333";
import Footer from "./FootterHuay";
//import Footer from "./FootterHuayTh";
//import Footer from "./Footer";
import FooterWeb from "./FooterWeb";

import * as loginActions from "redux/signin/action";

export default function Layout(props) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { items } = useSelector((store) => store.signin);
  //const { chat } = useSelector((store) => store.ui);

  useEffect(() => {
    const action = loginActions.reLogin();
    dispatch(action);
  }, [dispatch]);

  return (
    <div>
     

      {!items && pathname != "/" && pathname.includes("signin") && pathname.includes("signup")  && <HeaderWeb></HeaderWeb>}
      <Content></Content>
   
    </div>
  );
}
