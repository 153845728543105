import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import moment from 'moment'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EventNoteIcon from '@material-ui/icons/EventNote';
import axios from "axios";
import { API_URL } from "modules/configApp";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Container from "@material-ui/core/Container";
import SearchIcon from '@material-ui/icons/Search';
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MoneyIcon from "@material-ui/icons/Money";
import Grid from "@material-ui/core/Grid";
import { red } from "@material-ui/core/colors";
import GroupIcon from "@material-ui/icons/Group";
import LanguageIcon from "@material-ui/icons/Language";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { addCommas, lg } from '../configApp'

import * as memberActions from "redux/member/action";


const lgtext = {
  lao:{
    1:'ໂພຍຫວຍ',
    2:'ຍ້ອນກັບ',
    3:'ຍອດລວມ',
    4:'ຖ້າຜົນ',
    5:'ກີບ',
    6:'ອອກຜົນແລ້ວ',
    7:'ຍັງບໍ່ອອກຜົນ',
    8:'ໂພຍທີ່ຍັງບໍ່ອອກ',
    9:'ໂພຍທີ່ອອກຜົນແລ້ວ',
    10:'ຍອດຊື້ມື້ນີ້',
    11:'ໂພຍ',
    12:'ໂພຍເລກທີ່',
    13:'ວັນທີ',
    14:'ເງິນເດີມພັນ',
    15:'ຜົນແພ້/ຊະນະ',
    16:'ລາຍລະອຽດ',
    17:'ຊະນະ',
    18:'ແພ້',
    19:'ຍົກເລີກ',
    20:'ຍອດແທງ',
    21:'ຜົນໄດ້ເສຍ',
    22:'ຜົນ',
   
  },
  thai:{
    1:'โพยหวย',
    2:'ย้อนกลับ',
    3:'ยอดรวม',
    4:'รอผล',
    5:'บาท',
    6:'ออกผลแล้ว',
    7:'ยังไม่ออกผล',
    8:'โพยที่ยังไม่ออก',
    9:'โพยที่ออกผลแล้ว',
    10:'ยอดซื้อวันนี้',
    11:'โพย',
    12:'โพยเลขที่',
    13:'วันที่',
    14:'เงินเดิมพัน',
    15:'ผลแพ้/ชะนะ',
    16:'รายละเอียด',
    17:'ชนะ',
    18:'แพ้',
    19:'ยกเลิก',
    20:'ยอดแทง',
    21:'ผลได้เสีย',
    22:'ผล',
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    isLoadinggame: isLoading,
    itemsbilldetail,
  } = useSelector((store) => store.member);
  const history = useHistory();

  const {  dtl:itemsgame,name,nameth,enddateth,enddate,code,totalamount,totalreward,status,status_run } = itemsbilldetail
  const params = useParams()

  const dispatch = useDispatch();
  React.useEffect(() => {
    //const action2 = memberActions.GetCredit({});
    //dispatch(action2);
  }, []);

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);
  const [startDate, setTimedate] = React.useState(moment(Date.now()).format("YYYY-MM-DD"));

  const { id } = params
  React.useEffect(() => {
   
    const action = memberActions.GameHuayBillHistoryDetail(id);
    dispatch(action);
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);
  }, []);


 const showreward1 =({one})=> {
    if(parseFloat(one)>0){
        return  <ul className="press1">
        <li>
        <div className="reward_contain" >
        <div className="reward_head">รางวัลที่ 1</div>
        <div className="reward_body">{one}</div>
    </div>
        </li>
    </ul>
    }
 }

 const goBack =(path)=> {
   history.push(path)
 }

 const goPage =(id)=> {
   history.push(`/play/poy/${id}`)
 }

 const [tab, setValue] = React.useState(4);

  const changeTap = (tab) => {
    setValue(tab);
  };


  const HuayCancel =(id)=> {
    setOpen2(true)
  }

  const confirmremove = () => {
    const action = memberActions.HuayBillCancel(id);
    dispatch(action);
    handleClose2(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const checkno = (type)=> {
      switch(type){
        case "t3" : 
            return lg == 'thai' ? "3ตัวบน":'3ໂຕບົນ';
        case "f3" : 
            return lg == 'thai' ? "3ตัวหน้า":"3ໂຕຫນ້າ";
        case "tod3" : 
            return lg == 'thai' ? "3ตัวโต๊ด":"3ໂຕໂຕ໊ດ";
        case "t2" : 
            return lg == 'thai' ? "2ตัวบน":"2ໂຕບົນ";
        case "l3" : 
            return lg == 'thai' ? "3ตัวล่าง":"3ໂຕລ່າງ";
        case "l2" : 
            return lg == 'thai' ? "2ตัวล่าง":"2ໂຕລ່າງ";
        case "rt" : 
            return lg == 'thai' ? "วิ่งบน":"ວິ່ງບົນ";
        case "rl" : 
            return lg == 'thai' ? "วิ่งล่าง":"ວິ່ງລ່າງ";
        default: return ""
      }
  }
  const checstatus = (type)=> {
    switch(type){
      case 1 : 
          return <span className="dvv">{lgtext[lg]['4']}</span>;
      case 2 : 
          return <span className="dvv2">{lgtext[lg]['17']}</span>;
      case 3 : 
          return <span className="dvv3">{lgtext[lg]['18']}</span>;
      case 4 : 
          return <span className="dvv4">{lgtext[lg]['19']}</span>;
      
      default: return ""
    }
}

const checkwinloss =(amount,status_run)=> {
  
  if(parseFloat(amount)>0 && parseFloat(status_run) === 0){
    return <span style={{color:'green'}}>{addCommas(parseFloat(amount).toFixed(0))}</span>
  } else if(parseFloat(amount)<0 && parseFloat(status_run) === 0) {
    return <span style={{color:'red'}}>{addCommas(parseFloat(amount).toFixed(0))}</span>
  } else {
    return 0
  }
}
  return (
    
    <div className="bgboxcasinox">
     
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/payment")}
          className="btnback"
        >
          {lgtext[lg]['2']}
        </Button>
      </Container>
      {" "}
      <div style={{ height: 5 }}></div>
      <Container maxWidth="md">
        <div style={{ height: 1 }} id="sport"></div>
        <div className="bgboxcasinox">
          <div style={{ height: 20 }} id="casino"></div>
         
         
          <div style={{ height: 20 }}></div>
         
                
                   
                    <div className="bglotto">
                      <div ></div>
                      <div className="bgresult">{lgtext[lg]['12']} #{code} </div>
                     
                      <ul className="press3">
        <li> <div className="reward_contain" >
          <div className="reward_head">{lg == 'lao' ? name : nameth}</div>
          <div className="reward_body">{lg == 'lao' ? enddate : enddateth}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head2">{lgtext[lg]['3']}</div>
          <div className="reward_body2">{addCommas(parseFloat(totalamount).toFixed(0))} {lgtext[lg]['5']}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head2">{lgtext[lg]['21']}</div>
          <div className="reward_body2">{addCommas(parseFloat(totalreward).toFixed(0))} {lgtext[lg]['5']}</div>
      </div></li>
      </ul>
      <div className="bglotto_bottom">
     
        <div className="poy_detail">
            <ul className="press2">
                {
                    itemsgame && itemsgame.map((list,index)=>
                    <li key={index}> <div className="reward_contain" >
                    <div className="reward_head3"><span className="span001">ลำดับ {++index}</span>{checstatus(list.status)}</div>
                    <div className="reward_body2">
                        <div className="reward_body2_contain">
                        <div className="divleft">
                            <div className="divleft_contain_c">
                              <div className="divleft_head">{list.name}</div>
                              <div className="divleft_detail"><div className="divno">{list.no}</div>{checkno(list.type)}</div>
                            </div>
                        </div>
                        <div className="divright">
                        <div className="divleft_contain2">
                              <div className="divleft_headx">เลขที่ออก<span className="span001x">{list.issueNo} </span></div>
                              
                              <div className="divleft_detail">ราคาจ่าย<span className="span001x">{list.rewardx} {lgtext[lg]['5']}</span></div>
                            </div>
                        </div>
                        <div className="divbuttom233">
                        {lgtext[lg]['20']} : {addCommas(parseFloat(list.amount).toFixed(0))} {lgtext[lg]['5']}<span className="span001x">{lgtext[lg]['22']} : {checkwinloss(list.reward, status_run)} {lgtext[lg]['5']}</span>
                        </div>
                        </div>
                        
                    </div>
                    
                </div></li>
                    )
                }
            </ul>
        </div>
                      </div>
      
                     
                      
                    </div>
                 
                
               

          <div style={{ height: 10 }}></div>
        </div>

        

        {loadspin && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className={classes.root}>
          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
      <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">ຂໍ້ຄວາມແຈ້ງເຕືອນ</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            ເຈົ້າແນ່ໃຈບໍ່ວ່າຕ້ອງການລົບ ເລກທັງຫມົ ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2} variant="contained">
            ປິດ
            </Button>
            <Button onClick={confirmremove} color="secondary" variant="contained">
            ລົບ
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
