import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Snackbar from "@material-ui/core/Snackbar";
import CardContent from "@material-ui/core/CardContent";
import { Alert, AlertTitle } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eaf4fc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  root: {
    background: "transparent",
  },
});

export default function CustomizedTables() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { items, withdrawItems, isLoading } = useSelector(
    (store) => store.member
  );
  return (
    <div>
      <Card className={classes.root}>
        <div style={{ height: 10 }}></div>

        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h5"
            className="headtitle"
          >
            {t("withdraw.label")}
          </Typography>
          <div style={{ height: 20 }}></div>
          <div className="boxacc">
            <div className="boxaccleft"></div>
            <div className="boxaccright">
              <Avatar>
                <img
                  src={`/images/bank/${items?.accbank}.png`}
                  height="50"
                ></img>
              </Avatar>
            </div>
            <div className="boxaccleft">ธนาคาร</div>
            <div className="boxaccright">{items?.accbank}</div>
            <div className="boxaccleft">ชื่อบัญชี</div>
            <div className="boxaccright">{items?.accname}</div>
            <div className="boxaccleft">เลขบัญชี</div>
            <div className="boxaccright">{items?.accno}</div>
            <div className="boxaccleft">ยอดถอน</div>
            <div className="boxaccright">{withdrawItems[0].amount}</div>
            <div className="boxaccleft">สถานะ</div>
            <div className="boxaccright">รอโอน</div>
            <div className="boxaccleft">วันเวลาแจ้ง</div>
            <div className="boxaccright">{withdrawItems[0].dtnow}</div>
          </div>

          <div style={{ height: 10 }}></div>

          <div style={{ height: 10 }}></div>
        </CardContent>
      </Card>
    </div>
  );
}
