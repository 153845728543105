import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Container from "@material-ui/core/Container";

import { Lock } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import Snackbar from "@material-ui/core/Snackbar";
import CardContent from "@material-ui/core/CardContent";

import InputAdornment from "@material-ui/core/InputAdornment";

import TextField from "@material-ui/core/TextField";

import { red } from "@material-ui/core/colors";

import { Alert } from "@material-ui/lab";

import * as memberActions from "redux/member/action";
import * as uiActions from "redux/ui/action";

import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  rootbg: {
    backgroundColor: "#fff",
  },
  img: {
    flexGrow: 1,
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      smsotp: yup.string().required(),
    }),
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const submit = ({ smsotp }) => {
    const action = memberActions.ConfirmOTPAccount({
      smsotp,
      smsref: accountlistotpconfirm.smsref,
      ...props,
    });
    dispatch(action);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const actionacc = memberActions.GetAccount();
    dispatch(actionacc);

    const uiaction = uiActions.chatShow(false);
    dispatch(uiaction);
  }, []);

  const { statusacc, accountlistotpconfirm } = useSelector(
    (store) => store.member
  );

  const handleCloseState = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.clearNotify();
    dispatch(action);
  };

  return (
    <>
      <Container maxWidth="sm">
        <div style={{ height: 10 }}></div>
        <Card className={classes.root}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h5"
              style={{ textAlign: "center", color: "#c01f25" }}
            >
              {t("addaccount.label")}
            </Typography>

            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(submit)}
            >
              <div className="bgboxprofile">
                <img
                  src={`/images/bank/${accountlistotpconfirm?.accbank}.png`}
                  height="50"
                  className="imgbank"
                />
                <div class="bgline">{accountlistotpconfirm?.accname}</div>
                <div class="bgline">
                  {accountlistotpconfirm?.accbank} :{" "}
                  {accountlistotpconfirm?.accno}{" "}
                </div>
              </div>
              <div style={{ height: 30 }}></div>
              <Typography
                gutterBottom
                variant="h5"
                style={{ textAlign: "center", color: "#fff" }}
                component="h3"
              >
                Ref: {accountlistotpconfirm?.smsref} กรอกรหัส OTP
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                inputRef={register}
                name="smsotp"
                placeholder="ใส่รหัส OTP "
                id="smsotp"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
              >
                {t("confirmotp.label")}
              </Button>
            </form>
            <div style={{ height: 10 }}></div>
          </CardContent>
        </Card>

        <div style={{ height: 50 }}></div>

        <Snackbar
          open={statusacc?.useotp}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleCloseState}
        >
          <Alert onClose={handleCloseState} severity="error">
            คุณป้อนรหัส OTP ไม่ถูกต้อง
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
