import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import useFetch from "lib/useFetch";

import * as loginActions from "redux/signin/action";
import * as memberActions from "redux/member/action";

import * as yup from "yup";
//import { yupResolver } from '@hookform/resolvers'

 import { API_URL, addCommas, lg, PHOME } from "modules/configApp";

const lgtext = {
  thai:{
    1:'ติดต่อ สอบถาม',
    2:'เฟส',
    3:'ว๊อทแอพ',
    4:'ไลน์'
  },
  lao:{
    1:'ຕິດຕໍ່ ສອບຖາມ',
    2:'ເຟສບຸກ',
    3:'ວັອດແອັບ',
    4:'ລາຍ'
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 135,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      mobile: yup.string().required(),
      password: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const submit = ({ mobile, password }) => {
    if (checkbtn == 1) {
      const action = loginActions.Login({ mobile, password, ...props });
      dispatch(action);
    } else {
      const action = memberActions.Save({ mobile, password, ...props });
      dispatch(action);
    }
  };

  useEffect(() => {}, []);
  const dispatch = useDispatch();
  const { isLoading, message, count, show } = useSelector(
    (store) => store.signin
  );

  const {
    isLoading: isLoadingregis,
    message: messageregis,
    datanotify,
  } = useSelector((store) => store.member);

  const [checkbtn, setcheckbtn] = React.useState(1);

  const GoPage = () => {
    //setcheckbtn(2);

    history.push("/signup");
  };
  const GoPage2 = () => {
    //setcheckbtn(2);

    history.push("/contact");
  };

  const GoPageForgot = () => {
    history.push("/forgotpass");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const action = loginActions.clearsignin();
    dispatch(action);
  };

  const handleCloseregis = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.GetList();
    dispatch(action);
  };

  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);

  const contact = useFetch(API_URL+'/agent/1/findonlycontact',{},'get')
const gotoweb = (path)=> {
  history.push(path)
}
  return (
    <>

<div style={{ height: 125, paddingTop:7 }} className="mainlogin">
        <img src={PHOME.logohome} height={135} onClick={()=> gotoweb('/')}></img>
      </div>
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 90,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div className="bgconh1x">
          <Typography
            gutterBottom
            variant="h5"
            className="headtitle"
            component="h3"
          >
              {lgtext[lg]['1']}
          </Typography>
          {/*
 <Typography
              gutterBottom
              variant="h5"
              style={{ textAlign: "center", color: "#fff" }}
              component="h3"
            >
              {t("hlogin.label")}
            </Typography>
              */}
            
          <div style={{ height: 10 }}></div>
              
          <div style={{ height: 150 }}>
             <a href={contact?.item?.contact_facebooklink} target="bank"><img src="/images/facebook.png" height={70} ></img></a> 
             <a href={contact?.item?.contact_facebooklink} target="bank"> <div className="btnsubmit44" >
             {lgtext[lg]['2']} : {contact?.item?.contact_facebook}
            </div>
            </a>
          </div>
          
          <div style={{ height: 150 }}>
          <a href={`https://wa.me/${contact?.item?.contact_whatapp}`} target="bank"> <img src="/images/whatsapp-logo.png" height={80} ></img></a>
          <a href={`https://wa.me/${contact?.item?.contact_whatapp}`} target="bank"><div className="btnsubmit55" >
          {lgtext[lg]['3']} : {contact?.item?.contact_whatapp}
            </div>
            </a>
          </div>
          
          <div style={{ height: 150 }}>
          <a href={contact?.item?.contact_linelink} target="bank"> <img src="/images/line-logo2.png" height={80} ></img></a>
          <a href={contact?.item?.contact_linelink} target="bank"><div className="btnsubmit55" >
          {lgtext[lg]['4']} : {contact?.item?.contact_line}
            </div>
            </a>
          </div>
         
        </div>
        <div style={{ height: 70 }}></div>
        {/*
        ช่องทางการติดต่อ 

วัอดแอป    020 97021325

<CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
     */}
      </Container>
      <Snackbar
        open={show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
        ບໍ່ພົບຂໍ້ມູນສະມາຊິກ.
        </Alert>
      </Snackbar>
      <Snackbar
        open={datanotify.show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCloseregis}
      >
        <Alert onClose={handleCloseregis} severity="error">
          {t("hlogindub.label")}
        </Alert>
      </Snackbar>
    </>
  );
}
