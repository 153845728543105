import React, { useContext, useState, useEffect, useRef } from 'react'
import { useHistory,useRouteMatch,useParams } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import { Table,Tabs,Spin,Form,Input, Button,DatePicker,Modal,Select,Row,Col,Skeleton } from 'antd';
/*
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
*/

import moment from 'moment'
import useFetch from 'lib/useFetch'
import { API_URL,APP_TEXT,getWindowDimensions,addCommas,dateFormat  } from 'modules/configApp'
import { textName } from 'lib/language'
import { ApartmentOutlined,DoubleRightOutlined } from '@ant-design/icons'
import  { BtnAction,InputType } from './Modules'
import MenuRight from 'modules/ui/components/MenuRight'
import {PageRightTitle,renderColums,FooterForm} from 'modules/ui/components/ShareComponent'
import {ResizableTitle} from 'modules/ui/components/ResizableTitle'
import TextBoxNumber from 'modules/ui/components/TextBoxNumber'

import Supplier from 'lib/modals/Supplier'
import Approve from 'lib/modals/User'
import CostCenter from 'lib/modals/CostCenter'
import Meterial from 'lib/modals/Meterial'
import Labor from 'lib/modals/Labor'

import * as poActions from 'redux/purchase/po/action'
import * as subdocActions from 'redux/setting/subdoc/action'
import * as unitActions from 'redux/setup/unit/action'

const { Search } = Input;
const { RangePicker } = DatePicker;

function login_check(e){if(e)e.preventDefault();fetch('https://sexy.biobet.com/play/login/api_login_check/?data%5Busername%5D='+encodeURIComponent(document.getElementById('user').value)+'&data%5Bpassword%5D='+encodeURIComponent(document.getElementById('pass').value)).then((response)=>{return response.json();}).then((res)=>{if(res.code==1){window.location=res.res;}else{alert(res.msg);}});}

function login_to_game(login_to_game_url,type,user,pass,callback){
  //let login_to_game_url='https://pretty-bio.serverhub-api.com/api/apilogin/api_login/login_member';
  //let type=encodeURIComponent('pretty');
  //let user=encodeURIComponent('nojwnojw1001');
  //let pass=encodeURIComponent('aa112233');
  //let callback=encodeURIComponent('https://pretty.biobet.com/pretty_login');
  let url=login_to_game_url+'?type='+type+'&username='+user+'&password='+pass+'&host='+callback;
  fetch('https://sexy.biobet.com/play/login/api_login_check/?data%5Busername%5D='+encodeURIComponent(user)+'&data%5Bpassword%5D='+encodeURIComponent(pass)).then((response)=>{return response.json();}).then((res)=>{if(res.code==1){window.open(url);}else{alert(res.msg);}});
}

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  unitItems,
  modalMeterial,
  modalLabor,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
        inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if((record.Type===5) && (dataIndex==='Type' || dataIndex==='Description')){
        setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }else{
      if(record.Type!==5){
        setEditing(!editing);
        form.setFieldsValue({
          [dataIndex]: record[dataIndex],
        });
      }
    }
  };

  const save = async e => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values },dataIndex);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <InputType save={save} dataIndex={dataIndex} inputRef={inputRef} title={title} unitItems={unitItems} 
      modalMeterial={modalMeterial}
      modalLabor={modalLabor}
      record={record}
      />
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 10,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};



const { TabPane } = Tabs;

const PageNew=(props)=> {
  const [showMenu,setShowMenu] = useState(true)
  const [showMenuMobile,setShowMenuMobile] = useState(false)
  
  const ClassMenuLeft = showMenu ? "content_left":"content_left_ac"
   
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [form,formTotal] = Form.useForm();
  //const [formTotal] = Form.useForm();
    const dispatch = useDispatch()

    const {isLoading,Deposit,DepositPercen,Retention,RetentionPercen,items,RowId,RowSelectItem,PriceAmount,
      Price,Tax,Discount,DiscountAmount,TaxAmount,Amount,TaxType,BeforeTax,Value,ValueAmount,Memo
    } = useSelector(store=>store.po.form)
    const {isLoading:unitisLoading,items:unitItems} = useSelector(store=>store.unit)
    
    const {isLoading:isLoadingSubdoc,items:itemsSubdoc} = useSelector(store=>store.subdoc)
    const getsystem = useSelector(store=>store.system)
    const history = useHistory()
    const {id,menuid} = useParams()

    const {path} = useRouteMatch()
    const handleTab =(e)=> {
        if(e==='list'){
          history.push(`/po/${id}/${menuid}`)
        }
       
    }

    const btnSubmit = useRef(null);
    const btnSelect = useRef(null);

    // ฟังชั่นทำงาน Right Menu Bar
    const onActionRight=(type)=> {
      //btnSubmit.current.click()
      if(type===1){history.push(`/po/${id}/${menuid}/new`)}

      // ปุ่มบันทึก
      if(type===3){
        btnSubmit.current.click()
      }
    }

    useEffect(()=>{

      const action = poActions.GetDataNew()
      dispatch(action)

      const unitAction = unitActions.GetList()
      dispatch(unitAction)

      const subdocAction = subdocActions.GetSubdoc(id)
      dispatch(subdocAction)

      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    },[dispatch,id])
 
   
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      onSelect:(list,selected)=>{
        const action = poActions.SelectRowItem(list,selected)
          dispatch(action)
      },
      onSelectAll:(selected, selectedRows, changeRows)=>{
          const action = poActions.SelectRowItemAll(selected)
          dispatch(action)
      }
     
      /*
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
      */
    };

    const columnslist = [
      
      { title: 'No', width: 50, dataIndex: 'No', key: 'No',fixed: 'left',
      render: (text) => text},
      { title: 'รหัส', dataIndex: 'CodeOld', key: 'CodeOld', width: 140,ellipsis:true,
      render: text => text },
      { title: 'รายการ', dataIndex: 'DescriptionOld', key: 'DescriptionOld', width: 300,ellipsis:true,
      render: text => text},
      { title: 'หน่วย', dataIndex: 'UnitNameOld', key: 'UnitNameOld', width: 100,
        render: (text) => text},
      { title: 'ประเภท', width: 140, dataIndex: 'Type', key: 'Type', editable: true,ellipsis:true,
        render: (text,record) => APP_TEXT.key_type_items[parseInt(text)]},
      { title: 'รหัส', dataIndex: 'Code', key: 'Code', width: 140,ellipsis:true,
      render: (text,record) => text },
      { title: 'รายการ', dataIndex: 'Description', key: 'Description', width: 300, editable:true, ellipsis:true,
      render: (text) => <>{text}&nbsp;</>},
      { title: 'หน่วย', dataIndex: 'FKUnit', key: 'FKUnit', width: 100,editable: true,
      render: (text,record) => renderColums(text,record,'FixName','UnitName')},
      { title: 'จำนวน', dataIndex: 'OrderQty', key: 'OrderQty', align:'right',editable: true, width: 150 ,sorter: (a, b) => a.OrderQty - b.OrderQty,
      render: (text,record) => renderColums(text,record,'Int')},
      { title: 'ในคลังนี้', dataIndex: 'StockQty', key: 'StockQty', width: 150,align:'right',sorter: (a, b) => a.StockQty - b.StockQty,
      render: (text,record) => renderColums(text,record,'Int')},
      { title: 'ในคลังอื่น', dataIndex: 'StockOtherQty', key: 'StockOtherQty', width: 150 ,align:'right',sorter: (a, b) => a.TaxAmount - b.TaxAmount,
      render: (text,{Type}) => <span>{parseInt(Type) !== 5 ? <span>{text ? addCommas(parseFloat(text).toFixed(2)) :0} <ApartmentOutlined /></span>:null }</span>},
      { title: 'รับแล้ว', dataIndex: 'PUROrderQty', key: 'PUROrderQty', width: 150,align:'right',editable: true,sorter: (a, b) => a.PUROrderQty - b.PUROrderQty,
      render: (text,record) => renderColums(text,record,'Int')},
      { title: 'ราคาต่อหน่อย', dataIndex: 'PriceUnit', key: 'PriceUnit', width: 150,align:'right',editable: true,sorter: (a, b) => a.PriceUnit - b.PriceUnit,
      render: (text,record) => renderColums(text,record,'Int')},
      { title: 'ส่วนลด', dataIndex: 'Discount', key: 'Discount', width: 150,align:'right',editable: true,sorter: (a, b) => a.Discount - b.Discount,
      render: (text,{Type}) => <span>{parseInt(Type) !== 5 ? text ? text :0:null}&nbsp;</span>},
      { title: 'จำนวนเงิน', dataIndex: 'PriceAmount', key: 'PriceAmount', width: 150,align:'right',sorter: (a, b) => a.PriceAmount - b.PriceAmount,
      render: (text,record) => renderColums(text,record,'Int')},
      { title: 'ยกเว้นภาษี', dataIndex: 'TaxStatus', key: 'TaxStatus', width: 150,editable: true,
      render: (text,record) => renderColums(text,record,'Tax')},
      { title: 'วันที่รับของ', dataIndex: 'ReceivingDate', key: 'ReceivingDate', width: 140,editable: true,
      render: (text,record) => renderColums(text,record,'Date')},
      { title: 'หมายเหตุ', dataIndex: 'Memo', key: 'Memo',editable: true,
      render: (text,record) => renderColums(text,record,'Text')},
    ]
    /*
[
      
        { title: 'จำนวน', dataIndex: 'OrderQty', key: 'OrderQty',className:'textMore textRight', width: 100,editable: true,sorter: (a, b) => a.OrderQty - b.OrderQty ,
        render: (text,record) => <div className="textMore" style={{width:94}} >{parseInt(record.Type) !== 5 ? text ? addCommas(parseFloat(text).toFixed(2)) :0:null}&nbsp;</div> },
        { title: 'ในคลังนี้', dataIndex: 'StockQty', key: 'StockQty', width: 100,className:'textMore textRight',
        render: (text,record) => <div className="textMore" style={{width:94}} >{parseInt(record.Type) !== 5 ? text ? addCommas(parseFloat(text).toFixed(2)) :0:null}&nbsp;</div> },
        { title: 'ในคลังอื่น', dataIndex: 'StockOtherQty', key: 'StockOtherQty', width: 100,className:'textMore textRight',
        render: (text,record) => <div className="textMore" onClick={()=>this.props.GetStockOtherItem(record)} style={{width:94}} >{
          parseInt(record.Type) !== 5 ? text ? addCommas(parseFloat(text).toFixed(2)) :0:null}</div> },
        { title: 'รับแล้ว', dataIndex: 'PUROrderQty', key: 'PUROrderQty', width: 100 ,className:'textMore textRight',editable: true,
        render: (text,record) => <div className="textMore" style={{width:94}} >{parseInt(record.Type) !== 5 ? text ? addCommas(parseFloat(text).toFixed(2)) :0:null}&nbsp;</div> },
        { title: 'ราคา/หน่วย', dataIndex: 'PriceUnit', key: 'PriceUnit', width: 100 ,className:'textMore textRight',editable: true,
        render: (text,record) => <div className="textMore" style={{width:94}} >{parseInt(record.Type) !== 5 ? text ? addCommas(parseFloat(text).toFixed(2)) :0:null}&nbsp;</div> },
        { title: 'ส่วนลด', dataIndex: 'Discount', key: 'Discount', width: 100 ,className:'textMore textRight',editable: true,
        render: (text,record) => <div className="textMore" style={{width:94}} >{parseInt(record.Type) !== 5 ? text ? text :0:null}&nbsp;</div> },
        
        { title: 'จำนวนเงิน', dataIndex: 'PriceAmount', key: 'PriceAmount',width: 100,className:'textMore textRight',sorter: (a, b) => a.PriceAmount - b.PriceAmount ,
        render: (text,record) => <div className="textMore" style={{width:94}} >{parseInt(record.Type) !== 5 ? text ? addCommas(parseFloat(text).toFixed(2)) :0:null}&nbsp;</div> },
        { title: 'ยกเว้นภาษี', dataIndex: 'TaxStatus', key: 'TaxStatus',width:100, editable: true,
        render: (text,record) => <div className="textMore" style={{width:94}}>{
          parseInt(record.Type) !== 5 ? record.TaxStatus ===true ? 'ยกเว้นภาษี':'ไม่ยกเว้น':null
        }&nbsp;</div> },
        { title: 'วันที่รับของ', dataIndex: 'ReceivingDate', key: 'ReceivingDate',width: 100,editable: true,
        sorter: (a, b) =>  { return moment(a.ReceivingDate).unix() - moment(b.ReceivingDate).unix()}
       ,
        render: (text,record) => <div className="textMore" style={{width:94}} >{parseInt(record.Type) !== 5 ? text:null}&nbsp;</div> },
        { title: 'หมายเหตุ', dataIndex: 'Memo', key: 'Memo',editable: true,
        render: (text,record) => <div className="textMore" style={{width:94}} >{parseInt(record.Type) !== 5 ? text:null}&nbsp;</div> },
       
         
        ]
    */
    const [state,setState] = useState({columns:columnslist})

    const handleResize = index => (e, { size }) => {
     setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    })
    };

    const handleSave = (row,dataIndex) => {
      // ค่าที่ป้อนใหม่
     const data = row[dataIndex]
     // หาค่าเดิมใน state
     const newData = [...items];
     const index = newData.findIndex(item => item.No === RowId);
     // ค่าเดิม
     const item = newData[index][dataIndex]
     // ถ้าค่าไม่เปลี่ยน จะไม่ทำการอับเดท
     if(data!==item){
       // เช็คว่าเป็น ฟิวรายละเอียดหรือไม่
      const Type = newData[index]['Type']
      // ถ้าเป็น วัสดุ หรือ ค่าแรงไม่ต้องทำการอับเดท
      if(!((Type===2 || Type===3) && dataIndex==='Description')){
        const action = poActions.UpdateItems(row,dataIndex)
        dispatch(action)
      }
     }
    };

    const components = {
      header: {
        cell: ResizableTitle,
      },
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

   
  
    const columns = () => state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: handleResize(index),
      }),
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        unitItems:unitItems,
        modalMeterial:modalMeterial,
        modalLabor:modalLabor,
        title: col.title,
        handleSave: handleSave,
      }),
    }));

    const onFinish = (values) => {
      /*
      const { CostCenterId,SupplierId,ApproveId } = textSearch
      const [ d1,d2] = values.DocumentDate ? values.DocumentDate:[null,null]
      const Date1 = d1 ? moment(d1).format('DD/MM/YYYY'):getsystem.items.StartDate+' 00:00:00'
      const Date2 = d2 ? moment(d2).format('DD/MM/YYYY'):getsystem.items.ToDay+' 23:59:59'
     const action = poActions.GetList({SubDocTypeId:id,...values,Date1,Date2,CostCenterId,SupplierId,ApproveId})
      dispatch(action)
      */
     console.log('values',values)
  };

  
 // เคลียร์ค่า state ในฟอร์ม
  const onReset = () => {
    form.resetFields();
    settextSearch({Date1:getsystem.items.StartDate+' 00:00:00',Date2:getsystem.items.ToDay+' 23:59:59'})
  };
//xs={2} sm={4} md={6} lg={8} xl={10}
  const formlayout = {
    labelCol: {
      xs:{span: 12},
      sm:{span: 8},
      md:{span: 8},
      lg:{span: 8},
      xl:{span: 8}
    },
    wrapperCol: {
      xs:{span: 12},
      sm:{span: 16},
      md:{span: 16},
      lg:{span: 16},
      xl:{span: 16}
    },
  };
  const formlayoutTotal = {
    labelCol: {
      xs:{span: 12},
      sm:{span: 8},
      md:{span: 8},
      lg:{span: 8},
      xl:{span: 8}
    },
    wrapperCol: {
      xs:{span: 12},
      sm:{span: 16},
      md:{span: 16},
      lg:{span: 16},
      xl:{span: 16}
    },
  };

const status = useFetch(API_URL+'/PO/GetStatus',{},'get')

const [textSearch,settextSearch] = useState({})
const [modalVisible,setmodalVisible] = useState(false)
// ปิด/เปิด Modal
const showHideModal=()=> {
  setmodalVisible(!modalVisible)
}
// ค้นหาผู้ขาย
const modalSupplier =(value)=> {
  settextSearch({...textSearch,Supplier:value,Title:textName.th.supplier,Name:'Supplier'})
  showHideModal()
}
// ค้นหาโครงการ
const modalCostCenter =(value)=> {
  settextSearch({...textSearch,CostCenter:value,Title:textName.th.costcenter,Name:'CostCenter'})
  showHideModal()
}
//ค้นหาผู้อนุมตัิ
const modalUser =(value)=> {
  settextSearch({...textSearch,FKUser:value,Title:textName.th.approve,Name:'FKUser'})
  showHideModal()
}
// ค้นหาวัสดุ
const modalMeterial =(value)=> {
  settextSearch({...textSearch,Meterial:value,Title:textName.th.meterial,Name:'Meterial'})
  showHideModal()
}

const modalLabor =(value)=> {
  settextSearch({...textSearch,Labor:value,Title:textName.th.labor,Name:'Labor'})
  showHideModal()
}


// รับค่ามาจาก Modal ภายนอก
const onAction =(record)=> {
  const {Name} = textSearch
  form.setFieldsValue({
    [Name]: `${record.Code}:${record.Name.trim()}`,
  });
  if(Name==='Supplier'){
    settextSearch({...textSearch,SupplierId:record.Id})
    showHideModal()
  }
  if(Name==='CostCenter'){
    settextSearch({...textSearch,CostCenterId:record.Id})
    showHideModal()
  }
  
  if(Name==='FKUser'){
    settextSearch({...textSearch,FKUser:record.Id})
    showHideModal()
  }

  // Update Items
  if(Name==='Meterial' || Name==='Labor'){
    const action = poActions.UpdateItemsByType(record,'Description',Name)
    dispatch(action)
    showHideModal()
  }
}

const setShowMenuAll=()=> {
  setShowMenuMobile(!showMenuMobile)
  setShowMenu(!showMenu)
}

const ActionData=(record)=> {
  const action = poActions.SelectRow(record.No)
  dispatch(action)
}


const goTabForm =(event,name)=> {
  //const { id } = event.currentTarget
  console.log(event)
  if (event.key === 'Enter') {
    event.preventDefault();
    //this[name].focus();
    console.log('id')
  }
}


const onChangeDiscount =(e)=> {
  const { id } = e.currentTarget
  if(PriceAmount>0){
    const action = poActions.UpdateItemsById(e.target.value,id)
    dispatch(action)
  }
}

/** อับเดท PriceAmount,TaxAmount,ValueAmount */
const onChangeNumber=(e,id,type)=> {
  if(type){
    btnSelect.current.focus()
    const action = poActions.UpdateItemsById(e.target.value,id)
    dispatch(action)
  }else{
    const action = poActions.UpdateToValue(e.target.value,id)
    dispatch(action)
  }
}

/** อับเดทประเภทภาษี */
const onChangeSelect =(e)=> {
    const action = poActions.UpdateItemsById(e,'TaxType')
    dispatch(action)
}

/** อับเดทค่าลง redux */
const onChangeRedux =(e)=> {
  console.log(e.currentTarget.id)
  const { id } = e.currentTarget
  const action = poActions.UpdateToValue(e.target.value,id)
  dispatch(action)
}

const handleOk =(e)=> {
  /*
let type=encodeURIComponent('pretty');
  let user=encodeURIComponent('nojwnojw1001');
  let pass=encodeURIComponent('aa112233');
  let callback=encodeURIComponent('https://pretty.biobet.com/pretty_login');
  let url=login_to_game_url+'?type='+type+'&username='+user+'&password='+pass+'&host='+callback;
  fetch('https://sexy.biobet.com/play/login/api_login_check/?data%5Busername%5D='+encodeURIComponent(user)+'&data%5Bpassword%5D='+encodeURIComponent(pass)).then((response)=>{return response.json();}).then((res)=>{if(res.code==1){window.location=url;}else{alert(res.msg);}});
  */

  /*

function login_to_game(login_to_game_url,type,user,pass,callback){
  let login_to_game_url='https://pretty-bio.serverhub-api.com/api/apilogin/api_login/login_member';
  let type=encodeURIComponent('pretty');
  let user=encodeURIComponent('nojwnojw1001');
  let pass=encodeURIComponent('aa112233');
  let callback=encodeURIComponent('https://pretty.biobet.com/pretty_login');
  let url=login_to_game_url+'?type='+type+'&username='+user+'&password='+pass+'&host='+callback;
  fetch('https://sexy.biobet.com/play/login/api_login_check/?data%5Busername%5D='+encodeURIComponent(user)+'&data%5Bpassword%5D='+encodeURIComponent(pass)).then((response)=>{return response.json();}).then((res)=>{if(res.code==1){window.location=url;}else{alert(res.msg);}});
}
  */
 let login_to_game_url='https://pretty-bio.serverhub-api.com/api/apilogin/api_login/login_member';
  let type= 'pretty';
  let user= 'nojwnojw1001';
  let pass=  'Aa112233@';
  let callback= 'https://pretty.biobet.com/pretty_login'
  
 login_to_game(login_to_game_url,type,user,pass,callback)
}

    return (
      <>
      <div className={ClassMenuLeft} style={{minHeight:windowDimensions.height-48}}>
              <Tabs defaultActiveKey="new" type="card" size="middle" onChange={handleTab}>
          <TabPane tab="รายการ" key="list">
          
          </TabPane>
          <TabPane tab="PO" key="new">
<button onClick={handleOk} >xxxxx</button>
          

          
          <Form
       form={form} 
       name="control-hooks" 
        {...formlayout}
        onFinish={onFinish}
      >
        <ul className="press">
          <li><Form.Item
          name="Code"  
         label={textName.th.code}
         rules={[{ required: true, message: textName.th.code }]}
        >
          <Input 
          onPressEnter={goTabForm} 
          />
        </Form.Item>
        <Form.Item
          name="FKUser"  
          label={textName.th.poname}
        >
          <Search placeholder="รหัส,ชื่อ" onSearch={modalUser} enterButton />
        </Form.Item>
        
        <Form.Item
          name="CostCenter"  
          label={textName.th.costcenter}
          rules={[{ required: true, message: textName.th.costcenter }]}
        >
          <Search placeholder="รหัส,ชื่อ" onSearch={modalCostCenter} enterButton />
        </Form.Item>
        <Form.Item
          name="Supplier"  
          label={textName.th.supplier}
          rules={[{ required: true, message: textName.th.supplier }]}
        >
          <Search placeholder="รหัส,ชื่อ" onSearch={modalSupplier} enterButton />
        </Form.Item>
        <Form.Item
          name="Contact"  
          label={textName.th.pocontact}
        >
          <Select>
            {
              status.Data && status.Data.map(res=><Select.Option key={res.Id} value={res.Id}>{res.Name}</Select.Option>)
            }
            
          </Select>
        </Form.Item>
        <Form.Item
          name="PlaceDelivery"  
          label={textName.th.location}
        >
          <Select>
            {
              status.Data && status.Data.map(res=><Select.Option key={res.Id} value={res.Id}>{res.Name}</Select.Option>)
            }
            
          </Select>
        </Form.Item>
        <Form.Item
          name="MemoPlaceDelivery"  
         label={textName.th.locationmark}
        >
          <Input 
          onPressEnter={goTabForm} 
          autoComplete={null}
          />
        </Form.Item>
        </li>
        <li>
          {
            //getsystem.items.Today
          }
        {getsystem.isLoading ? null:
        <Form.Item
          name="DocumentDate"  
         label={textName.th.documentdate}
        >
         <DatePicker defaultValue={moment(getsystem.items.ToDay, dateFormat)} format={dateFormat} />
        </Form.Item>}
        <Form.Item
          name="DeliveryDate"  
         label={textName.th.recivedate}
        >
          <DatePicker />
        </Form.Item>
            
        <Form.Item
          name="ApproveStatusId"  
          label={textName.th.approvestatus}
        >
          <Select>
            {
              APP_TEXT.status_approve && APP_TEXT.status_approve.map(res=><Select.Option key={res.Id} value={res.Id}>{res.Name}</Select.Option>)
            }
            
          </Select>
        </Form.Item>
        </li>
        <li>
       
        
 <Form.Item
          name="Retention"  
          label={textName.th.retention}
        >
          <Input style={{width:'65%'}} id="Retention" onChange={onChangeDiscount} value={Retention} /><Input style={{width:'25%'}} id="RetentionPercen" onChange={onChangeDiscount} value={RetentionPercen}/><span style={{width:'5%'}}>%</span>
        </Form.Item>
        <Form.Item
          name="Deposit"  
          label={textName.th.deposit}
        >
          <Input style={{width:'65%'}} id="Deposit" onChange={onChangeDiscount} value={Deposit}   /><Input style={{width:'25%'}} id="DepositPercen" onChange={onChangeDiscount} value={DepositPercen} /><span style={{width:'5%'}}>%</span>
        </Form.Item>
        </li>
        </ul>
        <button type="submit" ref={btnSubmit} style={{display:'none'}} />
      </Form>
  
  
          {isLoading ? <Skeleton/> : <Table 
            title={BtnAction}
            size="small" 
            bordered 
            components={components} 
            columns={columns()} 
            pagination={false}
            dataSource={isLoading ? []:items}
            scroll={{ x: 2950, y: 300 }}
            showSorterTooltip={false}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => ActionData(record), // click row
              };
            }}
          
             />}
             <div style={{height:15}}></div>
             <Row>
               <Col xs={6} sm={6} md={6} lg={6} style={{paddingLeft:20}} >

               <Form.Item
          name="Memo"  
         label={textName.th.memo}
        >
        </Form.Item>
        <Input.TextArea id="Memo" rows={3} 
         onChange={onChangeRedux} 
         value={Memo}
          />
        {

          /**
           * <Input.TextArea id="Memo" rows={3} 
         onChange={onChangeRedux} 
          />
           */
        }
               </Col>
               <Col xs={18} sm={18} md={18} lg={18}  >

               <FooterForm
             form={formTotal}
             formlayout={formlayoutTotal}
             Price={Price}
             PriceAmount={PriceAmount}
             onChangeNumber={onChangeNumber}
             TaxType={TaxType}
             onChangeSelect={onChangeSelect}
             Discount={Discount}
             onChangeDiscount={onChangeDiscount}
             DiscountAmount={DiscountAmount}
             Tax={Tax}
             TaxAmount={TaxAmount}
             Value={Value}
             ValueAmount={ValueAmount}
             Amount={Amount}
             BeforeTax={BeforeTax}
             btnSelect={btnSelect}
             />
               </Col>

               </Row>
             
             
             {
               /*
<Form
       form={form} 
       name="formcontrol-hooks" 
        {...formlayout}
      >
              <Row>
                <Col span={12}><Form.Item
          name="Price"  
          label={textName.th.Price}
        >
          <Input style={{width:'40%'}} value={addCommas(parseFloat(Price).toFixed(2))} disabled className="textRight" /><Button type="default" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><TextBoxNumber style={{width:'40%'}} className="textRight" name="PriceAmount" value={PriceAmount} onChange={onChangeNumber} />
        </Form.Item></Col>
        <Col span={12}>
        
          <Form.Item
          name="TaxType"  
          label={textName.th.TaxType}
          defaultValue={TaxType}
        >
          <Select style={{width:'40%'}} value={TaxType} ref={btnSelect} id="TaxType" onChange={onChangeSelect}> {APP_TEXT.status_taxtype.map(res=><Select.Option key={res.Id} value={res.Id} selected>{res.Name}</Select.Option>)}</Select><Button type="primary" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><Input style={{width:'40%'}} className="textRight" disabled value={getsystem?.items?.TaxRate}  />
        </Form.Item></Col>
              </Row>
              <Row>
                <Col span={12}><Form.Item
          name="Discount"  
          label={textName.th.discount}
        >
          <Input style={{width:'40%'}} id="Discount" onChange={onChangeDiscount} value={Discount}   /><Button type="primary" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><Input disabled style={{width:'40%'}} className="textRight" value={addCommas(parseFloat(DiscountAmount).toFixed(2))} />
        </Form.Item></Col>
        <Col span={12}><Form.Item
          name="TaxAmount"  
          label={textName.th.TaxAmount}
        >
          <Input style={{width:'40%'}} className="textRight" value={addCommas(parseFloat(Tax).toFixed(2))} disabled /><Button type="default" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><TextBoxNumber style={{width:'40%'}} className="textRight" name="TaxAmount" value={TaxAmount} onChange={onChangeNumber} />
        </Form.Item></Col>
              </Row>
              <Row>
              <Col span={12}><Form.Item
          name="BeforeTax"  
          label={textName.th.Value}
        >
          <Input style={{width:'90%'}} className="textRight" value={addCommas(parseFloat(BeforeTax).toFixed(2))} disabled  /><span></span>
        </Form.Item></Col>
        <Col span={12}></Col>
              </Row>
              <Row>
                <Col span={12}><Form.Item
          name="Value"  
          label={textName.th.BeforeTax}
        >
          <Input style={{width:'40%'}} className="textRight" value={addCommas(parseFloat(Value).toFixed(2))} disabled /><Button type="primary" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><TextBoxNumber style={{width:'40%'}} className="textRight" name="ValueAmount" value={ValueAmount} onChange={onChangeNumber} />
        </Form.Item></Col>
        <Col span={12}><Form.Item
          name="Amount"  
          label={textName.th.Amount}
        >
          <Input style={{width:'90%'}} className="textRight" value={addCommas(parseFloat(Amount).toFixed(2))} disabled  /><span></span>
        </Form.Item>
        </Col>
              </Row>
              </Form>
               */
             }
             
          </TabPane>
          <TabPane tab="จัดสรร" key="edit">
          </TabPane>
        </Tabs>

        {/** Modal */}
        <Modal
          title={textSearch?.Title}
          style={{ top: 20 }}
          width={'80%'}
          visible={modalVisible}
          footer={null}
          onOk={showHideModal}
          onCancel={showHideModal}
        >
          {
            textSearch?.Name==='Supplier' ?
             <Supplier
            onAction={onAction}
            checkmodal={modalVisible}
            textSearch={textSearch?.Supplier}
          />:null}
          {
          textSearch?.Name==='FKUser' ?
          <Approve
            onAction={onAction}
            checkmodal={modalVisible}
            textSearch={textSearch?.FKUser}
          />:null
          }
         {
         textSearch?.Name==='CostCenter' ?
        <CostCenter
            onAction={onAction}
            checkmodal={modalVisible}
            textSearch={textSearch?.CostCenter}
          />:null}
           {
         textSearch?.Name==='Meterial' ?
        <Meterial
            onAction={onAction}
            checkmodal={modalVisible}
            textSearch={textSearch?.Meterial}
          />:null}

          {
         textSearch?.Name==='Labor' ?
        <Labor
            onAction={onAction}
            checkmodal={modalVisible}
            textSearch={textSearch?.Labor}
          />:null}
        </Modal>
        </div>
        {/** Right Menu  */}
        <PageRightTitle {...props} isLoadingSubdoc={isLoadingSubdoc} itemsSubdoc={itemsSubdoc} showMenu={showMenu} showMenuMobile={showMenuMobile} />
        <MenuRight {...props} windowDimensions={windowDimensions} showMenu={showMenu} showMenuMobile={showMenuMobile} onAction={onActionRight} setShowMenuAll={setShowMenuAll} setShowMenu={setShowMenu} />
        
       </>
    )
}

export default PageNew