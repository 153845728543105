import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import moment from 'moment'
import Checkbox from '@material-ui/core/Checkbox';

import { Alert } from "@material-ui/lab";
import Avatar from "@material-ui/core/Avatar";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EventNoteIcon from '@material-ui/icons/EventNote';
import axios from "axios";
import { API_URL } from "modules/configApp";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Container from "@material-ui/core/Container";
import SearchIcon from '@material-ui/icons/Search';
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MoneyIcon from "@material-ui/icons/Money";
import Grid from "@material-ui/core/Grid";
import { red } from "@material-ui/core/colors";
import GroupIcon from "@material-ui/icons/Group";
import LanguageIcon from "@material-ui/icons/Language";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";

import Dialog from "@material-ui/core/Dialog";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";


import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import LinearProgress from "@material-ui/core/LinearProgress";


import { CopyToClipboard } from "react-copy-to-clipboard";

import { addCommas, PHOME } from "../configApp";

import * as memberActions from "redux/member/action";

import { lg } from '../configApp'
import Deposit from '../transfer/Deposit'

const QRCode = require("qrcode.react");


export function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    //console.log('Error: ', error);
  };
}

export function decodeBase64Image(base64Str) {
  var matches = base64Str.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
  var image = {};
  if (!matches || matches.length !== 3) {
    throw new Error("Invalid base64 string");
  }

  image.type = matches[1];
  image.data = matches[2];

  return image;
}

const lgtext = {
  lao:{
    1:'ໂພຍຫວຍ',
    2:'ຍ້ອນກັບ',
    3:'ຍອດລວມ',
    4:'ຖ້າຜົນ',
    5:'ກີບ',
    6:'ອອກຜົນແລ້ວ',
    7:'ຍັງບໍ່ອອກຜົນ',
    8:'ໂພຍທີ່ຍັງບໍ່ອອກ',
    9:'ໂພຍທີ່ອອກຜົນແລ້ວ',
    10:'ຍອດຊື້ມື້ນີ້',
    11:'ໂພຍ',
    12:'ໂພຍເລກທີ່',
    13:'ວັນທີ',
    14:'ເງິນເດີມພັນ',
    15:'ຜົນແພ້/ຊະນະ',
    16:'ລາຍລະອຽດ',
    17:'ຊະນະ',
    18:'ແພ້',
    19:'ຍົກເລີກ',
    20:'ຍົກເລີກ',
    21:'ຂໍຮັບ QRcode ຊຳລະເງິນ',
    22:'ບໍ່ພົບຂໍ້ມູນ',
   
  },
  thai:{
    1:'โพยหวย',
    2:'ย้อนกลับ',
    3:'ยอดรวม',
    4:'รอผล',
    5:'บาท',
    6:'ออกผลแล้ว',
    7:'ยังไม่ออกผล',
    8:'โพยที่ยังไม่ออก',
    9:'โพยที่ออกผลแล้ว',
    10:'เลือกโพยที่ต้องการจ่าย',
    11:'โพย',
    12:'โพยเลขที่',
    13:'วันที่',
    14:'เงินเดิมพัน',
    15:'ผลแพ้/ชะนะ',
    16:'รายละเอียด',
    17:'ชนะ',
    18:'แพ้',
    19:'ยกเลิก',
    20:'ยอดรวม',
    21:'ขอรับ QRcode ชำระเงิน',
    22:'ไม่มีข้อมูล',
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    isLoadinggame: isLoading,
    itemsbill:itemsgame,
    totalall,
  } = useSelector((store) => store.member);
  const history = useHistory();


  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    //dispatch(action2);
  }, []);

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);
  const [startDate, setTimedate] = React.useState(moment(Date.now()).format("YYYY-MM-DD"));
  const { lineid, huaybank, maketing } = useSelector((store) => store.member);
  
  React.useEffect(() => {
    //const datenow = moment(Date.now()).format("YYYY-MM-DD")
    const action = memberActions.GameHuayBillHistory(5,lineid);
    dispatch(action);

    const action2 = memberActions.GetHuayBank(lineid);
    dispatch(action2);

    //GetHuayBank
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);

    
  }, []);


 const showreward1 =({one})=> {
    if(parseFloat(one)>0){
        return  <ul className="press1">
        <li>
        <div className="reward_contain" >
        <div className="reward_head">รางวัลที่ 1</div>
        <div className="reward_body">{one}</div>
    </div>
        </li>
    </ul>
    }
 }

 
 const goBack =(path)=> {
   history.push(path)
 }


 const goPage =(id)=> {
   history.push(`/play/poypayment/${id}`)
 }

 const [tab, setValue] = React.useState(4);

  const changeTap = (tab) => {
    setValue(tab);
   
    if(tab ==3){
      const action = memberActions.GameHuayBillHistory(0,lineid);
      dispatch(action);
    }else{
      const action = memberActions.GameHuayBillHistory(5,lineid);
      dispatch(action);
    }
    
  };

  const checstatus = (type)=> {
    switch(type){
      case 1 : 
          return <span className="dvv">{lgtext[lg]['4']}</span>;
      case 2 : 
          return <span className="dvv2">{lgtext[lg]['17']}</span>;
      case 3 : 
          return <span className="dvv3">{lgtext[lg]['18']}</span>;
      case 4 : 
          return <span className="dvv4">{lgtext[lg]['19']}</span>;
      
      default: return ""
    }
}

const checkreward = (rows) => {
  const { status, status_run, winloss} = rows
  if(status_run === 1){
    return 0
  }else if(status === 4){
    return 0
  } else {
    return addCommas(parseFloat(winloss).toFixed(0))
  }
}

const goPageCal = (code,amount) => {

}

const [checked, setChecked] = React.useState([]);

  const handleChange = (event,id,amount) => {
    console.log(id)
    if(event.target.checked){
        const newdata = [{
            id:id,
            amount:amount
        }]
        const xcxcx = newdata.reduce((res,item)=>{
            return [...res, {
                id:item.id,
                amount:item.amount
            }]
        },[...checked])

        console.log('xcxcx',xcxcx)
       
        setChecked(xcxcx);
    } else {
       const newdata = checked.filter(x=>x.id !== id)
       setChecked(newdata);
    }
   
  };

  const showamount =()=> {
    const sumdeposittotal = checked.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );

    return sumdeposittotal
  }


  const [copied, setcopied] = React.useState(false);

  const setCopyall = () => {
    setcopied(true);
  };
  

  const [openerror, setopenerror] = React.useState(false);

  const handleCloseState = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenerror(false);
  };


  const [boxpay, setboxpay] = React.useState(false);
  const [file, setfile] = React.useState(null);
  const [filename, setfilename] = React.useState(null);
  const [slipexten, setslipexten] = React.useState(null);
  const [slipphoto, setslipphoto] = React.useState(null);

  const sendorder =()=> {
    //setboxpay(true)
    let newitem = {
      lineid:lineid,
      items:checked,
      maketing
      //bankname:huaybank?.bankname,
      //bank:huaybank?.bank
     }
   
        const action = memberActions.BookingConfirmNew({ ...newitem });
        dispatch(action);
        const liff = window.liff;
        liff.closeWindow();
  }

  const handleChangePhoto = (event) => {
    if (event.target.files[0]) {
      setfilename(event.target.files[0]);

      const path = event.target.files[0];

      getBase64(path, (result) => {
        const { data: dataimg, type } = decodeBase64Image(result);

        setslipexten(`data:${type};base64`)
        setslipphoto(dataimg)
        /*
        const listnew = [
          {
            memberexten: `data:${type};base64`,
            memberphoto: dataimg,
          },
        ];
        */


        setfile(null);

      });
    }
  };

  const btnphoto = React.useRef(null);

  const onslip = () => {
    btnphoto.current.click();
  }

  const submitBook = () => {
   let newitem = {
    lineid:lineid,
    items:checked,
    bankname:huaybank.bankname,
    bank:huaybank.bank
   }
      const action = memberActions.BookingConfirmNew({ ...newitem, slip:slipexten,slipphoto:slipphoto });
      dispatch(action);
      const liff = window.liff;
      liff.closeWindow();
  };

  return (
    <>
    <div className="bgboxcasinox">
     
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/")}
          className="btnback"
        >
          {lgtext[lg]['2']}
        </Button>
      </Container>
      {" "}
      <div style={{ height: 5 }}></div>
      <Container maxWidth="md">
        <div style={{ height: 1 }} id="sport"></div>
        <div className="bgboxcasinox">
          <div style={{ height: 20 }} id="casino"></div>
          <div className="headsport">
            <span>{lgtext[lg]['1']}</span>
          </div>
         
          <div style={{ height: 20 }}></div>
         
                
                   
                    <div className="bglotto">
                      <div ></div>
                      <div className="bgresult">{lgtext[lg]['10']} </div>
                     
                    
      <div className="bglotto_bottom">
      
        <div className="poy_detail">
            <ul className="press2">
                {
                    itemsgame && itemsgame.map((list,index)=>
                    <li key={index}> <div className="reward_contain" >
                    <div className="reward_head3"><span className="span001">{lgtext[lg]['12']} #{list.code}</span>{checstatus(list.status)}  </div>
                    <div className="reward_body2">
                        <div className="reward_body2_contain">
                        <div className="divleft">
                            <div className="divleft_contain">
                              <div className="divleft_head">{lg == 'lao' ? list.name:list.nameth}</div>
                              <div className="divleft_detail">{lgtext[lg]['13']} : {lg == 'lao' ? list.enddate:list.enddateth}</div>
                            </div>
                        </div>
                    
                        <div className="divright">
                        <div className="divleft_contain2">
                              <div className="divleft_head">{lgtext[lg]['14']}<span className="span001x">{addCommas(parseFloat(list.amount).toFixed(0))} {lgtext[lg]['5']}</span></div>
                              <div className="divleft_detail">{lgtext[lg]['15']} <span className="span001x">{checkreward(list)} {lgtext[lg]['5']}</span></div>
                            </div>
                        </div>
                        <div className="divbuttom233">
                        <EventNoteIcon className="mline3" style={{fontSize:20}} /> {list.date} <QueryBuilderIcon style={{fontSize:18}} className="mline3" /> {list.time} <span className="span001x"> <Button onClick={()=>goPage(list._id)}  size="small"  variant="contained" >
                        {lgtext[lg]['16']} 
                  </Button>
                  <Checkbox
        checked={checked.filter(x=>`${x.id}` === `${list._id}`).length>0}
        onChange={(e)=>handleChange(e,list._id,list.amount)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
                   </span>
                        </div>
                        </div>
                        
                    </div>
                    
                </div></li>
                    )
                }
            </ul>
            {
              itemsgame && itemsgame.length === 0 && <div style={{height:100,textAlign:'center',color:'#000',paddingTop:40}}> {lgtext[lg]['22']}</div>
            }
        </div>
                      </div>
      
                     
                      
                    </div>
                 
                    <div style={{ height: 20 }}></div>
               <div>
                {
                    checked && checked.length>0 && <div>
                       <div className="btnsubmit2"  >
                       {lgtext[lg]['3']} = {showamount()}
            </div>
                 
                               
                                       <div className="btnsubmit" onClick={()=>sendorder()} >
                                        {
                                          maketing === 1 && lgtext[lg]['21']
                                        }
                                         {
                                          maketing !== 1 && 'ส่งโพย'
                                        }
                                     
            </div>
            
                    </div>
                }
 
 

                    
                
               </div>

            
          <div style={{ height: 10 }}></div>
        </div>

        

        {loadspin && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className={classes.root}>
          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
    </div>
    <Dialog
        fullScreen
        open={boxpay}
        onClose={()=>setboxpay(false)}
        //TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={()=>setboxpay(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h7" className={classes.title}>
            ข้อมูลธนาคารสำหรับการโอนเงิน
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.rootbg}>
          <Container
            maxWidth="sm"
            style={{
              paddingTop: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Card className={classes.rootbody}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  style={{ textAlign: "center", color: "#265d93" }}
                >
                  ຝາກເງິນ
                </Typography>

                <form
                  className={classes.form}
                  noValidate
                  //onSubmit={handleSubmit(submit)}
                >
                  {/*
 <input
                placeholder="กรอกเบอร์มือถือ (ไม่ต้องใส่ -)"
                className={classes.input}
              ></input>
                */}
                 
                  
          
                    <>
                      <div className="boxacc">
                        <div className="boxaccleft"></div>
                        <div className="boxaccright">
                          <Avatar>
                            <img
                              src={`/images/bank/${huaybank?.bankname}.png`}
                              height="50"
                            ></img>
                          </Avatar>
                        </div>
                        <div className="boxaccleft">ທະນາຄານ</div>
                        <div className="boxaccright">{huaybank?.bankname} </div>
                        <div className="boxaccleft">ຊື່ບັນຊີ</div>
                        <div className="boxaccright">{huaybank?.accname}</div>
                        <div className="boxaccleft">ເລກບັນຊີ</div>

                        <div className="boxaccright">
                        {huaybank?.accno}
                          <CopyToClipboard
                            text={huaybank?.accno}
                            onCopy={() => setCopyall()}
                          >
                            <Button
                              variant="contained"
                              color="default"
                              style={{ backgroundColor: "#e8a608" }}
                            >
                              ຄັດລອກ
                            </Button>
                          </CopyToClipboard>
                        </div>
                        
                    {
                      PHOME?.qrcode == 1 && <div>
                        <div>QRCode</div>
                        <img
                      src={`${PHOME.qrcodephoto}`}
                      width={250}
                    ></img></div>
                    }
                  
                        <div className="boxaccleft">ຍອດໂອນ</div>
                        <div className="boxaccright">
                          {parseFloat(showamount()).toFixed(0)} ກີບ
                        </div>
                      </div>
                    </>
                  
                 
                  <div
                    style={{
                      height: 10,
                    }}
                  ></div>
                  <div style={{ height: 3 }}></div>
                
                    <div>
                    <Alert severity="warning">
                      <font style={{ fontSize: 16 }}>
                       
                        <b style={{ color: "green" }}>
                      
                          <span>ເມື່ອໂອນແລ້ວ ກະລຸນາແນບບິນໂອນເງິນ <Button onClick={()=>onslip()}  variant="contained"
                              color="default"
                              style={{ backgroundColor: "#e8a608" }}>ແນບບິນ</Button> ສຳເລັດແລ້ວກົດປຸ່ມ ຍືນຍັນດ້ານລຸ່ມ</span>
                        
                        </b>
                        
                      </font>
                    </Alert>
                    <div style={{ height: 10 }}></div>
                    {
                      slipexten && <img
                      src={`${slipexten},${slipphoto}`}
                      width={250}
                    ></img>
                    }
                    
                    </div>
                  
                  <input
                  type="file"
                  name="file"
                  id="file"
                  class="inputfile"
                  ref={btnphoto}
                  onChange={(e) => handleChangePhoto(e)}
                />
                  <div style={{ height: 10 }}></div>
                  <div className="btnsubmit" onClick={submitBook} >
                        ຍືນຢັນ
                      </div>
                  <div style={{ height: 50 }}></div>
                </form>
              </CardContent>
            </Card>
            {isLoading && (
              <div className={classes.rootLoading}>
                <CircularProgress color="secondary" />
              </div>
            )}
          </Container>
        </div>
      </Dialog>
    </>
  );
}
