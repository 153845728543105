import axios from "axios";
import moment from "moment";
import { API_URL, WEB_TOKEN } from "modules/configApp";

const SIGNIN_REQUEST = "SIGNIN/REQUEST";
const SIGNIN_SUCCESS = "SIGNIN/SUCCESS";
const SIGNIN_FAILED = "SIGNIN/FAILED";
const SIGNIN_PASS_FAILED = "SIGNIN/SIGNIN_PASS_FAILED";
const SIGNOUT_SUCCESS = "SIGNOUT/SUCCESS";
const SIGNIN_CLEAR = "SIGIN/SIGNIN_CLEAR";

function Login({ mobile, password, history }) {
  return async (dispatch) => {
    dispatch({ type: SIGNIN_REQUEST });
    try {
      const results = await axios({
        method: "post",
        url: `${API_URL}/members/login`,
        //url: `${BASE_URL}/menu/GetRoleInUser?Id=${localStorage.getItem('UserId')}`,
        data: { mobile, password, WEB_TOKEN },

        //headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
      });

      //const result = await axios.get(BASE_URL,{ headers:{'Content-Type': 'application/json','X-Signature':'Signature'} })
      if (results.data.result == "ok") {
        dispatch({ type: SIGNIN_SUCCESS, payload: results.data });

        localStorage.setItem("tokenstatus", "ok");
        localStorage.setItem("Signature", "Signature");
        localStorage.setItem("AdminToken", results.data.access_token);
        localStorage.setItem("mobile", mobile);
        //localStorage.setItem("UserName", results.data.Data.Name);

        localStorage.setItem(
          "timelogin",
          moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss")
        );
        history.push("/dashboard");
      } else if (results.data.Status === 1) {
        // รหัสผ่านไม่ถูกต้อง
        dispatch({ type: SIGNIN_PASS_FAILED, payload: results.data.Status });
      } else {
        // ชื่อผู้ใช้ไม่ถูกต้อง

        dispatch({ type: SIGNIN_FAILED, payload: results.data.Status });
      }
    } catch (err) {
      const { response } = err;
      const { status } = response?.data?.error;
      if (status === 401) {
      }
      dispatch({ type: SIGNIN_FAILED });
    }
  };
}

const reLogin = () => {
  return (dispatch) => {
    const loginStatus = localStorage.getItem("tokenstatus");
    if (loginStatus === "ok") {
      dispatch({ type: SIGNIN_SUCCESS, payload: true });
    }
  };
};

const isLoggedIn = () => {
  const loginStatus = localStorage.getItem("tokenstatus");
  return loginStatus === "ok";
};

const logout = ({ push }) => {
  return (dispatch) => {
    localStorage.removeItem("tokenstatus");
    localStorage.removeItem("Signature");
    localStorage.removeItem("AdminToken");
    localStorage.removeItem("mobile");

    localStorage.clear();
    dispatch({ type: SIGNOUT_SUCCESS });
    push("/signin");
  };
};
const clearsignin = () => {
  return (dispatch) => {
    dispatch({ type: SIGNIN_CLEAR });
  };
};

const SUM_KEY = () => {
  return (dispatch) => {
    dispatch({ type: "SUM_KEY" });
  };
};

export {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILED,
  SIGNOUT_SUCCESS,
  SIGNIN_PASS_FAILED,
  SIGNIN_CLEAR,
  Login,
  SUM_KEY,
  reLogin,
  isLoggedIn,
  logout,
  clearsignin,
};
