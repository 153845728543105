const UI_REQUEST = "UI/REQUEST";
const UI_SUCCESS = "UI/SUCCESS";
const UI_FAILED = "UI/FAILED";
const UI_NOTIFY_REQUEST = "UI/NOTIFY_REQUEST";
const UI_NOTIFY_SUCCESS = "UI/NOTIFY_SUCCESS";
const UI_NOTIFY_FAILED = "UI/NOTIFY_FAILED";
const UI_CLEAR_REQUEST = "UI/UI_CLEAR_REQUEST";
const UI_CHAT_REQUEST = "UI/UI_CHAT_REQUEST";

function loadMenu() {
  return async (dispach, getState) => {
    dispach({ type: UI_REQUEST });
    try {
      //const result = await axios.get(BASE_URL,{ headers:{'Content-Type': 'application/json','X-Signature':'Signature'} })

      dispach({ type: UI_SUCCESS, payload: [] });
    } catch (err) {
      dispach({ type: UI_FAILED });
    }
  };
}

function clearNotify() {
  return async (dispach) => {
    dispach({ type: UI_CLEAR_REQUEST });
  };
}

function chatShow(chat) {
  return async (dispach) => {
    dispach({ type: UI_CHAT_REQUEST, payload: chat });
  };
}

const CHAT_LIST_SUCCESS = "CHAT_LIST/SUCCESS";
function reciveNotify(msg) {
  return async (dispach, getState) => {
    dispach({ type: UI_NOTIFY_REQUEST });
    try {
      // เช็คว่าเป็นรายการของ ลูกค้าหรือเปล่า ถ้าเป็นถึงจะแจ้ง

      /*
      const checkdata = msg.filter(
        (x) => x.mobile == localStorage.getItem("mobile")
      );

      console.log("checkdata", checkdata);
      //if (localStorage.getItem("mobile") == msg.mobile) {
      if (checkdata.length > 0) {
        //const data = { ...msg };
        const data = { ...checkdata[0] };
        dispach({ type: UI_NOTIFY_SUCCESS, payload: data });

        // ถ้าเป็น Chat จาก admin
        if (msg?.chatmember === true) {
          const { chat } = getState();
          const { historyItems } = chat;
          dispach({
            type: CHAT_LIST_SUCCESS,
            payload: {
              historyitems: historyItems,
              dataitems: msg?.items,
              count: 1,
            },
          });
        }
      }
*/

      if (msg instanceof Array) {
        const checkdata = msg.filter(
          (x) => x.mobile == localStorage.getItem("mobile")
        );

        //if (localStorage.getItem("mobile") == msg.mobile) {
        if (checkdata.length > 0) {
          //const data = { ...msg };
          const data = { ...checkdata[0] };
          dispach({ type: UI_NOTIFY_SUCCESS, payload: data });

          // ถ้าเป็น Chat จาก admin

          if (msg?.chatmember === true) {
            const { chat } = getState();
            const { historyItems } = chat;
            dispach({
              type: CHAT_LIST_SUCCESS,
              payload: {
                historyitems: historyItems,
                dataitems: msg?.items,
                count: 1,
              },
            });
          }
        }
      } else {
        if (localStorage.getItem("mobile") == msg.mobile) {
          if (msg?.chatmember === true) {
            const { chat } = getState();
            const { historyItems } = chat;
            dispach({
              type: CHAT_LIST_SUCCESS,
              payload: {
                historyitems: historyItems,
                dataitems: msg?.items,
                count: 1,
              },
            });
          } else {
            console.log("step3");
            // แสดงว่าเป็น ยืนยันฝากจาก Bigpay
            dispach({ type: UI_NOTIFY_SUCCESS, payload: msg });
          }
        }
      }
    } catch (err) {
      dispach({ type: UI_NOTIFY_FAILED });
    }
  };
}

export {
  UI_REQUEST,
  UI_SUCCESS,
  UI_FAILED,
  UI_NOTIFY_REQUEST,
  UI_NOTIFY_SUCCESS,
  UI_NOTIFY_FAILED,
  UI_CLEAR_REQUEST,
  UI_CHAT_REQUEST,
  clearNotify,
  loadMenu,
  chatShow,
  reciveNotify,
};
