import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachmentIcon from "@material-ui/icons/InsertPhoto";
import FaceIcon from "@material-ui/icons/Face";

import * as memberActions from "redux/member/action";
import * as uiActions from "redux/ui/action";
import * as chatActions from "redux/chat/action";
import * as yup from "yup";
import { Button } from "antd";
import moment from "moment";

export function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    //console.log('Error: ', error);
  };
}

export function decodeBase64Image(base64Str) {
  var matches = base64Str.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
  var image = {};
  if (!matches || matches.length !== 3) {
    throw new Error("Invalid base64 string");
  }

  image.type = matches[1];
  image.data = matches[2];

  return image;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "90vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "60vh",
    overflowY: "auto",
    //bottom: 100,
    backgroundColor: "#94abd5",
  },
  ListItemTextCss: {
    maxWidth: "50%",
    //minWidth: 100,
    backgroundColor: "#88e579",
    padding: 2,
    margin: 3,
    borderRadius: 15,
    float: "right",
    clear: "left",
  },
  ListItemTextCssL: {
    maxWidth: "50%",
    //minWidth: 100,
    fontFamily: "Arial, Helvetica, sans-serif",
    backgroundColor: "#ffffff",
    padding: 2,
    margin: 3,
    borderRadius: 15,
    //float: "left",
    clear: "left",
  },
  ListItemTextHead: {
    paddingTop: 10,
    paddingLeft: 5,
    textDecoration: "underline",
  },
});

export default function AutoGrid() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const btnSubmit = React.useRef(null);
  const btnclick = React.useRef(null);
  const btnphoto = React.useRef(null);
  const chatContainer = React.useRef(null);

  const scrollToMyRef = () => {
    const scroll =
      chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
    chatContainer.current.scrollTo(0, scroll);
  };

  const scrollToBottom = () => {
    //console.log("scrollToBottom");
    scrollToMyRef();
    //btnclick.current.click();
  };

  const history = useHistory();
  const goPage = (path) => {
    history.push(path);
  };
  const { lastpost, historyItems, startpage } = useSelector(
    (store) => store.chat
  );
  const { lastadminpost } = useSelector((store) => store.ui);

  React.useEffect(() => {
    const action = chatActions.GetHistory();
    dispatch(action);
    const uiaction = uiActions.chatShow(true);
    dispatch(uiaction);
    scrollToBottom();
    setmsgtext("");
    btnclick.current.click();
  }, [dispatch]);

  // สั่งให้เลื่อนปุ่มมาล่างสุด
  React.useEffect(() => {
    scrollToBottom();
    setmsgtext("");
  }, [lastpost, lastadminpost]);

  React.useEffect(() => {
    if (startpage) {
      scrollToBottom();
      setmsgtext("");
    }
  }, [startpage]);

  const sendMsg = () => {
    scrollToBottom();
    setmsgtext("");
    btnSubmit.current.click();
  };

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      credit: yup.string().required(),
    }),
  });

  const submit = ({ credit }) => {
    //setmsgtext("");
    setsticker(false);
    const listnew = [
      {
        mobile: localStorage.getItem("mobile"),
        msg: credit,
        membermsg: credit,
        lastpost: moment(Date.now()).format("HH:mm"),
      },
    ];
    //setlastpost(moment(Date.now()).format("DD/MM/YYYY HH:mm"));
    const action = chatActions.GetList(listnew, "text");
    dispatch(action);
    sendMsg();

    //props.history.push("/deposit/pending");
    //const action = memberActions.Booking({ ...dataSave, ...props });
    //dispatch(action);
  };

  const goFIle = () => {
    setsticker(false);
    btnphoto.current.click();
  };
  const onChangeText = (e) => {
    //setsticker(false);
    setmsgtext(e.target.value);
  };
  const [data, setdata] = React.useState([]);
  const [msgtext, setmsgtext] = React.useState(null);
  const [file, setfile] = React.useState(null);
  const [filename, setfilename] = React.useState(null);

  const handleChangePhoto = (event) => {
    if (event.target.files[0]) {
      setfilename(event.target.files[0]);

      const path = event.target.files[0];

      getBase64(path, (result) => {
        const { data: dataimg, type } = decodeBase64Image(result);

        const listnew = [
          {
            mobile: localStorage.getItem("mobile"),
            memberexten: `data:${type};base64`,
            memberphoto: dataimg,
            lastpost: moment(Date.now()).format("HH:mm"),
          },
        ];

        const action = chatActions.GetList(listnew, "photo");
        dispatch(action);

        setfile(null);
        scrollToBottom();
      });
    }
  };

  const ShowMessage = ({ record, key }) => {
    let ListItemTextCss = null;
    let aligntext = null;
    let avataruser = false;
    if (record.membermsg || record?.memberphoto) {
      // กรณีเป็นข้อความของ ลูกค้า
      ListItemTextCss = classes.ListItemTextCss;
      aligntext = "right";
      return (
        <ListItem key={key}>
          <Grid container>
            <Grid item xs={12}>
              {record?.memberphoto && record?.memberexten !== "sticker" && (
                <div style={{ float: aligntext }}>
                  <img
                    src={`${record?.memberexten},${record?.memberphoto}`}
                    width={250}
                  ></img>
                </div>
              )}

              {record.membermsg && (
                <ListItemText
                  className={classes.ListItemTextCss}
                  align={aligntext}
                  primary={record?.membermsg}
                ></ListItemText>
              )}

              {record?.memberexten === "sticker" && (
                <div style={{ float: aligntext }}>
                  <img
                    src={`/images/sticker/${record?.memberphoto}`}
                    width={100}
                  ></img>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align={aligntext}
                secondary={record.lastpost}
              ></ListItemText>
            </Grid>
          </Grid>
        </ListItem>
      );
    } else {
      ListItemTextCss = classes.ListItemTextCssL;
      aligntext = "left";
      avataruser = true;
      return (
        <ListItem key={key}>
          <Grid container>
            <Grid item xs={12}>
              {avataruser && (
                <ListItemIcon>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://material-ui.com/static/images/avatar/1.jpg"
                  />
                  <div className={classes.ListItemTextHead}>{record.user}</div>
                </ListItemIcon>
              )}
              {record?.adminexten &&
                record?.adminexten !== "gif" &&
                record?.adminexten !== "sticker" && (
                  <div>
                    <img
                      src={`${record?.adminexten},${record?.adminphoto}`}
                      width={250}
                    ></img>
                  </div>
                )}

              {!record?.adminexten && (
                <ListItemText
                  className={ListItemTextCss}
                  //className="ListItemTextCss"
                  align={aligntext}
                  primary={record?.adminrmsg}
                >
                  {/*<span className="ListItemTextCss">{record?.adminrmsg}</span>*/}
                </ListItemText>
              )}

              {record?.adminexten === "gif" && (
                <div>
                  <img
                    //height="182"
                    //width="274"
                    alt="GIF"
                    referrerpolicy="origin-when-cross-origin"
                    className="gifphoto"
                    src={record?.adminphoto}
                  ></img>
                </div>
              )}
              {record?.adminexten === "sticker" && (
                <div>
                  <img
                    src={`/images/sticker/${record?.adminphoto}`}
                    width={100}
                  ></img>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <ListItemText
                align={aligntext}
                secondary={record.lastpost}
              ></ListItemText>
            </Grid>
          </Grid>
        </ListItem>
      );
    }
  };

  const [sticker, setsticker] = React.useState(false);
  const setstickerto = (photo) => {
    const listnew = [
      {
        mobile: localStorage.getItem("mobile"),
        memberexten: `sticker`,
        memberphoto: photo,
        lastpost: moment(Date.now()).format("HH:mm"),
      },
    ];

    const action = chatActions.GetList(listnew, "photo");
    dispatch(action);

    setsticker(false);
  };

  const setstickershow = () => {
    setsticker(!sticker);
  };
  return (
    <div>
      {sticker && (
        <div className="bgsticker">
          <ul className="ulsticker">
            {[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
            ].map((res, index) => (
              <li key={index}>
                <img
                  src={`/images/sticker/${res}.png`}
                  width="60"
                  height="60"
                  onClick={() => setstickerto(`${res}.png`)}
                ></img>
              </li>
            ))}
          </ul>
        </div>
      )}
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={12}>
          {/*
             <List className={classes.messageArea} ref={chatContainer}>
            <a href="#pbottom" ref={btnclick}></a>

            {historyItems &&
              historyItems.map((res, index) => (
                <ShowMessage record={res} key={index} />
              ))}
            <a name="pbottom"></a>
          </List>
            */}

          <div className="bgmsgbody" ref={chatContainer}>
            <a href="#pbottom" ref={btnclick}></a>

            {historyItems &&
              historyItems.map((res, index) => (
                <ShowMessage record={res} key={index} />
              ))}
            <a name="pbottom"></a>
          </div>

          <Divider />
          <div className="bgmsg">
            {" "}
            <Grid
              container
              style={{ padding: "20px", backgroundColor: "#fff" }}
            >
              <Grid xs={1} align="left">
                <input
                  type="file"
                  name="file"
                  id="file"
                  class="inputfile"
                  ref={btnphoto}
                  onChange={(e) => handleChangePhoto(e)}
                />

                <AttachmentIcon onClick={goFIle} style={{ color: "#000" }} />
              </Grid>
              <Grid xs={1} align="left">
                <FaceIcon onClick={setstickershow} style={{ color: "#000" }} />
              </Grid>

              <Grid item xs={9}>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit(submit)}
                >
                  <TextField
                    //label="Type Something"
                    inputRef={register}
                    fullWidth
                    id="credit"
                    name="credit"
                    value={msgtext}
                    onChange={(e) => onChangeText(e)}
                    className="bgtextchat"
                  />
                  <button
                    ref={btnSubmit}
                    type="submit"
                    style={{ display: "none" }}
                  ></button>
                </form>
              </Grid>
              <Grid xs={1} align="right">
                <SendIcon onClick={sendMsg} style={{ color: "#d82603" }} />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
