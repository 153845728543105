import axios from 'axios'
import { API_URL } from 'modules/configApp'

const COSTCENTER_REQUEST = 'COSTCENTER/REQUEST'
const COSTCENTER_SUCCESS = 'COSTCENTER/SUCCESS'
const COSTCENTER_FAILED = 'COSTCENTER/FAILED'
const PartAPI = 'CostCenter'
const GetListTree = (data) => {
    return async (dispatch) => {
        dispatch({type:COSTCENTER_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetListTree`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:COSTCENTER_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:COSTCENTER_FAILED})
          }
         
      }
}

export {
    COSTCENTER_REQUEST,
    COSTCENTER_SUCCESS,
    COSTCENTER_FAILED,
    GetListTree
}