import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Container from "@material-ui/core/Container";

import Card from "@material-ui/core/Card";
import Snackbar from "@material-ui/core/Snackbar";
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CodeIcon from "@material-ui/icons/Code";

import { red } from "@material-ui/core/colors";

import { AlertTitle, Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import * as memberActions from "redux/member/action";
import * as uiActions from "redux/ui/action";

import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  rootbg: {
    backgroundColor: "#fff",
  },
  img: {
    flexGrow: 1,
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      accname: yup.string().required(),
      accno: yup.string().required(),
      //accbank: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/

  const [accbank, setaccbank] = React.useState(null);
  const onChange = (e) => {
    setaccbank(e.target.value);
  };
  const submit = ({ accname, accno }) => {
    const action = memberActions.SaveAccount({
      accname,
      accno,
      accbank,
      ...props,
    });
    dispatch(action);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    //const action = memberActions.GetCredit({ ...props });
    //dispatch(action);

    const uiaction = uiActions.chatShow(false);
    dispatch(uiaction);
  }, []);

  const { notify, isLoading, statusacc } = useSelector((store) => store.member);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const action = memberActions.clearNotify();
    dispatch(action);
  };

  const handleCloseState = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.clearNotify();
    dispatch(action);
  };

  return (
    <>
      <Container maxWidth="sm">
        <div style={{ height: 10 }}></div>
        {isLoading ? (
          <div className={classes.rootLoading}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Card className={classes.root}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h5"
                style={{ textAlign: "center", color: "#fff" }}
              >
                {t("addaccount.label")}
              </Typography>

              <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(submit)}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  inputRef={register}
                  id="accname"
                  name="accname"
                  placeholder={t("accname.label")}
                  autoComplete="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControl
                  variant="outlined"
                  fullWidth
                  placeholder="ธนาคาร"
                  className={classes.formControl}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ color: "#fff" }}
                  >
                    {t("selectbank.label")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth
                    inputRef={register}
                    placeholder="ธนาคาร"
                    ///value={bank}
                    name="accbank"
                    onChange={onChange}
                  >
                    <MenuItem value="">
                      <em>{t("bank.label")}</em>
                    </MenuItem>
                    {[
                      { Id: "bbl", Photo: "b1", Name: "กรุงเทพ" },
                      { Id: "krung", Photo: "b2", Name: "กรุงศรีอยุธยา" },
                      { Id: 3, Photo: "b3", Name: "เกียรตินาคิน" },
                      { Id: 4, Photo: "b4", Name: "ยูโอบี" },
                      { Id: 5, Photo: "b5", Name: "ออมสิน" },
                      { Id: 6, Photo: "b6", Name: "สแตนดาร์ดชาร์เตอร์ด" },
                      { Id: "ktb", Photo: "b7", Name: "กรุงไทย" },
                      { Id: "tmb", Photo: "b8", Name: "ทหารไทย" },
                      { Id: "kbank", Photo: "b9", Name: "กสิกรไทย" },
                      { Id: 10, Photo: "b10", Name: "เกียรตินาคิน" },
                      { Id: 11, Photo: "b11", Name: "ธนชาติ" },
                      { Id: "scb", Photo: "b12", Name: "ไทยพานิชณ์" },
                    ].map((res) => (
                      <MenuItem value={res.Id} key={res.Id}>
                        <img
                          src={`/images/bankhome/${res.Photo}.png`}
                          height="22"
                        ></img>{" "}
                        &nbsp; ธ. {res.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  inputRef={register}
                  id="accno"
                  name="accno"
                  placeholder={t("accno.label")}
                  autoComplete="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CodeIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  {t("confirmaddbank.label")}
                </Button>
              </form>
              <div style={{ height: 10 }}></div>
              <Alert severity="warning">
                <AlertTitle>หมายเหตุ</AlertTitle>
                <Typography variant="h7" color="textPrimary" component="h5">
                  ชื่อบัญชีต้องเป็นชื่อเดียวกันกับที่ลงทะเบียนไว้เท่านั้น
                </Typography>
              </Alert>
            </CardContent>
          </Card>
        )}

        <div style={{ height: 50 }}></div>

        <Snackbar
          open={statusacc?.useacc}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleCloseState}
        >
          <Alert onClose={handleCloseState} severity="error">
            คุณป้อนเลขบัญชีซ้ำ กรุณาป้อนใหม่
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
