import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as memberActions from "redux/member/action";
import { LINE_TOKEN  } from 'modules/configApp'


export default function MediaCard(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {

    const liff = window.liff;
    
    const fetchData = async () => {
      await liff.init({ liffId: `${LINE_TOKEN}` }).catch(err=>{throw err});
      if (liff.isLoggedIn()) {
        let getProfile = await liff.getProfile();
        //setuserLineID(getProfile.userId)
        //setName(getProfile.displayName)
        //setpictureUrl(getProfile.pictureUrl)
        const action = memberActions.handlelineid({name:getProfile.displayName,lineid:getProfile.userId},history,"/withdraw");
        dispatch(action);
      }else{
        liff.login();
      }
      /*const action = memberActions.handlelineid({name:"testline",lineid:"testline"},history);
      dispatch(action);*/
    }
    //const action = memberActions.handlelineid({name:"lakky",lineid:"U11430535ffc3b7ea0b5f5a6077cd44d0"},history,"/withdraw");
    //dispatch(action);
    // call the function
    fetchData()
    /*
    fetchData()
      */
  }, []);
  
  
  localStorage.removeItem("tokenstatus");
  localStorage.removeItem("Signature");
  localStorage.removeItem("AdminToken");
  localStorage.removeItem("mobile");
  localStorage.clear();

  return (
<div style={{color:'#fff'}}>
  กำลังเข้าสู่ระบบ HUAY-D ออโต้
</div>

  );
}
