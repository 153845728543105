import {  ACCOUNT_REQUEST,ACCOUNT_SUCCESS,ACCOUNT_NEWS_REQUEST,ACCOUNT_NEWS_SUCCESS,
    ACCOUNT_SAVE_REQUEST,ACCOUNT_SAVE_SUCCESS,ACCOUNT_SAVE_FAILED,
    ACCOUNT_EDIT_REQUEST,ACCOUNT_EDIT_SUCCESS,ACCOUNT_EDIT_FAILED } from './action'

const initailState = {
    isLoading:false,
    items:[],
    formx:{
        Id:'New',
        AccName:null,
        AccNo:null,
        UserName:null,
        Password:null,
        Amount:0,
        Using:true
    }
}

export default function (state = initailState,action){
    switch (action.type) {
        case ACCOUNT_REQUEST:
            return {
                ...state,isLoading:true,items:[]
            }
        case ACCOUNT_SUCCESS:
            const items = action.payload.map((res,index)=>({...res,key:++index}))
                return {
                    ...state,isLoading:false,items
                }
        case ACCOUNT_NEWS_REQUEST:
            return {
                ...state,isLoading:true,formx:{
                    ...initailState.formx
                }
            }
        case ACCOUNT_NEWS_SUCCESS:
                return {
                    ...state,isLoading:false,formx:{
                        ...initailState.formx
                    }
                }
        case ACCOUNT_SAVE_REQUEST:
            return {
                ...state,isLoading:true
            }
        case ACCOUNT_SAVE_SUCCESS:
                return {
                    ...state,isLoading:false,formx:{...action.payload,Id:`${action.payload.Id}`}
                }
        case ACCOUNT_SAVE_FAILED:
                    return {
                        ...state,isLoading:false
                    }
        case ACCOUNT_EDIT_REQUEST:
            return {
                        isLoading:true,formx:{
                         ...initailState.formx
                        }
                    }
        case ACCOUNT_EDIT_SUCCESS:
            return {
                        ...state,isLoading:false,formx:{
                            ...action.payload,Id:`${action.payload.Id}`}
                    }
        case ACCOUNT_EDIT_FAILED:
            return {
                        ...state,isLoading:false
                    }
        default:
            return state
    }
}