import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";

import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";

//import { emphasize, withStyles } from "@material-ui/core/styles";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//import Chip from "@material-ui/core/Chip";
//import HomeIcon from "@material-ui/icons/Home";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
//import history from "history";

import * as memberActions from "redux/member/action";
import CountdownTimer from "./CountdownTimer";
import LottoBetlist from "./Lotto_Betlist";
import LottoMain from "./Lotto_Main";
import { getRemainingTimeUntilMstimestamp } from "./ConutdownTimerUntils";

import "./betlotto.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    isLoadinggame: isLoading,
    lottototal,
    addlottototal,
    betamount,
    betlist3t,
    lottonotify,
    betlist3tod,
    betlist3f,
    betlist3l,
    betlist2t,
    betlist2l,
    betlistrt,
    betlistrl,
    tabcontent,bgphoto,isLoading:isLoadingloto,huaytitle,rewards
  } = useSelector((store) => store.member);
  const { tabmain } = tabcontent;
  const history = useHistory();

  const [open, setOpen] = React.useState(false);


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    dispatch(action2);
    history.push('/lotto/payment')
  }, []);

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);


  const { type,id } = useParams();

  const defaultRemainTime = {
    seconds: "00",
    minutes: "00",
    hours: "00",
    days: "00",
    end: 1,
  };

  const [remainingTime, setRemainingTime] = React.useState(defaultRemainTime);

  React.useEffect(() => {
    
    const action2 = memberActions.GetLotto(id);
    dispatch(action2);

   setTimeout(()=> updateRemainTime(parseInt(type)) ,1)
   

  }, []);

  function updateRemainTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMstimestamp(countdown));
  }


  const handlebtnclickrate = () => {
    const dispacrj = memberActions.btnclickrate();
    dispatch(dispacrj);
  };

  const removeall = () => {
    handleClickOpen2(true);
  };
  const confirmremove = () => {
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);
    handleClose2(false);
  };

  const handleClose = () => {
    const memdispatch = memberActions.hidelottonotify();
    dispatch(memdispatch);
  };

  const handlebetall = (e) => {
    let items = {
      betamount: e.target.value,
      delitem: 2, // ประเภทแก้ไขราคาทั้งหมด
    };
    const memdispatch = memberActions.handleamount(items);
    dispatch(memdispatch);
  };

  const SaveBill =()=> {
    const memdispatch = memberActions.SaveBill(id,history);
    dispatch(memdispatch);
  }

  const gopage = (pat) => {
    history.push(pat);
  };

  const showreward1 =({one})=> {
    if(parseFloat(one)>0){
        return  <ul className="press1">
        <li>
        <div className="reward_contain" >
        <div className="reward_head">ລາງວັນທີ 1</div>
        <div className="reward_body">{one}</div>
    </div>
        </li>
    </ul>
    }
 }

 const checkclassone =(data)=> {
    if(parseFloat(data.one)>0){
        return  <ul className="press3">
        <li> <div className="reward_contain" >
          <div className="reward_head">3 ໂຕຫນ້າ</div>
          <div className="reward_body">{data.front3_1}, {data.front3_2}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head">3 ໂຕລ່າງ</div>
          <div className="reward_body">{data.under3_1}, {data.under3_2}</div>
      </div></li>
      <li> <div className="reward_contain" >
      <div className="reward_head">2 ໂຕລ່າງ</div>
          <div className="reward_body">{data.under2}</div>
      </div></li>
      </ul>
    }else{
        return <div >
          <ul className="press1">
        <li> <div className="reward_contain" >
          <div className="reward_head">3 ໂຕບົນ</div>
          <div className="reward_body">{data.upon3 ? data.upon3:'-'}</div>
      </div></li>
      
      </ul>
           <ul className="press2">
        <li> <div className="reward_contain" >
          <div className="reward_head">2 ໂຕບົນ</div>
          <div className="reward_body">{data.upon2 ? data.upon2:'-'}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head">2 ໂຕລ່າງ</div>
          <div className="reward_body">{data.under2 ? data.under2:'-'}</div>
      </div></li>
      </ul></div>
       
    }
 }

//console.log('remainingTime',remainingTime)
  
    return  <>
    {" "}
    <div className="headpaddingtop"></div>
    <Container
      maxWidth="md"
      style={{
        paddingTop: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
      }}
    >
      <Button
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        variant="outlined"
        onClick={() => gopage("/play/lotto")}
        className="btnback"
      >
        ຍ້ອນກັບ
      </Button>
    </Container>
    <Container maxWidth="md">
          <div className="bgboxcasinox">
            <div style={{ height: 2 }} id="casino"></div>
            <div className="titlelottobet">
            ຜົນລາງວັນ
            </div>
            <div style={{ height: 10 }}></div>
  
            <div className="topcontain">
              <div className={bgphoto}></div>
              <div className="headlotto">
              <span>{huaytitle}</span>
              </div>
  
              <div className="lottocontaner">
                <div style={{ height: 3 }}></div>
  
                {
                          showreward1(rewards)
                      }
                      {checkclassone(rewards)}
                
              </div>
            </div>
  
            <div style={{ height: 10 }}></div>
          </div>
  
          <div className={classes.root}>
            <div style={{ height: 10 }}></div>
          </div>
        </Container>
  </>
}
