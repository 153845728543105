import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";

import { FieldTimeOutlined } from "@ant-design/icons";
import Container from "@material-ui/core/Container";

import { red } from "@material-ui/core/colors";


import * as memberActions from "redux/member/action";
import { lg, PHOME } from "../configApp";
import VideoPlayer from 'react-video-js-player';

const lgtext = {
  thai:{
    1:'วิธีแทงหวย'
  },
  lao:{
    1:'ວິທີ່ການຊື້ເລກ'
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    data,
    items,
    isLoadinggame: isLoading,
    itemsgame,
  } = useSelector((store) => store.member);
  const history = useHistory();

  const goPage = (page) => {
    history.push(page);
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    dispatch(action2);
  }, []);

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);

  React.useEffect(() => {
    const action = memberActions.GameHuayList(4);
    dispatch(action);
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);
  }, []);

  const { items: item } = useSelector((store) => store.member);
  const showmenu = (menu) => {
    const action = memberActions.GameAllList(menu);
    dispatch(action);
  };

  const goPageLotto = (data) => {
   // console.log('data',data)
    const { timestamp, code, statusclose, openstart } = data;
    if(openstart !== 0){
      history.push(`${timestamp}/${code}/lottobet`);
    } else {
      //lotto
      history.push(`/play/lotto/${code}`);
    }
   
  };

  const goPath =(path)=> {
    history.push(path);
  }

  return (
    <div className="bgboxcasinox">
      {" "}
      <div style={{ height: 50 }}></div>
      <Container maxWidth="md">
        <div style={{ height: 40 }} id="sport"></div>
        <div className="bgboxcasinox">
          <div style={{ height: 20 }} id="casino"></div>
          <div className="headsport">
            <span>{lgtext[lg]['1']}</span>
          </div>
          <div style={{ height: 20 }}></div>
          <div className="vdiomainxx">
            {
              PHOME.vedio === 1 &&  <VideoPlayer
              controls={true}
              src={PHOME.playlotto}
              //poster="/images/aff9.jpeg"
              className="vdiomain2"
             //width={vidovaheit.w}
             // height={vidovaheit.h}
              autoplay={true}
          />
            }
               
     </div>

          <div style={{ height: 10 }}></div>
        </div>

        {loadspin && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className={classes.root}>
          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
    </div>
  );
}
