import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Button from "@material-ui/core/Button";
//import axios from "axios";
//import { API_URL } from "modules/configApp";

import Container from "@material-ui/core/Container";
//import Grid from "@material-ui/core/Grid";

import Card from "@material-ui/core/Card";

import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";

import { red } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";

import * as memberActions from "redux/member/action";
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    //left: 500,

    position: "absolute",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const { data, items } = useSelector((store) => store.member);
  const history = useHistory();

  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    dispatch(action2);
    const action = memberActions.GameEvoList("habanerogamelist");
    dispatch(action);
  }, []);

  const {
    isLoading,
    loadgame,
    gamelist,
    items: item,
  } = useSelector((store) => store.member);

  //memberActions
  const goPage = (page) => {
    history.push(page);
  };

  const logingame = async (game) => {
    setloadspin(true);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=habanero&game=" + game;
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
  };
  const [loadspin, setloadspin] = React.useState(false);
  console.log("item", item);
  return (
    <div>
      {" "}
      <Container maxWidth="sm">
        <Card className={classes.headpaper}>
          <Breadcrumbs className={classes.paper} aria-label="breadcrumb">
            <StyledBreadcrumb
              component="a"
              href="#"
              label="กลับ"
              icon={<ArrowBackOutlinedIcon fontSize="small" />}
              onClick={() => goPage("/casino")}
            />
            <StyledBreadcrumb
              component="a"
              href="#"
              label="Habanero"
              //onClick={clickopen}
            />
          </Breadcrumbs>
        </Card>
      </Container>
      <div style={{ height: 10 }}></div>
      <Container maxWidth="sm">
        <div className="divheadcasino">
          <img src="/images/evoplay/hab.png" className="imgcasinoh"></img>
        </div>

        <div className="divbgcasino">
          <ul className="pressitem">
            {isLoading ? (
              <div className="divLoadingfixxx">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              ""
            )}

            {gamelist &&
              gamelist.map((res) => (
                <li key={res.game_id}>
                  <div
                    className="divlogo1"
                    style={{ cursor: "pointer" }}
                    onClick={() => logingame(res.gamekey, res.gamebrandid)}
                    //onClick={() => window.open("https://www.google.com")}
                  >
                    <img
                      src={`/images/habanero/${res.gamekey}.png`}
                      className="imgcasinoitem"
                    ></img>
                  </div>
                  <div className="divtextcasino">{res.gamename}</div>
                </li>
              ))}
          </ul>
        </div>
        {loadspin && (
          <div className="divLoading">
            <CircularProgress color="secondary" />
          </div>
        )}
        <div className={classes.root}>
          {/*
 <Card className={classes.rootCard}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://bio2-auto.biocore-api.com/api/apilogin/api_login/login?agent=oec&username=" +
                    items.username +
                    "&password=" +
                    items.agentpass +
                    "&type=pretty&host=http://www.casinobio.net"
                )
              }
            >
              <CardMedia
                className={classes.mediaHead}
                image="/images/pretty.jpg"
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h3">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    Pretty Gaming{" "}
                    <Button variant="contained" color="secondary">
                      เข้าเล่น
                    </Button>
                  </Grid>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
            */}

          <div style={{ height: 10 }}></div>
          {/*
 <Card className={classes.rootCard}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://bio2-auto.biocore-api.com/api/apilogin/api_login/login?agent=oec&username=" +
                    items.username +
                    "&password=" +
                    items.agentpass +
                    "&type=dg&host=http://www.casinobio.net"
                )
              }
            >
              <CardMedia
                className={classes.mediaHead}
                image="/images/dg.jpg"
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h3">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    Dream Gaming{" "}
                    <Button variant="contained" color="secondary">
                      เข้าเล่น
                    </Button>
                  </Grid>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
            */}

          <div style={{ height: 10 }}></div>
          {/*
 <Card className={classes.rootCard}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://bio2-auto.biocore-api.com/api/apilogin/api_login/login?agent=oec&username=" +
                    items.username +
                    "&password=" +
                    items.agentpass +
                    "&type=sexy&host=http://www.casinobio.net"
                )
              }
            >
              <CardMedia
                className={classes.mediaHead}
                image="/images/sexy.jpeg"
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h3">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    Sexy Baccarat{" "}
                    <Button variant="contained" color="secondary">
                      เข้าเล่น
                    </Button>
                  </Grid>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
            */}

          <div style={{ height: 10 }}></div>

          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
    </div>
  );
}
