import React from "react";

//import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";

import Table from "./Table";

export default function FullWidthTabs(props) {
  return (
    <>
      <Container maxWidth="md">
        <div style={{ height: 10 }}></div>
        <Table />
      </Container>
    </>
  );
}
