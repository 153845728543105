import axios from 'axios'
import { message} from 'antd'
import { API_URL } from 'modules/configApp'

const WEBSITE_REQUEST = 'WEBSITE/REQUEST'
const WEBSITE_SUCCESS = 'WEBSITE/SUCCESS'
const WEBSITE_FAILED = 'WEBSITE/FAILED'
const WEBSITE_NEWS_REQUEST = 'WEBSITE/WEBSITE_NEWS_REQUEST'
const WEBSITE_NEWS_SUCCESS = 'WEBSITE/WEBSITE_NEWS_SUCCESS'
const WEBSITE_NEWS_FAILED = 'WEBSITE/WEBSITE_NEWS_FAILED'
const WEBSITE_SAVE_REQUEST = 'WEBSITE/WEBSITE_SAVE_REQUEST'
const WEBSITE_SAVE_SUCCESS = 'WEBSITE/WEBSITE_SAVE_SUCCESS'
const WEBSITE_SAVE_FAILED = 'WEBSITE/WEBSITE_SAVE_FAILED'
const WEBSITE_EDIT_REQUEST = 'WEBSITE/WEBSITE_EDIT_REQUEST'
const WEBSITE_EDIT_SUCCESS = 'WEBSITE/WEBSITE_EDIT_SUCCESS'
const WEBSITE_EDIT_FAILED = 'WEBSITE/WEBSITE_EDIT_FAILED'
const WEBSITE_ADD_ROW = 'WEBSITE/WEBSITE_ADD_ROW'
const WEBSITE_REQUEST_DEL_ROW = 'WEBSITE/WEBSITE_REQUEST_DEL_ROW'
const WEBSITE_DEL_ROW = 'WEBSITE/WEBSITE_DEL_ROW'
const WEBSITE_SELECT_ROW = 'WEBSITE/WEBSITE_SELECT_ROW'
const WEBSITE_SELECT_ROW_ITEM = 'WEBSITE/WEBSITE_SELECT_ROW_ITEM'
const WEBSITE_SELECT_ROW_ALL = 'WEBSITE/WEBSITE_SELECT_ROW_ALL'
const WEBSITE_UPDATE_ITEMS = 'WEBSITE/WEBSITE_UPDATE_ITEMS'
const WEBSITE_UPDATE_ITEMS_BYID = 'WEBSITE/WEBSITE_UPDATE_ITEMS_BYID'
const WEBSITE_UPDATE_BYVALUE = 'WEBSITE/WEBSITE_UPDATE_BYVALUE'

const PartAPI = 'Website'
const GetList = (data) => {
    return async (dispatch) => {
        dispatch({type:WEBSITE_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetList`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:WEBSITE_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:WEBSITE_FAILED})
          }
         
      }
}

const Save = ({Id,Token,Using,Name,WebsiteAccount,history}) => {
    return async (dispatch) => {
        dispatch({type:WEBSITE_SAVE_REQUEST})
          try{
              const {data:{Status,Data,Text}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/Save`,
                  data:{Id,Token,Using,Name,WebsiteAccount}
          })

          if(Status===0){
            message.success('บันทึกข้อมูลสำเร็จ');
            history.push('/website/edit/'+Data.Id)
            dispatch({type:WEBSITE_SAVE_SUCCESS,payload:Data})
          }else{
            message.error('ข้อมูลซ้ำกัน กรุณาป้อนใหม่');
            dispatch({type:WEBSITE_SAVE_FAILED,payload:Text})
          }
          }catch(err){
            message.error('ระบบมีปัญหา กรุณาป้อนใหม่');
            dispatch({type:WEBSITE_SAVE_FAILED,payload:err})
          }
         
      }
}


const GetDataNew = ()=> {
    return async (dispatch) => {
        dispatch({type:WEBSITE_NEWS_REQUEST})
        try{
            const {data:{Data}} = await axios({
                method: 'post',
                url: `${API_URL}/Bank/GetList`,
                data:{}
        })
            dispatch({type:WEBSITE_NEWS_SUCCESS,payload:Data})
            
        }catch(err){
            dispatch({type:WEBSITE_NEWS_FAILED})
        }
    }
}


const GetData =(Id)=> {
    return async (dispatch) => {
        dispatch({type:WEBSITE_EDIT_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'get',
                  url: `${API_URL}/${PartAPI}/GetData?Id=${Id}`,
                  headers:{'Content-Type': 'application/json','token':localStorage.getItem('Signature')}
          })
          dispatch({type:WEBSITE_EDIT_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:WEBSITE_EDIT_FAILED})
          }
         
      }
}


const AddRow =()=> {
    return (dispatch,getState) => {
        const { unit } = getState()
        dispatch({type:WEBSITE_ADD_ROW,payload:{unit}})
    }
}

const DelRow =()=> {
    return (dispatch,getState) => {
        const { system } = getState()
        const { items } = system
        const { TaxRate,Fraction } = items
        dispatch({type:WEBSITE_REQUEST_DEL_ROW})
        setTimeout(()=>dispatch({type:WEBSITE_DEL_ROW,payload:{TaxRate,Fraction}}),1)
        
    }
}

const SelectRow =(row)=> {
    return dispatch => {
        dispatch({type:WEBSITE_SELECT_ROW,payload:row})
    }
}

const DelAllRow =()=> {
    return dispatch => {
        dispatch({type:WEBSITE_NEWS_SUCCESS})
    }
}

const SelectRowItem =(row,selected)=> {
    return dispatch => {
        dispatch({type:WEBSITE_SELECT_ROW_ITEM,payload:{items:{...row},selected}})
    }
}
const SelectRowItemAll =(selected)=> {
    return dispatch => {
        dispatch({type:WEBSITE_SELECT_ROW_ALL,payload:selected})
    }
}


const UpdateItems =(row,dataIndex)=> {
    return (dispatch,getState) => {
        const { system,unit } = getState()
        const { items } = system
        const { TaxRate,Fraction,PRQty } = items
        let ExtenObject = {}
        if(dataIndex==='FKUnit'){
            const { Name } = unit.items.find(res=>res.Id===row.FKUnit)
            ExtenObject = {
                UnitName:Name
            }
        }
        dispatch({type:WEBSITE_UPDATE_ITEMS,payload:{...row,...ExtenObject,dataIndex,PRQty,TaxRate,Fraction}})
    }
}

const UpdateItemsByType =(row,dataIndex,Type)=> {
    return (dispatch) => {
 
        if(Type==='Account'){
            const { AccNo,AccName,Id,BankName } = row
            const NewPoitem = {
                AccNo,AccName,Id,BankName
            }
            dispatch({type:WEBSITE_UPDATE_ITEMS,payload:{...NewPoitem,dataIndex}})
        
        }
        
        
    }
}

export {
    WEBSITE_REQUEST,
    WEBSITE_SUCCESS,
    WEBSITE_FAILED,
    WEBSITE_NEWS_REQUEST,
    WEBSITE_NEWS_SUCCESS,
    WEBSITE_NEWS_FAILED,
    WEBSITE_SAVE_REQUEST,
    WEBSITE_SAVE_SUCCESS,
    WEBSITE_SAVE_FAILED,
    WEBSITE_EDIT_REQUEST,
    WEBSITE_EDIT_SUCCESS,
    WEBSITE_EDIT_FAILED,
    WEBSITE_SELECT_ROW,
    WEBSITE_UPDATE_ITEMS,
    GetList,
    GetDataNew,
    GetData,
    Save,
    AddRow,
    DelAllRow,
    DelRow,
    SelectRow,
    SelectRowItem,
    SelectRowItemAll,
    UpdateItems,
    UpdateItemsByType
}