import axios from 'axios'
import { message} from 'antd'
import { API_URL } from 'modules/configApp'

const BANK_REQUEST = 'BANK/REQUEST'
const BANK_SUCCESS = 'BANK/SUCCESS'
const BANK_FAILED = 'BANK/FAILED'
const BANK_NEWS_REQUEST = 'BANK/BANK_NEWS_REQUEST'
const BANK_NEWS_SUCCESS = 'BANK/BANK_NEWS_SUCCESS'
const BANK_NEWS_FAILED = 'BANK/BANK_NEWS_FAILED'
const BANK_SAVE_REQUEST = 'BANK/BANK_SAVE_REQUEST'
const BANK_SAVE_SUCCESS = 'BANK/BANK_SAVE_SUCCESS'
const BANK_SAVE_FAILED = 'BANK/BANK_SAVE_FAILED'
const BANK_EDIT_REQUEST = 'BANK/BANK_EDIT_REQUEST'
const BANK_EDIT_SUCCESS = 'BANK/BANK_EDIT_SUCCESS'
const BANK_EDIT_FAILED = 'BANK/BANK_EDIT_FAILED'
const PartAPI = 'Bank'
const GetList = (data) => {
    return async (dispatch) => {
        dispatch({type:BANK_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetList`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:BANK_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:BANK_FAILED})
          }
         
      }
}

const Save = ({Id,Token,Using,Name,Url,history}) => {
    return async (dispatch) => {
        dispatch({type:BANK_SAVE_REQUEST})
          try{
              const {data:{Status,Data,Text}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/Save`,
                  data:{Id,Token,Using,Name,Url}
          })

          if(Status===0){
            message.success('บันทึกข้อมูลสำเร็จ');
            history.push('/bank/edit/'+Data.Id)
            dispatch({type:BANK_SAVE_SUCCESS,payload:Data})
          }else{
            message.error('ข้อมูลซ้ำกัน กรุณาป้อนใหม่');
            dispatch({type:BANK_SAVE_FAILED,payload:Text})
          }
          }catch(err){
            message.error('ระบบมีปัญหา กรุณาป้อนใหม่');
            dispatch({type:BANK_SAVE_FAILED,payload:err})
          }
         
      }
}


const GetDataNew =()=> {
    return (dispatch) => {
        dispatch({type:BANK_NEWS_REQUEST})
        try{
            setTimeout(()=>dispatch({type:BANK_NEWS_SUCCESS}),100)
            
        }catch(err){
            dispatch({type:BANK_NEWS_FAILED})
        }
    }
}


const GetData =(Id)=> {
    return async (dispatch) => {
        dispatch({type:BANK_EDIT_REQUEST})
          try{
              const {data:{Data:{Main}}} = await axios({
                  method: 'get',
                  url: `${API_URL}/${PartAPI}/GetData?Id=${Id}`,
                  headers:{'Content-Type': 'application/json','token':localStorage.getItem('Signature')}
          })
          dispatch({type:BANK_EDIT_SUCCESS,payload:Main})
        
          }catch(err){
            dispatch({type:BANK_EDIT_FAILED})
          }
         
      }
}

export {
    BANK_REQUEST,
    BANK_SUCCESS,
    BANK_FAILED,
    BANK_NEWS_REQUEST,
    BANK_NEWS_SUCCESS,
    BANK_NEWS_FAILED,
    BANK_SAVE_REQUEST,
    BANK_SAVE_SUCCESS,
    BANK_SAVE_FAILED,
    BANK_EDIT_REQUEST,
    BANK_EDIT_SUCCESS,
    BANK_EDIT_FAILED,
    GetList,
    GetDataNew,
    GetData,
    Save
}