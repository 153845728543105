export const textName = {th:{
    code:'เลขที่',
    url:'URL',
    website:'เว็บไซต์',
    lastupdate:'อับเดทล่าสุด',
    date:'วันที่',
    mobile:'เบอร์มือถือ',
    bank:'ธนาคาร',
    accname:'ชื่อบัญชี',
    accno:'เลขที่บัญชี',
    name:'ชื่อ',
    username:'Username',
    password:'Password',
    address:'ที่อยู่',
    telephone:'โทรศัพท์',
    suppliergroup:'กลุ่มผู้ขาย',
    costcenter:'โครงการ/แผนก',
    supplier:'ผู้ขาย',
    status:'สถานะ',
    documentdate:'วันที่เอกสาร',
    recivedate:'วันที่รับของ',
    approve:'ผู้อนุมัติ',
    poname:'ผู้สั่งซื้อ',
    pocontact:'ผู้ติดต่อ',
    location:'สถานที่ส่งของ',
    locationmark:'หมายเหตุส่งของ',
    meterial:'วัสดุ',
    labor:'ค่าแรง',
    approvestatus:'สถานะการอนุมัติ',
    Price:'จำนวนเงิน',
    TaxType:'ประเภทภาษี',
    discount:'ส่วนลด',
    TaxAmount:'ภาษีมูลค่าเพิ่ม',
    Value:'มูลค่าสินค้า/บริการ',
    BeforeTax:'ยอดเงินไม่รวมภาษี',
    Amount:'ยอดเงินสุทธิ',
    retention:'Retention',
    deposit:'มัดจำ',
    description:'รายละเอียด',
    memo:'หมายเหตุ',
    btn:{
      create:'เพิ่มใหม่',
      del:'ลบ',
      save:'บันทึก',
      cancel:'ยกเลิก',
      close:'ปิด',
      print:'พิมพ์',
      preview:'ดูตัวอย่าง',
      approve:'ยืนยัน',
      reject:'ส่งกลับ',
      upload:'อัพโหลด',
      download:'ดาวน์โหลด',
    },
    docstatus:{
      new:'เพิ่มใหม่',
      edit:'แก้ไข'
    }
  }}