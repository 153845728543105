import axios from 'axios'
import moment from 'moment'
import { API_URL } from 'modules/configApp'

const SYSTEM_REQUEST = 'SYSTEM/REQUEST'
const SYSTEM_SUCCESS = 'SYSTEM/SUCCESS'
const SYSTEM_FAILED = 'SYSTEM/FAILED'
const PartAPI = 'User'
const GetSystem = () => {
    return async (dispatch) => {
        dispatch({type:SYSTEM_REQUEST})
          try{
            const result = await axios.get(API_URL+'/'+PartAPI+'/GetSystem',{ headers:{'Content-Type': 'application/json','X-Signature':'Signature'} })
            

            const { SetDate } = result.data.Data[0]

              
                let ToDay = moment(Date.now()).format('DD/MM/YYYY')
                let StartDate = moment(Date.now()).format('DD/MM/YYYY')
                if(SetDate===1){
                    StartDate = moment().subtract(7,'d').format('DD/MM/YYYY')
                }
            
                if(SetDate===2){
                    StartDate = moment().subtract(1, 'months').format('DD/MM/YYYY')
                }
            
                if(SetDate===3){
                    StartDate = moment().subtract(3, 'months').format('DD/MM/YYYY')
                }
            
                if(SetDate===4){
                    StartDate = moment().subtract(1, 'years').format('DD/MM/YYYY')
                }
                
                dispatch({type:SYSTEM_SUCCESS,payload:{...result.data.Data[0],StartDate,ToDay}})
        
          }catch(err){
            dispatch({type:SYSTEM_FAILED})
          }
         
      }
}

export {
    SYSTEM_REQUEST,
    SYSTEM_SUCCESS,
    SYSTEM_FAILED,
    GetSystem
}