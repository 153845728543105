import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import reducers from 'redux/reducers'

 function configureStore(initailState){
    const middleware = [thunk]

    const store = createStore(
        reducers,
        initailState,
        applyMiddleware(...middleware)
    )
    return store
}

export default configureStore