import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import Container from "@material-ui/core/Container";
import LanguageIcon from "@material-ui/icons/Language";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";

import Snackbar from "@material-ui/core/Snackbar";
import useFetch from "lib/useFetch";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { API_URL, addCommas } from "modules/configApp";
import * as memberActions from "redux/member/action";

import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  img: {
    flexGrow: 1,
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  rootbg: {
    backgroundColor: "#171414",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { errorchangepass, changepass, isLoading } = useSelector(
    (store) => store.member
  );

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      password: yup.string().required(),
      repassword: yup.string().required(),
      confirmrepassword: yup.string().required(),
    }),
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/

  const dispatch = useDispatch();
  const submit = ({ password, repassword, confirmrepassword }) => {
    if (repassword !== confirmrepassword) {
      setOpen(true);
    } else {
      const action = memberActions.ChangePassword({
        password,
        repassword,
        ...props,
      });
      dispatch(action);
    }
  };
  const goBack = (path) => {
    props.history.push(path);
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosepass = () => {
    const action = memberActions.clearNotify();
    dispatch(action);
  };

  const handleSuccess = () => {
    window.location = "/";
  };

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setbanknotify(false);
    const action = memberActions.clearNotify();
    dispatch(action);
  };
  const [banknotify, setbanknotify] = React.useState(false);
  const [text, settext] = React.useState("");
  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);
  return (
    <>
      <div className="headpaddingtop"></div>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/")}
          className="btnback"
        >
          ย้อนกลับ
        </Button>
      </Container>
      <div style={{ height: 20 }}></div>
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div className="bgconh1">
          <Typography
            gutterBottom
            variant="h5"
            className="headtitle"
            component="h3"
          >
            เปลี่ยนรหัสผ่าน
          </Typography>
          {/*
 <Typography
              gutterBottom
              variant="h5"
              style={{ textAlign: "center", color: "#fff" }}
              component="h3"
            >
              {t("hregister.label")}
            </Typography>
              */}
          <div style={{ height: 20 }}></div>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(submit)}
          >
            <input
              ref={register}
              type="password"
              id="password"
              name="password"
              class="form__input"
              placeholder="ป้อนรหัสผ่านเดิม"
              autocomplete="off"
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />
            <div style={{ height: 20 }}></div>
            <input
              ref={register}
              type="password"
              id="repassword"
              name="repassword"
              class="form__input"
              placeholder="ป้อนรหัสผ่านใหม่"
              autocomplete="off"

              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />
            <div style={{ height: 20 }}></div>
            <input
              ref={register}
              type="password"
              id="confirmrepassword"
              name="confirmrepassword"
              class="form__input"
              placeholder="ยืนยันรหัสผ่านใหม่"
              autocomplete="off"
            />

            <div style={{ height: 20 }}></div>

            <div className="btnsubmit" onClick={submit2}>
              {!isLoading && "บันทึกข้อมูล"}
              {isLoading && (
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
            <button type="submit" ref={btnSubmit} className="btnnone"></button>
            <div style={{ height: 50 }}></div>

            <div style={{ height: 10 }}></div>
          </form>
          <div style={{ height: 90 }}></div>
        </div>

        {/*
        isLoading && (
          <div className={classes.rootLoading}>
            <CircularProgress color="secondary" />
          </div>
        )
        */}
      </Container>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          คุณยืนยันรหัสผ่านไม่ตรงกัน กรุณาป้อนใหม่
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorchangepass}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClosepass}
      >
        <Alert onClose={handleClosepass} severity="error">
          คุณป้อนรหัสผ่านเดิมไม่ถูกต้อง กรุณาป้อนใหม่
        </Alert>
      </Snackbar>

      <Snackbar
        open={changepass}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSuccess}
      >
        <Alert onClose={handleSuccess} severity="success">
          เปลี่ยนรหัสผ่านใหม่เรียบร้อย ระบบจะทำ Logout อัตโนมัติ
        </Alert>
      </Snackbar>
    </>
  );

  /*
  return (
    <>
      <Container maxWidth="md">
        <div style={{ height: 30 }}></div>
        <Typography
          gutterBottom
          variant="h5"
          component="h5"
          style={{ textAlign: "center", color: "#fff" }}
        >
          {t("changepass.label")}
        </Typography>
        <div className="affline"></div>
        <div style={{ height: 20 }}></div>

        <div style={{ height: 10 }}></div>

        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(submit)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputRef={register}
            name="password"
            type="password"
            //label="ใส่ยอดเงิน"
            placeholder={`รหัสผ่านเดิม`}
            autoComplete="email"
            //autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputRef={register}
            name="repassword"
            type="password"
            //label="ใส่ยอดเงิน"
            placeholder={`รหัสผ่านใหม่`}
            autoComplete="email"
            //autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputRef={register}
            name="confirmrepassword"
            //label="ใส่ยอดเงิน"
            placeholder={`ยืนยันรหัสผ่านใหม่`}
            autoComplete="email"
            //autoFocus
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          <div style={{ height: 10 }}></div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            //style={{ backgroundColor: "green" }}
            color="secondary"
          >
            บันทึกข้อมูล
          </Button>
        </form>
      </Container>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          คุณยืนยันรหัสผ่านไม่ตรงกัน กรุณาป้อนใหม่
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorchangepass}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClosepass}
      >
        <Alert onClose={handleClosepass} severity="error">
          คุณป้อนรหัสผ่านเดิมไม่ถูกต้อง กรุณาป้อนใหม่
        </Alert>
      </Snackbar>

      <Snackbar
        open={changepass}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSuccess}
      >
        <Alert onClose={handleSuccess} severity="success">
          เปลี่ยนรหัสผ่านใหม่เรียบร้อย ระบบจะทำ Logout อัตโนมัติ
        </Alert>
      </Snackbar>
    </>
  );
  */
}
