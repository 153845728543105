import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CachedIcon from "@material-ui/icons/Cached";
import { Menu, MenuItem } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import LockIcon from "@material-ui/icons/Lock";
import HistoryIcon from "@material-ui/icons/History";
import GroupWorkIcon from "@material-ui/icons/GroupAdd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import MoneyIcon from "@material-ui/icons/Money";
import CircularProgress from "@material-ui/core/CircularProgress";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreditCardIcon from "@material-ui/icons/AccountBalanceWallet";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import HomeIcon from "@material-ui/icons/Home";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import TodayIcon from "@material-ui/icons/Today";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import RemoveCircleSharpIcon from "@material-ui/icons/RemoveCircleSharp";
import { red } from "@material-ui/core/colors";
import * as uiActions from "redux/ui/action";
import * as loginAcions from "redux/signin/action";
import * as memberActions from "redux/member/action";
import { addCommas } from "../../configApp";
//import { Agent } from "modules/configApp";
import io from "socket.io-client";
import { ENDPOINT } from "modules/configApp";
import { Button } from "antd";
import { LOGO } from 'modules/configApp'
//const ENDPOINT = "http://128.199.177.142:9000/";
//const logo = "images/biglogo2.png";
//const logo = "images/biglogo.jpeg";
//const logo = "images/themblue/logo333.png";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //backgroundColor:'#c20731',
    backgroundColor: "#fff",
    //opacity: 0.9,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawerHeaderFix: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    //backgroundColor: "#181818",
    backgroundColor: "#000000",
  },
  drawerHeaderFix2: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    backgroundColor: "#181818",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  avatar: {
    backgroundColor: red[500],
  },
  money: {
    color: "#a29134",
  },
}));

export default function PersistentDrawerRight() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const changeLanguage = (event) => {
    localStorage.setItem("LG", event);
    setAnchorEl(null);
    i18n.changeLanguage(event);
  };

  const { items: itemsSignin } = useSelector((store) => store.signin);
  const { isLoading, photo } = useSelector((store) => store.member);

  //console.log(items);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openleft, setOpenleft] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen2 = () => {
    setOpenleft(true);
  };

  const handleDrawerClose2 = () => {
    setOpenleft(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goPage = (path) => {
    if (path == "/member/signout") {
      dispatch(loginAcions.logout(history));
    } else {
      history.push(path);
      setOpenleft(false);
      setOpen(false);
      setState({ ...state, right: false });
    }
  };

  const loadCredit = () => {
    const action = memberActions.GetCredit({});
    dispatch(action);
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const MenuTop2 = [
    {
      Id: 2,
      Name: "ตั้งค่าบัญชี",
      Link: "/",
      Photo: "infor.png",
      Icon: <SettingsIcon />,
    },
    {
      Id: 11,
      Name: "คู่มือการใช้งาน",
      Link: "/",
      Photo: "turnover.png",
      Icon: <ChromeReaderModeIcon />,
    },
   
  ];

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <List>
        <div className="bgheadmenu">ระบบการเงิน</div>
      </List>
      <div className="mainrightbar">
        <div className="mdgin10" onClick={() => goPage("/dashboard")}>
          <div className="divmbox">
            <HomeIcon style={{ fontSize: 30 }} />
            <div>หน้าแรก</div>
          </div>
        </div>

        <div className="mdgin10" onClick={() => goPage("/deposit")}>
          <div className="divmbox">
            <CreditCardIcon style={{ fontSize: 30 }} />
            <div>เติมเงิน</div>
          </div>
        </div>
      </div>
      <div className="mainrightbar">
        <div className="mdgin10" onClick={() => goPage("/withdraw")}>
          <div className="divmbox">
            <MonetizationOnIcon style={{ fontSize: 30 }} />
            <div>ถอนเงิน</div>
          </div>
        </div>

        <div className="mdgin10" onClick={() => goPage("/member/history")}>
          <div className="divmbox">
            <HistoryIcon style={{ fontSize: 30 }} />
            <div>ประวัติ</div>
          </div>
        </div>
      </div>

      <Divider />
      <List>
        <div className="bgheadmenu">สิทธิประโยชน์</div>
      </List>
      <div className="mainrightbar">
        <div className="mdgin10">
          <div className="divmbox">
            <GroupWorkIcon style={{ fontSize: 30 }} />
            <div>แนะนำเพื่อน</div>
          </div>
        </div>

        <div className="mdgin10">
          <div className="divmbox">
            <MoneyIcon style={{ fontSize: 30 }} />
            <div>คืนยอดเสีย</div>
          </div>
        </div>
      </div>
      <div className="mainrightbar">
        <div className="mdgin10">
          <div className="divmbox">
            <ImportantDevicesIcon style={{ fontSize: 30 }} />
            <div>แลกรางวัล</div>
          </div>
        </div>

        <div className="mdgin10">
          <div className="divmbox">
            <TodayIcon style={{ fontSize: 30 }} />
            <div>กิจกรรม</div>
          </div>
        </div>
      </div>

      <List></List>
      <List>
        {MenuTop2.map(({ Id, Name, Link, Photo, Icon }, index) => (
          <ListItem
            button
            key={Id}
            onClick={() => goPage(Link)}
            className="bglinedot"
          >
            <ListItemIcon style={{ color: "#000" }}>{Icon}</ListItemIcon>
            <ListItemText style={{ color: "#000" }} primary={Name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
// /images/biglogo7777.png
// /images/logoptgame.png
//const pathimg = "/images/biglogo7777.png"
//const pathimg = "/images/skylot44.png"
const pathimg = "/images/skylo44-new.png"
  return (
    <>
      <div className="hhtop">
      <ul className="ufooter">
      

 <li onClick={() => goPage("/play/lotto")}>
          <div className={pathname.includes("lotto") && "acbuttom"}>
            <img src="/images/them2/huay.png"></img>
            <div className="acbottomac">หวย</div>
          </div>
        </li>

 <li onClick={() => goPage("/play/sport")}>
          <div className={pathname.includes("sport") && "acbuttom"}>
            <img src="/images/them2/ball.png"></img>
            <div className="acbottomac">กีฬา</div>
          </div>
        </li>
 
        <li onClick={() => goPage("/play/casinos")}>
          <div className={pathname.includes("casinos") && "acbuttom"}>
            <img src="/images/them2/casino.png"></img>
            <div className="acbottomac">คาสิโน</div>
          </div>
        </li>

        <li onClick={() => goPage("/play/slot")} >
          <div className={pathname.includes("slot") && "acbuttom"} >
            <img src="/images/them2/sl.png"></img>
            <div className="acbottomac">สล๊อต</div>
          </div>
        </li>
        <li >
            <div className="pgloss" onClick={toggleDrawer("right", true)}><img src={photo} className="imgold" /></div>
        </li>
      </ul>
      </div>
      <div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          <div className="dmenu">
            <span className="mmdmenu" onClick={toggleDrawer("right", false)}>
            <img src={photo} className="imgold" />
            </span>
            เมนู
          </div>
          {list("right")}
        </Drawer>
      </div>
    </>
    /*
<div className="hhtop">
      <ul className="hfooter">
        <li>
          
          {<img src="/images/biglogo2.png" height="40"></img>}
        </li>
        <li>
         
        </li>
        <li></li>
        <li>
        <img src="/images/logout2.png" onClick={logout} height="30"></img>
        </li>
      </ul>
    </div>
          */
  );
}
