import axios from 'axios'
import { API_URL } from 'modules/configApp'

const SUPPLIER_REQUEST = 'SUPPLIER/REQUEST'
const SUPPLIER_SUCCESS = 'SUPPLIER/SUCCESS'
const SUPPLIER_FAILED = 'SUPPLIER/FAILED'
const PartAPI = 'Supplier'
const GetList = (data) => {
    return async (dispatch) => {
        dispatch({type:SUPPLIER_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetList`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:SUPPLIER_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:SUPPLIER_FAILED})
          }
         
      }
}

export {
    SUPPLIER_REQUEST,
    SUPPLIER_SUCCESS,
    SUPPLIER_FAILED,
    GetList
}