import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import moment from 'moment'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EventNoteIcon from '@material-ui/icons/EventNote';
import axios from "axios";
import { API_URL } from "modules/configApp";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Container from "@material-ui/core/Container";
import SearchIcon from '@material-ui/icons/Search';
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MoneyIcon from "@material-ui/icons/Money";
import Grid from "@material-ui/core/Grid";
import { red } from "@material-ui/core/colors";
import GroupIcon from "@material-ui/icons/Group";
import LanguageIcon from "@material-ui/icons/Language";

import { addCommas } from "../configApp";

import * as memberActions from "redux/member/action";

import { lg } from '../configApp'

const lgtext = {
  lao:{
    1:'ໂພຍຫວຍ',
    2:'ຍ້ອນກັບ',
    3:'ຍອດລວມ',
    4:'ຖ້າຜົນ',
    5:'ກີບ',
    6:'ອອກຜົນແລ້ວ',
    7:'ຍັງບໍ່ອອກຜົນ',
    8:'ໂພຍທີ່ຍັງບໍ່ອອກ',
    9:'ໂພຍທີ່ອອກຜົນແລ້ວ',
    10:'ຍອດຊື້ມື້ນີ້',
    11:'ໂພຍ',
    12:'ໂພຍເລກທີ່',
    13:'ວັນທີ',
    14:'ເງິນເດີມພັນ',
    15:'ຜົນແພ້/ຊະນະ',
    16:'ລາຍລະອຽດ',
    17:'ຊະນະ',
    18:'ແພ້',
    19:'ຍົກເລີກ',
   
  },
  thai:{
    1:'โพยหวย',
    2:'ย้อนกลับ',
    3:'ยอดรวม',
    4:'รอผล',
    5:'บาท',
    6:'ออกผลแล้ว',
    7:'ยังไม่ออกผล',
    8:'โพยที่ยังไม่ออก',
    9:'โพยที่ออกผลแล้ว',
    10:'ยอดซื้อวันนี้',
    11:'โพย',
    12:'โพยเลขที่',
    13:'วันที่',
    14:'เงินเดิมพัน',
    15:'ผลแพ้/ชะนะ',
    16:'รายละเอียด',
    17:'ชนะ',
    18:'แพ้',
    19:'ยกเลิก',
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    isLoadinggame: isLoading,
    itemsbill:itemsgame,
    totalall,
  } = useSelector((store) => store.member);
  const history = useHistory();


  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    //dispatch(action2);
  }, []);

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);
  const [startDate, setTimedate] = React.useState(moment(Date.now()).format("YYYY-MM-DD"));
  const { lineid } = useSelector((store) => store.member);
  React.useEffect(() => {
    //const datenow = moment(Date.now()).format("YYYY-MM-DD")
    const action = memberActions.GameHuayBillHistory(1,lineid);
    dispatch(action);
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);
  }, []);


 const showreward1 =({one})=> {
    if(parseFloat(one)>0){
        return  <ul className="press1">
        <li>
        <div className="reward_contain" >
        <div className="reward_head">รางวัลที่ 1</div>
        <div className="reward_body">{one}</div>
    </div>
        </li>
    </ul>
    }
 }

 const goBack =(path)=> {
   history.push(path)
 }

 const goPage =(id)=> {
   history.push(`/play/poy/${id}`)
 }

 const [tab, setValue] = React.useState(4);

  const changeTap = (tab) => {
    setValue(tab);
   
    if(tab ==3){
      const action = memberActions.GameHuayBillHistory(0,lineid);
      dispatch(action);
    }else{
      const action = memberActions.GameHuayBillHistory(1,lineid);
      dispatch(action);
    }
    
  };

  const checstatus = (type, list)=> {
    if(list.status_run == 0 && list.winloss ==0 ) {
      return <span className="dvv3">แพ้</span>;
    }else if(list.status_run == 0 && list.winloss > 0 ) {
      return <span className="dvv2">ชนะ</span>;
    } else {
      return <span className="dvv">รอผล</span>;
    }
    /*
    switch(type){
      case 1 : 
          return <span className="dvv">{lgtext[lg]['4']}ss</span>;
      case 2 : 
          return <span className="dvv2">{lgtext[lg]['17']}xx</span>;
      case 3 : 
          return <span className="dvv3">{lgtext[lg]['18']}ll</span>;
      case 4 : 
          return <span className="dvv">รอผล</span>;
      
      default: return ""
    }
    */
}

const checkreward = (rows) => {
  const { status, status_run, winloss} = rows
  if(status_run === 1){
    return 0
  }else if(status === 4){
    return 0
  } else {
    return addCommas(parseFloat(winloss).toFixed(0))
  }
}

  return (
    
    <div className="bgboxcasinox">
    
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/")}
          className="btnback"
        >
          {lgtext[lg]['2']}
        </Button>
      </Container>
      {" "}
      <div style={{ height: 5 }}></div>
      <Container maxWidth="md">
        <div style={{ height: 1 }} id="sport"></div>
        <div className="bgboxcasinox">
          <div style={{ height: 20 }} id="casino"></div>
          <div className="headsport">
            <span>{lgtext[lg]['1']}</span>
          </div>
         
          <div style={{ height: 20 }}></div>
         
                
                   
                    <div className="bglotto">
                      <div ></div>
                      <div className="bgresult">{lgtext[lg]['10']} </div>
                     
                      <ul className="press3">
        <li> <div className="reward_contain" >
          <div className="reward_head">{lgtext[lg]['3']}</div>
          <div className="reward_body">{addCommas(parseFloat(totalall).toFixed(0))} {lgtext[lg]['5']}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head2">{lgtext[lg]['6']}</div>
          <div className="reward_body2">0 {lgtext[lg]['5']}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head2">{lgtext[lg]['7']}</div>
          <div className="reward_body2">0 {lgtext[lg]['5']}</div>
      </div></li>
      </ul>
      <div className="bglotto_bottom">
      <ul className="poy">
          <li className={tab == 4 && "active"} onClick={() => changeTap(4)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <LanguageIcon />
              {lgtext[lg]['11']}
            </Grid>
          </li>
          <li className={tab == 1 && "active"} onClick={() => changeTap(1)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <GroupIcon />
              {lgtext[lg]['8']}
            </Grid>
          </li>
         

          <li className={tab == 3 && "active"} onClick={() => changeTap(3)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <AccountBalanceWalletIcon />
              {lgtext[lg]['9']}
            </Grid>
          </li>
        </ul>
        <div className="poy_detail">
            <ul className="press2">
                {
                    itemsgame && itemsgame.map((list,index)=>
                    <li key={index}> <div className="reward_contain" >
                    <div className="reward_head3"><span className="span001">{lgtext[lg]['12']} #{list.code}</span>{checstatus(list.status, list)}</div>
                    <div className="reward_body2">
                        <div className="reward_body2_contain">
                        <div className="divleft">
                            <div className="divleft_contain">
                              <div className="divleft_head">{lg == 'lao' ? list.name:list.nameth}</div>
                              <div className="divleft_detail">{lgtext[lg]['13']} : {lg == 'lao' ? list.enddate:list.enddateth}</div>
                            </div>
                        </div>
                    
                        <div className="divright">
                        <div className="divleft_contain2">
                              <div className="divleft_head">{lgtext[lg]['14']}<span className="span001x">{addCommas(parseFloat(list.amount).toFixed(0))} {lgtext[lg]['5']}</span></div>
                              <div className="divleft_detail">{lgtext[lg]['15']} <span className="span001x">{checkreward(list)} {lgtext[lg]['5']}</span></div>
                            </div>
                        </div>
                        <div className="divbuttom233">
                        <EventNoteIcon className="mline3" style={{fontSize:20}} /> {list.date} <QueryBuilderIcon style={{fontSize:18}} className="mline3" /> {list.time} <span className="span001x"> <Button size="small" onClick={()=>goPage(list._id)} variant="contained" >
                        {lgtext[lg]['16']} <SearchIcon style={{fontSize:20}}  />
                  </Button> </span>
                        </div>
                        </div>
                        
                    </div>
                    
                </div></li>
                    )
                }
            </ul>
        </div>
                      </div>
      
                     
                      
                    </div>
                 
                
               

          <div style={{ height: 10 }}></div>
        </div>

        

        {loadspin && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className={classes.root}>
          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
    </div>
  );
}
