import React from 'react'
import { Route,Switch,useRouteMatch,Redirect } from 'react-router-dom'

import PageList from './PageList'
import PageNew from './PageNew'

export default function Routes() {
    
    const {path} = useRouteMatch()
    return (
        <Switch>
            <Route path={`${path}/:id/:menuid/new`} component={PageNew}></Route>
            <Route path={`${path}/:id/:menuid/edit/:docid`}>PO EDIT</Route>
            <Route path={`${path}/:id/:menuid`} component={PageList}></Route>
        </Switch>
    )
}
