import {  ROLEMENU_REQUEST,ROLEMENU_SUCCESS } from './action'

const initailState = {
    isLoading:false,
    items:[]
}

export default function (state = initailState,action){
    switch (action.type) {
        case ROLEMENU_REQUEST:
            return {
                ...state,isLoading:true,items:[]
            }
        case ROLEMENU_SUCCESS:
                return {
                    ...state,isLoading:false,items:action.payload
                }
        default:
            return state
    }
}