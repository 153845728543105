import axios from 'axios'
import { API_URL } from 'modules/configApp'

const METERIAL_REQUEST = 'METERIAL/REQUEST'
const METERIAL_SUCCESS = 'METERIAL/SUCCESS'
const METERIAL_FAILED = 'METERIAL/FAILED'
const PartAPI = 'Item'
const GetList = (data) => {
    return async (dispatch) => {
        dispatch({type:METERIAL_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetList`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:METERIAL_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:METERIAL_FAILED})
          }
         
      }
}

export {
    METERIAL_REQUEST,
    METERIAL_SUCCESS,
    METERIAL_FAILED,
    GetList
}