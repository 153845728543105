import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb,Tag,Form,Row,Col,Input,Button,Select } from 'antd'
import { HomeOutlined,DoubleRightOutlined} from '@ant-design/icons'
import { textName } from 'lib/language'
import { addCommas,APP_TEXT } from 'modules/configApp'
import moment from 'moment'
import TextBoxNumber from './TextBoxNumber'

const PageRightTitle = ({isLoadingSubdoc,itemsSubdoc,showMenu,match:{url}})=> {
const Title = url.includes('new') && <Breadcrumb.Item><Tag color="#f50">{textName.th.docstatus.new}</Tag></Breadcrumb.Item>
    return (
        <div>
            {isLoadingSubdoc ? null:<div className={showMenu ? 'page_title_head':'page_title_head_ac'}>
        <Breadcrumb>
    <Breadcrumb.Item >
      <Link to="/dashboard"><HomeOutlined /></Link>
    </Breadcrumb.Item>
         <Breadcrumb.Item>{itemsSubdoc.Name}</Breadcrumb.Item>
         {Title}
  </Breadcrumb>
        </div>}
        </div>
    )
}

const renderColums =(text,record,DataType,dataIndex)=> {
    if(DataType==='Int'){
      return <>{parseInt(record.Type) !== 5 ? text ? addCommas(parseFloat(text).toFixed(2)) :0:null}&nbsp;</>
    }else if(DataType==='Date'){
      return <>{parseInt(record.Type) !== 5 ? text ? moment(text).format('DD/MM/YYYY') :null:null}&nbsp;</>
    }else if(DataType==='Tax'){
      return <>{parseInt(record.Type) !== 5 ? text ? APP_TEXT.key_txtstatus[text] :null:null}&nbsp;</>
    }else if(DataType==='FixName'){
      return <>{parseInt(record.Type) !== 5 ? text ? record[dataIndex] :null:null}&nbsp;</>
    }else{
      return <>{parseInt(record.Type) !== 5 ? text ? text :null:null}&nbsp;</>
    }
  }

const FooterForm =({form,onChangeDiscount,formlayout,DiscountAmount,BeforeTax,Tax,TaxAmount,Value,ValueAmount,Amount,Discount,Price,PriceAmount,onChangeNumber,TaxType,btnSelect,onChangeSelect,getsystem})=> {
  return <Form
  form={form} 
  name="formcontrol-hooks" 
   {...formlayout}
 >
         <Row>
           <Col span={12}><Form.Item
     name="Price"  
     label={textName.th.Price}
   >
     <Input style={{width:'40%'}} value={addCommas(parseFloat(Price).toFixed(2))} disabled className="textRight" /><Button type="default" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><TextBoxNumber style={{width:'40%'}} className="textRight" name="PriceAmount" value={PriceAmount} onChange={onChangeNumber} />
   </Form.Item></Col>
   <Col span={12}>
   
     <Form.Item
     name="TaxType"  
     label={textName.th.TaxType}
     defaultValue={TaxType}
   >
     <Select style={{width:'40%'}} value={TaxType} ref={btnSelect} id="TaxType" onChange={onChangeSelect}> {APP_TEXT.status_taxtype.map(res=><Select.Option key={res.Id} value={res.Id} selected>{res.Name}</Select.Option>)}</Select><Button type="primary" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><Input style={{width:'40%'}} className="textRight" disabled value={getsystem?.items?.TaxRate}  />
   </Form.Item></Col>
         </Row>
         <Row>
           <Col span={12}><Form.Item
     name="Discount"  
     label={textName.th.discount}
   >
     <Input style={{width:'40%'}} id="Discount" onChange={onChangeDiscount} value={Discount}   /><Button type="primary" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><Input disabled style={{width:'40%'}} className="textRight" value={addCommas(parseFloat(DiscountAmount).toFixed(2))} />
   </Form.Item></Col>
   <Col span={12}><Form.Item
     name="TaxAmount"  
     label={textName.th.TaxAmount}
   >
     <Input style={{width:'40%'}} className="textRight" value={addCommas(parseFloat(Tax).toFixed(2))} disabled /><Button type="default" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><TextBoxNumber style={{width:'40%'}} className="textRight" name="TaxAmount" value={TaxAmount} onChange={onChangeNumber} />
   </Form.Item></Col>
         </Row>
         <Row>
         <Col span={12}><Form.Item
     name="BeforeTax"  
     label={textName.th.Value}
   >
     <Input style={{width:'90%'}} className="textRight" value={addCommas(parseFloat(BeforeTax).toFixed(2))} disabled  /><span></span>
   </Form.Item></Col>
   <Col span={12}></Col>
         </Row>
         <Row>
           <Col span={12}><Form.Item
     name="Value"  
     label={textName.th.BeforeTax}
   >
     <Input style={{width:'40%'}} className="textRight" value={addCommas(parseFloat(Value).toFixed(2))} disabled /><Button type="primary" style={{width:'10%'}} disabled icon={<DoubleRightOutlined />}></Button><TextBoxNumber style={{width:'40%'}} className="textRight" name="ValueAmount" value={ValueAmount} onChange={onChangeNumber} />
   </Form.Item></Col>
   <Col span={12}><Form.Item
     name="Amount"  
     label={textName.th.Amount}
   >
     <Input style={{width:'90%'}} className="textRight" value={addCommas(parseFloat(Amount).toFixed(2))} disabled  /><span></span>
   </Form.Item>
   </Col>
         </Row>
         </Form>
}

  export {
    renderColums,
    PageRightTitle,
    FooterForm
  }