import axios from 'axios'
import { API_URL } from 'modules/configApp'

const ROLEMENU_REQUEST = 'ROLEMENU/REQUEST'
const ROLEMENU_SUCCESS = 'ROLEMENU/SUCCESS'
const ROLEMENU_FAILED = 'ROLEMENU/FAILED'
const PartAPI = 'User'
const GetRoleMenu = (menuid) => {
    return async (dispatch) => {
        dispatch({type:ROLEMENU_REQUEST})
          try{
           
                
                dispatch({type:ROLEMENU_SUCCESS,payload:{
                  ReadView:'True',
                  CreateEdit:'True',
                  PrintOut:'True',
                  Del:'True',
                  Cancel:'True'
                }})
        
          }catch(err){
            dispatch({type:ROLEMENU_FAILED})
          }
         
      }
}

export {
    ROLEMENU_REQUEST,
    ROLEMENU_SUCCESS,
    ROLEMENU_FAILED,
    GetRoleMenu
}