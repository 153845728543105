import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import * as loginActions from "redux/signin/action";
import * as memberActions from "redux/member/action";

import * as yup from "yup";
//import { yupResolver } from '@hookform/resolvers'
import { LOGO,PHOME,LINE_TOKEN  } from 'modules/configApp'
import VideoPlayer from 'react-video-js-player';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 135,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      mobile: yup.string().required(),
      password: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
const [vidova, setVedio] = React.useState(true);
  const [vidovaheit, setVedioh] = React.useState({
    w:200,
    h:130
  });

  const submit = ({ mobile, password }) => {
    if (checkbtn == 1) {
      const action = loginActions.Login({ mobile, password, ...props });
      dispatch(action);
    } else {
      const action = memberActions.Save({ mobile, password, ...props });
      dispatch(action);
    }
  };

  const [userLineID, setuserLineID] = React.useState("");
  const [name, setName] = React.useState("");
  const [pictureUrl, setpictureUrl] = React.useState("");

  useEffect(() => {

    const liff = window.liff;
    
    const fetchData = async () => {
      await liff.init({ liffId: `${LINE_TOKEN}` }).catch(err=>{throw err});
      if (liff.isLoggedIn()) {
        let getProfile = await liff.getProfile();
        //setuserLineID(getProfile.userId)
        //setName(getProfile.displayName)
        //setpictureUrl(getProfile.pictureUrl)
        const action = memberActions.handlelineid({name:getProfile.displayName,lineid:getProfile.userId},history,"/play/resultlotto");
        dispatch(action);
      }else{
        liff.login();
      }
      /*const action = memberActions.handlelineid({name:"testline",lineid:"testline"},history);
      dispatch(action);*/
    }
    const action = memberActions.handlelineid({name:"testlinexx",lineid:"U9e7ca054dda5050b338128f1aeda0dd5"},history,"/play/resultlotto");
    //dispatch(action);
    // call the function
    fetchData()
    /*
    fetchData()
     
      .catch(console.error);
      */
  }, []);
  const dispatch = useDispatch();
  const { isLoading, message, count, show } = useSelector(
    (store) => store.signin
  );

  const {
    isLoading: isLoadingregis,
    message: messageregis,
    datanotify,
  } = useSelector((store) => store.member);

  const [checkbtn, setcheckbtn] = React.useState(1);
 

  const GoPage = () => {
    //setcheckbtn(2);

    history.push("/signup");
  };
  const GoPage2 = () => {
    //setcheckbtn(2);

    history.push("/contact");
  };

  const GoPageForgot = () => {
    history.push("/forgotpass");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const action = loginActions.clearsignin();
    dispatch(action);
  };

  const handleCloseregis = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.GetList();
    dispatch(action);
  };

  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);

  localStorage.removeItem("tokenstatus");
  localStorage.removeItem("Signature");
  localStorage.removeItem("AdminToken");
  localStorage.removeItem("mobile");
  localStorage.clear();

  return (
<div style={{color:'#fff'}}>
  กำลังเข้าสู่ระบบ หวยออโต้
</div>

  );
}
