import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "store/configureStore";
import history from "./history";

//import packageJson from "../package.json";
import withClearCache from "./ClearCache";

//import "./App.css";
//import "./gridstyle.css";
//import "./AppGreen.css";
//import "./App333.css"; // Skylot44
import "./Daddy44.css"; // daddy44
//import "./Lek95.css"; // Lek95
//import "./Mom147.css"; // mom147

//import "./atmlotto2.css"; // SBD19
//import "./lot2day.css"; // lot2day
//import "./mylot789.css"; // mylot789

//import "./playlot999.css"; // playlot999 ยกเลิก

import "./index.css";
import "./indexall.css";
import "./i18n";
import Layout from "modules/ui/components/Layout";

/*
ชื่อ ขวาบน
.MuiDrawer-paperAnchorRight
*/

const store = configureStore();

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  return (
    <Suspense fallback={null}>
      <Provider store={store}>
        <Router history={history}>
          <Layout></Layout>
        </Router>
      </Provider>
    </Suspense>
  );
}

export default App;
