import axios from 'axios'
import { API_URL } from 'modules/configApp'

const PO_REQUEST = 'PO/REQUEST'
const PO_SUCCESS = 'PO/SUCCESS'
const PO_FAILED = 'PO/FAILED'
const PO_NEWS_REQUEST = 'PO/PO_NEWS_REQUEST'
const PO_NEWS_SUCCESS = 'PO/PO_NEWS_SUCCESS'
const PO_NEWS_FAILED = 'PO/PO_NEWS_FAILED'
const PO_ADD_ROW = 'PO/PO_ADD_ROW'
const PO_DEL_ROW = 'PO/PO_DEL_ROW'
const PO_REQUEST_DEL_ROW = 'PO/PO_REQUEST_DEL_ROW'
const PO_DELALL_ROW = 'PO/PO_DELALL_ROW'
const PO_SELECT_ROW = 'PO/PO_SELECT_ROW'
const PO_SELECT_ROW_ALL = 'PO/PO_SELECT_ROW_ALL'
const PO_SELECT_ROW_ITEM = 'PO/PO_SELECT_ROW_ITEM'
const PO_UPDATE_ITEMS = 'PO/PO_UPDATE_ITEMS'
const PO_UPDATE_ITEMS_BYID = 'PO/PO_UPDATE_ITEMS_BYID'
const PO_UPDATE_BYVALUE = 'PO/PO_UPDATE_BYVALUE'
const PartAPI = 'po'

const GetList = (data) => {
    return async (dispatch) => {
        dispatch({type:PO_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetList`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:PO_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:PO_FAILED})
          }
         
      }
}

const GetDataNew =()=> {
    return (dispatch) => {
        dispatch({type:PO_NEWS_REQUEST})
        try{
            dispatch({type:PO_NEWS_SUCCESS})
        }catch(err){
            dispatch({type:PO_NEWS_FAILED})
        }
    }
}

const AddRow =()=> {
    return (dispatch,getState) => {
        const { unit } = getState()
        dispatch({type:PO_ADD_ROW,payload:{unit}})
    }
}

const DelRow =()=> {
    return (dispatch,getState) => {
        const { system } = getState()
        const { items } = system
        const { TaxRate,Fraction } = items
        dispatch({type:PO_REQUEST_DEL_ROW})
        setTimeout(()=>dispatch({type:PO_DEL_ROW,payload:{TaxRate,Fraction}}),1)
        
    }
}

const SelectRow =(row)=> {
    return dispatch => {
        dispatch({type:PO_SELECT_ROW,payload:row})
    }
}

const DelAllRow =()=> {
    return dispatch => {
        dispatch({type:PO_NEWS_SUCCESS})
    }
}

const SelectRowItem =(row,selected)=> {
    return dispatch => {
        dispatch({type:PO_SELECT_ROW_ITEM,payload:{items:{...row},selected}})
    }
}
const SelectRowItemAll =(selected)=> {
    return dispatch => {
        dispatch({type:PO_SELECT_ROW_ALL,payload:selected})
    }
}


const UpdateItems =(row,dataIndex)=> {
    return (dispatch,getState) => {
        const { system,unit } = getState()
        const { items } = system
        const { TaxRate,Fraction,PRQty } = items
        let ExtenObject = {}
        if(dataIndex==='FKUnit'){
            const { Name } = unit.items.find(res=>res.Id===row.FKUnit)
            ExtenObject = {
                UnitName:Name
            }
        }
        dispatch({type:PO_UPDATE_ITEMS,payload:{...row,...ExtenObject,dataIndex,PRQty,TaxRate,Fraction}})
    }
}

const UpdateItemsByType =(row,dataIndex,Type)=> {
    return (dispatch,getState) => {
        const { system } = getState()
        const { items } = system
        const { TaxRate,Fraction } = items
      
        if(Type==='Meterial'){
            const { Code,Name,UnitName,FairPrice,FKDefaultunit } = row
            const NewPoitem = {
                Code,Description:Name,UnitName,PriceUnit:FairPrice,FKUnit:FKDefaultunit
            }
            dispatch({type:PO_UPDATE_ITEMS,payload:{...NewPoitem,dataIndex,PRQty:items.PRQty,TaxRate,Fraction}})
        }else if(Type==='Labor'){
            const { Code,Name,UnitName,Price,FKUnit } = row
            const NewPoitem = {
                Code,Description:Name,UnitName,PriceUnit:Price,FKUnit
            }
            dispatch({type:PO_UPDATE_ITEMS,payload:{...NewPoitem,dataIndex,PRQty:items.PRQty,TaxRate,Fraction}})
        }else{
            const NewPoitem = {
                Discount:row.Discount
            }
            
        
            dispatch({type:PO_UPDATE_ITEMS,payload:{...NewPoitem,dataIndex,PRQty:items.PRQty}})
        }
        
        
    }
}

const UpdateItemsById =(value,dataIndex)=> {
    return (dispatch,getState) => {
        const { system } = getState()
        const { items } = system
        //const { TaxRate,RetantionType,CostCenterQty,CostCenterPrice,POPrice,POQty,PRDay,Fraction } = items
        const { TaxRate,Fraction } = items
        dispatch({type:PO_UPDATE_ITEMS_BYID,payload:{value,dataIndex,TaxRate,Fraction}})
    }
}

const UpdateToValue =(value,dataIndex)=> {
    return dispatch => {
        dispatch({type:PO_UPDATE_BYVALUE,payload:{value,dataIndex}})
    }
   
}

export {
    PO_REQUEST,
    PO_SUCCESS,
    PO_FAILED,
    PO_NEWS_REQUEST,
    PO_NEWS_SUCCESS,
    PO_NEWS_FAILED,
    PO_ADD_ROW,
    PO_DEL_ROW,
    PO_REQUEST_DEL_ROW,
    PO_DELALL_ROW,
    PO_SELECT_ROW,
    PO_UPDATE_ITEMS,
    PO_UPDATE_ITEMS_BYID,
    PO_UPDATE_BYVALUE,
    PO_SELECT_ROW_ITEM,
    PO_SELECT_ROW_ALL,
    GetList,
    GetDataNew,
    AddRow,
    DelRow,
    SelectRow,
    UpdateItems,
    UpdateItemsByType,
    UpdateItemsById,
    UpdateToValue,
    SelectRowItem,
    DelAllRow,
    SelectRowItemAll
}