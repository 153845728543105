import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Snackbar from "@material-ui/core/Snackbar";
import InputAdornment from "@material-ui/core/InputAdornment";
import { red } from "@material-ui/core/colors";
import { Lock, Call } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { emphasize, withStyles } from "@material-ui/core/styles";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FilterNoneIcon from "@material-ui/icons/FilterNone";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import { Alert, AlertTitle } from "@material-ui/lab";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";

import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  img: {
    flexGrow: 1,
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  btn: {
    color: "#e08c09",
    borderColor: "#45351d",
  },
  formalert: {
    background: "transparent",
    fontSize: 16,
    color: "#f49b0e",
  },
  formalertbank: {
    fontSize: 16,
    backgroundColor: "#ddd",
  },
  submit4: {
    backgroundColor: "#ef9f22",
    //color: "#000",
    height: 40,
    fontSize: 20,
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [credit, setCredit] = React.useState(0);
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      credit: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });
  const submit = ({ credit }) => {
    const dataSave = {
      credit,
    };
    console.log(dataSave);
    /*
    const action = memberActions.Save({ ...dataSave, ...props });
    dispatch(action);
    */
  };

  const { data, isLoading } = useSelector((store) => store.member);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const setCreditNew = (credit) => {
    setCredit(credit);
  };
  const setCreditNew2 = (credit) => {
    setCredit(credit.target.value);
  };

  const openBank = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Card className={classes.root}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h5"
              style={{ textAlign: "center", color: "#c01f25" }}
            >
              {t("deposit.label")}
            </Typography>

            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(submit)}
            >
              {/*
 <input
                placeholder="กรอกเบอร์มือถือ (ไม่ต้องใส่ -)"
                className={classes.input}
              ></input>
                */}
              <Alert
                severity="warning"
                className={classes.formalert}
                icon={<AccessTimeIcon />}
              >
                กรุณาโอนภายใน 08:00 นาที
              </Alert>
              <Alert
                severity="info"
                className={classes.formalertbank}
                icon={<img src="/images/blank.png" height="10"></img>}
              >
                <List className={classes.root}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src="/images/bankhome/b1.png" height="50"></img>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="ธนาคารกรุงเทพ" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src="/images/accname.png" height="50"></img>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="นายอาทิตย์ สอนจรูณ" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src="/images/accno.png" height="50"></img>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="066778779" />
                  </ListItem>
                </List>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      size="small"
                      //className={classes.btn}
                      onClick={() => openBank("http://www.google.com")}
                    >
                      ลิ้งค์ธนาคารแจ้งฝาก
                    </Button>
                  </Grid>
                </Grid>
              </Alert>

              <div style={{ height: 10 }}></div>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit4}
              >
                5000.89
              </Button>
              <Alert severity="warning">
                เมื่อโอนแล้วกรุณากดยืนยันด้านล่าง
              </Alert>

              <div style={{ height: 50 }}></div>

              <div style={{ height: 50 }}></div>
            </form>
          </CardContent>
          {/*
<CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
     */}
        </Card>
        {isLoading && (
          <div className={classes.rootLoading}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </Container>
    </>
  );
}
