import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import PageList from "./PageList";

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/`} component={PageList}></Route>
    </Switch>
  );
}
