import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GroupIcon from "@material-ui/icons/Group";
import Container from "@material-ui/core/Container";
import LanguageIcon from "@material-ui/icons/Language";
import Typography from "@material-ui/core/Typography";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

import Grid from "@material-ui/core/Grid";
import Tab1 from "./Aff1";
import Tab4 from "./Aff4";
import Tab3 from "./Aff3";
import Tab2 from "./Aff2";
import { CopyToClipboard } from "react-copy-to-clipboard";
//import MovingText from "react-moving-text";
import VideoPlayer from 'react-video-js-player';
import { PHOME, lg } from "../configApp";

const lgtext = {
  thai:{
    1:'ลิ้งค์ร่ำรวย รับ',
    2:'ยอดเล่น',
    3:'อัตรา ',
    4:'ส่งลิ้งร่ำรวยให้เพื่อนสมัครรับค่าคอมๆ',
    5:'และรับคอมมิชชั่นจากเพื่อนของเพื่อนคุณอีก',
    6:'ลิ้งค์ร่ำรวย',
    7:'คลิกลิ้งค์',
    8:'ยอดรวม',
    9:'สมาชิก',
    10:'ถอนรายได้',
    11:'จาก',
  },
  lao:{
    1:'ລິ້ງລ້ຳລວຍ ຮັບ',
    2:'ຍອດຫລິ້ນ',
    3:'ອັດຕາ ',
    4:'ສົ່ງຕໍ່ລິ້ງລ້ຳລວຍຂອງເຮົາໃຫ້ໝູ່ສະໝັກຮັບຄອມມິດຊັ້ນຂອງໝູ່',
    5:'ແລະຄ່າຄອມມິດຊັ້ນຂອງໝູ່ ຂອງໝູ່ອີກ',
    6:'ລິ້ງລ້ຳລວຍ',
    7:'ຄລິກລິ້ງ',
    8:'ຍອດລວມ',
    9:'ສະມາຊິກ',
    10:'ຖອນລາຍໄດ້',
    11:'ຈາກ',
  }
}

export default function FullWidthTabs(props) {
  const { t } = useTranslation();
  const [tab, setValue] = React.useState(4);

  const changeTap = (tab) => {
    setValue(tab);
  };

  const [copied, setcopied] = React.useState(false);
  const [vidova, setVedio] = React.useState(true);
  const [vidovaheit, setVedioh] = React.useState({
    w:200,
    h:130
  });

  const setCopyall = () => {
    setcopied(true);
  };
  const { items, lottotypeitems } = useSelector((store) => store.member);

  const checkrateactive = () => {
   
    const data = lottotypeitems && lottotypeitems.find(x => x.typerun == 3)
    if(data?.rate == 90){
      return '8%'
    } else {
      return '7%'
    }
   
  }

  return (
    <>
      <div className="headpaddingtop"></div>
      <Container maxWidth="md">
        <div style={{ height: 30 }}></div>
        <Typography
          gutterBottom
          variant="h5"
          component="h5"
          style={{ textAlign: "center" }}
          className="headaff"
        >
          {lgtext[lg]['1']}
          <font color="#e8a608">7%,1% </font>{lgtext[lg]['11']}
          {items?.agent?.afftype == 1 ? lgtext[lg]['2'] : "ยอดเสีย"}
        </Typography>
        <div className="affline"></div>
        <div style={{ height: 10 }}></div>
      
      {
        /*
 <div className="maffdes">
        <span className="spanday2">{lgtext[lg]['3']} 750</span>&nbsp;
        {lgtext[lg]['4']} <span className="sp1">8%</span> {lgtext[lg]['5']} <span className="sp1">1%</span>
        </div>
        */
      }
       
        <div style={{ height: 10 }}></div>
        <div className="maffdes">
        <span className="spanday2">{lgtext[lg]['3']} 830</span>&nbsp;
        {lgtext[lg]['4']} <span className="sp1">7%</span> {lgtext[lg]['5']} <span className="sp1">1%</span>
        </div>
        <div style={{ height: 10 }}></div>
        <div class="container topcontain">
          <img src="/images/affiliate2.png" height="80"></img>
          <div
            style={{
              textAlign: "center",
              color: "#000",
              height: 40,
              fontSize: 14,
            }}
          >
            {lgtext[lg]['6']}
            <CopyToClipboard
              text={`${items?.agent?.url}/signup?aff=${items?.affcode}`}
              onCopy={() => setCopyall()}
            >
              <a className="btn-gradaff">{lgtext[lg]['7']}</a>
            </CopyToClipboard>
            {copied ? (
              <span style={{ textAlign: "right", color: "#e8a608" }}></span>
            ) : null}
          </div>
          <div className={copied ? "affbgacc" : "affbg"}>
            {items?.agent?.url}/signup?aff={items?.affcode}
          </div>

        </div>

        <div style={{ height: 10 }}></div>
       
        <div className="vdiomainxx">
          {
            PHOME.vedioaff === 1 && <VideoPlayer
            controls={true}
            src={PHOME.aff}
            //poster="/images/aff9.jpeg"
            className="vdiomain"
           //width={vidovaheit.w}
           // height={vidovaheit.h}
            autoplay={true}
        />
          }

                </div>
        <div style={{ height: 10 }}></div>
        <ul className="aff">
          <li className={tab == 4 && "active"} onClick={() => changeTap(4)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <LanguageIcon />
              {lgtext[lg]['8']}
            </Grid>
          </li>
          <li className={tab == 1 && "active"} onClick={() => changeTap(1)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <GroupIcon />
              {lgtext[lg]['9']}
            </Grid>
          </li>
          {/*
<li className={tab == 2 && "active"} onClick={() => changeTap(2)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <MoneyIcon />
              รายได้
            </Grid>
          </li>
            */}

          <li className={tab == 3 && "active"} onClick={() => changeTap(3)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <AccountBalanceWalletIcon />
              {lgtext[lg]['10']}
            </Grid>
          </li>
        </ul>
       
        <div style={{ height: 10 }}></div>
        {tab == 1 && <Tab1 type={items?.agent?.afftype} />}
        {tab == 4 && <Tab4 type={items?.agent?.afftype} rate={checkrateactive()} />}
        {tab == 2 && <Tab2 type={items?.agent?.afftype} />}
        {tab == 3 && <Tab3 type={items?.agent?.afftype} />}
      </Container>
      <div style={{ height: 120 }}></div>
    
     
    </>
  );
}
