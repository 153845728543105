import {
  CHAT_HISTORY_SUCCESS,
  CHAT_HISTORY_REQUEST,
  CHAT_HISTORY_FAILED,
  CHAT_LIST_REQUEST,
  CHAT_LIST_SUCCESS,
  CHAT_LIST_FAILED,
} from "./action";
import moment from "moment";
const initailState = {
  isLoading: false,
  isLoadingHis: false,
  historyItems: [],
  item: [],
  lastpost: null,
  startpage: false,
  countchat: 0,
};

export default function (state = initailState, action) {
  switch (action.type) {
    case CHAT_HISTORY_REQUEST:
      return {
        ...state,
        isLoadingHis: true,
      };
    case CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingHis: false,
        startpage: true,
        countchat: 0,
        historyItems: action.payload.dataitems,
      };
    case CHAT_LIST_REQUEST:
      return {
        ...state,
        isLoadingHis: true,
      };
    case CHAT_LIST_SUCCESS:
      // เพิ่มรายการเดิม เติมรายการใหม่
      const { dataitems, historyitems } = action.payload;

      const newitem = dataitems.reduce((result, list) => [...result, list], [
        ...historyitems,
      ]);

      return {
        ...state,
        isLoadingHis: false,
        historyItems: newitem,
        countchat: action.payload?.count ? action.payload?.count : 0,
        lastpost: moment(Date.now()),
      };
    default:
      return state;
  }
}
