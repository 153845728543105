import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Table,Spin,Row,Col,Form,Input, Button } from 'antd';
import { Resizable } from 'react-resizable';
//import useFetch from 'lib/useFetch'
import { getWindowDimensions,pageSize  } from 'modules/configApp'
import { textName } from 'lib/language'
import {renderColums} from 'modules/ui/components/ShareComponent'
import * as laborActions from 'redux/setup/labor/action'

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const Supplier=({onAction,textSearch,checkmodal})=> {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [form] = Form.useForm();
    const dispatch = useDispatch()
    const {isLoading,items} = useSelector(store=>store.labor)
   
    const ActionData =(record)=> {
        onAction(record)
    }

    useEffect(()=>{
      // ถ้าเปิด modal ถึงดึงข้อมูลใหม่
      if(checkmodal){
        const Code = textSearch?.split(':')[0]
        const action = laborActions.GetList({Name:Code})
        dispatch(action)
        form.setFieldsValue({
          Code: Code,
        });
      }
     
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
    
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
      
    },[textSearch,dispatch,form,checkmodal])
 
    const columnslist = [
      { title: 'รหัส', width: 180, dataIndex: 'Code', key: 'Code',ellipsis:true,
      render: (text) => text},
      { title: 'ชื่อ', dataIndex: 'Name', key: 'Name', width: 350, ellipsis:true,
      render: text => text },
      { title: 'หน่วย', dataIndex: 'UnitName', key: 'UnitName', width: 200, ellipsis:true,
      render: text => text },
      { title: 'ราคาต่อหน่วย', dataIndex: 'Price', key: 'Price', ellipsis:true,
      render: (text,record) => renderColums(text,record,'Int')},
      { title: 'กลุ่มค่าแรง', dataIndex: 'LaborGroupName', key: 'LaborGroupName', width: 200, ellipsis:true,
      render: text => text },
    ]
    const [state,setState] = useState({columns:columnslist})

    const handleResize = index => (e, { size }) => {
     setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    })
    };

    const components = {
      header: {
        cell: ResizableTitle,
      },
    };

   
  
    const columns = () => state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: handleResize(index),
      }),
    }));

    const onFinish = (values) => {
     const action = laborActions.GetList({...values})
      dispatch(action)
  };

  

  const onReset = () => {
    form.resetFields();
  };

  const formlayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };


const goTabForm =(event,name)=> {
  if (event.key === 'Enter') {
    event.preventDefault();
    //this[name].focus();
    console.log('ok')
  }
}
    return (
      <div style={{minHeight:windowDimensions.height-243}} >
     
     <Form
       form={form} 
       name="control-hooks" 
        {...formlayout}
        onFinish={onFinish}
        
      >
 <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
          name="Code"  
         label={textName.th.code}
        >
          <Input 
          onKeyPress={goTabForm} 
          />
        </Form.Item>
        
        <Form.Item
          name="Name"  
         label={textName.th.name}
        >
          <Input 
          onKeyPress={goTabForm} 
          />
        </Form.Item>
       
       
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
     
       
        <Form.Item 
        label=":"
        >
        <Button type="primary" htmlType="submit">
          ค้นหา
        </Button>
        <span className='dotbtn'></span>
        <Button type="primary" htmlType="button" onClick={onReset}>
          เคลียร์
        </Button>
        </Form.Item>
            </Col>
          
        </Row>

        
       
      </Form>
      
         
      
          {isLoading ? <Spin/> : <Table 
            size="small" 
            bordered 
            components={components} 
            columns={columns()} 
           pagination={pageSize}
            dataSource={isLoading ? []:items}
            scroll={{ x: 1800, y: 300 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => ActionData(record), // click row
              };
            }}
          
             />}

      
        
        </div>
       
    )
}

export default Supplier