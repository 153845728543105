import axios from "axios";
import { API_URL } from "modules/configApp";
const PartAPI = "members";

const CHAT_HISTORY_REQUEST = "CHAT_HISTORY/REQUEST";
const CHAT_HISTORY_SUCCESS = "CHAT_HISTORY/SUCCESS";
const CHAT_HISTORY_FAILED = "CHAT_HISTORY/FAILED";
const CHAT_LIST_REQUEST = "CHAT_LIST/REQUEST";
const CHAT_LIST_SUCCESS = "CHAT_LIST/SUCCESS";
const CHAT_LIST_FAILED = "CHAT_LIST/FAILED";

const GetHistory = () => {
  return async (dispatch, getState) => {
    const { signin } = getState();
    const { items } = signin;
    dispatch({ type: CHAT_HISTORY_REQUEST });
    try {
      const {
        data: { items: dataitems },
      } = await axios({
        method: "get",
        url: `${API_URL}/${PartAPI}/chatlist`,
        data: null,
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      dispatch({ type: CHAT_HISTORY_SUCCESS, payload: { dataitems } });
    } catch (err) {
      const { response } = err;
      // token หมดอายุ
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;
        // token หมดอายุ
        if (status === 422) {
          localStorage.removeItem("tokenstatus");
          localStorage.clear();
          window.location = "/";
        }
      }
    }
  };
};

const GetListAdmin = (data) => {
  return async (dispatch, getState) => {
    const { chat } = getState();
    const { historyItems } = chat;
    dispatch({
      type: CHAT_LIST_SUCCESS,
      payload: { historyitems: historyItems, dataitems: data },
    });
  };
};

const GetList = (dataitem, type) => {
  let objectdata = {};
  if (type === "text") {
    objectdata = {
      msg: dataitem[0].msg,
      memberphoto: null,
      memberexten: null,
    };
  } else {
    objectdata = {
      msg: null,
      memberphoto: dataitem[0].memberphoto,
      memberexten: dataitem[0].memberexten,
    };
  }
  return async (dispatch, getState) => {
    const { ui, signin, chat } = getState();
    const { items } = signin;
    const { socket } = ui;
    const { historyItems } = chat;
    dispatch({ type: CHAT_LIST_REQUEST });
    try {
      const {
        data: { code, item },
      } = await axios({
        method: "post",
        url: `${API_URL}/${PartAPI}/chat`,
        data: objectdata,
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("AdminToken")
              ? localStorage.getItem("AdminToken")
              : items.access_token
          }`,
        },
      });

      if (code === 200) {
        const ndata = { chat: 1, items: dataitem, dtnow: Date.now() };
        socket.emit("sent-message", ndata);
        dispatch({
          type: CHAT_LIST_SUCCESS,
          payload: { historyitems: historyItems, dataitems: dataitem },
        });
      } else {
        dispatch({ type: CHAT_LIST_FAILED, payload: "" });
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 401) {
        localStorage.removeItem("tokenstatus");
        localStorage.clear();
        window.location = "/";
      } else {
        const { status } = response?.data?.error;

        if (status === 422) {
          // มีรอยืนยัน
          dispatch({
            type: CHAT_LIST_FAILED,
            payload: {},
          });
        }
      }
    }
  };
};

export {
  CHAT_LIST_REQUEST,
  CHAT_LIST_SUCCESS,
  CHAT_LIST_FAILED,
  CHAT_HISTORY_REQUEST,
  CHAT_HISTORY_SUCCESS,
  CHAT_HISTORY_FAILED,
  GetList,
  GetHistory,
  GetListAdmin,
};
