import React,{ useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Button,Spin,Tooltip } from 'antd'
import { PrinterOutlined,SaveOutlined,LikeOutlined,ImportOutlined,DeleteOutlined,StopOutlined,CloseOutlined,
    ExportOutlined,FileAddOutlined,DislikeOutlined,EyeOutlined,DeliveredProcedureOutlined,DoubleLeftOutlined,DoubleRightOutlined } from '@ant-design/icons'
import * as rolemenuActions from 'redux/setting/rolemenu/action'
import { textName } from 'lib/language'


export default function MenuRight(props) {
    
    const { showMenu,setShowMenuAll,setShowMenu,windowDimensions,showMenuMobile,match:{params:{id,menuid},url}} = props
    const ClassMenuRight = showMenu ? "content_right":"content_right_ac"
    const dispatch = useDispatch()
    const {isLoading,items} = useSelector(store=>store.rolemenu)
   
    const { ReadView,CreateEdit,PrintOut,Del,Cancel } = items

    useEffect(()=>{
        const action  = rolemenuActions.GetRoleMenu(menuid)
        dispatch(action)
    },[dispatch,menuid,id])
   
    const onAction =(type)=> {
        props.onAction(type)
    }
    const CreateBtn =()=> {
        let classBtn = 'menubtn'
        let tooltips = 'top'
        if(showMenuMobile){
            classBtn = 'menubtn'
            tooltips = 'left'
        }else{
            classBtn = showMenu ? 'menubtn':'menubtn_ac'
            tooltips = showMenu ? 'left':'top'
        }
        const { create,save,approve,reject,download,upload,preview,print,cancel,del,close} = textName.th.btn

        // เช็คว่าอยู่หน้าไหน
        if(url.includes('new')){
            // กรณีหน้า New
            return <ul className={classBtn}>
           {CreateEdit && <li><Tooltip placement={tooltips} title={create} color='orange'><Button type="primary" onClick={()=>onAction(1)} shape="circle" size="large" icon={<FileAddOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>}
           {CreateEdit &&<li><Tooltip placement={tooltips} title={save} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<SaveOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>}
           {CreateEdit &&<li><Tooltip placement={tooltips} title={upload} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<ImportOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>}
        </ul>

        }else if(url.includes('edit')){
            // กรณีหน้า Edit
            console.log('Edit Page')
            return <ul className={classBtn}>
       
           <li><Tooltip placement={tooltips} title={create} color='orange'><Button type="primary" onClick={()=>onAction(1)} shape="circle" size="large" icon={<FileAddOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={save} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<SaveOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={approve} color='orange'><Button type="primary" onClick={()=>onAction(2)} shape="circle" size="large" icon={<LikeOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={reject} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<DislikeOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={download} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<ExportOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={upload} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<ImportOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           
           {/*<li><Tooltip placement={tooltips} title={save} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<DeliveredProcedureOutlined style={{fontSize:26}} />}></Button> </Tooltip></li>*/}
           <li><Tooltip placement={tooltips} title={preview} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<EyeOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={print} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<PrinterOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={cancel} color='orange'><Button type="default" danger onClick={()=>onAction(3)} shape="circle" size="large" icon={<CloseOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={close} color='orange'><Button type="default" danger onClick={()=>onAction(3)} shape="circle" size="large" icon={<StopOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
           <li><Tooltip placement={tooltips} title={del} color='orange'><Button type="default" danger onClick={()=>onAction(3)} shape="circle" size="large" icon={<DeleteOutlined style={{fontSize:26}} />}></Button> </Tooltip>  </li>
        </ul>

        }else{
            // กรณีหน้า List
            return <ul className={classBtn}>
           {CreateEdit && <li><Tooltip placement={tooltips} title={create} color='orange'><Button type="primary" onClick={()=>onAction(1)} shape="circle" size="large" icon={<FileAddOutlined style={{fontSize:26}} />}></Button> </Tooltip></li>}
           {ReadView && <li><Tooltip placement={tooltips} title={preview} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<EyeOutlined style={{fontSize:26}} />}></Button> </Tooltip></li>}
           {ReadView && <li><Tooltip placement={tooltips} title={print} color='orange'><Button type="primary" onClick={()=>onAction(3)} shape="circle" size="large" icon={<PrinterOutlined style={{fontSize:26}} />}></Button> </Tooltip></li>}
        </ul>
        }
        
    }

    return (
        <>
        <div style={{minHeight:windowDimensions.height-48}} className={ClassMenuRight}>
        {showMenu ? <DoubleLeftOutlined style={{fontSize:22,color:'red',textAlign:'left'}} onClick={()=>setShowMenu(!showMenu)} />:!showMenuMobile && <DoubleRightOutlined style={{fontSize:22,color:'red',textAlign:'right'}} onClick={()=>setShowMenu(!showMenu)} />}
        <div className="content_right_top">
            {isLoading ? <Spin />:CreateBtn()}
        </div>
        </div>
        <div className={showMenuMobile ? 'menu_mobile_right_ac':'menu_mobile_right'}>{showMenuMobile ? <Button onClick={setShowMenuAll} type="default" danger><DoubleRightOutlined /></Button>:<Button onClick={setShowMenuAll} type="default" danger><DoubleLeftOutlined /></Button>}</div>
        </>
    )
}
