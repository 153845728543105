import axios from 'axios'
import { API_URL } from 'modules/configApp'

const LABOR_REQUEST = 'LABOR/REQUEST'
const LABOR_SUCCESS = 'LABOR/SUCCESS'
const LABOR_FAILED = 'LABOR/FAILED'
const PartAPI = 'Labor'
const GetList = (data) => {
    return async (dispatch) => {
        dispatch({type:LABOR_REQUEST})
          try{
              const {data:{Data}} = await axios({
                  method: 'post',
                  url: `${API_URL}/${PartAPI}/GetList`,
                  data:data
                  ,
              headers:{'Content-Type': 'application/json','X-Signature':localStorage.getItem('Signature')}
          })
          dispatch({type:LABOR_SUCCESS,payload:Data})
        
          }catch(err){
            dispatch({type:LABOR_FAILED})
          }
         
      }
}

export {
    LABOR_REQUEST,
    LABOR_SUCCESS,
    LABOR_FAILED,
    GetList
}