import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import moment from 'moment'
import axios from "axios";
import { API_URL } from "modules/configApp";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Container from "@material-ui/core/Container";

import { red } from "@material-ui/core/colors";


import * as memberActions from "redux/member/action";
import CountdownTimer from "./CountdownTimer";
import { lg } from '../configApp'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

const lgtext = {
  thai:{
    1:'รางวัลที่ 1',
    2:'ตัวหน้า',
    3:'ตัวบน',
    4:'ตัวล่าง',
    5:'ย้อนกลับ',
    6:'ผลรางวัล',
  },
  lao:{
    1:'ລາງວັນທີ 1',
    2:'ໂຕຫນ້າ',
    3:'ໂຕບົນ',
    4:'ໂຕລ່າງ',
    5:'ຍ້ອນກັບ',
    6:'ຜົນລາງວັນ',
  }
}

export default function AutoGrid() {
  const classes = useStyles();
  const {
    isLoadinggame: isLoading,
    itemsresult:itemsgame,
  } = useSelector((store) => store.member);
  const history = useHistory();


  const dispatch = useDispatch();
  React.useEffect(() => {
    //const action2 = memberActions.GetCredit({});
    //dispatch(action2);
  }, []);

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);
  const [startDate, setTimedate] = React.useState(moment(Date.now()).format("YYYY-MM-DD"));

  React.useEffect(() => {
    const datenow = moment(Date.now()).format("YYYY-MM-DD")
    const action = memberActions.GameHuayResult(datenow);
    dispatch(action);
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);
  }, []);

  const onChangeDate =(e)=> {
    const datenow = moment(e.target.value).format("YYYY-MM-DD")
    const action = memberActions.GameHuayResult(datenow);
    dispatch(action);
  }
  

 const showreward1 =({one})=> {
    if(parseFloat(one)>0){
        return  <ul className="press1">
        <li>
        <div className="reward_contain" >
        <div className="reward_head">{lgtext[lg]['1']}</div>
        <div className="reward_body">{one}</div>
    </div>
        </li>
    </ul>
    }
 }

 const checkclassone =(data)=> {
    if(parseFloat(data.one)>0){
        return  <ul className="press3">
        <li> <div className="reward_contain" >
          <div className="reward_head">3 {lgtext[lg]['2']}</div>
          <div className="reward_body">{data.front3_1}, {data.front3_2}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head">3 {lgtext[lg]['4']}</div>
          <div className="reward_body">{data.under3_1}, {data.under3_2}</div>
      </div></li>
      <li> <div className="reward_contain" >
      <div className="reward_head">2 {lgtext[lg]['4']}</div>
          <div className="reward_body">{data.under2}</div>
      </div></li>
      </ul>
    }else{
        return <ul className="press2">
        <li> <div className="reward_contain" >
          <div className="reward_head">3 {lgtext[lg]['3']}</div>
          <div className="reward_body">{data.upon3}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head">2 {lgtext[lg]['4']}</div>
          <div className="reward_body">{data.under2}</div>
      </div></li>
      
      </ul>
    }
 }

 const goBack =(path)=> {
   history.push(path)
 }

  return (
    
    <div className="bgboxcasinox">
      <div className="headpaddingtopx"></div>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/")}
          className="btnback"
        >
          {lgtext[lg]['5']}
        </Button>
      </Container>
      {" "}
      <div style={{ height: 5 }}></div>
      <Container maxWidth="md">
        <div style={{ height: 1 }} id="sport"></div>
        <div className="bgboxcasinox">
          <div style={{ height: 20 }} id="casino"></div>
          <div className="headsport">
            <span>{lgtext[lg]['6']}</span>
          </div>
         
          <div style={{ height: 20 }}></div>
          <TextField type="date" defaultValue={startDate} onChange={(e)=>onChangeDate(e)} />
          {itemsgame &&
            itemsgame.map((list, index) => (
                <ul className="press1" key={index}>
                <li >
                  <div
                    className={
                      list.statusclose == 0 ? "lottoclose" : "lottorun"
                    }
                   
                  >
                   
                    <div className="bglotto">
                      <div className={list.bgphoto}></div>
                      <div className="bginter">{lg == 'lao' ? list.name : list.nameth} <span className="spanday2">{lg == 'lao' ? list.startdate:list.startdateth}</span></div>
                      <div className="bglotto_bottom">
                        
                      </div>
                     
                      {
                          showreward1(list)
                      }
                      {checkclassone(list)}
                    </div>
                  </div>
                </li>
                </ul>
            ))}

          <div style={{ height: 10 }}></div>
        </div>

        {loadspin && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className={classes.root}>
          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
    </div>
  );
}
