import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";

import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";

//import { emphasize, withStyles } from "@material-ui/core/styles";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//import Chip from "@material-ui/core/Chip";
//import HomeIcon from "@material-ui/icons/Home";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
//import history from "history";

import * as memberActions from "redux/member/action";
import CountdownTimer from "./CountdownTimer";
import LottoBetlist from "./Lotto_Betlist";
import LottoMain from "./Lotto_Main";
import { getRemainingTimeUntilMstimestamp } from "./ConutdownTimerUntils";
import { lg } from '../configApp'

import "./betlotto.css";

const lgtext = {
  lao:{
    1:'ລາງວັນທີ 1',
    2:'ໂຕຫນ້າ',
    3:'ໂຕບົນ',
    4:'ໂຕລ່າງ',
    5:'ຍ້ອນກັບ',
    6:'ຜົນລາງວັນ',
    7:'ອັດຕາ',
    8:'ໃສ່​ລາ​ຄາ​',
    9:'ກົດເລກເອງ',
    10:'ເລືອກຈາກແຜງ',
    11:'ເພິ່ມເລກ',
    12:'ລາຍການ',
    13:'ສົ່ງໂພຍ',
    14:'ຍົກ​ເລີກ​ທັງ​ຫມົດ​',
    15:'ຈໍາ​ນວນ​ທັງ​ຫມົດ',
    16:'ໃສ່ຈໍານວນ',
    17:'ກີບ',
    18:'ເອົາຈໍານວນດຽວກັນທັງຫມົດ',
    19:'ສົ່ງຕໍ່ລິ້ງລ້ຳລວຍຂອງເຮົາໃຫ້ໝູ່ສະໝັກຮັບຄອມມິດຊັ້ນຂອງໝູ່',
    20:'ແລະຄ່າຄອມມິດຊັ້ນຂອງໝູ່ ຂອງໝູ່ອີກ',
  },
  thai:{
    1:'รางวัลที่ 1',
    2:'ตัวหน้า',
    3:'ตัวบน',
    4:'ตัวล่าง',
    5:'ย้อนกลับ',
    6:'ผลรางวัล',
    7:'อัตรา',
    8:'ใส่ราคา',
    9:'กดเลขเอง',
    10:'เลือกจากแผง',
    11:'เพิ่มเลข',
    12:'รายการ',
    13:'ส่งโพย',
    14:'ยกเลิกทั้งหมด',
    15:'จำนวนทั้งหมด',
    16:'ใส่จำนวนเงิน',
    17:'บาท',
    18:'ใส่จำนวนเท่ากันทั้งหมด',
    19:'ส่งลิ้งร่ำรวยให้เพื่อนสมัครรับค่าคอมๆ',
    20:'และรับคอมมิชชั่นจากเพื่อนของคุณอีก',
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    isLoadinggame: isLoading,
    lottototal,
    addlottototal,
    betamount,
    betlist3t,
    lottonotify,
    betlist3tod,
    betlist3f,
    betlist3l,
    betlist2t,
    betlist2l,
    betlistrt,
    betlistrl,
    tabcontent,bgphoto,isLoading:isLoadingloto,huaytitle_th:huaytitle,rewards,notifybill,beterror,lottotypeitems,
  } = useSelector((store) => store.member);
 
  const { tabmain } = tabcontent;
  const history = useHistory();

  const [open, setOpen] = React.useState(false);


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const dispatch = useDispatch();
  
  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);

  const changerate =(type)=> {
    const action = memberActions.changerate(type)
    dispatch(action)
  }

  const checkrateactive = (type) => {
   
    const data = lottotypeitems && lottotypeitems.find(x => x.typerun == 3)
    if(type == 0 && data?.rate == 90){
      return 'active'
    }
    if(type == 1 && data?.rate == 95){
      return 'active'
    }
  }

  const checkrateactivenumber = () => {
   
    const data = lottotypeitems && lottotypeitems.find(x => x.typerun == 3)
    if(data?.rate == 90){
      return '8%'
    } else {
      return '7%'
    }
   
  }


  const checkhuayrate = () => {
   
    const data = lottotypeitems && lottotypeitems.find(x => x.typerun == 31)
    if(data) {
      return data.rate
    } else {
      return 700
    }
  }


  const { type,id } = useParams();

  const defaultRemainTime = {
    seconds: "00",
    minutes: "00",
    hours: "00",
    days: "00",
    end: 1,
  };

  const [remainingTime, setRemainingTime] = React.useState(defaultRemainTime);

  React.useEffect(() => {
    
    const action2 = memberActions.GetLotto(id,lineid);
    dispatch(action2);
    const action2x = memberActions.GetCredit({lineid});
      dispatch(action2x);
   

   setTimeout(()=> updateRemainTime(parseInt(type)) ,1)
   

  }, []);

  function updateRemainTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMstimestamp(countdown));
  }


  const handlebtnclickrate = () => {
    const dispacrj = memberActions.btnclickrate();
    dispatch(dispacrj);
  };

  const removeall = () => {
    handleClickOpen2(true);
  };
  const confirmremove = () => {
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);
    handleClose2(false);
  };

  const handleClose = () => {
    const memdispatch = memberActions.hidelottonotify();
    dispatch(memdispatch);
  };

  const handlebetall = (e) => {
    let items = {
      betamount: e.target.value,
      delitem: 2, // ประเภทแก้ไขราคาทั้งหมด
    };
    const memdispatch = memberActions.handleamount(items);
    dispatch(memdispatch);
  };
  const { lineid } = useSelector((store) => store.member);
  const SaveBill =()=> {
    const memdispatch = memberActions.SaveBill(id,lineid,history);
    dispatch(memdispatch);
  }

  const gopage = (pat) => {
    history.push(pat);
  };

  const showreward1 =({one})=> {
    if(parseFloat(one)>0){
        return  <ul className="press1">
        <li>
        <div className="reward_contain" >
        <div className="reward_head">ລາງວັນທີ 1</div>
        <div className="reward_body">{one}</div>
    </div>
        </li>
    </ul>
    }
 }

 const checkclassone =(data)=> {
    if(parseFloat(data.one)>0){
        return  <ul className="press3">
        <li> <div className="reward_contain" >
          <div className="reward_head">3 ตัวหน้า</div>
          <div className="reward_body">{data.front3_1}, {data.front3_2}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head">3 ตัวหลัง</div>
          <div className="reward_body">{data.under3_1}, {data.under3_2}</div>
      </div></li>
      <li> <div className="reward_contain" >
      <div className="reward_head">2 ตัวล่าง</div>
          <div className="reward_body">{data.under2}</div>
      </div></li>
      </ul>
    }else{
        return <div >
          <ul className="press1">
        <li> <div className="reward_contain" >
          <div className="reward_head">3 ตัวบน</div>
          <div className="reward_body">{data.upon3 ? data.upon3:'-'}</div>
      </div></li>
      
      </ul>
           <ul className="press2">
        <li> <div className="reward_contain" >
          <div className="reward_head">2 ตัวบน</div>
          <div className="reward_body">{data.upon2 ? data.upon2:'-'}</div>
      </div></li>
      <li> <div className="reward_contain" >
          <div className="reward_head">2 ตัวล่าง</div>
          <div className="reward_body">{data.under2 ? data.under2:'-'}</div>
      </div></li>
      </ul></div>
       
    }
 }

//console.log('remainingTime',remainingTime)
  
  if(remainingTime.days == "00" &&
  remainingTime.hours == "00" &&
  remainingTime.minutes == "00" &&
  remainingTime.seconds == "00" &&
  remainingTime.end == 2){
    return  <>
    {" "}
    <div className="headpaddingtopx"></div>
    <Container
      maxWidth="md"
      style={{
        paddingTop: 5,
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
      }}
    >
      <Button
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        variant="outlined"
        onClick={() => gopage("/play/lotto")}
        className="btnback"
      >
        {lgtext[lg]['5']}
      </Button>
    </Container>
    <Container maxWidth="md">
          <div className="bgboxcasinox">
            <div style={{ height: 2 }} id="casino"></div>
            <div className="titlelottobet">
            ผลรางวัล
            </div>
            <div style={{ height: 10 }}></div>
  
            <div className="topcontain">
              <div className={bgphoto}></div>
              <div className="headlotto">
              <span>{huaytitle}</span>
              </div>
  
              <div className="lottocontaner">
                <div style={{ height: 3 }}></div>
  
                {
                          showreward1(rewards)
                      }
                      {checkclassone(rewards)}
                
              </div>
            </div>
  
            <div style={{ height: 10 }}></div>
          </div>
  
          <div className={classes.root}>
            <div style={{ height: 10 }}></div>
          </div>
        </Container>
  </>
  }else{
    return (
      <>
        {" "}
        {tabmain == true && (
          <>
            {" "}
            <div className="headpaddingtopx"></div>
            <Container
              maxWidth="md"
              style={{
                paddingTop: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <Button
                color="primary"
                startIcon={<ArrowBackIosIcon />}
                variant="outlined"
                onClick={() => gopage("/play/lotto")}
                className="btnback"
              >
                {lgtext[lg]['5']}
              </Button>
            </Container>
          </>
        )}
        {tabmain == false && (
          <>
            {" "}
            <div className="headpaddingtopx"></div>
            <Container
              maxWidth="md"
              style={{
                paddingTop: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <Button
                color="primary"
                startIcon={<ArrowBackIosIcon />}
                variant="outlined"
                onClick={() => handlebtnclickrate()}
                className="btnback"
              >
                {lgtext[lg]['5']}
              </Button>
            </Container>
          </>
        )}
        <Container maxWidth="md">
          <div className="bgboxcasinox">
            <div style={{ height: 2 }} id="casino"></div>
            <div className="titlelottobet">
              {<CountdownTimer countdownTimestampMs={parseInt(type)} />}
            </div>
           
            <div class="lotto_tap">
              {
                /*
<span class={`lotto_tap1 ${checkrateactive(0)}`} onClick={()=> changerate(0)}>{lgtext[lg]['7']} 750</span>
             
lotto_tap2 ใช็สำหรับ ลาว
*/
              }
              
            <span class={`lotto_tap2_full ${checkrateactive(1)}`} 
            //onClick={()=> changerate(1)}
            >{lgtext[lg]['7']} {checkhuayrate()}</span></div>
      <div style={{ height: 10 }}></div>
            <div className="topcontain">
              <div className={bgphoto}></div>
              <div className="headlotto">
                {tabmain == true ? (
                  <span>{huaytitle}.</span>
                ) : (
                  <span>{lgtext[lg]['8']} {lottototal} {lgtext[lg]['12']}</span>
                )}
              </div>
  
              <div className="lottocontaner">
                <div style={{ height: 3 }}></div>
  
                {tabmain == false && (
                  <>
                    {" "}
                    <LottoBetlist betlist={betlistrt} numbertype="betlistrt" />
                    <LottoBetlist betlist={betlistrl} numbertype="betlistrl" />
                    <LottoBetlist betlist={betlist2t} numbertype="betlist2t" />
                    <LottoBetlist betlist={betlist2l} numbertype="betlist2l" />
                    <LottoBetlist betlist={betlist3t} numbertype="betlist3t" />
                    <LottoBetlist
                      betlist={betlist3tod}
                      numbertype="betlist3tod"
                    />
                    <LottoBetlist betlist={betlist3l} numbertype="betlist3l" />
                    <LottoBetlist betlist={betlist3f} numbertype="betlist3f" />
                    <ul className="lotto_press_submit">
                      <li>
                        {" "}
                        <div className="lotto_btn_close2">
                        {lgtext[lg]['18']}
                        </div>
                        <div className="lotto_btn_close_detail">
                          <input
                            type="number"
                            id="credit"
                            name="credit"
                            className="form__input"
                            placeholder={lgtext[lg]['16']}
                            onChange={(e) => handlebetall(e)}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="lotto_btn_close3">{lgtext[lg]['15']}</div>
                        <div className="lotto_btn_close_detail">
                          <input
                            type="text"
                            id="credit"
                            name="credit"
                            className="form__input2"
                            disabled
                            value={`${betamount} ${lgtext[lg]['17']}`}
                          />
                        </div>
                      </li>
                    </ul>
                    <ul className="lotto_press_submit">
                      <li>
                        {" "}
                        <div className="lotto_list" onClick={() => removeall()}>
                          <BlockIcon className="mverti" />  {lgtext[lg]['14']}
                        </div>
                      </li>
                      <li>
                        {" "}
                        <div
                          className="btndeposit"
                          onClick={() => SaveBill()}
                        >
                          <DoneIcon className="mverti" />
                          {lgtext[lg]['13']}
                        </div>
                      </li>
                    </ul>
                  </>
                )}
  
                {tabmain == true && <LottoMain removeall={removeall} />}
              </div>
            </div>
  
            <div style={{ height: 10 }}></div>
          </div>
  
          {loadspin && (
            <div className="divLoading">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          {isLoadingloto && (
            <div className="divLoading">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          <div className={classes.root}>
            <div style={{ height: 10 }}></div>
          </div>
        </Container>
        <div style={{ height: 80 }}></div>
        <Snackbar
          open={notifybill}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
          ຍອດເງິນບໍ່ພຽງພໍທີ່ຈະວາງເດີມພັນ.
          </Alert>
        </Snackbar>

        <Snackbar
          open={beterror}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
          ໃສ່ຈຳນວນເງິນເອງຂັ້ນຕ່ຳ 1,000 ກີບ.
          </Alert>
        </Snackbar>

        
        <Snackbar
          open={lottonotify}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
          {lgtext[lg]['11']} {addlottototal} {lgtext[lg]['12']}
          </Alert>
        </Snackbar>
        <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">แจ้งเตือน</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            แน่ใจแล้วใช่หรือไม่ ที่จะลบข้อมูล ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2} variant="contained">
            ปิด
            </Button>
            <Button onClick={confirmremove} color="secondary" variant="contained">
            ลบ
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  
}
