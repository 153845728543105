import axios from 'axios'
import { API_URL } from 'modules/configApp'

const SUBDOC_REQUEST = 'SUBDOC/REQUEST'
const SUBDOC_SUCCESS = 'SUBDOC/SUCCESS'
const SUBDOC_FAILED = 'SUBDOC/FAILED'
const PartAPI = 'subdoc'
const GetSubdoc = (Id) => {
    return async (dispatch) => {
        dispatch({type:SUBDOC_REQUEST})
          try{
            const result = await axios.get(API_URL+'/'+PartAPI+'/GetData?Id='+Id,{ headers:{'Content-Type': 'application/json','X-Signature':'Signature'} })
            const { Data } = result.data
                dispatch({type:SUBDOC_SUCCESS,payload:{...Data}})
          }catch(err){
            dispatch({type:SUBDOC_FAILED})
          }
      }
}
export {
    SUBDOC_REQUEST,
    SUBDOC_SUCCESS,
    SUBDOC_FAILED,
    GetSubdoc
}