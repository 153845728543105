import { combineReducers } from "redux";

import ui from "./ui/reducer";
import signin from "./signin/reducer";
import po from "./purchase/po/reducer";
import member from "./member/reducer";
import chat from "./chat/reducer";
// Setup Modules
import supplier from "./setup/supplier/reducer";
import unit from "./setup/unit/reducer";
import meterial from "./setup/meterial/reducer";
import labor from "./setup/labor/reducer";

// System Modules
import user from "./setting/user/reducer";
import bank from "./setting/bank/reducer";
import account from "./setting/account/reducer";
import website from "./setting/website/reducer";
import system from "./setting/system/reducer";
import subdoc from "./setting/subdoc/reducer";
import rolemenu from "./setting/rolemenu/reducer";

// Cost Center Modules
import costcenter from "./costcenter/project/reducer";
export default combineReducers({
  ui,
  signin,
  po,
  supplier,
  meterial,
  labor,
  user,
  system,
  subdoc,
  costcenter,
  rolemenu,
  unit,
  bank,
  account,
  website,
  member,
  chat,
});
