import {  WEBSITE_REQUEST,WEBSITE_SUCCESS,WEBSITE_NEWS_REQUEST,WEBSITE_NEWS_SUCCESS,
    WEBSITE_SAVE_REQUEST,WEBSITE_SAVE_SUCCESS,WEBSITE_SAVE_FAILED,
    WEBSITE_EDIT_REQUEST,WEBSITE_EDIT_SUCCESS,WEBSITE_EDIT_FAILED,WEBSITE_SELECT_ROW,
    WEBSITE_UPDATE_ITEMS } from './action'

const initailState = {
    isLoading:false,
    items:[],
    formx:{
        Id:'New',
        Name:null,
        items:[],
        Using:true,
        RowId:null
    }
}

export default function (state = initailState,action){
    switch (action.type) {
        case WEBSITE_REQUEST:
            return {
                ...state,isLoading:true,items:[]
            }
        case WEBSITE_SUCCESS:
            const items = action.payload.map((res,index)=>({...res,key:++index}))
                return {
                    ...state,isLoading:false,items
                }
        case WEBSITE_NEWS_REQUEST:
            return {
                ...state,isLoading:true,formx:{
                    ...initailState.formx
                }
            }
        case WEBSITE_NEWS_SUCCESS:
                return {
                    ...state,isLoading:false,formx:{
                        ...initailState.formx,
                        items:action.payload.map((res,index)=>({key:++index,Id:'New',FKBank:res.Id,Bank:res.Name}))
                    }
                }
        case WEBSITE_SAVE_REQUEST:
            return {
                ...state,isLoading:true
            }
        case WEBSITE_SAVE_SUCCESS:{
            const { items } = state.formx
                return {
                    ...state,isLoading:false,formx:{...action.payload,items,Id:`${action.payload.Id}`}
                }
        }
            
        case WEBSITE_SAVE_FAILED:
                    return {
                        ...state,isLoading:false
                    }
        case WEBSITE_EDIT_REQUEST:
            return {
                        isLoading:true,formx:{
                            ...initailState.formx
                        }
                    }
        case WEBSITE_EDIT_SUCCESS:
            return {
                        ...state,isLoading:false,formx:{
                           Id:`${action.payload.Main.Id}`,
                           Name:action.payload.Main.Name,
                           items:action.payload.WebsiteAccount.map((res,index)=>({...res,key:++index,Id:`${res.Id}`,Bank:res.BankName}))
                        }
                    }
        case WEBSITE_EDIT_FAILED:
            return {
                        ...state,isLoading:false
                    }
        case WEBSITE_SELECT_ROW:
            return {
                ...state,formx:{
                    ...state.formx,
                    RowId:action.payload
                }
            }
        case WEBSITE_UPDATE_ITEMS:
            {
                const {  formx } = state
            const { items,RowId } = formx

            const newData = [...items];
            const index = newData.findIndex(item => item.FKBank === RowId);
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...action.payload,AccBankName:action.payload.BankName,FKAccount:action.payload.Id });

            return {
                ...state,formx:{
                    ...state.formx,
                    items:newData,
                }
            }
            }

            
        default:
            return state
    }
}