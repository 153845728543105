import {  SYSTEM_REQUEST,SYSTEM_SUCCESS } from './action'

const initailState = {
    isLoading:false,
    items:[]
}

export default function (state = initailState,action){
    switch (action.type) {
        case SYSTEM_REQUEST:
            return {
                ...state,isLoading:true,items:[]
            }
        case SYSTEM_SUCCESS:
                return {
                    ...state,isLoading:false,items:action.payload
                }
        default:
            return state
    }
}