import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Container from "@material-ui/core/Container";

import { red } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import useFetch from "lib/useFetch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    //left: 500,

    position: "absolute",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  
  const history = useHistory();
  const { webtype } = useParams();

  const { isLoading, lineid } = useSelector(
    (store) => store.member
  );

  //memberActions
  const goPage = (page) => {
    history.push(page);
  };

  const logingame = async (game) => {
    setloadspin(true);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = `/logingame?lineid=${lineid}&gameid=${game.gameid}&fkprovider=${game.fkprovider}`;
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
  };

  const [loadspin, setloadspin] = React.useState(false);

  const gameitems = useFetch("https://apiadminbetg.com/members/gamedetail/"+webtype+"?webtoken=sswinbet&brandcode=" + webtype,
    {},
    "get"
  );

  //const webtype2 = `${webtype}`;
  //const webtype3 = webtype2.toLocaleLowerCase();
  return (
    <div>
      {" "}
      <div style={{ height: 5 }}></div>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goPage("/play/slot")}
          className="btnback"
        >
          ย้อนกลับ
        </Button>
      </Container>
      <div style={{ height: 15 }}></div>
      <Container maxWidth="md">
        <div className="divheadcasino">
          {
            webtype
          }
          {
            /*
 <img
            src={`/images/logogame3/${webtype3}.png`}
            className="imgcasinoh"
          ></img>
            */
          }
         
        </div>
        <div style={{ height: 15 }}></div>
        <div className="divbgcasino">
          <ul className="pressitem">
            {isLoading ? (
              <div className="divLoadingfixxx">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              ""
            )}

            {gameitems.items &&
              gameitems.items.map((res) => (
                <li key={res.id}>
                  <div
                    className="divlogo1"
                    style={{ cursor: "pointer" }}
                    onClick={() => logingame(res)}
                    //onClick={() => window.open("https://www.google.com")}
                  >
                    <img
                      src={`${res.image}`}
                      //className="imgcasinoitem"
                    ></img>
                  </div>
                  <div className="divtextcasinoitem">{res.name}</div>
                </li>
              ))}
          </ul>
        </div>
        {loadspin && (
          <div className="divLoading">
            <CircularProgress color="secondary" />
          </div>
        )}
        <div className={classes.root}>
          {/*
 <Card className={classes.rootCard}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://bio2-auto.biocore-api.com/api/apilogin/api_login/login?agent=oec&username=" +
                    items.username +
                    "&password=" +
                    items.agentpass +
                    "&type=pretty&host=http://www.casinobio.net"
                )
              }
            >
              <CardMedia
                className={classes.mediaHead}
                image="/images/pretty.jpg"
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h3">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    Pretty Gaming{" "}
                    <Button variant="contained" color="secondary">
                      เข้าเล่น
                    </Button>
                  </Grid>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
            */}

          <div style={{ height: 10 }}></div>
          {/*
 <Card className={classes.rootCard}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://bio2-auto.biocore-api.com/api/apilogin/api_login/login?agent=oec&username=" +
                    items.username +
                    "&password=" +
                    items.agentpass +
                    "&type=dg&host=http://www.casinobio.net"
                )
              }
            >
              <CardMedia
                className={classes.mediaHead}
                image="/images/dg.jpg"
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h3">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    Dream Gaming{" "}
                    <Button variant="contained" color="secondary">
                      เข้าเล่น
                    </Button>
                  </Grid>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
            */}

          <div style={{ height: 10 }}></div>
          {/*
 <Card className={classes.rootCard}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://bio2-auto.biocore-api.com/api/apilogin/api_login/login?agent=oec&username=" +
                    items.username +
                    "&password=" +
                    items.agentpass +
                    "&type=sexy&host=http://www.casinobio.net"
                )
              }
            >
              <CardMedia
                className={classes.mediaHead}
                image="/images/sexy.jpeg"
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h3">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    Sexy Baccarat{" "}
                    <Button variant="contained" color="secondary">
                      เข้าเล่น
                    </Button>
                  </Grid>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
            */}

          <div style={{ height: 10 }}></div>

          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
    </div>
  );
}
