import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { API_URL } from "modules/configApp";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import useFetch from "lib/useFetch";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

//import Grid from '@material-ui/core/Grid'
import { red } from "@material-ui/core/colors";

//import { emphasize, withStyles } from "@material-ui/core/styles";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//import Chip from "@material-ui/core/Chip";
//import HomeIcon from "@material-ui/icons/Home";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DialogContent from "@material-ui/core/DialogContent";

import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
//import history from "history";
//import "./indexall.css";
import * as memberActions from "redux/member/action";

function createMarkup(data) {
  return { __html: data };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const { data, items } = useSelector((store) => store.member);
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleCloses = () => {
    setOpens(false);
  };

  const goPage = (page) => {
    history.push(page);
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    //dispatch(action2);
  }, []);

  const loginqtech = async () => {
    setloadspin(true);
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/qtechgetlobbyurl`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    const sss = link.replace("zh-CN", "th-TH");
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = sss;
    //a.target = "_blank";
    /* a.download = <your_fileName>; */
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
    //window.open(link);
  };

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);

  const { items: item } = useSelector((store) => store.member);

  const promotion = useFetch(API_URL + "/promotion/1/deposit", {}, "get");

  return (
    <div className="hms">
      <div style={{ height: 100 }}></div>
      <div  style={{ textAlign: "center", fontSize: 36, color: "#fff" }}>
      ໂປໂມຊັ້ນ
      </div>
      <div className="mssssx">
        {" "}
        {/*
 <Container maxWidth="sm">
        <Card className={classes.headpaper}>
          <Breadcrumbs className={classes.paper} aria-label="breadcrumb">
            <StyledBreadcrumb
              component="a"
              href="#"
              label="คาสิโนออนไลน์"
              icon={<HomeIcon fontSize="small" />}
              onClick={handleClick}
            />
            <StyledBreadcrumb
              component="a"
              href="#"
              label="เลือกเดิมพัน"
              onClick={clickopen}
            />
          </Breadcrumbs>
        </Card>
      </Container>
        */}
        
        <Container maxWidth="md">
          {loadspin && (
            <div className="divLoading">
              <CircularProgress color="secondary" />
            </div>
          )}
          <div className={classes.root}>
            {promotion.item &&
              promotion.item.map((x, index) => (
                <>
                  <div key={index} style={{ textAlign: "center" }}>
                    <img
                      className={classes.mediaHead}
                      src={`${x.proexten},${x.prophoto}`}
                      //className="imgpro"
                    />
                    

                    <div
                     // style={{ fontSize: 16, color: "#fff", textAlign: "left" }}
                     // dangerouslySetInnerHTML={createMarkup(x.description)}
                    />
                  </div>
                  <div className="linepro"></div>
                  <div style={{ height: 30 }}></div>
                </>
              ))}

            <div style={{ height: 10 }}></div>

            <div style={{ height: 10 }}></div>

            <div style={{ height: 10 }}></div>
          </div>
         
          <div style={{ height: 20 }}></div>
          <div className="linepro"></div>
        </Container>
      </div>
      <div style={{ height: 180 }}></div>
    </div>
  );
}
