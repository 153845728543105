import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import * as memberActions from "redux/member/action";

import * as yup from "yup";
//import { yupResolver } from '@hookform/resolvers'
import { LOGO } from 'modules/configApp'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 135,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function MediaCard(props) {
  //const path = useRouteMatch();
  const { search } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      mobile: yup.string().required(),
      password: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const submit = ({ mobile, password }) => {
    const memberaff = localStorage.getItem("memberaff");
    const action = memberActions.Save({
      mobile: mobile,
      password,
      refurl: window.location.href,
      memberaff: memberaff ? memberaff : null,
      ...props,
    });
    dispatch(action);
  };

  useEffect(() => {
    const newaff = search.includes("aff");
    if (newaff) {
      const checkcode = search.split("=")[1];
      localStorage.setItem("memberaff", checkcode);
    }
  }, []);
  const dispatch = useDispatch();
  const { isLoading, message, count, show } = useSelector(
    (store) => store.signin
  );

  const {
    isLoading: isLoadingregis,
    message: messageregis,
    datanotify,
  } = useSelector((store) => store.member);

  const [checkbtn, setcheckbtn] = React.useState(1);

  const GoPage = () => {
    //setcheckbtn(2);

    history.push("/signin");
  };

  const GoPage2 = () => {
    //setcheckbtn(2);

    history.push("/contact");
  };
  const handleCloseregis = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.GetList();
    dispatch(action);
  };

  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);

  return (
    <>
       <div style={{ height: 125, paddingTop:7 }} className="mainlogin">
        <img src={LOGO} height={100}></img>
      </div>
     
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div className="bgconh1">
          <Typography
            gutterBottom
            variant="h5"
            className="headtitle"
            component="h3"
          >
            ສະຫມັກ ສະມາຊິກ
          </Typography>
          {/*
 <Typography
              gutterBottom
              variant="h5"
              style={{ textAlign: "center", color: "#fff" }}
              component="h3"
            >
              {t("hregister.label")}
            </Typography>
              */}
          <div style={{ height: 20 }}></div>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(submit)}
          >
            <input
              ref={register}
              type="text"
              id="username"
              name="mobile"
              class="form__input"
              placeholder="ໃສ່ເບີໂທທີ່ຕ້ອງການສະຫມັກ"
              autocomplete="off"
              onKeyPress={function (event) {
                var inputValue = event.charCode;
                if (inputValue < 48 || inputValue > 57) {
                  event.preventDefault();
                }
              }}
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />
            <div style={{ height: 20 }}></div>
            <input
              ref={register}
              type="password"
              id="password"
              name="password"
              class="form__input"
              placeholder="ລະຫັດຜ່ານ"
              autocomplete="off"
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />

            {message === 3 && (
              <Alert variant="outlined" severity="error">
                ຂໍ້ມູນສະມາຊິກບໍ່ຖືກຕ້ອງ ກະລຸນາໃສ່ອີກຄັ້ງ. !!
              </Alert>
            )}
            <div style={{ height: 20 }}></div>
            <div className="regispro">
              <b>ຂໍ້ກໍານົດແລະເງື່ອນໄຂ</b>
              <br></br>
              1. ຕ້ອງມີບັນຊີທະນາຄານທີ່ລົງທະບຽນເທົ່ານັ້ນ. ໃນການຝາກເງິນ
              <br></br>
              2. ເບີໂທລະສັບ, ຊື່-ນາມສະກຸນ, ເລກບັນຊີທະນາຄານ, ຊື່ບັນຊີທະນາຄານ,
              IP ອິນເຕີເນັດທີ່ໃຊ້ສໍາລັບການລົງທະບຽນຈະຕ້ອງບໍ່ຊ້ໍາກັນ.<br></br>
              3. ຢ່າໃຊ້ຂໍ້ມູນຂອງຜູ້ອື່ນເພື່ອລົງທະບຽນຫຼືວາງເດີມພັນ.<br></br>
              4. ມັນຖືກຫ້າມບໍ່ໃຫ້ຜູ້ອື່ນເຂົ້າສູ່ລະບົບເພື່ອເດີມພັນໃນບັນຊີຂອງຕົນເອງ.<br></br>
            </div>

            <div style={{ height: 20 }}></div>
            <div className="btnsubmit" onClick={submit2}>
              {!isLoadingregis && "ສະຫມັກ ສະມາຊິກ"}
              {isLoadingregis && (
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
            <button type="submit" ref={btnSubmit} className="btnnone"></button>
            <div style={{ height: 50 }}></div>
            <div className="title2">
              <span>ຫຼືທ່ານມີບັນຊີຢູ່ແລ້ວ</span>
            </div>
            <div style={{ height: 10 }}></div>
            <div className="btnsubmit2" onClick={GoPage}>
            ເຂົ້າ​ສູ່​ລະ​ບົບ
            </div>

            <div style={{ height: 10 }}></div>
            <div className="title2">
             
             </div>
             <div className="btnsubmit3" onClick={GoPage2}>
             ຕິດຕໍ່ - ສອບຖາມ
             </div>
          </form>
          <div style={{ height: 90 }}></div>
        </div>
        {isLoading && (
          <div className={classes.rootLoading}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </Container>

      <Snackbar
        open={datanotify.show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCloseregis}
      >
        <Alert onClose={handleCloseregis} severity="error">
        ໝາຍເລກຊ້ຳ
        </Alert>
      </Snackbar>
    </>
  );
}
