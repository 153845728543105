import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";

import { FieldTimeOutlined } from "@ant-design/icons";
import Container from "@material-ui/core/Container";

import { red } from "@material-ui/core/colors";
import MovingText from "react-moving-text";

import * as memberActions from "redux/member/action";
import CountdownTimer from "./CountdownTimer";
import { PHOME } from '../configApp'

//import Footter from '../ui/components/FootterHuayTh'
import Header from '../ui/components/HeaderGame'
import { lg } from '../configApp'

const lgtext = {
  lao:{
    1:'ເລືອກຫວຍ',
    2:'ຍ້ອນກັບ',
    3:'ຍອດລວມ',
    4:'ຖ້າຜົນ',
    5:'ກີບ',
    6:'ອອກຜົນແລ້ວ',
    7:'ຍັງບໍ່ອອກຜົນ',
    8:'ໂພຍທີ່ຍັງບໍ່ອອກ',
    9:'ໂພຍທີ່ອອກຜົນແລ້ວ',
    10:'ຍອດຊື້ມື້ນີ້',
    11:'ໂພຍ',
    12:'ໂພຍເລກທີ່',
    13:'ວັນທີ',
    14:'ເງິນເດີມພັນ',
    15:'ຜົນແພ້/ຊະນະ',
    16:'ລາຍລະອຽດ',
    17:'ຊະນະ',
    18:'ແພ້',
    19:'ຍົກເລີກ',
   
  },
  thai:{
    1:'เลือกหวย',
    2:'ย้อนกลับ',
    3:'ยอดรวม',
    4:'รอผล',
    5:'บาท',
    6:'ออกผลแล้ว',
    7:'ยังไม่ออกผล',
    8:'โพยที่ยังไม่ออก',
    9:'โพยที่ออกผลแล้ว',
    10:'ยอดซื้อวันนี้',
    11:'โพย',
    12:'โพยเลขที่',
    13:'วันที่',
    14:'เงินเดิมพัน',
    15:'ผลแพ้/ชะนะ',
    16:'รายละเอียด',
    17:'ชนะ',
    18:'แพ้',
    19:'ยกเลิก',
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    data,
    items,
    isLoadinggame: isLoading,
    itemsgame,
  } = useSelector((store) => store.member);
  const history = useHistory();

  const goPage = (page) => {
    history.push(page);
  };

  const dispatch = useDispatch();
  /*
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    dispatch(action2);
  }, []);
  */

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);

  React.useEffect(() => {
    
    const action = memberActions.GameHuayList(4);
    dispatch(action);
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);
  }, []);

  const { items: item,lineid } = useSelector((store) => store.member);

  const showmenu = (menu) => {
    const action = memberActions.GameAllList(menu);
    dispatch(action);
  };

  const goPageLotto = (data) => {
   // console.log('data',data)
    const { timestamp, code, statusclose, openstart } = data;
    if(openstart !== 0){
      history.push(`${timestamp}/${code}/lottobet`);
    } else {
      //lotto
      history.push(`/play/lotto/${code}`);
    }
   
  };

  const goPath =(path)=> {
    history.push(path);
  }

  return (
    <div className="bgboxcasinox">
     <Header />
      {" "}
      <div style={{ height: 90 }}></div>
      <Container maxWidth="md">
        <div style={{ height: 1 }} id="sport"></div>
        <div className="bgboxcasinox">
          <div style={{ height: 20 }} id="casino"></div>
          <div className="headsport">
            <span>{lgtext[lg]['1']}</span>
          </div>
          <div style={{ height: 20 }}></div>
          {itemsgame &&
            itemsgame.map((x, index) => (
              <>
                <div className="topcontain" key={index}>
                  <div className="headlotto">{x.title_th} <span className="btl_right"> 
              {
                PHOME.vediobet == 122 && 
<MovingText
                type="popIn"
                duration="1000ms"
                delay="0s"
                direction="normal"
                timing="ease"
                iteration="infinite"
                fillMode="none"
              ><div className="btn_tang" onClick={()=> goPath('/play/howto')}>&nbsp;ວິທີ່ການຊື້ເລກ&nbsp;</div></MovingText>
                
              }

    
              </span></div>
                  <ul className="press">
                    {x.items &&
                      x.items.map((list, index) => (
                        <li key={index}>
                          <div
                            className={
                              list.openstart == 0 ? "lottoclose" : "lottorun"
                            }
                            onClick={() => goPageLotto(list)}
                          >
                            <div class="xribbonlotto xribbon-top-right">
                              <span>ปิดรับ {list.time}</span>
                            </div>
                            <div className="bglotto">
                              <div className={list.bgphoto}></div>
                              <div className={list.bghead}>{list.name_th}</div>
                              <div className="bglotto_bottom">
                                <span className="spanday">{list.openth}</span>
                              </div>
                              {
                              list.openstart == 0 ?  <div className="appcountdown">
                              <span>
                                <FieldTimeOutlined />
                              </span>
                              <span>ยังไม่เปิดรับแทง</span>
                              </div> : <CountdownTimer
                              countdownTimestampMs={list.timestamp}
                            />
                            }
                              
                             
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div style={{ height: 10 }}></div>
              </>
            ))}

          <div style={{ height: 10 }}></div>
        </div>

        {loadspin && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className={classes.root}>
          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>

    </div>
  );
}
