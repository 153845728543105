import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PageList from "./PageListLao";
import Addaccount from "./Addaccount";
import ConfirmOtp from "./ConfirmOtp";

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/addaccount`} component={Addaccount}></Route>
      <Route path={`${path}/confirmotp`} component={ConfirmOtp}></Route>
      <Route path={`${path}/`} component={PageList}></Route>
    </Switch>
  );
}
