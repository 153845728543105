import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { useTheme } from '@material-ui/core/styles';
import * as memberActions from '../../../redux/member/action'

export default function ResponsiveDialog() {

    const {
        regisbankcode,
        regisbankname,
      } = useSelector((store) => store.member);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const bankcode = [
    {
      bank_code: "bcel",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານການຄ້າຕ່າງປະເທດລາວ (BCEL)",
    },
    {
      bank_code: "ldb",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານພັດທະນາລາວ (LDB)",
    },
    {
      bank_code: "apb",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານສົ່ງເສີມກະສິກຳ (APB)",
    },
    {
      bank_code: "lvb",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານລາວ-ຫວຽດ (LVB)",
    },
    {
      bank_code: "banklao12",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານຮ່ວມພັດທະນາ(JDB)",
    },
    {
      bank_code: "banklao1",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ເອຊີລິດາລາວ ຈຳກັດ",
    },
    {
      bank_code: "banklao2",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ບີໄອຊີ ລາວ ຈຳກັດ",
    },
    {
      bank_code: "banklao3",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ລາວ-ຈີນ",
    },
    {
      bank_code: "banklao4",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ອຸດສາຫະກຳ ແລະ ການຄ້າຈີນ",
    },
    {
      bank_code: "banklao5",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ອິນໂດຈີນ ຈຳກັດ",
    },
    {
      bank_code: "banklao6",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ກະສິກອນໄທ ຈຳກັດ",
    },
    {
      bank_code: "banklao7",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ມາຣູຮານເຈແປນລາວ",
    },
    {
      bank_code: "banklao8",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ພາບລິກ ເບີຣາດ ມະຫາຊົນ",
    },
    {
      bank_code: "banklao9",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ຊາຄອມແບັງ ລາວ",
    },
    {
      bank_code: "banklao10",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ເອັສທີ ຈຳກັດ",
    },
    {
      bank_code: "banklao11",
      bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
      bank_name_th: "ທະນາຄານ ຫວຽດຕິນ ລາວ ຈຳກັດ",
    },
    
    //ທະນາຄານຮ່ວມພັດທະນາ(JDB)
  ]


  const getBank =(bankcode2,banknameid)=> {
    handleClose()
    const bankdetail = {
        bankcode:bankcode2,
        bankname:banknameid,
    }
    const action = memberActions.handlebank(bankdetail)
    dispatch(action)
    //setbankCode(bankcode2)
    //setbankName(banknameid)
  }

  return (
    <div>
      <Button startIcon={<AccountBalanceIcon />} endIcon={<TouchAppIcon />} fullWidth variant="contained" color="primary" href="#contained-buttons" onClick={handleClickOpen}>
      {regisbankcode == false  ? 'เลือกธนาคาร คลิก' : regisbankname} 
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"เลือกธนาคาร"}</DialogTitle>
        <DialogContent>
            {
                bankcode.map((res,index)=> <div key={index} className='linebank' onClick={()=> getBank(res.bank_code,res.bank_name_th)}>{res.bank_name_th}</div> )
            }
       
        </DialogContent>
      
      </Dialog>
    </div>
  );
}
