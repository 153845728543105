import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import BankList from './BankList'

import * as memberActions from "redux/member/action";
import useFetch from "lib/useFetch";
import { LOGO } from 'modules/configApp'

import * as yup from "yup";

//import { yupResolver } from '@hookform/resolvers'
/*
ທະນາຄານ ເອຊີລິດາລາວ ຈຳກັດ
ທະນາຄານ ບີໄອຊີ ລາວ ຈຳກັດ
ທະນາຄານ ລາວ-ຈີນ
ທະນາຄານ ອຸດສາຫະກຳ ແລະ ການຄ້າຈີນ
ທະນາຄານ ອິນໂດຈີນ ຈຳກັດ
ທະນາຄານ ກະສິກອນໄທ ຈຳກັດ
ທະນາຄານ ມາຣູຮານເຈແປນລາວ
ທະນາຄານ ພາບລິກ ເບີຣາດ ມະຫາຊົນ
ທະນາຄານ ຊາຄອມແບັງ ລາວ
ທະນາຄານ ເອັສທີ ຈຳກັດ
ທະນາຄານ ຫວຽດຕິນ ລາວ ຈຳກັດ
*/
const bankcode = [
  {
    bank_code: "bcel",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານການຄ້າຕ່າງປະເທດລາວ (BCEL)",
  },
  {
    bank_code: "ldb",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານພັດທະນາລາວ (LDB)",
  },
  {
    bank_code: "apb",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານສົ່ງເສີມກະສິກຳ (APB)",
  },
  {
    bank_code: "lvb",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານລາວ-ຫວຽດ (LVB)",
  },
  {
    bank_code: "banklao12",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານຮ່ວມພັດທະນາ(JDB)",
  },
  {
    bank_code: "banklao1",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ເອຊີລິດາລາວ ຈຳກັດ",
  },
  {
    bank_code: "banklao2",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ບີໄອຊີ ລາວ ຈຳກັດ",
  },
  {
    bank_code: "banklao3",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ລາວ-ຈີນ",
  },
  {
    bank_code: "banklao4",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ອຸດສາຫະກຳ ແລະ ການຄ້າຈີນ",
  },
  {
    bank_code: "banklao5",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ອິນໂດຈີນ ຈຳກັດ",
  },
  {
    bank_code: "banklao6",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ກະສິກອນໄທ ຈຳກັດ",
  },
  {
    bank_code: "banklao7",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ມາຣູຮານເຈແປນລາວ",
  },
  {
    bank_code: "banklao8",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ພາບລິກ ເບີຣາດ ມະຫາຊົນ",
  },
  {
    bank_code: "banklao9",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ຊາຄອມແບັງ ລາວ",
  },
  {
    bank_code: "banklao10",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ເອັສທີ ຈຳກັດ",
  },
  {
    bank_code: "banklao11",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ທະນາຄານ ຫວຽດຕິນ ລາວ ຈຳກັດ",
  },
  
  //ທະນາຄານຮ່ວມພັດທະນາ(JDB)
]
const bankcode2 = [
  {
    bank_code: "kbank",
    bank_name: "KASIKORNBANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารกสิกรไทย จำกัด (มหาชน)",
  },
  {
    bank_code: "scb",
    bank_name: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.",
    bank_name_th: "ธนาคารไทยพาณิชย์ จำกัด (มหาชน)",
  },
  {
    bank_code: "bbl",
    bank_name: "BANGKOK BANK PUBLIC COMPANY LTD.",
    bank_name_th: "ธนาคารกรุงเทพ จำกัด (มหาชน)",
  },
  {
    bank_code: "ktb",
    bank_name: "KRUNG THAI BANK PUBLIC COMPANY LTD.",
    bank_name_th: "ธนาคารกรุงไทย จำกัด (มหาชน)",
  },
  {
    bank_code: "krungsri",
    bank_name: "BANK OF AYUDHYA",
    bank_name_th: "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
  },
  {
    bank_code: "tmb",
    bank_name: "TMB BANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคาร ทหารไทย",
  },
  {
    bank_code: "gsb",
    bank_name: "GOVERNMENT SAVING BANK",
    bank_name_th: "ธนาคารออมสิน",
  },
  {
    bank_code: "tbank",
    bank_name: "THANACHART BANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารธนชาต",
  },
  {
    bank_code: "baac",
    bank_name: "BANK FOR AGRICULTURE AND AGRICULTURAL CO-OPERATIVES",
    bank_name_th: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
  },
  /*
  {
    bank_code: "BOC",
    bank_name: "Bank of China",
    bank_name_th: "ธนาคารแห่งประเทศจีน",
  },
  */
  {
    bank_code: "cimb",
    bank_name: "CIMB (THAI) PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารซีไอเอ็มบีไทย จำกัด (มหาชน)",
  },
  {
    bank_code: "citi",
    bank_name: "CITIBANK, N.A. (CITI), BANGKOK BRANCH",
    bank_name_th: "ธนาคารซิตี้แบงก์ ",
  },
  {
    bank_code: "db",
    bank_name: "DEUTSCHE BANK AKTIENGESELLSCHAFT (DB)",
    bank_name_th: "ธนาคารดอยช์แบงก์",
  },
  {
    bank_code: "ghb",
    bank_name: "GOVERNMENT HOUSING BANK",
    bank_name_th: "ธนาคารอาคารสงเคราะห์",
  },

  {
    bank_code: "hsbc",
    bank_name: "HONGKONG and SHANGHAI CORPORATION LTD.",
    bank_name_th: "ธนาคารฮ่องกงและเซี่ยงไฮ้แบงกิ้งคอร์ปอเรชั่น จำกัด ",
  },
  {
    bank_code: "icbc",
    bank_name: "INDUSTRIAL AND COMMERCIAL BANK OF CHAINA (THAI)",
    bank_name_th: "ธนาคารสินเอเซีย จำกัด (มหาชน)",
  },
  {
    bank_code: "isbt",
    bank_name: "ISLAMIC BANK OF THAILAND (ISBT)",
    bank_name_th: "ธนาคารอิสลามแห่งประเทศไทย",
  },

  {
    bank_code: "kk",
    bank_name: "KIATNAKIN PHATRA BANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารเกียรตินาคินภัทร",
  },

  {
    bank_code: "lhbank",
    bank_name: "LAND AND HOUSES RETAIL BANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
  },
  {
    bank_code: "mhcb",
    bank_name: "MIZUHO BANK LTD.",
    bank_name_th: "ธนาคารมิซูโฮ",
  },

  {
    bank_code: "scbt",
    bank_name: "TANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LTD.",
    bank_name_th: "ธนาคารสแตนดาร์ดชาร์เตอร์ด",
  },
  /*
  {
    bank_code: "SMBC",
    bank_name: "SUMITOMO MITSUI BANKING CORPORATION (SMBC)",
    bank_name_th: "ธนาคารซูมิโตโม มิตซุย แบงค์กิ้ง คอร์ปอเรชั่น",
  },
  */

  {
    bank_code: "tcrb",
    bank_name: "THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (TCRB)",
    bank_name_th: "ธนาคารไทยเครดิต เพื่อรายย่อย",
  },
  {
    bank_code: "tisco",
    bank_name: "TISCO BANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารทิสโก้ จำกัด",
  },

  {
    bank_code: "uobt",
    bank_name: "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารยูโอบี",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 135,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      //AccBank: yup.string().required(),
      AccName: yup.string().required(),
      AccNo: yup.string().required(),
      AccNo2: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
const dispatch = useDispatch();
const { isLoading, message, notifyacc, regisbankcode } = useSelector(
  (store) => store.member
);

  const submit = ({ AccName, AccNo, AccNo2 }) => {
    const dataSave = {
      mobile: localStorage.getItem("mobile"),
      accbank: regisbankcode,
      accname: AccName,
      accno: AccNo,
    };
   

    if (!regisbankcode) {
      settext("ใส่ข้อมูลธนาคาร");
      setbanknotify(true);
    } else if (AccNo2 !== AccNo) {
      settext("ขออภัยค่ะ คุณยืนยันเลขที่บัญชีไม่ตรงกัน");
      setbanknotify(true);
    } else {
      const action = memberActions.ConfirmRegister({ ...dataSave, ...props });
      dispatch(action);
    }
  };

  useEffect(() => {}, []);
 

  const [bank, setbank] = React.useState("");
  const [banknotify, setbanknotify] = React.useState(false);
  const [text, settext] = React.useState("");

  const onChange = (event) => {
    setbank(event.target.value);
  };

  //const bankList = useFetch(API_URL + "/bank/GetList", {}, "post");

  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setbanknotify(false);
    const action = memberActions.clearNotify();
    dispatch(action);
  };

  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);

  const GoPage2 =()=> {
    history.push("/contact")
  }
  return (
    <>
       <div style={{ height: 125, paddingTop:7 }} className="mainlogin">
        <img src={LOGO} height={100}></img>
      </div>
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div className="bgconh1">
          <Typography
            gutterBottom
            variant="h5"
            style={{ textAlign: "center", color: "#000" }}
            component="h3"
          >
            ใส่ข้อมูลธนาคาร
          </Typography>
          {/*
 <Typography
              gutterBottom
              variant="h5"
              style={{ textAlign: "center", color: "#fff" }}
              component="h3"
            >
              {t("hregister.label")}
            </Typography>
              */}
          <div style={{ height: 20 }}></div>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(submit)}
          >
            <BankList />
            {
              /*
 <select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              fullWidth
              ref={register}
              value={bank}
              name="AccBank"
              class="form__input"
              onChange={onChange}
            >
              <option value="" disabled selected>
              ເລືອກທະນາຄານ
              </option>

              {bankcode.map((res) => (
                <option
                  value={res.bank_code}
                  key={res.bank_code}
                  //style={`background-image:url(/images/bankhome/${res.bank_code}.png)`}
                >
                  {res.bank_name_th}
                </option>
              ))}
            </select>
              */
            }
           
            <div style={{ height: 20 }}></div>
            <input
              ref={register}
              type="text"
              id="AccName"
              name="AccName"
              class="form__input"
              placeholder="ใส่ชื่อบัญชี"
              autocomplete="off"
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />
            <div style={{ height: 20 }}></div>
            <input
              ref={register}
              type="text"
              id="AccNo"
              name="AccNo"
              class="form__input"
              placeholder="ใส่เลขบัญชี (ห้ามใส่ -)"
              autocomplete="off"
              onKeyPress={function (event) {
                var inputValue = event.charCode;
                if (inputValue < 48 || inputValue > 57) {
                  event.preventDefault();
                }
              }}
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />
            <div style={{ height: 20 }}></div>
            <input
              ref={register}
              type="text"
              id="AccNo2"
              name="AccNo2"
              class="form__input"
              placeholder="ยืนยันเลขบัญชี"
              autocomplete="off"
              onKeyPress={function (event) {
                var inputValue = event.charCode;
                if (inputValue < 48 || inputValue > 57) {
                  event.preventDefault();
                }
              }}
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />

            {message === 3 && (
              <Alert variant="outlined" severity="error">
                ข้อมูลสมาชิกไม่ถูกต้อง กรุณาป้อนใหม่อีกครั้ง !!
              </Alert>
            )}
            <div style={{ height: 20 }}></div>

            <div className="btnsubmit" onClick={submit2}>
              {!isLoading && "ยืนยัน"}
              {isLoading && (
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
            <button type="submit" ref={btnSubmit} className="btnnone"></button>
            <div style={{ height: 50 }}></div>

            <div style={{ height: 10 }}></div>
            <div className="title2">
             
             </div>
           
          </form>
          <div style={{ height: 90 }}></div>
        </div>

        {/*
        isLoading && (
          <div className={classes.rootLoading}>
            <CircularProgress color="secondary" />
          </div>
        )
        */}
      </Container>
      <Snackbar
        open={banknotify}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose2}
      >
        <Alert onClose={handleClose2} severity="error">
          {text}
        </Alert>
      </Snackbar>
      <Snackbar
        open={notifyacc}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose2}
      >
        <Alert onClose={handleClose2} severity="error">
          ขออภัยข้อมูลบัญชีซ้ำกัน กรุณาป้อนมใหม่
        </Alert>
      </Snackbar>
    </>
  );
}
