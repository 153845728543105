import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Snackbar from "@material-ui/core/Snackbar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import DialogContent from "@material-ui/core/DialogContent";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { red } from "@material-ui/core/colors";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MovingText from "react-moving-text";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CircularProgress from "@material-ui/core/CircularProgress";

import TableCell from "@material-ui/core/TableCell";

import TableRow from "@material-ui/core/TableRow";

import { Alert } from "@material-ui/lab";

import Dialog from "@material-ui/core/Dialog";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";


import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import LinearProgress from "@material-ui/core/LinearProgress";


import Avatar from "@material-ui/core/Avatar";
import * as memberActions from "redux/member/action";
import * as yup from "yup";
import moment from "moment";

import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

import * as uiActions from "redux/ui/action";
import useFetch from "lib/useFetch";
import { API_URL, addCommas } from "modules/configApp";
import { useState } from "react";

const QRCode = require("qrcode.react");


export function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    //console.log('Error: ', error);
  };
}

export function decodeBase64Image(base64Str) {
  var matches = base64Str.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
  var image = {};
  if (!matches || matches.length !== 3) {
    throw new Error("Invalid base64 string");
  }

  image.type = matches[1];
  image.data = matches[2];

  return image;
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ color: "#9f1f14" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eaf4fc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  img: {
    flexGrow: 1,
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  rootbg: {
    backgroundColor: "#fff",
  },
  rootbody: {
    background: "#fff",
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  btn: {
    color: "#e08c09",
    borderColor: "#45351d",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#3a78c2",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#fff",
    fontSize: 16,
  },
  formalert: {
    background: "transparent",
    fontSize: 16,
    color: "#000",
  },
  formalertbank: {
    fontSize: 16,
    backgroundColor: "#ddd",
  },
  rootbuttom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#000",
    opacity: 0.9,
  },
  rootbuttomload: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    opacity: 0.9,
  },
  confirm: {
    backgroundColor: "#fff",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleC = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [credit, setCredit] = React.useState(0);
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      credit: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  const dispatch = useDispatch();

  /*
  useEffect(() => {
    const action = memberActions.GetBooking();
    dispatch(action);
  }, [dispatch]);
*/

  const {
    isLoading,
    item,
    items,
    wait,
    depositcomplete,
    showbox,
    showrun,
    bigpay,
  } = useSelector((store) => store.member);
  const { notify } = useSelector((store) => store.ui);

  const [values, setValues] = React.useState(null);

  useEffect(() => {
    /*
    if (values === null) {
      const action = memberActions.GetBooking();
      dispatch(action);
    }
*/
    //  console.log("values", values);

    if (showbox) {
      const mtimestamp = item?.mtimestamp
        ? item.mtimestamp
        : localStorage.getItem("mtimestamp");
      const updatedAt = item?.updatedAt
        ? item.updatedAt
        : localStorage.getItem("updatedAt");

      const b = moment(updatedAt);
      const a = moment(moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss"));
      const nowdate = a.diff(b, "minutes");

      const cTime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
      const currentTime = moment(cTime).format("X");

      const diffTime = mtimestamp - currentTime;
      const duration = moment.duration(diffTime * 1000, "milliseconds");
      const interval = 1000;
      const durationall = moment.duration(duration - interval, "milliseconds");

      //const txtH = `${durationall.hours()}`;
      const txtM = `${durationall.minutes()}`;
      const txtS = `${durationall.seconds()}`;

      //const fullH = txtH.length === 1 ? `0${txtH}` : txtH;
      const fullM = txtM.length === 1 ? `0${txtM}` : txtM;
      const fullS = txtS.length === 1 ? `0${txtS}` : txtS;
      const fulltime = `${fullM}:${fullS}`;
      //console.log(values);
      if (nowdate < 10) {
        //console.log("values", values);
        setTimeout(() => {
          setValues({
            ...values,
            timelong: fulltime,
          });
        }, 1000);
      } else {
        clearTimeout();
        setsubmitdisabled(false);
        const action = memberActions.GetBooking();
        dispatch(action);
      }

      return () => clearTimeout();
    }
  }, [values, showbox]);

  useEffect(() => {
   
  }, []);

  const [progress, setProgress] = React.useState(0);

  const [nstart, setnstart] = React.useState(false);

  const CancelBooking = () => {
    const action = memberActions.CancelBooking();
    dispatch(action);
  };

  const DelBooking = () => {
    const action = memberActions.DelBooking();
    dispatch(action);
  };


  
const [depositerr, setdepositerr] = React.useState(false);

  const handleClosedepositerr = () => {
    setdepositerr(false);
  };

  const submit = ({ credit }) => {
   
      if (parseFloat(credit) < parseFloat(items.agent.mindeposit)) {
        setdepositerr(true);
      } else {
         const dataSave = {
      credit,
      promotion: value,
    };
    setsubmitdisabled(true);
    setValues(1);
    const action = memberActions.Booking({ ...dataSave, ...props });
    dispatch(action);
      }
    
  };

  const submit222 = ({ credit }) => {
    const dataSave = {
      credit,
      promotion: value,
    };
    setsubmitdisabled(true);
    setValues(1);
    const action = memberActions.Booking({ ...dataSave, ...props });
    dispatch(action);
  };

  const [open, setOpen] = React.useState(false);
  const [openp, setOpenp] = React.useState(false);
  const [openc, setOpenc] = React.useState(false);
  const [submitdisabled, setsubmitdisabled] = React.useState(false);
  const handleClosewait = () => {
    //setshownot(false);
    setnstart(false);
    const action = uiActions.clearNotify();
    dispatch(action);
    const actionmember = memberActions.clearNotify();
    dispatch(actionmember);
    props.history.push("/dashboard");
  };
  const handleClosesuccess = () => {
    //setshownot(false);
    const action = uiActions.clearNotify();
    dispatch(action);
    const actionmember = memberActions.clearNotify();
    dispatch(actionmember);
    props.history.push("/dashboard");
  };

  const handleClose = () => {
    //setOpen(false);
    handleClickOpenp(true);
  };
  const handleCloseC = () => {
    //setOpen(false);
    setOpenc(false);
  };

  const handleClickOpenp = () => {
    setOpenp(true);
  };

  const handleClosep = () => {
    setOpenp(false);
  };
  const handleCloseYesp = () => {
    if ((nstart || wait) && !depositcomplete && !showrun) {
      setopenerror(true);
      handleCloseNotp();
    } else if (showrun) {
      setopenerror(true);
      handleCloseNotp();
    } else {
      setOpenp(false);
      setOpen(false);
      setsubmitdisabled(false);
      clearInterval();
      DelBooking();
    }
  };
  const handleCloseYesc = () => {
    setOpenc(false);
    submitBook();
  };

  const handleCloseNotp = () => {
    setOpenp(false);
  };

  const openBank = (link) => {
    setOpens(true);
    //window.open(link, "_blank");
  };

  const [openerror2, setopenerror2] = useState(false)
  const submitBookLao = () => {
    //clearTimeout();
    //setValues(1);
    if(!slipexten) {
      //alert('กรุณาแนบสลิปโอนด้วยค่ะ')
      setopenerror2(true)
    } else {
      setnstart(true);
      const action = memberActions.BookingConfirm({ ...props, slip:slipexten,slipphoto:slipphoto });
      dispatch(action);
    }
   
  };

  const submitBook = () => {
    setnstart(true);
      const action = memberActions.BookingConfirmNew({ ...props, slip:slipexten,slipphoto:slipphoto });
      dispatch(action);
   
  };

  const [opens, setOpens] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpens(true);
    setScroll(scrollType);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [value, setValue] = React.useState("1");

  const handleChange = (event) => {
    setValue(event.target.value);

    const data = promotion.item.find((x) => x._id == event.target.value);
    if (data) {
      const { pbonus, pmax, pmin, pturn } = data;
      let newbunus = (parseFloat(credit) * parseFloat(pbonus)) / 100;
      if (parseFloat(newbunus) > parseFloat(pmax)) {
        newbunus = pmax;
      }
      // เช็คว่าฝากมากกว่าเงื่อนไขหรือไม่
      if (parseFloat(credit) >= parseFloat(pmin)) {
        const turnover =
          (parseFloat(newbunus) + parseFloat(credit)) * parseFloat(pturn);
        setturnover(turnover);
        setbonus(newbunus);
        setpbonus(pbonus);
        setpmax(pmax);
        setpturn(pturn);
        //parseFloat(newbunus);
        // turnover;
      } else {
        setturnover(0);
        setbonus(0);
      }
    }

    //setCreditNew(credit);
  };
  const [newmember, setnewmember] = useState(0);
  const handleChangeNew = (event) => {
    setValue(event.target.value);
    setnewmember(1);

    if (1 == 1) {
      const { pbonus, pmax, pmin, pturn } = checkpromotion.datapromotion;
      let newbunus = (parseFloat(credit) * parseFloat(pbonus)) / 100;
      if (parseFloat(newbunus) > parseFloat(pmax)) {
        newbunus = pmax;
      }
      // เช็คว่าฝากมากกว่าเงื่อนไขหรือไม่
      if (parseFloat(credit) >= parseFloat(pmin)) {
        const turnover =
          (parseFloat(newbunus) + parseFloat(credit)) * parseFloat(pturn);
        setturnover(turnover);
        setbonus(newbunus);
        setpbonus(pbonus);
        setpmax(pmax);
        setpturn(pturn);
        //parseFloat(newbunus);
        // turnover;
      } else {
        setturnover(0);
        setbonus(0);
      }
    }

    //setCreditNew(credit);
  };

  const promotion = useFetch(API_URL + "/promotion/1/deposit", {}, "get");
  const checkpromotion = useFetch(
    API_URL + "/booking/1/checkpromotion",
    {},
    "get"
  );

  const [turnover, setturnover] = React.useState(0);
  const [bonus, setbonus] = React.useState(0);
  const [pbonus, setpbonus] = React.useState(0);
  const [pmax, setpmax] = React.useState(0);
  const [pturn, setpturn] = React.useState(0);
  const btnSubmit = React.useRef(null);
  const setCreditNew = (credit) => {
    let nvalue = value;
    if (checkpromotion?.turnoverstatus == 1) {
      nvalue = checkpromotion?.promotionid;
    }
    let data = null;
    if (newmember == 1) {
      data = checkpromotion?.datapromotion;
    } else {
      data = promotion.item.find((x) => x._id == nvalue);
    }

    if (data) {
      const { pbonus, pmax, pmin, pturn } = data;
      let newbunus = (parseFloat(credit) * parseFloat(pbonus)) / 100;
      if (parseFloat(newbunus) > parseFloat(pmax)) {
        newbunus = pmax;
      }
      // เช็คว่าฝากมากกว่าเงื่อนไขหรือไม่
      if (parseFloat(credit) >= parseFloat(pmin)) {
        const turnover =
          (parseFloat(newbunus) + parseFloat(credit)) * parseFloat(pturn);
        setturnover(turnover);
        setbonus(newbunus);
        setpbonus(pbonus);
        setpmax(pmax);
        setpturn(pturn);
        //parseFloat(newbunus);
        // turnover;
      } else {
        setturnover(0);
        setbonus(0);
      }
    }
    //console.log("promotion", aaa);
    setCredit(credit);
  };

  const setCreditNew2 = (credit) => {
    if (credit.target.value > 0) {
      setCreditNew(credit.target.value);
    } else {
      setCreditNew(0);
    }
  };

  const [copied, setcopied] = React.useState(false);

  const setCopyall = () => {
    setcopied(true);
  };
  //console.log("checkpromotion", checkpromotion);
  const goBack = (path) => {
    props.history.push(path);
  };

  const submit2 = () => {
    btnSubmit.current.click();
  };

  const [openerror, setopenerror] = React.useState(false);

  const handleCloseState = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenerror(false);
  };


  const [file, setfile] = React.useState(null);
  const [filename, setfilename] = React.useState(null);
  const [slipexten, setslipexten] = React.useState(null);
  const [slipphoto, setslipphoto] = React.useState(null);

  const handleChangePhoto = (event) => {
    if (event.target.files[0]) {
      setfilename(event.target.files[0]);

      const path = event.target.files[0];

      getBase64(path, (result) => {
        const { data: dataimg, type } = decodeBase64Image(result);

        setslipexten(`data:${type};base64`)
        setslipphoto(dataimg)
        /*
        const listnew = [
          {
            memberexten: `data:${type};base64`,
            memberphoto: dataimg,
          },
        ];
        */


        setfile(null);

      });
    }
  };

  const btnphoto = React.useRef(null);

  const onslip = () => {
    btnphoto.current.click();
  }
  return (
    <>
      <div className="headpaddingtop"></div>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/")}
          className="btnback"
        >
          ย้อนกลับ
        </Button>
      </Container>
      {!nstart && !wait && (
        <Container
          maxWidth="md"
          style={{
            paddingTop: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Card className={classes.root}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h5"
                className="headtitle"
              >
                ฝากเงิน
              </Typography>
             
              <div style={{ height: 10 }}></div>
                <Alert severity="warning">
                ฝากเงิน
ได้ในเวลาทำการ
(08.30 - 01.00) น.
                </Alert>
              <div style={{ height: 10 }}></div>
              
              <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(submit)}
              >
                <input
                  ref={register}
                  type="text"
                  id="credit"
                  name="credit"
                  class="form__input"
                  placeholder={`ใส่ยอดเงินขั้นต่ำ ${
                    items?.agent?.mindeposit
                  } บาท`}
                  value={credit ? credit : null}
                  onChange={setCreditNew2}
                />

                <Grid container spacing={1} style={{ paddingTop: 20 }}>
                  <Grid item xs={4} sm={4}>
                    <div className="btnnew" onClick={() => setCreditNew(100)}>
                      100
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div className="btnnew" onClick={() => setCreditNew(500)}>
                      500
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div className="btnnew" onClick={() => setCreditNew(1000)}>
                      1,000
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div className="btnnew" onClick={() => setCreditNew(3000)}>
                      3,000
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div className="btnnew" onClick={() => setCreditNew(5000)}>
                      5,000
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div className="btnnew" onClick={() => setCreditNew(10000)}>
                      10,000
                    </div>
                  </Grid>
                </Grid>
                <div style={{ height: 10 }}></div>
               
                <div style={{ height: 2 }}></div>

                <div style={{ height: 10 }}></div>
                
                <div className="btnsubmit" onClick={submit2}>
                ยืนยัน
                </div>
               
                <button
                  type="submit"
                  ref={btnSubmit}
                  className="btnnone"
                ></button>
              </form>
              <div style={{ height: 100 }}></div>
            </CardContent>
          </Card>
          {isLoading && (
            <div className={classes.rootLoading}>
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </Container>
      )}

      <Dialog
        open={bigpay}
        onClose={handleClosewait}
        //aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleC id="alert-dialog-title" onClose={handleClosewait}>
          {"ขออภัยค่ะ"}
        </DialogTitleC>
        <DialogContent style={{ textAlign: "center" }}>
          <div>
            {/*<img src="/images/compelet.png" height="50"></img>*/}
            <img src="/images/error.png" height="50"></img>
          </div>
          {/*<div>ยอดเงินของคุณได้รับการยืนยันแล้วค่ะ</div>*/}
          <div>ขณะนี้ธนาคารฝากเงินมีปัญหา กรุณาลองใหม่อีกครั้ง</div>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}></DialogActions>
      </Dialog>

      <Dialog
        open={notify?.error}
        //open={true}
        onClose={handleClosewait}
        //aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleC id="alert-dialog-title" onClose={handleClosewait}>
          {"ขออภัยค่ะ"}
        </DialogTitleC>
        <DialogContent style={{ textAlign: "center" }}>
          {/*<div>ยอดเงินของคุณได้รับการยืนยันแล้วค่ะ</div>*/}

          <MovingText
            type="popIn"
            duration="1000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="infinite"
            fillMode="none"
          >
            <img src="/images/error.png" height="50"></img>
          </MovingText>
          <div style={{ height: 10 }}></div>
          <Alert color="error" style={{ fontSize: 18 }}>
            ไม่พบยอดเงิน !! <br></br>กรุณาโอนเงินก่อน กดยืนยัน นะคะ
          </Alert>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}></DialogActions>
      </Dialog>

      <Dialog
        open={notify?.success}
        //open={true}
        onClose={handleClosesuccess}
        //aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleC id="alert-dialog-title" onClose={handleClosesuccess}>
          {"ยินดีด้วยค่ะ"}
        </DialogTitleC>
        <DialogContent style={{ textAlign: "center" }}>
          <MovingText
            type="popIn"
            duration="1000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="infinite"
            fillMode="none"
          >
            <img src="/images/compelet.png" height="50"></img>
          </MovingText>
          <div style={{ height: 10 }}></div>
          <Alert color="success" style={{ fontSize: 18 }}>
            ยอดเงินของคุณได้รับการยืนยันแล้วค่ะ
          </Alert>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}></DialogActions>
      </Dialog>

      <Dialog
        open={depositcomplete}
        onClose={handleClosesuccess}
        //aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleC id="alert-dialog-title" onClose={handleClosesuccess}>
          {"ยินดีด้วยค่ะ"}
        </DialogTitleC>
        <DialogContent style={{ textAlign: "center" }}>
          <div>
            <img src="/images/compelet.png" height="50"></img>
          </div>
          <div>ยอดเงินของคุณได้รับการยืนยันแล้วค่ะ</div>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}></DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={showbox}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h7" className={classes.title}>
            กรุณาโอนด้วยบัญชีที่ลงทะเบียนแล้วเท่านั้น
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.rootbg}>
          <Container
            maxWidth="sm"
            style={{
              paddingTop: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Card className={classes.rootbody}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  style={{ textAlign: "center", color: "#265d93" }}
                >
                  ฝากเงิน
                </Typography>

                <form
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit(submit)}
                >
                  {/*
 <input
                placeholder="กรอกเบอร์มือถือ (ไม่ต้องใส่ -)"
                className={classes.input}
              ></input>
                */}
                  <Alert
                    severity="warning"
                    className={classes.formalert}
                    icon={<AccessTimeIcon />}
                  >
                    กรุณาโอนภายใน
                    <font style={{ fontSize: 20, color: "red" }}>
                      &nbsp;{values?.timelong}&nbsp;
                    </font>{" "}
                    นาที
                  </Alert>
                  {item?.qrcode && (
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ textAlign: "center" }}
                      >
                        <div className="mqrcode">สแกนจ่ายผ่าน QRCode</div>
                        <div>
                          <QRCode value={item?.qrcode} size={250} />
                        </div>
                      </Grid>
                    </Grid>
                  )}{" "}
                  {!item?.qrcode && (
                    <>
                      <div className="boxacc">
                        <div className="boxaccleft"></div>
                        <div className="boxaccright">
                          <Avatar>
                            <img
                              src={`/images/bank/${item?.bankname}.png`}
                              height="50"
                            ></img>
                          </Avatar>
                        </div>
                        <div className="boxaccleft">ธนาคาร</div>
                        <div className="boxaccright">{item?.name} </div>
                        <div className="boxaccleft">ชื่อบัญชี</div>
                        <div className="boxaccright">{item?.accname}</div>
                        <div className="boxaccleft">เลขบัญชี</div>
                        <div className="boxaccright">
                          {item?.accno}{" "}
                          <CopyToClipboard
                            text={item?.accno}
                            onCopy={() => setCopyall()}
                          >
                            <Button
                              variant="contained"
                              color="default"
                              style={{ backgroundColor: "#e8a608" }}
                            >
                              คัดลอก
                            </Button>
                          </CopyToClipboard>
                        </div>
                        <div className="boxaccleft">ยอดโอน</div>
                        <div className="boxaccright">
                          {parseFloat(item?.amount).toFixed(0)} บาท
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      height: 10,
                    }}
                  ></div>
                  <div style={{ height: 3 }}></div>
                  {!item?.qrcode && (
                    <div>
                          <Alert severity="warning">
                      <font style={{ fontSize: 16 }}>
                       
                        <b style={{ color: "green" }}>
                          {" "}
                          {item?.bankname === 'bcel' ? <span>เมื่อโอนแล้ว โปรดแนบสลิปการโอนเงิน <Button onClick={()=>onslip()}  variant="contained"
                              color="default"
                              style={{ backgroundColor: "#e8a608" }}>คลิกแนบไฟล์</Button> เสร็จแล้วกดปุ่ม ยืนยันด้านล่าง</span>:'เมื่อโอนแล้วกรุณากด ยืนยัน ด้านล่าง'}
                          {}
                        </b>{" "}
                        
                      </font>
                    </Alert>
                    <div style={{ height: 10 }}></div>
                    {
                      slipexten && <img
                      src={`${slipexten},${slipphoto}`}
                      width={250}
                    ></img>
                    }
                    
                    </div>
                  )}
                  <input
                  type="file"
                  name="file"
                  id="file"
                  class="inputfile"
                  ref={btnphoto}
                  onChange={(e) => handleChangePhoto(e)}
                />
                  <div style={{ height: 10 }}></div>
                  {(nstart || wait) && !depositcomplete && !showrun ? (
                    <div className="btnsubmit" onClick={submitBook}>
                       รอระบบยืนยันใน 1 นาที
หากยอดเงินไม่เข้ากรุณาติดต่อเจ้าหน้าที่
{
/*
 <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
*/
                      }
                    </div>
                  ) : showrun ? (
                    <div className="btnsubmit">
                     รอยืนยันใน 1 นาที
หากยอดเงินไม่เข้ากรุณาติดต่อเจ้าหน้าที่
                      {
/*
 <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
*/
                      }
                     
                    </div>
                  ) : (
                    !item?.qrcode &&
                    item.bankname != "bigpay" && (
                      <div className="btnsubmit" onClick={submitBook}>
                        ยืนยัน
                      </div>
                    )
                  )}
                  <div style={{ height: 50 }}></div>
                </form>
              </CardContent>
            </Card>
            {isLoading && (
              <div className={classes.rootLoading}>
                <CircularProgress color="secondary" />
              </div>
            )}
          </Container>
        </div>
      </Dialog>

      <Dialog
        open={openp}
        onClose={handleClosep}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        ต้องการยกเลิกฝาก
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={handleCloseYesp}
            color="primary"
            fullWidth
            variant="contained"
          >
            ใช่
          </Button>
          <Button
            onClick={handleCloseNotp}
            color="primary"
            variant="outlined"
            fullWidth
          >
            ไม่ใช่
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openc}
        onClose={handleCloseC}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("areyoursure.label")}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={handleCloseYesc}
            color="secondary"
            fullWidth
            variant="contained"
          >
            {t("btn_yes.label")}
          </Button>
          <Button
            onClick={handleCloseC}
            color="primary"
            variant="outlined"
            fullWidth
          >
            {t("btn_no.label")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openerror2}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setopenerror2(false)}
      >
        <Alert onClose={() => setopenerror2(false)} severity="error">
        กรุณาแนบสลิปโอนด้วยค่ะ
        </Alert>
      </Snackbar>

      <Snackbar
        open={openerror}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCloseState}
      >
        <Alert onClose={handleCloseState} severity="error">
          ขออภัยค่ะ ไม่สามารถยกเลิกรายการฝากได้ จนกว่าจะหมดเวลา
        </Alert>
      </Snackbar>

      <Dialog
        open={depositerr}
        onClose={handleClosedepositerr}
        //aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleC id="alert-dialog-title" onClose={handleClosedepositerr}>
          {"ใส่จำนวนเงิน"}
        </DialogTitleC>
        <DialogContent style={{ textAlign: "center" }}>
          <div>
            {/*<img src="/images/compelet.png" height="50"></img>*/}
            <img src="/images/error.png" height="50"></img>
          </div>
          {/*<div>ยอดเงินของคุณได้รับการยืนยันแล้วค่ะ</div>*/}
          <div>ใส่ยอดเงินขั้นต่ำ {items?.agent?.mindeposit} บาท</div>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}></DialogActions>
      </Dialog>
    </>
  );
}
