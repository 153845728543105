import {  SUBDOC_REQUEST,SUBDOC_SUCCESS } from './action'

const initailState = {
    isLoading:false,
    items:{}
}

export default function (state = initailState,action){
    switch (action.type) {
        case SUBDOC_REQUEST:
            return {
                ...state,isLoading:true,items:{}
            }
        case SUBDOC_SUCCESS:
                return {
                    ...state,isLoading:false,items:action.payload
                }
        default:
            return state
    }
}